import { Card, CardHeader, Grid, IconButton, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Popover from "react-bootstrap/Popover";
import { DebounceInput } from 'react-debounce-input';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setLoadingStatus } from '../../redux/actions/loading';
import S3Service from '../../services/S3Service';
import { capitalizeFirstLetter, convertUnderscoreSeparateLetter, formatBytes } from '../../utils/functions';
import Auth from '../../utils/auth';
const ListFiles = ({ folderProps }) => {
    // let { folder } = useParams();
    const [searchName, setSearchName] = useState("");
    const { folder, setFolder } = folderProps
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.loading.status);
    const [listFiles, setListFiles] = useState([]);
    const [filterFiles, setFilterFiles] = useState([])
    let history = useHistory();

    const fetchFolderList = (folder) => {
        dispatch(setLoadingStatus(true));
        S3Service.getUploadFiles(folder).then((res) => {
            dispatch(setLoadingStatus(false));
            setListFiles(res.data.data);
            setFilterFiles(res.data.data)
        }).catch(err => {
            dispatch(setLoadingStatus(false));
            history.push('/upload-file-manager')
        })
    };

    useEffect(() => {
        if (folder) {
            fetchFolderList(folder)
        }
    }, [folder]);

    const getLogo = (name) => {
        const type = name.split('.').pop().toLowerCase();

        switch (type) {
            case 'pdf':
            case 'docx':
            case 'doc':
                return '/icons/upload-pdf.svg'
            case 'jpg':
            case 'jpeg':
            case 'png':
                return '/icons/upload-img.svg'
            default:
                return '/icons/upload-default.svg'
        }
    }

    const handleSearch = (search) => {
        setSearchName(search);
        setFilterFiles(() => listFiles.filter(file => file.name.toLowerCase().includes(search.toLowerCase())))
    }
    const fetchPdf = async (url) => {
        const token = Auth.getInstance().getAuthToken(); // Replace with your actual token
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                // Create a Blob from the response and open the PDF in a new tab
                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.target = '_blank';  // Open in a new tab
                link.click();
                // window.open(blob)
            } else {
                console.error('Failed to fetch the PDF');
            }
        } catch (error) {
            console.error('Error fetching the PDF:', error);
        }
    };


    return (
        <div className="content">
            <ul className="uk-breadcrumb m-0">
                <li>
                    <a onClick={() => setFolder(null)}>All Folders</a>
                </li>
                {folder.split('/').map((fl, i) => <li className="uk-">
                    <a onClick={() => setFolder(folder.split('/').filter((fl, index) => index <= i).join('/'))}>{convertUnderscoreSeparateLetter(fl, "-")}</a>
                </li>)}

            </ul>
            <div className='d-flex justify-content-between w-100'>
                <h1 className="d-flex align-items-center gap-1">Upload File Manager</h1>
                <div className="list-page-search-container search-block">
                    <form className="uk-search uk-search-default">
                        <DebounceInput
                            className="uk-search-input"
                            type="search"
                            placeholder="Search By Name"
                            onChange={(e) => handleSearch(e.target.value)}
                            value={searchName}
                            debounceTimeout={500}
                            minLength={1}
                        />
                        {!searchName ? (
                            <span className="bd-form-icon">
                                <i className="fa fa-search"></i>
                            </span>
                        ) : (
                            <span className="bd-form-icon" onClick={() => handleSearch('')}>
                                <i className="fa fa-times"></i>
                            </span>
                        )}
                    </form>
                </div>
            </div>
            {!filterFiles.length && isLoading
                ? <div className="text-center" style={{ fontSize: "18px" }}>Fetching Library...</div>
                :
                filterFiles.length ? <Grid container spacing={{ xs: 2, md: 3 }} className='pt-4'>
                    {filterFiles.map((file, index) => {
                        // console.log(file);

                        if (file.type === 'file') {
                            return (
                                <Grid item xs={1} sm={3} md={1} key={index} className='text-center p-2'><div className='text-center' onClick={() => {
                                    fetchPdf(`${process.env.REACT_APP_UPLOADS_URL}/${file?.path.split('/').filter((fl, index) => index > 0).join('/')}`)
                                    // /window.open(`${process.env.REACT_APP_UPLOADS_URL}/${file?.path.split('/').filter((fl, index) => index > 0).join('/')}`)
                                }} >
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="bottom"
                                        overlay={
                                            <Popover id={`popover-positioned-`}>
                                                <Popover.Content>
                                                    <div>size:  {file?.size ? formatBytes(file?.size) : "N/A"}</div>
                                                    <div style={{ fontSize: "12px" }} ><Moment date={file.modifiedDate} format="MM/DD/YYYY hh:mm A" /></div>
                                                </Popover.Content>
                                            </Popover>
                                        }
                                    >
                                        <div className='uk-link d-flex align-items-center gap-2 flex-column'  >
                                            <img src={getLogo(file?.name)} style={{ width: "60px", height: "60px" }} className='py-2' />
                                            <div className='mx-1' style={{ fontSize: "12px", overflowWrap: 'anywhere' }} >{file?.name || "N/A"}</div>
                                        </div>
                                    </OverlayTrigger>
                                </div>
                                </Grid>
                            )
                        } else {
                            return (
                                <Grid item xs={3} key={index} >
                                    <Card sx={{ cursor: 'pointer' }} onClick={() => {
                                        setFolder((folder) => `${folder}/${file.name}`)
                                    }} >
                                        <CardHeader
                                            avatar={
                                                <img
                                                    src={"/icons/upload-folder.svg"}
                                                    className=""
                                                    alt=""
                                                    style={{ width: "30px", height: "30px" }}
                                                />
                                            }
                                            action={
                                                <IconButton aria-label="settings" sx={{ pointerEvents: "none" }}>
                                                    {file.totalFiles}
                                                </IconButton>
                                            }
                                            title={capitalizeFirstLetter(file.name, '-')}
                                        />
                                    </Card>
                                </Grid>
                            )
                        }

                    })}
                </Grid> : <div className='text-center text-large' style={{ fontSize: "18px" }}>No Files To Display</div>
            }
        </div>
    )
}

export default ListFiles