import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Collapse, Divider, FormControl, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, TextField, ThemeProvider, Tooltip, Typography, tooltipClasses } from '@mui/material';

import { getTransactionEdocById, getTransactionEsignById, getTransactionInitialById } from '../../../../redux/actions/transaction';
import { LEGAL_AND_OR_ASSIGN, LEGAL_OR_ASSIGN, LEGAL_OWNER } from '../../../../utils/signer';

import './formDataSidebar.css';
import clsx from "clsx";
import FormDataDrawer from './accordions/FormDataDrawer';
import SurveyWaiverFormDataModal from '../../../common/SurveyWaiverFormDataModal';
import Auth from '../../../../utils/auth';
import { faCalendarDay, faFileSignature, faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { dateFormatter } from '../../../../utils/common';

const titles = [
    "Acceptance of Vessel",
    "Conditional Acceptance of Vessel",
    "Conditional Acceptance with Survey Allowance of Vessel",
    "Rejection of Vessel",
    "YBAA Amendment to PSA (Short Form)",
];

const customTooltip = {
    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
    {
        marginLeft: '-65px',
    },
}
const customTooltipSmall = {
    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
    {
        marginLeft: '-90px',
    },
}
const customTooltipLarge = {
    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
    {
        marginLeft: '-40px',
    },
}

const FormDataSideBar = ({ leftSideBarRef = {}, transactionId, documentId, sliderFor, reloadPdf = null, participantForm = null, onPanelClose = null, menuType, isPdfEdit, signatureAssigner, setSignatureAssigner, style, theme, signatureRoles, formFieldsProps = {}, documentName, resetModal = () => { } }) => {
    const dispatch = useDispatch();

    const dataRequiredFields = useSelector((state) => state.transactions.requiredFields);
    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const loggedUser = Auth?.getInstance()?.getUserData(); //Get logged User
    const loginEmail = loggedUser?.email; //Get logged User email
    const dataDeal = dataTransaction || {};
    const dataEdocuments = dataDeal.t_edocuments || [];
    const dataExclusion = dataDeal.t_purchase_exclusion || [];
    const dataAddendum = dataDeal.t_purchase_addendum || [];
    const dataTradeIn = dataDeal.t_trade_in_vessel || {};
    const dataTerms = dataDeal?.t_terms || {};

    const dataSellerClosing = dataDeal.t_seller_closing;
    const dataBuyerClosing = dataDeal.t_buyer_closing;
    const pdfEditordata = useSelector((state) => state);
    // const toys = dataDeal.t_toys || [];
    // const tailers = dataDeal.t_trailer || [];
    // const additional_price = toys.reduce((total, toy) => total + toy.item_value, 0);

    const tradeRequiredFields = {
        hull_number: "Hull No.",
        length: "Length",
        make: "Make",
        model: "Model",
        boat_type: "Boat Type",
        year: "Year",
        accept_reject_date: "Trade Vessel Accept/Reject Date",
        delivery_location: "Trade Vessel Delivery Location",
        allowance: "Allowance",
        exclusion_list: "Trade Vessel Exclusion List",
        doc_no: "Please input at least one: Doc No., Reg No., or Title No.",
        engine_make: "Engine Make",
        engine_model: "Engine Model",
        engine_hp: "Engine HP"
    }

    // const tradeInVesselDocument = dataEdocuments.find(doc => doc.task_type == 'trade-in-vessel') || null;
    const surveyWaiverDocument = dataEdocuments.find(doc => doc.task_type == 'survey-waiver') || null;

    const buyerClosingDcument = dataEdocuments.find(doc => doc.task_type == 'buyer-closing-statement') || null;
    const sellerClosingDcument = (dataEdocuments.find(doc => doc.task_type == 'seller-closing-statement') || dataEdocuments.find(doc => doc.task_type == 'disbursement-of-funds---seller')) || dataEdocuments.find(doc => doc.task_type == 'escrow-agreement') || null;

    const acceptanceOfVesselDocuments = dataEdocuments.filter(doc => doc.task_type == 'acceptance-of-vessel').sort((a, b) => {
        if (a.sort_order == b.sort_order) {
            return a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1
        }
        return a.sort_order > b.sort_order ? 1 : -1
    }) || [];

    const exclusionDocuments = dataEdocuments.filter(doc => doc.task_type == 'purchase-&-sale-agreement-exclusion').sort((a, b) => {
        if (a.sort_order == b.sort_order) {
            return a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1
        }
        return a.sort_order > b.sort_order ? 1 : -1
    }) || [];

    const addendumDocuments = dataEdocuments.filter(doc => doc.task_type == 'purchase-&-sale-agreement-addendum').sort((a, b) => {
        if (a.sort_order == b.sort_order) {
            return a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1
        }
        return a.sort_order > b.sort_order ? 1 : -1
    }) || [];
    const ybaaAmendmentToPsaDocuments = dataEdocuments.filter(doc => doc.task_type == 'ybaa-amendment-to-psa-(short-form)').sort((a, b) => {
        if (a.sort_order == b.sort_order) {
            return a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1
        }
        return a.sort_order > b.sort_order ? 1 : -1
    }) || [];

    const customDocument = dataTransaction?.t_edocuments?.find(doc => doc.task_type == 'custom-esign-transaction-template') || null;

    const materialDamageDocument = dataEdocuments.find(doc => doc.task_type == 'material-damage-disclosure-deals') || null;

    const [asterix, setAsterix] = useState({});
    const [show, setShow] = useState(false);
    const [surveyWaiverFormShow, setSurveyWaiverFormShow] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const [multiFormId, setMultiFormId] = useState(null)
    const [open, setOpen] = useState({
        formData: false,
        exclusion: false,
        addendum: false,
        amendment: false,
    });

    // for formFields dragging and editing functionality
    const { vesselFields, ybaaAmendmentField, acceptanceOfVesselField, sellerClosing, agreementAmmendmuntField, buyerClosing, agreementExclusion, offerFields, termFields, coBrokerFields, tradeInVesselFields, buyerFields, coBuyerFields, sellerFields, coSellerFields, exclusionFields, reloadKey, formFieldsEdit, setFormFieldsEdit, handleFormFieldsChange, onFormFieldsChangesSave, onFormFieldsChangesCancle, handleFormFieldCopyClick, copyStatus } = formFieldsProps;
    useEffect(() => {
        if (transactionId && sliderFor == 'editor') {
            dispatch(getTransactionInitialById(transactionId)).then((data) => {
                dispatch(getTransactionEdocById(transactionId, menuType === 'invitation', dataDeal.broker?.company_id)).then((edoc) => {
                    dispatch(getTransactionEsignById(transactionId));
                });
            });
        }
    }, []);

    // close all forms on reload
    useEffect(() => {
        setOpen((prev) => ({
            formData: false,
            exclusion: false,
            addendum: false,
            amendment: false,
        }))
    }, [reloadKey])

    useEffect(() => {
        const vesselRequireds = dataRequiredFields.vessel;
        const buyerRequireds = dataRequiredFields.buyer;
        const coBuyerRequireds = dataRequiredFields.co_buyer;
        const offerRequireds = dataRequiredFields.offer;
        const sellerRequireds = dataRequiredFields.seller;
        const coSellerRequireds = dataRequiredFields.co_seller;
        const termsRequireds = dataRequiredFields.terms;
        const coBrokerRequireds = dataRequiredFields.co_broker;
        const trailerRequireds = dataRequiredFields.trailer;

        let vesselAsterix = "empty";
        let buyerAsterix = "empty";
        let coBuyerAsterix = "empty";
        let offerAsterix = "empty";
        let sellerAsterix = "empty";
        let coSellerAsterix = "empty";
        let termsAsterix = "empty";
        let coBrokerAsterix = "empty";
        let trailerAsterix = "empty";

        if (vesselRequireds.length > 0 && vesselRequireds.length < 5) vesselAsterix = "notFulfill";
        if (buyerRequireds.length > 0 && buyerRequireds.length < ((dataTransaction?.buyer_type === LEGAL_OWNER || dataTransaction?.buyer_type === LEGAL_OR_ASSIGN || dataTransaction?.buyer_type === LEGAL_AND_OR_ASSIGN) ? 4 : 8)) buyerAsterix = "notFulfill";
        if (buyerRequireds.length === 1 && buyerRequireds[0]['title']) buyerAsterix = "empty";
        if (coBuyerRequireds.length > 0 && coBuyerRequireds.length < 4) coBuyerAsterix = "notFulfill";
        if (offerRequireds.length > 0 && offerRequireds.length < 5) offerAsterix = "notFulfill";
        if (sellerRequireds.length > 0 && sellerRequireds.length < (dataTransaction?.seller_type === LEGAL_OWNER ? 4 : 8)) sellerAsterix = "notFulfill";
        if (sellerRequireds.length === 1 && sellerRequireds[0]['title']) sellerAsterix = "empty";
        if (coSellerRequireds.length > 0 && coSellerRequireds.length < 4)
            coSellerAsterix = "notFulfill";
        if (termsRequireds.length > 0 && termsRequireds.length < 2) termsAsterix = "notFulfill";
        if (coBrokerRequireds.length > 0 && coBrokerRequireds.length < 6) coBrokerAsterix = "notFulfill";

        if (vesselRequireds.length === 0) vesselAsterix = "fulfill";
        if (buyerRequireds.length === 0) buyerAsterix = "fulfill";
        if (coBuyerRequireds.length === 0) coBuyerAsterix = "fulfill";
        if (offerRequireds.length === 0) offerAsterix = "fulfill";
        if (sellerRequireds.length === 0) sellerAsterix = "fulfill";
        if (coSellerRequireds.length === 0) coSellerAsterix = "fulfill";
        if (termsRequireds.length === 0) termsAsterix = "fulfill";
        if (coBrokerRequireds.length === 0) coBrokerAsterix = "fulfill";

        if (dataTransaction?.owner_or_legal === 2) sellerAsterix = "fulfill";
        if (trailerRequireds.length === 0) trailerAsterix = "fulfill";

        // if (tailers.reduce((total, trailer) => total + parseFloat(trailer.price), additional_price) !== dataTransaction?.t_offers?.purchase_price_additional_units) offerAsterix = "notFulfill";

        const val = {
            vessel: vesselAsterix,
            buyer: buyerAsterix,
            co_buyer: coBuyerAsterix,
            offer: offerAsterix,
            seller: sellerAsterix,
            co_seller: coSellerAsterix,
            terms: termsAsterix,
            co_broker: coBrokerAsterix,
            trailer: trailerAsterix
        };

        if ([null, false].includes(dataTerms?.materially_damaged)) {
            val.materially_damaged = "fulfill";
        }
        if (dataTerms?.materially_damaged) {
            val.materially_damaged = dataTerms?.material_damage_detail ? "fulfill" : "empty";
        }

        if (dataTransaction?.t_toys?.length > 0) {
            val.toys = "fulfill";
        } else {
            val.toys = "empty";
        }
        if (dataTransaction?.t_exclusion?.length > 0) {
            val.exclusions = "fulfill";
        } else {
            val.exclusions = "empty";
        }

        if (dataTradeIn && dataTradeIn.id) {
            let tradeInRequiredFields = [];
            Object.keys(tradeRequiredFields).forEach(key => {
                if (key == "doc_no") {
                    if (!dataTradeIn["doc_no"] && !dataTradeIn["reg_no"] && !dataTradeIn["title_no"]) {
                        tradeInRequiredFields.push({ "": "Please input at least one: Doc No., Reg No., or Title No." });
                        return
                    }
                }
                if (!dataTradeIn[key]) {
                    tradeInRequiredFields.push({ [key]: tradeRequiredFields[key] });
                }
            });

            if (tradeInRequiredFields.length === 0) {
                val.tradeInVessel = "fulfill";
            } else if (tradeInRequiredFields.length === tradeRequiredFields.length) {
                val.tradeInVessel = "empty";
            } else {
                val.tradeInVessel = "notFulfill";
            }
        } else {
            Object.keys(tradeRequiredFields).forEach(key => {
                requiredFields.push({ [key]: tradeRequiredFields[key] });
            });
            val.tradeInVessel = "empty";
        }

        if (dataSellerClosing) {
            const { gross_sales_commission } = dataSellerClosing;
            if (gross_sales_commission || gross_sales_commission === 0) {
                val.sellerClosingStatement = "fulfill";
            } else {
                val.sellerClosingStatement = "empty";
            }
        } else {
            val.sellerClosingStatement = "empty";
        }

        if (dataBuyerClosing) {
            const { sales_price_total, sub_total, sales_tax_vessel, is_sales_tax } = dataBuyerClosing;
            if (is_sales_tax) {
                if (sales_price_total && sub_total && sales_tax_vessel >= 0) {
                    val.buyerClosingStatement = "fulfill";
                } else if (sales_price_total || sub_total || sales_tax_vessel >= 0) {
                    val.buyerClosingStatement = "notFulfill";
                } else {
                    val.buyerClosingStatement = "empty";
                }
            } else {
                if (sales_price_total && sub_total) {
                    val.buyerClosingStatement = "fulfill";
                } else if (sales_price_total || sub_total) {
                    val.buyerClosingStatement = "notFulfill";
                } else {
                    val.buyerClosingStatement = "empty";
                }
            }
        } else {
            val.buyerClosingStatement = "empty";
        }

        setAsterix(val);
    }, [dataRequiredFields, dataTransaction]);

    useEffect(() => {
        if (participantForm) {
            participantForm = participantForm.includes('Listing') || participantForm.includes('Selling') ? 'Co-Broker & Commission' : participantForm;
            handleOpenPanel(participantForm);
        }
    }, [participantForm])

    const handleOpenPanel = (formType, dataId = null) => {
        setSelectedForm(formType);
        setMultiFormId(dataId || null)
        resetModal()
        setShow(true);
    }

    const handleClick = (type) => {
        if (isPdfEdit) {
            if (type === "formData" && !open?.formData) {
                //Open a Warning pop-up
                Swal.fire({
                    title: 'Attention!',
                    html: `Changes in form data will remove all editions and reload the document.`,
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "red",
                    cancelButtonText: "Cancel",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: 'Okay',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        setOpen({
                            ...open,
                            [type]: !open[type]
                        });
                    }
                }).catch((err) => { console.log(err); });
            } else {
                setOpen({
                    ...open,
                    formData: !open[type] === true ? false : open.formData,
                    [type]: !open[type]
                });
            }
        } else {
            setOpen({
                ...open,
                [type]: !open[type]
            });
        }
    };

    const acceptanceOfVesselDataStatus = (acceptanceId) => {
        const dataAcceptance = dataDeal.t_acceptance?.find(el => el.id === acceptanceId) || {};
        let status = 'empty';

        if (dataAcceptance) {
            switch (dataAcceptance.status) {
                case 0:
                    status = 'fulfill';
                    break;
                case 1: {
                    if (!!dataAcceptance.days_to_complete_conditions && !!dataAcceptance.condition_1 || !!dataAcceptance.condition_2 || !!dataAcceptance.condition) {
                        status = 'fulfill';
                    } else {
                        status = 'empty';
                    }
                    break;
                }
                case 2: {
                    if (!!dataAcceptance.adjustment_amount &&
                        (!!dataAcceptance.condition || !!dataAcceptance.condition_1 || !!dataAcceptance.condition_2)
                    ) {
                        status = 'fulfill';
                    } else if (
                        !dataAcceptance.adjustment_amount &&
                        !dataAcceptance.condition_1 &&
                        !dataAcceptance.condition_2 &&
                        !dataAcceptance.condition
                    ) {
                        status = 'empty';
                    } else {
                        status = 'notFulfill';
                    }
                    break;
                }
                case 3: {
                    if (!!dataAcceptance.reason_for_rejection) {
                        status = 'fulfill';
                    } else {
                        status = 'empty';
                    }
                    break;
                }
                default:
                    status = 'empty';
                    break;
            }
        }
        return status;
    }

    const exclusionDataStatus = (exclusionId) => {
        const exclusion = dataExclusion.find(el => el.id === exclusionId);
        const blankExclusionStr = Array(20).fill().map((v, i) => "/:/").join("").slice(0, -3);
        return (exclusion?.exclusion && !["", blankExclusionStr].includes(exclusion?.exclusion)) ? "fulfill" : "empty";
    }

    const requiredFields = [
        { addendum_no: "Addendum Number" },
        { ammendment: "Provision #" },
    ];
    const requiredFieldsForAmmendment = [
        { addendum_no: "Addendum Number" },
    ];

    const addendumDataStatus = (addendumId) => {
        let status = 'empty';
        let notfilledFields = [];
        const addendum = dataAddendum.find(el => el.t_edocument_id === addendumId);
        if (addendum) {
            requiredFields.forEach((rf) => {
                const field_key = Object.keys(rf)[0];
                if (field_key === 'ammendment') {
                    if ((addendum[field_key] === "" || !addendum[field_key]) && (addendum[field_key] === "/://://://:/" || addendum[field_key] === "" || !addendum[field_key])) {
                        notfilledFields.push(rf);
                    }
                }
                else {
                    if (!addendum[field_key]) {
                        notfilledFields.push(rf);
                    }
                }
            });
            status = notfilledFields.length === 2
                ? "empty"
                : notfilledFields.length === 0
                    ? "fulfill"
                    : "notFulfill";
        } else {
            notfilledFields = requiredFields
            status = "empty";
        }
        return status;
    }
    const ammendmentDataStatus = (addendumId) => {
        let status = 'empty';
        let notfilledFields = [];
        const addendum = dataAddendum.find(el => el.t_edocument_id === addendumId);
        if (addendum) {
            requiredFieldsForAmmendment.forEach((rf) => {
                const field_key = Object.keys(rf)[0];
                if (field_key === 'ammendment') {
                    if ((addendum[field_key] === "" || addendum[field_key]) && (addendum[field_key] === "/://://://:/" || addendum[field_key] === "")) {
                        notfilledFields.push(rf);
                    }
                } else {
                    if (!addendum[field_key]) {
                        notfilledFields.push(rf);
                    }
                }
            });
            status = notfilledFields.length === 2
                ? "empty"
                : notfilledFields.length === 0
                    ? "fulfill"
                    : "notFulfill";
        } else {
            notfilledFields = requiredFields
            status = "empty";
        }
        return status;
    }
    useEffect(() => {
        if (show && selectedForm) {
            const alertUser = (e) => {
                if (e) {
                    e.preventDefault();
                    e.returnValue = "";
                }
            };

            window.addEventListener("beforeunload", alertUser);
            return () => {
                window.removeEventListener("beforeunload", alertUser);
            };
        }
    }, [show, selectedForm]);

    const onClosePanel = (isPDFReload) => {
        // if (isPDFReload) {
        setSelectedForm(null);
        // if (participantForm) {
        if (!isPdfEdit) {
            onPanelClose(true);
        }
        // }

        setShow(false);
        if (reloadPdf != null) {
            reloadPdf(isPDFReload);
        }
        //     return;
        // }

        // Swal.fire({
        //     title: 'Close Form!',
        //     text: "Changes that you made may not be saved.",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes',
        //     cancelButtonText: "No"
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         setSelectedForm(null);
        //         setShow(false);
        //     }
        // });
    }

    // function for show warning pop up and open form edition  panel
    const handleFormEditPanel = (formPanelName) => {
        //Open a Warning pop-up
        // Swal.fire({
        //     title: 'Attention!',
        //     html: `Changes in form data will remove all editions and reload the document.`,
        //     icon: "warning",
        //     showCancelButton: true,
        //     cancelButtonColor: "red",
        //     cancelButtonText: "Cancel",
        //     confirmButtonColor: "#3085d6",
        //     confirmButtonText: 'Okay',
        // }).then((result) => {
        //     if (result.isConfirmed) {
        handleOpenPanel(formPanelName);
        resetModal()
        //     }
        // }).catch((err) => { console.log(err, "Editor Form Panel Error"); });
    }


    return (
        <>
            <div ref={leftSideBarRef} className={`tm-sidebar-left uk-visible@m py-0 ${isPdfEdit && "pb-5"}`} style={isPdfEdit && { height: "200px" }}>
                <div className="sidebar-container add-deal">
                    <Box role="presentation">
                        {!isPdfEdit &&
                            <List className='py-0'>
                                <div className="my-1">
                                    <ListItemButton onClick={() => handleClick('formData')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                        <ListItemText><span>Form Data</span></ListItemText>
                                        {open['formData'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                    </ListItemButton>
                                    <Collapse in={open['formData']} timeout="auto" unmountOnExit>
                                        <ListItemButton onClick={() => handleOpenPanel('Vessel')}>
                                            <ListItemIcon>
                                                <i
                                                    className={clsx(
                                                        "fa",
                                                        { "fa-check-circle stepbar": asterix.vessel === "fulfill" },
                                                        { "fa-circle-o": asterix.vessel === "empty" || !asterix.vessel },
                                                        { "fa-circle": asterix.vessel === "notFulfill" }
                                                    )}
                                                ></i>
                                            </ListItemIcon>
                                            <ListItemText primary={'Vessel'} />
                                        </ListItemButton>
                                        <Divider />
                                        <Tooltip title={`${((menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_buyer_hide_by_invited_broker)) ? "Not Accessible " : ""}`} arrow placement='right' slotProps={{ popper: { sx: customTooltipSmall }, }} >
                                            <span>
                                                <ListItemButton
                                                    disabled={(menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_buyer_hide_by_invited_broker)}
                                                    onClick={() => handleOpenPanel('Buyer')}
                                                    style={((menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_buyer_hide_by_invited_broker))
                                                        ? { color: "red", opacity: 1, pointerEvents: "none" }
                                                        : {}}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": asterix.buyer === "fulfill" },
                                                                { "fa-circle-o": asterix.buyer === "empty" || !asterix.buyer },
                                                                { "fa-circle": asterix.buyer === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Buyer'} />
                                                </ListItemButton></span>
                                        </Tooltip>
                                        <Divider />
                                        <Tooltip title={`${((menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_buyer_hide_by_invited_broker)) ? "Not Accessible " : ""}`} arrow placement='right' slotProps={{ popper: { sx: customTooltip }, }} >
                                            <span>
                                                <ListItemButton
                                                    disabled={(menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_buyer_hide_by_invited_broker)}
                                                    onClick={() => handleOpenPanel('Co-Buyer')}
                                                    style={((menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_buyer_hide_by_invited_broker)) ? { color: "red", opacity: 1 } : {}}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": asterix.co_buyer === "fulfill" },
                                                                { "fa-circle-o": asterix.co_buyer === "empty" || !asterix.co_buyer },
                                                                { "fa-circle": asterix.co_buyer === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Co-Buyer'} />
                                                </ListItemButton>
                                            </span>
                                        </Tooltip>
                                        <Divider />
                                        <Tooltip title={`${((menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_seller_hide_by_invited_broker)) ? "Not Accessible " : ""}`} arrow placement='right' slotProps={{ popper: { sx: customTooltipSmall }, }}  >
                                            <span>
                                                <ListItemButton
                                                    disabled={(menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_seller_hide_by_invited_broker)}
                                                    onClick={() => handleOpenPanel('Seller')}
                                                    style={((menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_seller_hide_by_invited_broker))
                                                        ? { color: "red", opacity: 1 }
                                                        : {}}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": asterix.seller === "fulfill" },
                                                                { "fa-circle-o": asterix.seller === "empty" || !asterix.seller },
                                                                { "fa-circle": asterix.seller === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Seller'} />
                                                </ListItemButton></span>
                                        </Tooltip>
                                        <Divider />
                                        <Tooltip title={`${((menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_seller_hide_by_invited_broker)) ? "Not Accessible " : ""}`} arrow placement='right' slotProps={{ popper: { sx: customTooltip }, }} >
                                            <span>
                                                <ListItemButton
                                                    disabled={(menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_seller_hide_by_invited_broker)}
                                                    onClick={() => handleOpenPanel('Co-Seller')}
                                                    style={((menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_seller_hide_by_invited_broker)) ? { color: "red", opacity: 1 } : {}}
                                                >
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": asterix.co_seller === "fulfill" },
                                                                { "fa-circle-o": asterix.co_seller === "empty" || !asterix.co_seller },
                                                                { "fa-circle": asterix.co_seller === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Co-Seller'} />
                                                </ListItemButton>
                                            </span>
                                        </Tooltip>
                                        <Divider />
                                        <ListItemButton onClick={() => handleOpenPanel('Toys & Tenders')}>
                                            <ListItemIcon>
                                                <i
                                                    className={clsx(
                                                        "fa",
                                                        { "fa-check-circle stepbar": asterix.toys === "fulfill" },
                                                        { "fa-circle-o": asterix.toys === "empty" || !asterix.toys },
                                                        { "fa-circle": asterix.toys === "notFulfill" }
                                                    )}
                                                ></i>
                                            </ListItemIcon>
                                            <ListItemText primary={'Toys & Tenders'} />
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton onClick={() => handleOpenPanel('Trailer')}>
                                            <ListItemIcon>
                                                <i
                                                    className={clsx(
                                                        "fa",
                                                        { "fa-check-circle stepbar": asterix.trailer === "fulfill" },
                                                        { "fa-circle-o": asterix.trailer === "empty" || !asterix.trailer },
                                                        { "fa-circle": asterix.trailer === "notFulfill" }
                                                    )}
                                                ></i>
                                            </ListItemIcon>
                                            <ListItemText primary={'Trailer'} />
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton onClick={() => handleOpenPanel('Trade-In Vessel')}>
                                            <ListItemIcon>
                                                <i
                                                    className={clsx(
                                                        "fa",
                                                        { "fa-check-circle stepbar": asterix.tradeInVessel === "fulfill" },
                                                        { "fa-circle-o": asterix.tradeInVessel === "empty" || !asterix.tradeInVessel },
                                                        { "fa-circle": asterix.tradeInVessel === "notFulfill" }
                                                    )}
                                                ></i>
                                            </ListItemIcon>
                                            <ListItemText primary={'Trade In'} />
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton onClick={() => handleOpenPanel('Offer')}>
                                            <ListItemIcon>
                                                <i
                                                    className={clsx(
                                                        "fa",
                                                        { "fa-check-circle stepbar": asterix.offer === "fulfill" },
                                                        { "fa-circle-o": asterix.offer === "empty" || !asterix.offer },
                                                        { "fa-circle": asterix.offer === "notFulfill" }
                                                    )}
                                                ></i>
                                            </ListItemIcon>
                                            <ListItemText primary={'Offer'} />
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton onClick={() => handleOpenPanel('Terms')}>
                                            <ListItemIcon>
                                                <i
                                                    className={clsx(
                                                        "fa",
                                                        { "fa-check-circle stepbar": asterix.terms === "fulfill" },
                                                        { "fa-circle-o": asterix.terms === "empty" || !asterix.terms },
                                                        { "fa-circle": asterix.terms === "notFulfill" }
                                                    )}
                                                ></i>
                                            </ListItemIcon>
                                            <ListItemText primary={'Terms'} />
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton onClick={() => handleOpenPanel('Exclusions')}>
                                            <ListItemIcon>
                                                <i
                                                    className={clsx(
                                                        "fa",
                                                        { "fa-check-circle stepbar": asterix.exclusions === "fulfill" },
                                                        { "fa-circle-o": asterix.exclusions === "empty" || !asterix.exclusions },
                                                        { "fa-circle": asterix.exclusions === "notFulfill" }
                                                    )}
                                                ></i>
                                            </ListItemIcon>
                                            <ListItemText primary={'Exclusions'} />
                                        </ListItemButton>
                                        <Divider />
                                        <Tooltip title={`${menuType === "invitation" ? "Not Accessible " : ""}`} arrow placement='right' slotProps={{ popper: { sx: customTooltipLarge }, }} >
                                            <span>
                                                <ListItemButton onClick={() => handleOpenPanel('Co-Broker & Commission')}
                                                    disabled={menuType === "invitation"}
                                                    style={menuType === "invitation" ? { color: "red", opacity: 1 } : {}}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": asterix.co_broker === "fulfill" },
                                                                { "fa-circle-o": asterix.co_broker === "empty" || !asterix.co_broker },
                                                                { "fa-circle": asterix.co_broker === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Co-Broker & Commission'} />
                                                </ListItemButton>
                                            </span>
                                        </Tooltip>
                                        <Divider sx={{ borderBottomWidth: 6 }} className='my-2' />

                                        <ListItemButton onClick={() => handleOpenPanel('Seller Closing')}>
                                            <ListItemIcon>
                                                <i
                                                    className={clsx(
                                                        "fa",
                                                        { "fa-check-circle stepbar": asterix.sellerClosingStatement === "fulfill" },
                                                        { "fa-circle-o": asterix.sellerClosingStatement === "empty" || !asterix.sellerClosingStatement },
                                                        { "fa-circle": asterix.sellerClosingStatement === "notFulfill" }
                                                    )}
                                                ></i>
                                            </ListItemIcon>
                                            <ListItemText primary={'Seller Closing'} />
                                        </ListItemButton>
                                        <Divider />

                                        <ListItemButton onClick={() => handleOpenPanel('Buyer Closing')}>
                                            <ListItemIcon>
                                                <i
                                                    className={clsx(
                                                        "fa",
                                                        { "fa-check-circle stepbar": asterix.buyerClosingStatement === "fulfill" },
                                                        { "fa-circle-o": asterix.buyerClosingStatement === "empty" || !asterix.buyerClosingStatement },
                                                        { "fa-circle": asterix.buyerClosingStatement === "notFulfill" }
                                                    )}
                                                ></i>
                                            </ListItemIcon>
                                            <ListItemText primary={'Buyer Closing'} />
                                        </ListItemButton>
                                        <Divider />
                                        {/* <Divider textAlign="center" className='my-3 border-5' >
                                        <Typography variant="button" gutterBottom >Form Data</Typography>
                                    </Divider> */}
                                    </Collapse>
                                {/* {!open['formData'] && <Divider />} */}
                                </div>

                                {/* Form Data Button for Trade-In Vessel */}
                                {/* {tradeInVesselDocument &&
                                <Fragment>
                                    <ListItemButton onClick={() => handleOpenPanel('Trade-In Vessel')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.tradeInVessel === "fulfill" },
                                                    { "fa-circle-o": asterix.tradeInVessel === "empty" || !asterix.tradeInVessel },
                                                    { "fa-circle": asterix.tradeInVessel === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Trade-In Vessel'} />
                                    </ListItemButton>
                                    <Divider />
                                </Fragment>
                            } */}

                            {/* Form Data Button for Survey Waiver */}
                            {/* {surveyWaiverDocument &&
                                <Fragment>
                                    <ListItemButton onClick={() => setSurveyWaiverFormShow(true)}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa fa-check-circle stepbar",
                                                    // { "fa-check-circle stepbar": asterix.tradeInVessel === "fulfill" },
                                                    // { "fa-circle-o": asterix.tradeInVessel === "empty" || !asterix.tradeInVessel },
                                                    // { "fa-circle": asterix.tradeInVessel === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Survey Waiver'} />
                                    </ListItemButton>
                                    <Divider />
                                </Fragment>
                            } */}

                            {/* Form Data Button for IYBA Purchase & Sale Addendum */}
                            {/* {addendumDocuments.length > 0 &&
                                <div className="my-1">
                                    <ListItemButton onClick={() => handleClick('addendum')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                        <ListItemText primary="IYBA Purchase & Sale Addendum" />
                                        {open['addendum'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                    </ListItemButton>
                                    <Collapse in={open['addendum']} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {addendumDocuments.map((addendum, ind) => (
                                                <ListItemButton sx={{ pl: 4 }} key={addendum.id} onClick={() => handleOpenPanel('IYBA Purchase & Sale Addendum', addendum.id)} >
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": addendumDataStatus(addendum.id) === "fulfill" },
                                                                { "fa-circle-o": addendumDataStatus(addendum.id) === "empty" || !addendumDataStatus(addendum.id) },
                                                                { "fa-circle": addendumDataStatus(addendum.id) === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <Tooltip title={`${addendum.title} ${ind > 0 ? '#' + ind : ''}`} placement='right'>
                                                        <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                                    </Tooltip>
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </div>
                            } */}

                            {/* Form Data Button for IYBA Purchase & Sale Agreement Exclusion */}
                            {/* {exclusionDocuments.length > 0 &&
                                <div className="my-1">
                                    <ListItemButton onClick={() => handleClick('exclusion')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                        <ListItemText primary="IYBA Purchase & Sale Agreement Exclusion" />
                                        {open['exclusion'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                    </ListItemButton>
                                    <Collapse in={open['exclusion']} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {exclusionDocuments.map((exclusion, ind) => (
                                                <ListItemButton sx={{ pl: 4 }} key={exclusion.id} onClick={() => handleOpenPanel('IYBA Purchase & Sale Agreement Exclusion', exclusion.id)}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": exclusionDataStatus(exclusion.id) === "fulfill" },
                                                                { "fa-circle-o": exclusionDataStatus(exclusion.id) === "empty" || !exclusionDataStatus(exclusion.id) },
                                                                { "fa-circle": exclusionDataStatus(exclusion.id) === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <Tooltip title={`${exclusion.title} ${ind > 0 ? '#' + ind : ''}`} placement='right'>
                                                        <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                                    </Tooltip>
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </div>
                            } */}

                            {/* Form Data Button for Acceptance of Vessel */}
                            {acceptanceOfVesselDocuments.length > 0 &&
                                <div className="my-1">
                                    <ListItemButton onClick={() => handleClick('acceptance')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                        <ListItemText primary="Acceptance of Vessel" />
                                        {open['acceptance'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                    </ListItemButton>
                                    <Collapse in={open['acceptance']} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {acceptanceOfVesselDocuments.map((acceptance, ind) => (
                                                <ListItemButton sx={{ pl: 4 }} key={acceptance.id} onClick={() => handleOpenPanel('IYBA Acceptance of Vessel', acceptance.id)}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": acceptanceOfVesselDataStatus(acceptance.id) === "fulfill" },
                                                                { "fa-circle-o": acceptanceOfVesselDataStatus(acceptance.id) === "empty" || !acceptanceOfVesselDataStatus(acceptance.id) },
                                                                { "fa-circle": acceptanceOfVesselDataStatus(acceptance.id) === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <Tooltip title={`${acceptance && acceptance.status
                                                        ? titles[acceptance.duplicate_occurrence]
                                                        : acceptance?.is_duplicate ? acceptance?.duplicate_title : acceptance?.t_esign_template?.template?.template_name}`} placement='right'>
                                                        <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                                    </Tooltip>
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </div>
                            }

                            {materialDamageDocument &&
                                <Fragment>
                                    <ListItemButton onClick={() => handleOpenPanel('Material Damage Disclosure')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.materially_damaged === "fulfill" },
                                                    { "fa-circle-o": asterix.materially_damaged === "empty" || !asterix.materially_damaged },
                                                    { "fa-circle": asterix.materially_damaged === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Material Damage Disclosure'} />
                                    </ListItemButton>
                                    <Divider />
                                </Fragment>
                            }

                            {/* Form Data Button for  */}
                            {/* {ybaaAmendmentToPsaDocuments.length > 0 &&
                                <div className="my-1">
                                    <ListItemButton onClick={() => handleClick('amendment')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                        <ListItemText primary="YBAA Amendment to PSA (Short Form)" />
                                        {open['amendment'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                    </ListItemButton>
                                    <Collapse in={open['amendment']} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {ybaaAmendmentToPsaDocuments.map((acceptance, ind) => (
                                                <ListItemButton sx={{ pl: 4 }} key={acceptance.id} onClick={() => handleOpenPanel('YBAA Amendment to PSA (Short Form)', acceptance.id)}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": ammendmentDataStatus(acceptance.id) === "fulfill" },
                                                                { "fa-circle-o": ammendmentDataStatus(acceptance.id) === "empty" || !ammendmentDataStatus(acceptance.id) },
                                                                { "fa-circle": ammendmentDataStatus(acceptance.id) === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <Tooltip title={`${acceptance && acceptance.status
                                                        ? titles[acceptance.duplicate_occurrence]
                                                        : acceptance?.is_duplicate ? acceptance?.duplicate_title : acceptance?.t_esign_template?.template?.template_name}`} placement='right'>
                                                        <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                                    </Tooltip>
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </div>
                            } */}
                        {/* </List> */}

                                {/* Form Data Button for IYBA Purchase & Sale Agreement Exclusion */}
                                {/* // {exclusionDocuments.length > 0 &&
                                //     <div className="my-1">
                                //         <ListItemButton onClick={() => handleClick('exclusion')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                //             <ListItemText primary="IYBA Purchase & Sale Agreement Exclusion" />
                                //             {open['exclusion'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                //         </ListItemButton>
                                //         <Collapse in={open['exclusion']} timeout="auto" unmountOnExit>
                                //             <List component="div" disablePadding>
                                //                 {exclusionDocuments.map((exclusion, ind) => (
                                //                     <ListItemButton sx={{ pl: 4 }} key={exclusion.id} onClick={() => handleOpenPanel('IYBA Purchase & Sale Agreement Exclusion', exclusion.id)}>
                                //                         <ListItemIcon>
                                //                             <i
                                //                                 className={clsx(
                                //                                     "fa",
                                //                                     { "fa-check-circle stepbar": exclusionDataStatus(exclusion.id) === "fulfill" },
                                //                                     { "fa-circle-o": exclusionDataStatus(exclusion.id) === "empty" || !exclusionDataStatus(exclusion.id) },
                                //                                     { "fa-circle": exclusionDataStatus(exclusion.id) === "notFulfill" }
                                //                                 )}
                                //                             ></i>
                                //                         </ListItemIcon>
                                //                         <Tooltip title={`${exclusion.title} ${ind > 0 ? '#' + ind : ''}`} placement='right'>
                                //                             <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                //                         </Tooltip>
                                //                     </ListItemButton>
                                //                 ))}
                                //             </List>
                                //         </Collapse>
                                //         <Divider />
                                //     </div>
                                // } */}

                                {/* Form Data Button for Acceptance of Vessel */}
                                {/* {acceptanceOfVesselDocuments.length > 0 &&
                                    <div className="my-1">
                                        <ListItemButton onClick={() => handleClick('acceptance')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                            <ListItemText primary="Acceptance of Vessel" />
                                            {open['acceptance'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                        </ListItemButton>
                                        <Collapse in={open['acceptance']} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {acceptanceOfVesselDocuments.map((acceptance, ind) => (
                                                    <ListItemButton sx={{ pl: 4 }} key={acceptance.id} onClick={() => handleOpenPanel('IYBA Acceptance of Vessel', acceptance.id)}>
                                                        <ListItemIcon>
                                                            <i
                                                                className={clsx(
                                                                    "fa",
                                                                    { "fa-check-circle stepbar": acceptanceOfVesselDataStatus(acceptance.id) === "fulfill" },
                                                                    { "fa-circle-o": acceptanceOfVesselDataStatus(acceptance.id) === "empty" || !acceptanceOfVesselDataStatus(acceptance.id) },
                                                                    { "fa-circle": acceptanceOfVesselDataStatus(acceptance.id) === "notFulfill" }
                                                                )}
                                                            ></i>
                                                        </ListItemIcon>
                                                        <Tooltip title={`${acceptance && acceptance.status
                                                            ? titles[acceptance.duplicate_occurrence]
                                                            : acceptance?.is_duplicate ? acceptance?.duplicate_title : acceptance?.t_esign_template?.template?.template_name}`} placement='right'>
                                                            <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                                        </Tooltip>
                                                    </ListItemButton>
                                                ))}
                                            </List>
                                        </Collapse>
                                        <Divider />
                                    </div>
                                } */}

                                {/* {materialDamageDocument &&
                                    <Fragment>
                                        <ListItemButton onClick={() => handleOpenPanel('Material Damage Disclosure')}>
                                            <ListItemIcon>
                                                <i
                                                    className={clsx(
                                                        "fa",
                                                        { "fa-check-circle stepbar": asterix.materially_damaged === "fulfill" },
                                                        { "fa-circle-o": asterix.materially_damaged === "empty" || !asterix.materially_damaged },
                                                        { "fa-circle": asterix.materially_damaged === "notFulfill" }
                                                    )}
                                                ></i>
                                            </ListItemIcon>
                                            <ListItemText primary={'Material Damage Disclosure'} />
                                        </ListItemButton>
                                        <Divider />
                                    </Fragment>
                                } */}

                                {/* Form Data Button for  */}
                                {/* {ybaaAmendmentToPsaDocuments.length > 0 &&
                                    <div className="my-1">
                                        <ListItemButton onClick={() => handleClick('amendment')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                            <ListItemText primary="YBAA Amendment to PSA (Short Form)" />
                                            {open['amendment'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                        </ListItemButton>
                                        <Collapse in={open['amendment']} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {ybaaAmendmentToPsaDocuments.map((acceptance, ind) => (
                                                    <ListItemButton sx={{ pl: 4 }} key={acceptance.id} onClick={() => handleOpenPanel('YBAA Amendment to PSA (Short Form)', acceptance.id)}>
                                                        <ListItemIcon>
                                                            <i
                                                                className={clsx(
                                                                    "fa",
                                                                    { "fa-check-circle stepbar": ammendmentDataStatus(acceptance.id) === "fulfill" },
                                                                    { "fa-circle-o": ammendmentDataStatus(acceptance.id) === "empty" || !ammendmentDataStatus(acceptance.id) },
                                                                    { "fa-circle": ammendmentDataStatus(acceptance.id) === "notFulfill" }
                                                                )}
                                                            ></i>
                                                        </ListItemIcon>
                                                        <Tooltip title={`${acceptance && acceptance.status
                                                            ? titles[acceptance.duplicate_occurrence]
                                                            : acceptance?.is_duplicate ? acceptance?.duplicate_title : acceptance?.t_esign_template?.template?.template_name}`} placement='right'>
                                                            <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                                        </Tooltip>
                                                    </ListItemButton>
                                                ))}
                                            </List>
                                        </Collapse>
                                        <Divider />
                                    </div>
                                } */}
                            </List>
                        }
                        {((isPdfEdit && signatureAssigner) || (customDocument?.t_esign_template?.is_custom_template && signatureAssigner)) &&
                            <>
                                {/* <div className='mt-3 signers-div'>
                                    <Typography mb={2}>Add Signatures</Typography>
                                    <Box sx={{ minWidth: 120 }} mb={2}>
                                        <FormControl variant="outlined" size="small" sx={{ minWidth: 120, width: '100%' }}>
                                            <InputLabel>Signer</InputLabel>
                                            <ThemeProvider theme={theme}>
                                                <Select
                                                    value={signatureAssigner}
                                                    onChange={(e) => { setSignatureAssigner(e.target.value) }}
                                                    displayEmpty
                                                    label="Signer"
                                                >
                                                    {signatureRoles?.map((roleObj, idx) => {
                                                        return (
                                                            <MenuItem key={idx + 2} value={roleObj?.role}>
                                                                <ListItemIcon sx={{ minWidth: '18px !important', margin: '5px' }}><div style={{ backgroundColor: roleObj?.bgColor, width: '18px', height: '18px' }}></div></ListItemIcon>
                                                                <ListItemText>{roleObj?.role}</ListItemText>
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </ThemeProvider>
                                        </FormControl>
                                    </Box>

                                    <Typography mb={1} mt={2}>Signature fields</Typography>
                                    <Box mb={1}>
                                        <div draggable style={style} onDragStart={(e) => e.dataTransfer.setData('type', "Signature")}>
                                            <FontAwesomeIcon icon={faFileSignature} /> {"Signature"}
                                        </div>
                                    </Box>

                                    <Box mb={1}>
                                        <div draggable style={style} onDragStart={(e) => e.dataTransfer.setData('type', "Initials")}>
                                            <FontAwesomeIcon icon={faSignature} /> {"Initials"}
                                        </div>
                                    </Box>

                                    <Box mb={3} onDragStart={(e) => e.dataTransfer.setData('type', "Date Signed")}>
                                        <div draggable style={style}>
                                            <FontAwesomeIcon icon={faCalendarDay} /> {"Date Signed"}
                                        </div>
                                    </Box>
                                </div> */}

                                <div className='mt-1 formdata-fields-div'>
                                    <Typography mt={0}>Drag & Drop Fields</Typography>
                                    <List className='py-0'>
                                        {(vesselFields?.length > 0) && <div className="my-1">
                                            <ListItemButton key={"vesselFields_Drag_Drop"} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('vesselField')}><span>Vessel</span></ListItemText>
                                                {
                                                    open['vesselField'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_vessel_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                            key={"is_vessel_edit"}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_vessel_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                            key={"vessel"}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesSave("vessel"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                        key={"vesselField"}

                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_vessel_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('vesselField'); setFormFieldsEdit((prev) => ({ ...prev, is_vessel_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_vessel_edit ?
                                                                    <>
                                                                        {/* <Tooltip title={"Click to Cancle"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                            key={"formFieldsEdit_is_vessel_edit"}

                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_vessel_edit"); }}
                                                                                className="fa fa-times mr-2" aria-hidden="true" style={{ fontSize: "17px" }}></i>
                                                                        </Tooltip> */}
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                            key={"formFieldsEdit_vessel"}
                                                                        >

                                                                            <i
                                                                                onClick={() => { handleClick('vesselField'); onFormFieldsChangesSave("vessel"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                            key={"formFieldsEdit_vesselField"}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleFormEditPanel("Vessel"); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>

                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('vesselField'); setFormFieldsEdit((prev) => ({ ...prev, is_vessel_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('vesselField')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>
                                            <Collapse style={{ marginBottom: "20px" }} in={open['vesselField']} timeout="auto" unmountOnExit>
                                                {
                                                    vesselFields?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                                // justifyContent: "space-between",
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                    {/* <i
                                                                        className=" fa fa-copy ml-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue) }}
                                                                    ></i> */}
                                                                </div>
                                                                <div
                                                                    // className='col-8'
                                                                    style={!formFieldsEdit?.is_vessel_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        // border: "1.5px dotted #0063A6",
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_vessel_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    disabled={vField?.fieldValue === null}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                // style={{ "textAlign": "start", "width": "100%" }}
                                                                                // onChange={(e) => handleFormFieldsChange(e.target.value, vField?.fieldId, "VesselFields")}
                                                                                // disabled={["Category", "Manufacturer/Builder *"].includes(vField?.fieldName) ? true : false}
                                                                                // autoFocus
                                                                                // error={vField?.fieldName.includes("*") && (!vField?.fieldValue) || (["Stock", "Year *", "Length *"].includes(vField?.fieldName) && !(/^[0-9.,]+$/.test(vField?.fieldValue)))}
                                                                                // helperText={"Number Wanted"}
                                                                                // variant="outlined"

                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <ListItemText
                                                                                    style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                    draggable={vField?.fieldValue !== null}
                                                                                    onDragStart={(e) => {
                                                                                        e.dataTransfer.setData('type', "FormFields");
                                                                                        e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                        e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                        e.dataTransfer.setData('formType', "vesselFields");
                                                                                    }}
                                                                                    primary={`${vField?.fieldValue || '\u00A0'}`} />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>}

                                        {/* buyer form fields panel */}
                                        {(buyerFields?.length > 0) && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('buyerField')}><span>Buyer</span></ListItemText>
                                                {
                                                    open['buyerField'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_buyer_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_buyer_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesSave("buyer"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_buyer_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('buyerField'); setFormFieldsEdit((prev) => ({ ...prev, is_buyer_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_buyer_edit ?
                                                                    <>
                                                                        {/* <Tooltip title={"Click to Cancle"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_buyer_edit"); }}
                                                                                className="fa fa-times mr-2" aria-hidden="true" style={{ fontSize: "17px" }}></i>
                                                                        </Tooltip> */}
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >

                                                                            <i
                                                                                onClick={() => { handleClick('buyerField'); onFormFieldsChangesSave("buyer"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleFormEditPanel("Buyer"); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('buyerField'); setFormFieldsEdit((prev) => ({ ...prev, is_buyer_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('buyerField')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>

                                            <Collapse style={{ marginBottom: "20px" }} in={open['buyerField']} timeout="auto" unmountOnExit>
                                                {
                                                    buyerFields?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                                // justifyContent: "space-between",
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                    {/* <i
                                                                        className=" fa fa-copy ml-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue) }}
                                                                    ></i> */}
                                                                </div>
                                                                <div
                                                                    // className='w-100' 
                                                                    style={!formFieldsEdit?.is_buyer_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        // border: "1.5px dotted #0063A6",
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_buyer_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    disabled={vField?.fieldValue === null}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                // onChange={(e) => handleFormFieldsChange(e.target.value, vField?.fieldId, "BuyerFields")}
                                                                                // disabled={["Country", "State *"].includes(vField?.fieldName) ? true : false}
                                                                                // autoFocus
                                                                                // error={vField?.fieldName.includes("*") && (!vField?.fieldValue) || (["Mobile Phone *", "Postal Code *"].includes(vField?.fieldName) && !(/^[0-9]+(,[0-9]{3})*$/.test(vField?.fieldValue)))}
                                                                                // helperText={"Number Wanted"}
                                                                                // variant="outlined"
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <ListItemText
                                                                                style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                draggable={vField?.fieldValue !== null}
                                                                                onDragStart={(e) => {
                                                                                    e.dataTransfer.setData('type', "FormFields");
                                                                                    e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                    e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                    e.dataTransfer.setData('formType', "buyerFields");
                                                                                }}
                                                                                primary={`${vField?.fieldValue || '\u00A0'}`} />
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>}

                                        {/* co-buyer form fields panel */}
                                        {(coBuyerFields?.length > 0) && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('coBuyerField')}><span>Co-Buyer</span></ListItemText>
                                                {
                                                    open['coBuyerField'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_cobuyer_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_cobuyer_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesSave("cobuyer"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_cobuyer_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('coBuyerField'); setFormFieldsEdit((prev) => ({ ...prev, is_cobuyer_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_cobuyer_edit ?
                                                                    <>
                                                                        {/* <Tooltip title={"Click to Cancle"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_cobuyer_edit"); }}
                                                                                className="fa fa-times mr-2" aria-hidden="true" style={{ fontSize: "17px" }}></i>
                                                                        </Tooltip> */}
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >

                                                                            <i
                                                                                onClick={() => { handleClick('coBuyerField'); onFormFieldsChangesSave("cobuyer"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleFormEditPanel("Co-Buyer"); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('coBuyerField'); setFormFieldsEdit((prev) => ({ ...prev, is_cobuyer_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('coBuyerField')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>

                                            <Collapse style={{ marginBottom: "20px" }} in={open['coBuyerField']} timeout="auto" unmountOnExit>
                                                {
                                                    coBuyerFields?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                                // justifyContent: "space-between",
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                    {/* <i
                                                                        className=" fa fa-copy ml-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue) }}
                                                                    ></i> */}
                                                                </div>
                                                                <div
                                                                    // className='w-100' 
                                                                    style={!formFieldsEdit?.is_cobuyer_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        // border: "1.5px dotted #0063A6",
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_cobuyer_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    disabled={vField?.fieldValue === null}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                // onChange={(e) => handleFormFieldsChange(e.target.value, vField?.fieldId, "CoBuyerFields")}
                                                                                // disabled={["Country", "State"].includes(vField?.fieldName) ? true : false}
                                                                                // autoFocus
                                                                                // error={vField?.fieldName.includes("*") && (!vField?.fieldValue) || (["Mobile Phone *", "Postal Code *"].includes(vField?.fieldName) && !(/^[0-9]+(,[0-9]{3})*$/.test(vField?.fieldValue)))}
                                                                                // helperText={"Number Wanted"}
                                                                                // variant="outlined"
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <ListItemText
                                                                                style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                draggable={vField?.fieldValue !== null}
                                                                                onDragStart={(e) => {
                                                                                    e.dataTransfer.setData('type', "FormFields");
                                                                                    e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                    e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                    e.dataTransfer.setData('formType', "coBuyerFields");
                                                                                }}
                                                                                primary={`${vField?.fieldValue || '\u00A0'}`} />
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>}

                                        {/* seller form fields panel */}
                                        {(sellerFields?.length > 0) && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('sellerField')}><span>Seller</span></ListItemText>
                                                {
                                                    open['sellerField'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_seller_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_seller_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesSave("seller"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_seller_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('sellerField'); setFormFieldsEdit((prev) => ({ ...prev, is_seller_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_seller_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_seller_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >

                                                                            <i
                                                                                onClick={() => { handleClick('sellerField'); onFormFieldsChangesSave("seller"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleFormEditPanel("Seller"); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('sellerField'); setFormFieldsEdit((prev) => ({ ...prev, is_seller_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('sellerField')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>

                                            <Collapse style={{ marginBottom: "20px" }} in={open['sellerField']} timeout="auto" unmountOnExit>
                                                {
                                                    sellerFields?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                                // justifyContent: "space-between",
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                    {/* <i
                                                                        className=" fa fa-copy ml-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue) }}
                                                                    ></i> */}
                                                                </div>
                                                                <div
                                                                    // className='w-100' 
                                                                    style={!formFieldsEdit?.is_seller_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        // border: "1.5px dotted #0063A6",
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_seller_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    disabled={vField?.fieldValue === null}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                // onChange={(e) => handleFormFieldsChange(e.target.value, vField?.fieldId, "SellerFields")}
                                                                                // disabled={["Country"].includes(vField?.fieldName) ? true : false}
                                                                                // autoFocus
                                                                                // error={vField?.fieldName.includes("*") && (!vField?.fieldValue) || (["Mobile Phone *", "Postal Code *"].includes(vField?.fieldName) && !(/^[0-9]+(,[0-9]{3})*$/.test(vField?.fieldValue)))}
                                                                                // helperText={"Number Wanted"}
                                                                                // variant="outlined"
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <ListItemText
                                                                                style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                draggable={vField?.fieldValue !== null}
                                                                                onDragStart={(e) => {
                                                                                    e.dataTransfer.setData('type', "FormFields");
                                                                                    e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                    e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                    e.dataTransfer.setData('formType', "sellerFields");
                                                                                }}
                                                                                primary={`${vField?.fieldValue || '\u00A0'}`} />
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>}

                                        {/* co-seller form fields panel */}
                                        {(coSellerFields?.length > 0) && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('coSellerField')}><span>Co-Seller</span></ListItemText>
                                                {
                                                    open['coSellerField'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_coseller_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_coseller_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesSave("coseller"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_coseller_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('coSellerField'); setFormFieldsEdit((prev) => ({ ...prev, is_coseller_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_coseller_edit ?
                                                                    <>
                                                                        {/* <Tooltip title={"Click to Cancle"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_coseller_edit"); }}
                                                                                className="fa fa-times mr-2" aria-hidden="true" style={{ fontSize: "17px" }}></i>
                                                                        </Tooltip> */}
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >

                                                                            <i
                                                                                onClick={() => { handleClick('coSellerField'); onFormFieldsChangesSave("coseller"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleFormEditPanel("Co-Seller"); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('coSellerField'); setFormFieldsEdit((prev) => ({ ...prev, is_coseller_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('coSellerField')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>

                                            <Collapse style={{ marginBottom: "20px" }} in={open['coSellerField']} timeout="auto" unmountOnExit>
                                                {
                                                    coSellerFields?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                                // justifyContent: "space-between",
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                    {/* <i
                                                                        className=" fa fa-copy ml-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue) }}
                                                                     ></i> */}
                                                                </div>
                                                                <div
                                                                    // className='w-100' 
                                                                    style={!formFieldsEdit?.is_coseller_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        // border: "1.5px dotted #0063A6",
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_coseller_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    disabled={vField?.fieldValue === null}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                // onChange={(e) => handleFormFieldsChange(e.target.value, vField?.fieldId, "CoSellerFields")}
                                                                                // disabled={["Country", "State"].includes(vField?.fieldName) ? true : false}
                                                                                // autoFocus
                                                                                // error={vField?.fieldName.includes("*") && (!vField?.fieldValue) || (["Mobile Phone *", "Postal Code *"].includes(vField?.fieldName) && !(/^[0-9]+(,[0-9]{3})*$/.test(vField?.fieldValue)))}
                                                                                // helperText={"Number Wanted"}
                                                                                // variant="outlined"
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <ListItemText
                                                                                style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                draggable={vField?.fieldValue !== null}
                                                                                onDragStart={(e) => {
                                                                                    e.dataTransfer.setData('type', "FormFields");
                                                                                    e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                    e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                    e.dataTransfer.setData('formType', "coSellerFields");
                                                                                }}
                                                                                primary={`${vField?.fieldValue || '\u00A0'}`} />
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>}

                                        {/* trade in vessel form fields panel */}
                                        {(tradeInVesselFields?.length > 0) && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('tradeInVesselField')}><span className='mr-1'>Trade In Vessel</span></ListItemText>
                                                {
                                                    open['tradeInVesselField'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_trade_in_vessel_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_trade_in_vessel_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesSave("trade_in_vessel"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_trade_in_vessel_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('tradeInVesselField'); setFormFieldsEdit((prev) => ({ ...prev, is_trade_in_vessel_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_trade_in_vessel_edit ?
                                                                    <>
                                                                        {/* <Tooltip title={"Click to Cancle"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_trade_in_vessel_edit"); }}
                                                                                className="fa fa-times mr-2" aria-hidden="true" style={{ fontSize: "17px" }}></i>
                                                                        </Tooltip> */}
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >

                                                                            <i
                                                                                onClick={() => { handleClick('tradeInVesselField'); onFormFieldsChangesSave("trade_in_vessel"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleFormEditPanel("Trade-In Vessel"); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('tradeInVesselField'); setFormFieldsEdit((prev) => ({ ...prev, is_trade_in_vessel_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('tradeInVesselField')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>
                                            <Collapse style={{ marginBottom: "20px" }} in={open['tradeInVesselField']} timeout="auto" unmountOnExit>
                                                {
                                                    tradeInVesselFields?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                                // justifyContent: "space-between",
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                    {/* <i
                                                                        className=" fa fa-copy ml-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue) }}
                                                                    ></i> */}
                                                                </div>
                                                                <div
                                                                    // className='col-8'
                                                                    style={!formFieldsEdit?.is_trade_in_vessel_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        // border: "1.5px dotted #0063A6",
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_trade_in_vessel_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    disabled={vField?.fieldValue === null}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                // onChange={(e) => handleFormFieldsChange(e.target.value, vField?.fieldId, "TradeInVesselFields")}
                                                                                // disabled={["Category *", "Manufacturer/Builder *"].includes(vField?.fieldName) ? true : false}
                                                                                // autoFocus
                                                                                // error={vField?.fieldName.includes("*") && (!vField?.fieldValue) || (["Stock", "Year *", "Length *", "Trade In Value *"].includes(vField?.fieldName) && !(/^[0-9.,]+$/.test(vField?.fieldValue)))}
                                                                                // helperText={"Number Wanted"}
                                                                                // variant="outlined"
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <ListItemText
                                                                                    style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                    draggable={vField?.fieldValue !== null}
                                                                                    onDragStart={(e) => {
                                                                                        e.dataTransfer.setData('type', "FormFields");
                                                                                        e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                        e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                        e.dataTransfer.setData('formType', "tradeInVesselFields");
                                                                                    }}
                                                                                    primary={`${vField?.fieldValue || '\u00A0'}`} />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>}

                                        {/* offer form fields panel */}
                                        {(offerFields?.length > 0) && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('offerField')}><span>Offer</span></ListItemText>
                                                {
                                                    open['offerField'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_offer_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_offer_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesSave("offer"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_offer_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('offerField'); setFormFieldsEdit((prev) => ({ ...prev, is_offer_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_offer_edit ?
                                                                    <>
                                                                        {/* <Tooltip title={"Click to Cancle"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_offer_edit"); }}
                                                                                className="fa fa-times mr-2" aria-hidden="true" style={{ fontSize: "17px" }}></i>
                                                                        </Tooltip> */}
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('offerField'); onFormFieldsChangesSave("offer"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleFormEditPanel("Offer"); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('offerField'); setFormFieldsEdit((prev) => ({ ...prev, is_offer_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('offerField')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>
                                            <Collapse style={{ marginBottom: "20px" }} in={open['offerField']} timeout="auto" unmountOnExit>
                                                {
                                                    offerFields?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                                // justifyContent: "space-between",
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                    {/* <i
                                                                        className=" fa fa-copy ml-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue) }}
                                                                    ></i> */}
                                                                </div>
                                                                <div
                                                                    // className='col-8'
                                                                    style={!formFieldsEdit?.is_offer_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        // border: "1.5px dotted #0063A6",
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_offer_edit ?
                                                                            <>
                                                                                {
                                                                                    // vField?.fieldName.includes("Date") ?
                                                                                    //     <DatePicker
                                                                                    //         className={clsx("uk-input")}
                                                                                    //         style={{ cursor: "pointer", color: "#000" }}
                                                                                    //         format={'MM/DD/YYYY'}
                                                                                    //         value={vField?.fieldValue && dayjs(vField?.fieldValue)}
                                                                                    //         onChange={(date, dateString) => {
                                                                                    //             handleFormFieldsChange(dateFormatter(dateString), vField?.fieldId, "OfferFields", vField?.fieldName)
                                                                                    //         }}
                                                                                    //     /> :

                                                                                    <TextField
                                                                                        value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                        disabled={vField?.fieldValue === null}
                                                                                        style={{ "textAlign": "start", "width": "88%" }}
                                                                                    // onChange={(e) => handleFormFieldsChange(e.target.value, vField?.fieldId, "OfferFields", vField?.fieldName)}
                                                                                    // autoFocus
                                                                                    // disabled={["Balance", "Purchase Price (Vessel)", "Less Trade Allowance", "Purchase Price (Addt'l Units)"].includes(vField?.fieldName) ? true : false}
                                                                                    // error={vField?.fieldName.includes("*") && (!vField?.fieldValue) || (["Deposit Due In (# of Days)", "Total Purchase Price *", "Less Deposit *"].includes(vField?.fieldName) && !(/^[0-9.,]+$/.test(vField?.fieldValue)))}
                                                                                    // helperText={"Number Wanted"}
                                                                                    // variant="outlined"
                                                                                    />
                                                                                }
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <ListItemText
                                                                                    style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                    draggable={vField?.fieldValue !== null}
                                                                                    onDragStart={(e) => {
                                                                                        e.dataTransfer.setData('type', "FormFields");
                                                                                        e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                        e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                        e.dataTransfer.setData('formType', "offerFields");
                                                                                    }}
                                                                                    primary={`${vField?.fieldValue || '\u00A0'}`} />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>}

                                        {/* terms form fields panel */}
                                        {(termFields?.length > 0) && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('termField')}><span>Terms</span></ListItemText>
                                                {
                                                    open['termField'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_terms_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_terms_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesSave("terms"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_terms_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('termField'); setFormFieldsEdit((prev) => ({ ...prev, is_terms_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_terms_edit ?
                                                                    <>
                                                                        {/* <Tooltip title={"Click to Cancle"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_terms_edit"); }}
                                                                                className="fa fa-times mr-2" aria-hidden="true" style={{ fontSize: "17px" }}></i>
                                                                        </Tooltip> */}
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >

                                                                            <i
                                                                                onClick={() => { handleClick('termField'); onFormFieldsChangesSave("terms"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleFormEditPanel("Terms"); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('termField'); setFormFieldsEdit((prev) => ({ ...prev, is_terms_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('termField')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>

                                            <Collapse style={{ marginBottom: "20px" }} in={open['termField']} timeout="auto" unmountOnExit>
                                                {
                                                    termFields?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                                // justifyContent: "space-between",
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                    {/* <i
                                                                        className=" fa fa-copy ml-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue) }}
                                                                    ></i> */}
                                                                </div>
                                                                <div
                                                                    // className='w-100' 
                                                                    style={!formFieldsEdit?.is_terms_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        // border: "1.5px dotted #0063A6",
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_terms_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    disabled={vField?.fieldValue === null}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                // onChange={(e) => handleFormFieldsChange(e.target.value, vField?.fieldId, "ExclusionFields")}
                                                                                // autoFocus
                                                                                // error={(vField?.fieldName.includes("*") && (!vField?.fieldValue))}
                                                                                // helperText={"Number Wanted"}
                                                                                // variant="outlined"
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <ListItemText
                                                                                style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                draggable={vField?.fieldValue !== null}
                                                                                onDragStart={(e) => {
                                                                                    e.dataTransfer.setData('type', "FormFields");
                                                                                    e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                    e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                    e.dataTransfer.setData('formType', "termFields");
                                                                                }}
                                                                                primary={`${vField?.fieldValue || '\u00A0'}`} />
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>}

                                        {/* exclusions form fields panel */}
                                        {(exclusionFields?.length > 0) && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('exclusionField')}><span>Exclusions</span></ListItemText>
                                                {
                                                    open['exclusionField'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_exclusion_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_exclusion_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesSave("exclusion"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_exclusion_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('exclusionField'); setFormFieldsEdit((prev) => ({ ...prev, is_exclusion_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_exclusion_edit ?
                                                                    <>
                                                                        {/* <Tooltip title={"Click to Cancle"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_exclusion_edit"); }}
                                                                                className="fa fa-times mr-2" aria-hidden="true" style={{ fontSize: "17px" }}></i>
                                                                        </Tooltip> */}
                                                                        {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >

                                                                            <i
                                                                                onClick={() => { handleClick('exclusionField'); onFormFieldsChangesSave("exclusion"); }}
                                                                                className="fa fa-save mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip> */}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleFormEditPanel("Exclusions"); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('exclusionField'); setFormFieldsEdit((prev) => ({ ...prev, is_exclusion_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('exclusionField')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>

                                            <Collapse style={{ marginBottom: "20px" }} in={open['exclusionField']} timeout="auto" unmountOnExit>
                                                {
                                                    exclusionFields?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                                // justifyContent: "space-between",
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                    {/* <i
                                                                        className=" fa fa-copy ml-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue) }}
                                                                    ></i> */}
                                                                </div>
                                                                <div
                                                                    // className='w-100' 
                                                                    style={!formFieldsEdit?.is_exclusion_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        // border: "1.5px dotted #0063A6",
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_exclusion_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    disabled={vField?.fieldValue === null}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                // onChange={(e) => handleFormFieldsChange(e.target.value, vField?.fieldId, "ExclusionFields")}
                                                                                // autoFocus
                                                                                // error={(vField?.fieldName.includes("*") && (!vField?.fieldValue))}
                                                                                // helperText={"Number Wanted"}
                                                                                // variant="outlined"
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <ListItemText
                                                                                style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                draggable={vField?.fieldValue !== null}
                                                                                onDragStart={(e) => {
                                                                                    e.dataTransfer.setData('type', "FormFields");
                                                                                    e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                    e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                    e.dataTransfer.setData('formType', "exclusionFields");
                                                                                }}
                                                                                primary={`${vField?.fieldValue || '\u00A0'}`} />
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>}



                                        {/* co-broker & commission form fields panel */}
                                        {
                                            (coBrokerFields?.length > 0) && <div className="my-1">
                                                <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                    <ListItemText onClick={() => handleClick('coBrokerField')}><span>Co-Broker & Commission</span></ListItemText>
                                                    {
                                                        open['coBrokerField'] ?
                                                            <>
                                                                {
                                                                    formFieldsEdit?.is_cobroker_edit ?
                                                                        <>
                                                                            <Tooltip title={"Click to Drag"} placement="bottom"
                                                                                PopperProps={{
                                                                                    modifiers: [
                                                                                        {
                                                                                            name: 'offset',
                                                                                            options: {
                                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                            >
                                                                                <i
                                                                                    onClick={() => { onFormFieldsChangesCancle("is_cobroker_edit"); }}
                                                                                    className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                            </Tooltip>
                                                                            {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                                 PopperProps={{
                                                                                     modifiers: [
                                                                                         {
                                                                                             name: 'offset',
                                                                                             options: {
                                                                                                 offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                             },
                                                                                         },
                                                                                     ],
                                                                                 }}
                                                                             >
                                                                                 <i
                                                                                     onClick={() => { onFormFieldsChangesSave("cobroker"); }}
                                                                                     className="fa fa-save mr-2 mt-1"
                                                                                 ></i>
                                                                             </Tooltip> */}
                                                                        </>
                                                                        :
                                                                        <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_cobroker_edit: true }))}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>
                                                                }
                                                                <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('coBrokerField'); setFormFieldsEdit((prev) => ({ ...prev, is_cobroker_edit: false })) }}></i>
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    formFieldsEdit?.is_cobroker_edit ?
                                                                        <>
                                                                            {/*   <Tooltip title={"Click to Cancle"} placement="bottom"
                                                                                 PopperProps={{
                                                                                     modifiers: [
                                                                                         {
                                                                                             name: 'offset',
                                                                                             options: {
                                                                                                 offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                             },
                                                                                         },
                                                                                     ],
                                                                                 }}
                                                                             >
                                                                                 <i
                                                                                     onClick={() => { onFormFieldsChangesCancle("is_cobroker_edit"); }}
                                                                                     className="fa fa-times mr-2" aria-hidden="true" style={{ fontSize: "17px" }}></i>
                                                                             </Tooltip> */}
                                                                            {/* <Tooltip title={"Click to Save"} placement="bottom"
                                                                                 PopperProps={{
                                                                                     modifiers: [
                                                                                         {
                                                                                             name: 'offset',
                                                                                             options: {
                                                                                                 offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                             },
                                                                                         },
                                                                                     ],
                                                                                 }}
                                                                             >

                                                                                 <i
                                                                                     onClick={() => { handleClick('coBrokerField'); onFormFieldsChangesSave("cobroker"); }}
                                                                                     className="fa fa-save mr-2 mt-1"
                                                                                 ></i>
                                                                             </Tooltip> */}
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <Tooltip title={"Click to Edit"} placement="bottom"
                                                                                PopperProps={{
                                                                                    modifiers: [
                                                                                        {
                                                                                            name: 'offset',
                                                                                            options: {
                                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                            >
                                                                                <i
                                                                                    onClick={() => { handleFormEditPanel("Co-Broker & Commission"); }}
                                                                                    className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                                ></i>
                                                                            </Tooltip>
                                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                                PopperProps={{
                                                                                    modifiers: [
                                                                                        {
                                                                                            name: 'offset',
                                                                                            options: {
                                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                            },
                                                                                        },
                                                                                    ],
                                                                                }}
                                                                            >
                                                                                <i
                                                                                    onClick={() => { handleClick('coBrokerField'); setFormFieldsEdit((prev) => ({ ...prev, is_cobroker_edit: true })) }}
                                                                                    className="fa fa-copy mr-2 mt-1"
                                                                                ></i>
                                                                            </Tooltip>}
                                                                        </>
                                                                }
                                                                {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                    PopperProps={{
                                                                        modifiers: [
                                                                            {
                                                                                name: 'offset',
                                                                                options: {
                                                                                    offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                },
                                                                            },
                                                                        ],
                                                                    }}
                                                                >
                                                                    <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('coBrokerField')}></i>
                                                                </Tooltip>}
                                                            </>
                                                    }
                                                </ListItemButton>

                                                <Collapse style={{ marginBottom: "20px" }} in={open['coBrokerField']} timeout="auto" unmountOnExit>
                                                    {/* for deal broker details */}
                                                    <p className='mt-3 mb-0 font-weight-bold'>Deal Broker</p>
                                                    {
                                                        coBrokerFields[0]?.map((vField, index) => (
                                                            <Fragment key={index}>
                                                                <ListItem className='form-fields ' style={{
                                                                    display: "block",
                                                                    padding: "5px 10px 5px 10px"
                                                                    // justifyContent: "space-between",
                                                                }}>
                                                                    <div style={{ minWidth: "70px" }}>
                                                                        <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                        {/* <i
                                                                             className=" fa fa-copy ml-2"
                                                                             style={{ cursor: "pointer" }}
                                                                             onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue) }}
                                                                         ></i> */}
                                                                    </div>
                                                                    <div
                                                                        // className='w-100' 
                                                                        style={!formFieldsEdit?.is_cobroker_edit ? {
                                                                            border: "1.5px dotted #0063A6",
                                                                            padding: "0px 5px 0px 4px"
                                                                        } : {
                                                                            // border: "1.5px dotted #0063A6",
                                                                            padding: "0px"
                                                                        }}>
                                                                        {
                                                                            formFieldsEdit?.is_cobroker_edit ?
                                                                                <>
                                                                                    <TextField
                                                                                        value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                        disabled={vField?.fieldValue === null}
                                                                                        style={{ "textAlign": "start", "width": "88%" }}
                                                                                    // onChange={(e) => handleFormFieldsChange(e.target.value, vField?.fieldId, "coBrokerDealSideFields")}
                                                                                    // autoFocus
                                                                                    // error={(vField?.fieldName.includes("*") && (!vField?.fieldValue))}
                                                                                    // helperText={"Number Wanted"}
                                                                                    // variant="outlined"
                                                                                    />
                                                                                    {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                        className="fa fa-copy ml-2 mt-2"
                                                                                        style={{ cursor: "pointer", position: "relative" }}
                                                                                        onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                    >
                                                                                        {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                            <div
                                                                                                style={{
                                                                                                    position: 'absolute',
                                                                                                    bottom: '-30px',
                                                                                                    left: '50%',
                                                                                                    transform: 'translateX(-50%)',
                                                                                                    background: '#3E8EFF',
                                                                                                    color: 'white',
                                                                                                    padding: '5px 5px',
                                                                                                    borderRadius: '5px',
                                                                                                    fontSize: '10px',
                                                                                                }}
                                                                                            >
                                                                                                Copied!
                                                                                            </div>
                                                                                        )}
                                                                                    </i>}
                                                                                </>
                                                                                :
                                                                                <ListItemText
                                                                                    style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                    draggable={vField?.fieldValue !== null}
                                                                                    onDragStart={(e) => {
                                                                                        e.dataTransfer.setData('type', "FormFields");
                                                                                        e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                        e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                        e.dataTransfer.setData('formType', "coBrokerDealSideFields");
                                                                                    }}
                                                                                    primary={`${vField?.fieldValue || '\u00A0'}`} />
                                                                        }
                                                                    </div>
                                                                </ListItem>
                                                                {/* <Divider /> */}
                                                            </Fragment>
                                                        ))
                                                    }
                                                    {/* for other side broker details */}
                                                    <p className='mt-3 mb-0 font-weight-bold'>Other Side's Broker</p>
                                                    {
                                                        coBrokerFields[1]?.map((vField, index) => (
                                                            <Fragment key={index}>
                                                                <ListItem className='form-fields ' style={{
                                                                    display: "block",
                                                                    padding: "5px 10px 5px 10px"
                                                                    // justifyContent: "space-between",
                                                                }}>
                                                                    <div style={{ minWidth: "70px" }}>
                                                                        <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                        {/* <i
                                                                             className=" fa fa-copy ml-2"
                                                                             style={{ cursor: "pointer" }}
                                                                             onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue) }}
                                                                         ></i> */}
                                                                    </div>
                                                                    <div
                                                                        // className='w-100' 
                                                                        style={!formFieldsEdit?.is_cobroker_edit ? {
                                                                            border: "1.5px dotted #0063A6",
                                                                            padding: "0px 5px 0px 4px"
                                                                        } : {
                                                                            // border: "1.5px dotted #0063A6",
                                                                            padding: "0px"
                                                                        }}>
                                                                        {
                                                                            formFieldsEdit?.is_cobroker_edit ?
                                                                                <>
                                                                                    <TextField
                                                                                        value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                        disabled={vField?.fieldValue === null}
                                                                                        style={{ "textAlign": "start", "width": "88%" }}
                                                                                    // onChange={(e) => handleFormFieldsChange(e.target.value, vField?.fieldId, "coBrokerOtherSideFields")}
                                                                                    // autoFocus
                                                                                    // error={(vField?.fieldName.includes("*") && (!vField?.fieldValue))}
                                                                                    // helperText={"Number Wanted"}
                                                                                    // variant="outlined"
                                                                                    />
                                                                                    {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                        className="fa fa-copy ml-2 mt-2"
                                                                                        style={{ cursor: "pointer", position: "relative" }}
                                                                                        onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                    >
                                                                                        {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                            <div
                                                                                                style={{
                                                                                                    position: 'absolute',
                                                                                                    bottom: '-30px',
                                                                                                    left: '50%',
                                                                                                    transform: 'translateX(-50%)',
                                                                                                    background: '#3E8EFF',
                                                                                                    color: 'white',
                                                                                                    padding: '5px 5px',
                                                                                                    borderRadius: '5px',
                                                                                                    fontSize: '10px',
                                                                                                }}
                                                                                            >
                                                                                                Copied!
                                                                                            </div>
                                                                                        )}
                                                                                    </i>}
                                                                                </>
                                                                                :
                                                                                <ListItemText
                                                                                    style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                    draggable={vField?.fieldValue !== null}
                                                                                    onDragStart={(e) => {
                                                                                        e.dataTransfer.setData('type', "FormFields");
                                                                                        e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                        e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                        e.dataTransfer.setData('formType', "coBrokerOtherSideFields");
                                                                                    }}
                                                                                    primary={`${vField?.fieldValue || '\u00A0'}`} />
                                                                        }
                                                                    </div>
                                                                </ListItem>
                                                                {/* <Divider /> */}
                                                            </Fragment>
                                                        ))
                                                    }
                                                    {/* <Divider /> */}
                                                </Collapse>
                                            </div>
                                        }

                                        {/* toys & tenders form fields panel */}
                                        {<div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText ><span>Toys & Tenders</span></ListItemText>
                                                {
                                                    <Tooltip title={"Click to Edit"} placement="bottom"
                                                        PopperProps={{
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                    },
                                                                },
                                                            ],
                                                        }}
                                                    >
                                                        <i
                                                            onClick={() => { handleFormEditPanel("Toys & Tenders"); }}
                                                            className="fa fa-edit mr-1 mt-1"
                                                        ></i>
                                                    </Tooltip>
                                                }
                                            </ListItemButton>
                                        </div>}

                                        {/* trailer form fields panel */}
                                        {<div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText ><span>Trailer</span></ListItemText>
                                                {
                                                    <Tooltip title={"Click to Edit"} placement="bottom"
                                                        PopperProps={{
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                    },
                                                                },
                                                            ],
                                                        }}
                                                    >
                                                        <i
                                                            onClick={() => { handleFormEditPanel("Trailer"); }}
                                                            className="fa fa-edit mr-1 mt-1"
                                                        ></i>
                                                    </Tooltip>
                                                }
                                            </ListItemButton>
                                        </div>}

                                        {/* Survey Waiver form fields panel */}


                                        {/* {documentName === "Survey Waiver" && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText ><span>Survey Waiver</span></ListItemText>
                                                {
                                                    <Tooltip title={"Click to Edit"} placement="bottom"
                                                        PopperProps={{
                                                            modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                    },
                                                                },
                                                            ],
                                                        }}
                                                    >
                                                        <i
                                                            onClick={() => { setSurveyWaiverFormShow(true); }}
                                                            className="fa fa-edit mr-1 mt-1"
                                                        ></i>
                                                    </Tooltip>
                                                }
                                            </ListItemButton>
                                        </div>} */}

                                        {/* IYBA Purchase & Sale Addendum form fields panel */}
                                        {/* {documentName === "Purchase & Sale Agreement Addendum" && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('agreementAmmendment')}><span>IYBA Purchase & Sale Agreement Addendum</span></ListItemText>
                                                {
                                                    open['agreementAmmendment'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_ybaaAmendment_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_agreementExclusion_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>

                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_agreementExclusion_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('agreementAmmendment'); setFormFieldsEdit((prev) => ({ ...prev, is_agreementExclusion_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_agreementExclusion_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_agreementExclusion_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => {
                                                                                    handleOpenPanel("IYBA Purchase & Sale Addendum", documentId);
                                                                                }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('agreementAmmendment'); setFormFieldsEdit((prev) => ({ ...prev, is_agreementExclusion_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('agreementAmmendment')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>
                                            <Collapse style={{ marginBottom: "20px" }} in={open['agreementAmmendment']} timeout="auto" unmountOnExit>
                                                {
                                                    agreementAmmendmuntField?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                </div>
                                                                <div
                                                                    style={!formFieldsEdit?.is_agreementExclusion_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_agreementExclusion_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                    disabled={vField?.fieldValue === ""}
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <>

                                                                                <ListItemText
                                                                                    style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                    draggable={vField?.fieldValue !== ""}
                                                                                    onDragStart={(e) => {
                                                                                        e.dataTransfer.setData('type', "FormFields");
                                                                                        e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                        e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                        e.dataTransfer.setData('formType', "agreementAmmendmuntField");
                                                                                    }}
                                                                                    primary={`${vField?.fieldValue || "\u00A0"}`} />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            <Divider />
                                                        </Fragment>
                                                    ))
                                                }
                                                <Divider />
                                            </Collapse>
                                        </div>} */}


                                        {/* Seller Closing form fields panel */}
                                        <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: documentName === "Seller Closing Statement" ? "#1ea2d5" : "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('sellerClosing')}><span>Seller Closing</span></ListItemText>
                                                {
                                                    open['sellerClosing'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_sellerClosing_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_sellerClosing_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>

                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_sellerClosing_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('sellerClosing'); setFormFieldsEdit((prev) => ({ ...prev, is_sellerClosing_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_sellerClosing_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_sellerClosing_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleFormEditPanel("Seller Closing"); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('sellerClosing'); setFormFieldsEdit((prev) => ({ ...prev, is_sellerClosing_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('sellerClosing')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>
                                            <Collapse style={{ marginBottom: "20px" }} in={open['sellerClosing']} timeout="auto" unmountOnExit>
                                                {
                                                    sellerClosing?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                </div>
                                                                <div
                                                                    style={!formFieldsEdit?.is_sellerClosing_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_sellerClosing_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                    disabled={vField?.fieldValue === ""}
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <>

                                                                                <ListItemText
                                                                                    style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                    draggable={vField?.fieldValue !== ""}
                                                                                    onDragStart={(e) => {
                                                                                        e.dataTransfer.setData('type', "FormFields");
                                                                                        e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                        e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                        e.dataTransfer.setData('formType', "sellerClosing");
                                                                                    }}
                                                                                    primary={`${vField?.fieldValue || "\u00A0"}`} />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>
                                        {/* closingggggggggggggggggg */}
                                        {/* Buyer Closing form fields panel */}
                                        <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: documentName === "Buyer Closing Statement" ? "#1ea2d5" : "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('buyerClosing')}><span>Buyer Closing</span></ListItemText>
                                                {
                                                    open['buyerClosing'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_buyerClosing_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_buyerClosing_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>

                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_buyerClosing_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('buyerClosing'); setFormFieldsEdit((prev) => ({ ...prev, is_buyerClosing_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_buyerClosing_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_buyerClosing_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleFormEditPanel("Buyer Closing"); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('buyerClosing'); setFormFieldsEdit((prev) => ({ ...prev, is_buyerClosing_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('buyerClosing')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>
                                            <Collapse style={{ marginBottom: "20px" }} in={open['buyerClosing']} timeout="auto" unmountOnExit>
                                                {
                                                    buyerClosing?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                </div>
                                                                <div
                                                                    style={!formFieldsEdit?.is_buyerClosing_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_buyerClosing_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                    disabled={vField?.fieldValue === ""}
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <>

                                                                                <ListItemText
                                                                                    style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                    draggable={vField?.fieldValue !== ""}
                                                                                    onDragStart={(e) => {
                                                                                        e.dataTransfer.setData('type', "FormFields");
                                                                                        e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                        e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                        e.dataTransfer.setData('formType', "buyerClosing");
                                                                                    }}
                                                                                    primary={`${vField?.fieldValue || "\u00A0"}`} />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>
                                        {/* IYBA Purchase & Sale Agreement Exclusion form fields panel */}
                                        {/* {documentName === "Purchase & Sale Agreement Exclusion" && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('agreementExclusion')}><span>IYBA Purchase & Sale Agreement Exclusion</span></ListItemText>
                                                {
                                                    open['agreementExclusion'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_agreementExclusion_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_agreementExclusion_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>

                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_agreementExclusion_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('agreementExclusion'); setFormFieldsEdit((prev) => ({ ...prev, is_agreementExclusion_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_agreementExclusion_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_agreementExclusion_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleOpenPanel("IYBA Purchase & Sale Agreement Exclusion", documentId); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('agreementExclusion'); setFormFieldsEdit((prev) => ({ ...prev, is_agreementExclusion_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('agreementExclusion')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>
                                            <Collapse style={{ marginBottom: "20px" }} in={open['agreementExclusion']} timeout="auto" unmountOnExit>
                                                {
                                                    agreementExclusion?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                </div>
                                                                <div
                                                                    style={!formFieldsEdit?.is_agreementExclusion_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_agreementExclusion_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                    disabled={vField?.fieldValue === ""}
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <>

                                                                                <ListItemText
                                                                                    style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                    draggable={vField?.fieldValue !== ""}
                                                                                    onDragStart={(e) => {
                                                                                        e.dataTransfer.setData('type', "FormFields");
                                                                                        e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                        e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                        e.dataTransfer.setData('formType', "agreementExclusion");
                                                                                    }}
                                                                                    primary={`${vField?.fieldValue || "\u00A0"}`} />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            <Divider />
                                                        </Fragment>
                                                    ))
                                                }
                                                <Divider />
                                            </Collapse>
                                        </div>} */}

                                        {materialDamageDocument &&
                                            <Fragment>
                                                <ListItemButton onClick={() => handleOpenPanel('Material Damage Disclosure')}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": asterix.materially_damaged === "fulfill" },
                                                                { "fa-circle-o": asterix.materially_damaged === "empty" || !asterix.materially_damaged },
                                                                { "fa-circle": asterix.materially_damaged === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Material Damage Disclosure'} />
                                                </ListItemButton>
                                                <Divider />
                                            </Fragment>
                                        }
                                        {/* IYBA Acceptance of Vessel form fields panel */}
                                        {documentName === "IYBA Acceptance of Vessel" && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('iybaAcceptance')}><span>IYBA Acceptance of Vessel</span></ListItemText>
                                                {
                                                    open['iybaAcceptance'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_ybaaAmendment_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_iybaAcceptance_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>

                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_ybaaAmendment_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('iybaAcceptance'); setFormFieldsEdit((prev) => ({ ...prev, is_ybaaAmendment_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_ybaaAmendment_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_iybaAcceptance_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleOpenPanel("IYBA Acceptance of Vessel", documentId); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('iybaAcceptance'); setFormFieldsEdit((prev) => ({ ...prev, is_ybaaAmendment_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('iybaAcceptance')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>
                                            <Collapse style={{ marginBottom: "20px" }} in={open['iybaAcceptance']} timeout="auto" unmountOnExit>
                                                {
                                                    acceptanceOfVesselField?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                </div>
                                                                <div
                                                                    style={!formFieldsEdit?.is_ybaaAmendment_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_ybaaAmendment_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                    disabled={!vField?.fieldValue}
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <ListItemText
                                                                                    style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                    draggable={vField?.fieldValue !== null}
                                                                                    onDragStart={(e) => {
                                                                                        e.dataTransfer.setData('type', "FormFields");
                                                                                        e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                        e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                        e.dataTransfer.setData('formType', "acceptanceOfVesselField");
                                                                                    }}
                                                                                    primary={`${vField?.fieldValue || "\u00A0"}`} />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            {/* <Divider /> */}
                                                        </Fragment>
                                                    ))
                                                }
                                                {/* <Divider /> */}
                                            </Collapse>
                                        </div>}

                                        {/* YBAA Amendment to PSA (Short Form) form fields panel */}
                                        {/* {documentName === "YBAA Amendment to PSA (Short Form)" && <div className="my-1">
                                            <ListItemButton sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} >
                                                <ListItemText onClick={() => handleClick('ybaaAmendment')}><span>YBAA Amendment to PSA (Short Form)</span></ListItemText>
                                                {
                                                    open['ybaaAmendment'] ?
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_ybaaAmendment_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_ybaaAmendment_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>

                                                                    </>
                                                                    :
                                                                    <Tooltip title={"Click to Copy"} placement="bottom"
                                                                        PopperProps={{
                                                                            modifiers: [
                                                                                {
                                                                                    name: 'offset',
                                                                                    options: {
                                                                                        offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                    },
                                                                                },
                                                                            ],
                                                                        }}
                                                                    >
                                                                        <i
                                                                            onClick={() => setFormFieldsEdit((prev) => ({ ...prev, is_ybaaAmendment_edit: true }))}
                                                                            className="fa fa-copy mr-2 mt-1"
                                                                        ></i>
                                                                    </Tooltip>
                                                            }
                                                            <i className="fa fa-chevron-up p-1" onClick={() => { handleClick('ybaaAmendment'); setFormFieldsEdit((prev) => ({ ...prev, is_ybaaAmendment_edit: false })) }}></i>
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                formFieldsEdit?.is_ybaaAmendment_edit ?
                                                                    <>
                                                                        <Tooltip title={"Click to Drag"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { onFormFieldsChangesCancle("is_ybaaAmendment_edit"); }}
                                                                                className="fa fa-arrows-alt mr-2 mt-1" aria-hidden="true"></i>
                                                                        </Tooltip>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Tooltip title={"Click to Edit"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleOpenPanel("YBAA Amendment to PSA (Short Form)", documentId); }}
                                                                                className={`fa fa-edit ${!loggedUser?.is_hide_drag_drop_copy ? "mr-3" : "mr-1"}  mt-1`}
                                                                            ></i>
                                                                        </Tooltip>
                                                                        {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Copy"} placement="bottom"
                                                                            PopperProps={{
                                                                                modifiers: [
                                                                                    {
                                                                                        name: 'offset',
                                                                                        options: {
                                                                                            offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            }}
                                                                        >
                                                                            <i
                                                                                onClick={() => { handleClick('ybaaAmendment'); setFormFieldsEdit((prev) => ({ ...prev, is_ybaaAmendment_edit: true })) }}
                                                                                className="fa fa-copy mr-2 mt-1"
                                                                            ></i>
                                                                        </Tooltip>}
                                                                    </>
                                                            }
                                                            {!loggedUser?.is_hide_drag_drop_copy && <Tooltip title={"Click to Drag"} placement="bottom"
                                                                PopperProps={{
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, 3], // Adjust the second value (5) to control the distance
                                                                            },
                                                                        },
                                                                    ],
                                                                }}
                                                            >
                                                                <i className="fa fa-arrows-alt p-1 mt-1" onClick={() => handleClick('ybaaAmendment')}></i>
                                                            </Tooltip>}
                                                        </>
                                                }
                                            </ListItemButton>
                                            <Collapse style={{ marginBottom: "20px" }} in={open['ybaaAmendment']} timeout="auto" unmountOnExit>
                                                {
                                                    ybaaAmendmentField?.map((vField, index) => (
                                                        <Fragment key={index}>
                                                            <ListItem className='form-fields ' style={{
                                                                display: "block",
                                                                padding: "5px 10px 5px 10px"
                                                            }}>
                                                                <div style={{ minWidth: "70px" }}>
                                                                    <ListItemText style={{ "cursor": "default", "display": "inline-block" }} primary={`${vField?.fieldName} :`} />
                                                                </div>
                                                                <div
                                                                    style={!formFieldsEdit?.is_ybaaAmendment_edit ? {
                                                                        border: "1.5px dotted #0063A6",
                                                                        padding: "0px 5px 0px 4px"
                                                                    } : {
                                                                        padding: "0px"
                                                                    }}>
                                                                    {
                                                                        formFieldsEdit?.is_ybaaAmendment_edit ?
                                                                            <>
                                                                                <TextField
                                                                                    value={`${vField?.fieldValue || (vField?.fieldValue == 0 ? vField?.fieldValue : '\u00A0')}`}
                                                                                    style={{ "textAlign": "start", "width": "88%" }}
                                                                                    disabled={vField?.fieldValue === ""}
                                                                                />
                                                                                {((vField?.fieldValue != null) && (!(Number.isNaN(vField?.fieldValue))) && (vField?.fieldValue != undefined) && (vField?.fieldValue !== "")) && <i
                                                                                    className="fa fa-copy ml-2 mt-2"
                                                                                    style={{ cursor: "pointer", position: "relative" }}
                                                                                    onClick={(e) => { handleFormFieldCopyClick(vField?.fieldValue, vField?.fieldId) }}
                                                                                >
                                                                                    {((copyStatus?.copied) && (copyStatus?.copiedFieldId === vField?.fieldId)) && (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '-30px',
                                                                                                left: '50%',
                                                                                                transform: 'translateX(-50%)',
                                                                                                background: '#3E8EFF',
                                                                                                color: 'white',
                                                                                                padding: '5px 5px',
                                                                                                borderRadius: '5px',
                                                                                                fontSize: '10px',
                                                                                            }}
                                                                                        >
                                                                                            Copied!
                                                                                        </div>
                                                                                    )}
                                                                                </i>}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <ListItemText
                                                                                    style={vField?.fieldValue !== null ? { "cursor": "move", "textAlign": "start", "wordBreak": "break-all" } : { "textAlign": "start", "wordBreak": "break-all" }}
                                                                                    draggable={vField?.fieldValue !== ""}
                                                                                    onDragStart={(e) => {
                                                                                        e.dataTransfer.setData('type', "FormFields");
                                                                                        e.dataTransfer.setData('fieldId', `${vField?.fieldId}`);
                                                                                        e.dataTransfer.setData('key', `${vField?.key}`);
                                                                                        e.dataTransfer.setData('formType', "ybaaAmendmentField");
                                                                                    }}
                                                                                    primary={`${vField?.fieldValue || "\u00A0"} `} />
                                                                            </>
                                                                    }
                                                                </div>
                                                            </ListItem>
                                                            <Divider />
                                                        </Fragment>
                                                    ))
                                                }
                                                <Divider />
                                            </Collapse>
                                        </div>} */}

                                    </List>
                                </div>
                            </>
                        }
                    </Box >
                </div >
            </div >
            <FormDataDrawer show={show} onClose={onClosePanel} formType={selectedForm} multiFormId={multiFormId} menuType={menuType} handleOpenPanel={handleOpenPanel} />
            <SurveyWaiverFormDataModal show={surveyWaiverFormShow} onClose={(isRefresh = false) => { setSurveyWaiverFormShow(false); if (isRefresh && reloadPdf != null) { reloadPdf(true); } }} vesselId={dataTransaction?.t_vessel?.id} />
        </>
    )
}

export default FormDataSideBar;