import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Auth from '../../../../../utils/auth';
import FormRow from "../../../../common/FormRow";

import { makeStyles } from '@mui/styles';
import moment from "moment";
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { eSignExemptOfBoatSoldForRemovalFromFLByANonresPurch, getTransactionEdocById } from '../../../../../redux/actions/transaction';
import { convertFirstCharIntoSmallCase, isSigned, renderMaskedValue, unauthorizedErrorToast } from '../../../../../utils/functions';
import {
    CORPORATE_OWNED,
    ENTITY_AND_OR_ASSIGN,
    JOINT_OWNER,
    LEGAL_AND_OR_ASSIGN,
    LEGAL_OR_ASSIGN,
    LEGAL_OWNER,
    LLC_OWNED,
    TRUST_OWNED
} from "../../../../../utils/signer";
import FormInputWrapper from '../../../../common/FormInputWapper';
import HandSignUploadModal from '../../../../common/HandSignUploadModal';
import SignatureMessageModal from '../../../../common/SignatureMessageModal';
import { ViewDocumentsModal } from '../../../../common/ViewDocumentsModal';
import CustomActionButtons from './CustomActionButtons';
import CustomRenderStatus from './CustomRenderStatus';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    previewSendDiv: {
        width: "250px !important"
    },
    handSignedDiv: {
        marginTop: "-4px"
    }
});

/**
* @param {Function} updateDocumentObj // It is used to update payload object
* @param {String} selectedRole //It is used to check which role is selected from signature tab
* @param {Object} payload //Prepared object for the back-end
* @param {Function} makeDropDownSigners // Send signers array to the signature tab for displaying signer drop-down
* @returns 
*/
export const EsignExemptOfBoatSoldForRemovalFromFLByANonresPurch = ({ transactionId, updateDocumentObj, makeDropDownSigners, selectedRole, payload, roles, menuType }) => {

    const dispatch = useDispatch();

    const [isSuccess, setSuccess] = useState(undefined);
    const [isError, setError] = useState(undefined);
    const [message, setMessage] = useState("");
    const [showAdditionalModal, setShowAdditionalModal] = useState(false);
    const [emailDetailsToSend, setEmailDetailsToSend] = useState(null);
    const [showHandsignModal, setShowHandsignModal] = useState(false);
    const [handSignDetails, setHandSignDetails] = useState(null);
    const [showDocument, setShowDocument] = useState(false);
    const [document, setDocument] = useState(null);
    const loginEmail = Auth.getInstance().getUserData().email;

    //Get logged User
    const user = Auth.getInstance().getUserData();
    const classes = useStyles();

    const deals = useSelector((state) => state.transactions);
    const dataTransaction = deals.transaction || {};
    const dataBuyer = dataTransaction.buyer || {};
    const dataBroker = dataTransaction.broker;
    const dataVessel = dataTransaction.t_vessel;
    const requiredFields = deals ? deals.requiredOptionFields : [];

    const eDoc = deals.transaction
        ? deals.transaction.t_edocuments.find(doc => doc.task_type === "exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003")
        : null;

    const signers = eDoc && eDoc.request_signature ? eDoc.request_signature.signers : [];
    const isHandSignedOnly = eDoc?.t_esign_template?.template?.is_hand_signed || false;

    const links = [
        { label: "Deals", link: `/deals/view/${transactionId}/main` },
        { label: "Exempt of Boat Sold for Removal from FL by a Nonres Purch - gt500003", link: "" },
    ];

    /**
     * useEffect to generate the array of signers which is available 
     * for this document only
    */
    useEffect(() => {
        if (!updateDocumentObj || !isValidSend()) {
            return;
        }
        let signerArr = [];

        if (!dataTransaction?.employing_broker && !isSigned(signers, "broker")) {
            signerArr.push("broker")
        }

        if (dataTransaction?.buyer && !isSigned(signers, "buyer")) {
            signerArr.push("buyer");
        }

        makeDropDownSigners(signerArr);
    }, [dataTransaction, signers.length]);

    //Apply use Effect when role is changed from the signature tab
    useEffect(() => {
        /**
        * Check selected role is included for this document &
        * Check the selected role's status is not equal to signed &
        * Check this document has proper data for the sign this document.
        */
        if (selectedRole && roles.includes(selectedRole)
            && (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === selectedRole)?.status != "Signed")
            && dataTransaction && isValidSend()) {

            //Prepare object for the payload
            let obj = {}
            obj[selectedRole] = {
                document_name: "exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003",
                id: dataTransaction?.id
            };

            //Update payload object
            updateDocumentObj(obj, selectedRole);
            return;
        }
    }, [selectedRole]);

    let vesselName = `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}` !== "'   "
        ? `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}`
        : "N/A";

    let brokerName = dataBroker
        ? `${dataBroker.first_name || ""} ${dataBroker.last_name}` === " "
            ? "N/A"
            : `${dataBroker.first_name || ""} ${dataBroker.last_name}`
        : "N/A";

    /**
     * @param {Object} evt // Event Object
     * @param {String} role
    */
    const previewByStep = (evt, role) => {
        dispatch(setLoadingStatus(true));
        dispatch(eSignExemptOfBoatSoldForRemovalFromFLByANonresPurch(dataTransaction.id, "preview", role)).then((res) => {
            dispatch(setLoadingStatus(false));
            if (res.status !== 200) {
                throw res;
            } else {
                // CloudFront URL Always unique on Click of preview
                // window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
                window.open(res.data.url, '_blank');
            }
        }).catch((err) => {
            dispatch(setLoadingStatus(false));
            switch (err.status) {
                case 400:
                    setMessage(`${err.data.error.message}`);
                    break;
                case 401:
                    toast.error("You are not authorized to access this action.")
                    break;
                default:
                    setMessage(`${err.data.message}`);
                    break;
            }
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 5000);
        });
    }

    /**
     * @param {Object} evt // Event Object
     * @param {String} mode //It can be "send" or "hand-signed"
     * @param {*} role 
     * @param {*} data // selected date for the hand signed
    */
    const sendByStep = (mode, role, data = null) => {
        let obj = {};
        if (data) {
            if (mode == 'signed') {
                obj["signed_date"] = data.signed_date;
                obj["signed_document"] = JSON.stringify(data.documents);
            } else {
                obj["additional_email_content"] = data.additional_email_content || '';
            }
            obj["selected_recipients"] = data.selectedUser
            obj["is_email_send"] = data.is_email_send ? 'yes' : 'no';
            obj["sign_url_access_days"] = data.sign_url_access_days;
        }
        obj["requested_by"] = user.id
        dispatch(setLoadingStatus(true));
        dispatch(eSignExemptOfBoatSoldForRemovalFromFLByANonresPurch(dataTransaction.id, mode, role, obj)).then(res => {
            dispatch(setLoadingStatus(false));
            if (res.status === 200) {
                if (data.signed_date) {
                    setMessage("Signed Date saved successfully");
                    toast.success(`Hand signed documents uploaded successfully.`);
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(undefined);
                    }, 3000);
                } else {

                    toast.success("Document sent for Hand Signed successfully.");
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(undefined);
                    }, 3000);

                }
            } else {
                switch (res.status) {
                    case 400:
                        setMessage(`${res.data.error.message}`);
                        break;
                    case 401:
                        unauthorizedErrorToast()
                        break;
                    default:
                        toast.error(`Something went wrong while upload hand signed documents.`);
                        setMessage(`${res.data.message}`);
                        break;
                }
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 5000);
            }
        });
    }

    const onAdditionalMessageModalOpen = (mode, role, data = null) => {
        const selectedRecipients = eDoc.request_signature?.signers?.find((signer) => signer.request_signer?.role === role) || {};
        setEmailDetailsToSend({ mode: mode, role: role, data: data, selectedRecipients: selectedRecipients.selected_recipients || [] });
        setShowAdditionalModal(true);
    }

    const onCloseAdditionalEmailModal = (isEmailSend, emailAdditionalContent = null) => {
        setShowAdditionalModal(false);
        if (isEmailSend) {
            sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, { ...emailAdditionalContent.data, is_email_send: true, selectedUser: emailAdditionalContent.data.selected_recipients })
        }
    }

    /**
     * On open Hand Sign document selection modal after selecting date
     * @param {*} role 
     * @param {*} signDate 
    */
    const onHandSignUploadModalOpen = (role, signDate) => {
        const selectedRecipients = eDoc.request_signature?.signers?.find((signer) => signer.request_signer?.role === role) || {};
        const client = dataTransaction[role.toLowerCase().replace('-', '_')];
        setHandSignDetails({
            documentName: eDoc.t_esign_template?.template?.template_name,
            signDate: signDate,
            role: role,
            client: client,
            selectedRecipients: selectedRecipients.selected_recipients || []
        });
        if (signDate && signDate != null) {
            setShowHandsignModal(true);
        }
    }

    /**
     * On close Hand Sign modal and submit uploaded document to sendByStep() function
     * @param {*} isUploaded 
     * @param {*} documentUploadFiles 
    */
    const onCloseHandsignModal = (isUploaded, documentUploadFiles = null, selectedUser = []) => {
        setShowHandsignModal(false);
        if (isUploaded) {
            sendByStep("signed", handSignDetails.role, { signed_date: handSignDetails.signDate, documents: documentUploadFiles, selectedUser: selectedUser })
        }
        setHandSignDetails(null);
    }

    /**
     * On open view hand signed uploaded modal
     * @param {*} document 
    */
    const handleOpenViewDocumentModal = (document) => {
        setDocument(document);
        setShowDocument(true);
    }

    /**
     * On close view hand signed uploaded modal
    */
    const handleCloseModal = () => {
        setDocument(null);
        setShowDocument(false);
    }

    /**
     * Check this document is valid for preview or not
     * @returns True or False
    */
    const isValidPreview = () => {
        return deals.requiredFields.buyer.length === 0 &&
            deals.requiredFields.co_broker.length === 0;
    };

    /**
     * Check this document is valid for send or not
     * @returns True or False
    */
    const isValidSend = () => {
        let valid = !deals.requiredFields.buyer.some(obj => Object.keys(obj).includes('email'));

        if (dataTransaction.co_buyer_contact_id) {
            valid = valid && !deals.requiredFields.co_buyer.some(obj => Object.keys(obj).includes('email'))
        }

        return valid;
    };



    const handleSignedDate = (role, signedDate) => {
        if (signedDate) {
            return moment(signedDate ? signedDate : null).utc().format("MM-DD-yyyy")
        }

        if (!signedDate) {
            if (eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.status === 'Signed') {
                const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
                return `${moment(eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.esigned_date || null).local().format("MM-DD-yyyy hh:mm A")} ${timezone}`;
            }
        }

        return null;
    }



    /**
     * This fucntion is used to change the payload 
     * as per user manually checks the checkbox of particular checkbox
     * @param {Object} e //Event Object 
     * @param {String} role 
     * @returns 
    */
    const checkBoxandler = (e, role) => {
        if (!isValidSend()) {
            return;
        }
        let obj = {};
        obj[role] = {
            document_name: "exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003",
            id: dataTransaction?.id
        };
        updateDocumentObj(obj, role, !e.target.checked, true);
    }
    const renderAction = (role) => {
        return (
            <CustomActionButtons
                role={role}
                signers={signers}
                isValidSend={isValidSend}
                handleOpenViewDocumentModal={handleOpenViewDocumentModal}
                previewByStep={previewByStep}
                onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
                onHandSignUploadModalOpen={onHandSignUploadModalOpen}
                dataTransaction={dataTransaction}
                deals={deals}
                isHandSignedOnly={true}
                menuType={menuType}
            />
        )
    }
    const renderStatus = (role) => {
        return (<CustomRenderStatus
            role={role}
            signedDate={signers.find(signer => signer.request_signer.role === role)?.signed_date}
            signers={signers}
            isValidSend={isValidSend}
            handleOpenViewDocumentModal={handleOpenViewDocumentModal}
            previewByStep={previewByStep}
            onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
            onHandSignUploadModalOpen={onHandSignUploadModalOpen}
            dataTransaction={dataTransaction}
            deals={deals}
            eDoc={eDoc}
            handleSignedDate={handleSignedDate}
            isHandSignedOnly={true}
        />
        )
    }

    /**
     * Function that used for Display Broker Name and Email Address as per the status 
     * If status is not Sent or Signed then display current Broker data otherwise display Signer data instead
     * @returns Broker Name and Email Address 
    */
    const getBrokerNameAndEmail = () => {
        return !["Sent", "Signed"].includes(signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `broker`)?.status)
            ? { name: brokerName, email: dataBroker.email }
            : {
                name: (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `broker`))?.request_signer?.name,
                email: (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `broker`))?.request_signer?.email_address
            }
    }

    return (
        <>
            <div className="uk-container uk-container-small uk-position-relative m-0">
                <div className="scroll-div">
                    <div className="form-container scroll-fix-div">
                        <div className="esign-send listing-agreement">
                            {!updateDocumentObj &&
                                <>
                                    {isSuccess ? (
                                        <div className="uk-alert uk-alert-primary" data-uk-alert>
                                            <p>{message}</p>
                                        </div>
                                    ) : isError ? (
                                        <div className="uk-alert uk-alert-danger" data-uk-alert>
                                            <p>{message}</p>
                                        </div>
                                    ) : null}
                                </>
                            }

                            <h4>
                                <b>{eDoc?.t_esign_template?.template?.template_name}</b>
                            </h4>

                            <FormRow><hr /></FormRow>
                            <FormRow>
                                <FormInputWrapper label="Vessel">{vesselName}</FormInputWrapper>
                            </FormRow>
                            <FormRow><hr /></FormRow>

                            {/* {menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail ? <></> :  */}
                            <>
                                <div className="client-row">
                                    <FormInputWrapper className="signer-name-block" label="Buyer">
                                        {[LEGAL_OWNER, LEGAL_OR_ASSIGN, LEGAL_AND_OR_ASSIGN, JOINT_OWNER].indexOf(dataTransaction?.buyer_type) > -1 && (
                                            (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                                                ? renderMaskedValue(`${dataBuyer?.first_name} ${dataBuyer?.last_name}`.length)
                                                : `${dataBuyer?.first_name} ${dataBuyer?.last_name}`
                                        )}
                                        {[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1 && (
                                            (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                                                ? renderMaskedValue(`${dataBuyer?.trust_signer_name}`.length)
                                                : `${dataBuyer?.trust_signer_name}`
                                        )}
                                        {!([LEGAL_OWNER, LEGAL_OR_ASSIGN, LEGAL_AND_OR_ASSIGN, JOINT_OWNER, ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1) && (
                                            `N/A`
                                        )}
                                    </FormInputWrapper>
                                    <FormInputWrapper className="signer-email-block" label="Buyer's Email">
                                        {[LEGAL_OWNER, LEGAL_OR_ASSIGN, JOINT_OWNER, LEGAL_AND_OR_ASSIGN].indexOf(dataTransaction?.buyer_type) > -1 && (
                                            (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                                                ? renderMaskedValue(`${dataBuyer?.email}`.length)
                                                : `${dataBuyer?.email}`
                                        )}
                                        {[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1 && (
                                            (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                                                ? renderMaskedValue(`${dataBuyer?.trust_signer_email}`.length)
                                                : `${dataBuyer?.trust_signer_email}`
                                        )}
                                        {!([LEGAL_OWNER, LEGAL_OR_ASSIGN, JOINT_OWNER, LEGAL_AND_OR_ASSIGN, ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1) && (
                                            `N/A`
                                        )}
                                    </FormInputWrapper>
                                    <div className={!updateDocumentObj && "signers_div"}>
                                        {signers && signers.find(signer => signer.request_signer.role === 'Buyer')
                                            ? renderStatus("Buyer")
                                            : renderAction("Buyer")}
                                    </div>
                                    {eDoc && updateDocumentObj && !isSigned(signers, "Buyer") &&
                                        <div className="col-1">
                                            <input
                                                type="checkbox"
                                                className="signatures_checkbox"
                                                onChange={(e) => { checkBoxandler(e, "buyer") }}
                                                checked={payload?.buyer?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                                                disabled={
                                                    (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "buyer" || (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                                                }
                                            />
                                        </div>
                                    }
                                </div>
                                <FormRow><hr /></FormRow>
                            </>

                            {dataBroker &&
                                menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail ? <></> : <>
                                <div className="client-row">
                                    <FormInputWrapper label="Broker" className="signer-name-block">{getBrokerNameAndEmail().name || "N/A"}</FormInputWrapper>
                                    <FormInputWrapper label="Broker's Email" className="signer-email-block">{getBrokerNameAndEmail().email || "N/A"}</FormInputWrapper>
                                    {eDoc &&
                                        <div className={!updateDocumentObj && "signers_div"}>
                                            {signers &&
                                                signers.find(signer => signer.request_signer.role === 'Broker')
                                                ? renderStatus("Broker")
                                                : renderAction("Broker")
                                            }
                                        </div>
                                    }
                                    {eDoc &&
                                        updateDocumentObj && !isSigned(signers, "Broker") &&
                                        <div className="col-1">
                                            <input
                                                type="checkbox"
                                                className="signatures_checkbox"
                                                onChange={(e) => { checkBoxandler(e, "broker"); }}
                                                checked={payload?.broker?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                                                disabled={
                                                    (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "broker"
                                                }
                                            />
                                        </div>
                                    }
                                </div>
                                <FormRow><hr /></FormRow>
                            </>
                            }

                            <br />
                            {requiredFields && requiredFields.length > 0 && (
                                <div className="form-row uk-padding-small">
                                    <p style={{ color: '#5479A3' }}>Required fields for Exempt of Boat Sold for Removal from FL by a Nonres Purch - gt500003.</p>
                                    <div>
                                        {requiredFields.map((obj, key) => {
                                            return key === requiredFields.length - 1
                                                ? `${Object.values(obj)[0]}`
                                                : `${Object.values(obj)[0]}, `;
                                        })}
                                    </div>
                                </div>
                            )}

                            {deals.requiredFields.buyer && deals.requiredFields.buyer.some(obj => Object.keys(obj).includes('email')) && (
                                <div className="form-row uk-padding-small">
                                    <p style={{ color: '#5479A3' }}>Required fields for Buyer.</p>
                                    <div>
                                        {deals.requiredFields.buyer.map((obj, key) => {
                                            return Object.values(obj)[0] == 'Email'
                                                ? `${Object.values(obj)[0]}`
                                                : ``;
                                        })}
                                    </div>
                                </div>
                            )}

                            {/* {deals.requiredFields.offer.length !== 0 && (
                        <div className="form-row uk-margin-small">
                            <label className="uk-form-label" htmlFor="name">
                                Offer (Send)
                            </label>
                            <div>
                                {deals.requiredFields.offer.map((obj, key) => {
                                    return key === deals.requiredFields.offer.length - 1
                                        ? `${Object.values(obj)[0]}`
                                        : `${Object.values(obj)[0]}, `;
                                })}
                            </div>
                        </div>
                    )} */}
                        </div>
                    </div>
                </div>
                <br />
            </div>
            <SignatureMessageModal show={showAdditionalModal} onClose={(isEmailSend, emailAdditionalContent = null) => onCloseAdditionalEmailModal(isEmailSend, emailAdditionalContent)} emailDetail={emailDetailsToSend} isNoGenerateLink={true} />
            {showHandsignModal && <HandSignUploadModal show={showHandsignModal} onClose={onCloseHandsignModal} signData={handSignDetails} />}
            {showDocument && <ViewDocumentsModal documentTask={document} onClose={handleCloseModal} />}
        </>
    )
}
