import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { getAllTransactions, updateTransaction } from "../../redux/actions/transaction";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../redux/actions/loading";
import ThemeButton from "./buttons/ThemeButton";
import { useEffect } from "react";
import TemplatesService from "../../services/TemplatesService";
import "./formPreviewModal.css"


const FormPreviewModal = ({ document,previewByStep, onClose }) => {
    const subDocumentId = document?.template?.edocument?.id || null;
    const docArr = ["Acceptance of Vessel","Release of Liability and Hold Harmless"]
    const [previewDocs, setpreviewDocs] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (subDocumentId) {
            dispatch(setLoadingStatus(true))
            TemplatesService.getEsignTemplateByIybaDocument(subDocumentId)
            .then(({ data }) => {
                const sortedTemplates = data?.templates?.sort((a,b) => a.is_main > b.is_main ? -1 : 1)
                setpreviewDocs(sortedTemplates);
                dispatch(setLoadingStatus(false))
            })
            .catch((err) => {
                dispatch(setLoadingStatus(false))
                toast.error("Error while fetching documents.", {
                    autoClose: 2000
                });
                onClose();
            })
        }
    },[])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        "border-radius": "20px",
        bgcolor: "#fff",
        border: '2px solid #000',
        boxShadow: 24,
        p: 0,
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={true}
            style={{
                borderRadius: "20px"
            }}
            closeAfterTransition
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box sx={style}>
                <div className="uk-modal-header modalHeader">

                    <h4 className="uk-modal-title modalTitle">Preview Documents</h4>
                </div>
                <div className="uk-modal-body modalBody">
                    <table className="uk-table uk-table-middle uk-table-responsive uk-table-divider" style={{ width : "-webkit-fill-available"}}>
                        <tbody>
                            {
                                previewDocs && previewDocs.map((template) => {
                                    return (
                                        <tr>
                                            <td>{`${template?.template_name || "N/A"} ${template?.is_main ? "(Main Doc)" : ""}`}</td>
                                            <td><ThemeButton onClick={() => {
                                                if (docArr.includes(template?.edocument?.title)) {
                                                    previewByStep(template?.template_name, template?.edocument?.id)
                                                    return;
                                                }
                                                previewByStep(template?.edocument?.title, template?.edocument?.id)
                                            }}>Preview</ThemeButton></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="uk-modal-footer uk-text-right modalFooter">
                    <button value="Submit" className="uk-button uk-button-primary" type="button"
                        onClick={onClose} >Close</button>
                </div>
            </Box>
        </Modal>
    );
};

export default FormPreviewModal;