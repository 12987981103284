import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import datatableStyles from "../../../../../utils/datatable-style";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import TransactionService from "../../../../../services/TransactionService";
import FormPreviewModal from "../../../../common/FormPreviewModal";
import { convertUnderscoreSeparateLetter } from "../../../../../utils/functions";
import { getSignURLForHandSign, getTransactionEdocById, getTransactionEsignById } from "../../../../../redux/actions/transaction";
import { getPlainTransactionData } from "../../../../../utils/stepFilter";
import { buyerSellerTransaction } from "../../../../../redux/actions/transaction";
import StyleSwitch from "../../../../common/buttons/StyleSwitch";

const FormViewTable = ({ searchProp, deal, tags, openModal, setTagList }) => {

  const dispatch = useDispatch();

  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const isLoading = useSelector((state) => state.loading.status);
  const dataEdocuments = dataTransaction?.t_edocuments || [];
  const [documents, setDocuments] = useState([]);
  const [transactionEdoc, setTransactionEdoc] = useState([]);
  const [showPreviewModal, SetshowPreviewModal] = useState(false);
  const [previewDocument, setPreviewDocument] = useState(null);
  const [search, setSearch] = useState({});
  const removeNullValues = (obj) => {
    for (const key in obj) {
      if (!obj[key]) {
        delete obj[key]
      }
    }
    return obj
  }

  const fetchDocuments = async (transactionId, filter, needEDoc = false, tags = "") => {
    try {
      dispatch(setLoadingStatus(true));
      const { data } = await TransactionService.fetchAllDocuments(transactionId, removeNullValues({ ...filter, tags: tags ?? "" }));
      setDocuments(data.templates);
      setTagList(data?.tags)
      setTransactionEdoc(data.transactionEdoc);
      if (needEDoc) {
        dispatch(getTransactionEdocById(dataTransaction?.id)).then((response) => {
          dispatch(setLoadingStatus(false));
        }).catch((error) => {
          dispatch(setLoadingStatus(false));
        });
      } else {
        dispatch(setLoadingStatus(false));
      }
    } catch (error) {
      console.log("======================= Error =========================");
      console.log(error);
      console.log("========================================================");
      dispatch(setLoadingStatus(false));
      toast.error("Error while fetching documents.", {
        autoClose: 2000
      })
    }
  }
  useEffect(async () => {
    if (tags || tags === "") {
      fetchDocuments(dataTransaction?.id, { ...search, ...searchProp }, false, tags);
    }
  }, [tags])
  useEffect(async () => {
    if (dataTransaction && dataTransaction.id ) {
      fetchDocuments(dataTransaction?.id, { ...search, ...searchProp }, false, tags);
    }
  }, [dataTransaction?.id, search, searchProp, openModal.current])

  const getESignedDocumentLink = async (s3_bucket, folder_path, file) => {
    try {
      await dispatch(getSignURLForHandSign({
        s3_bucket: s3_bucket, folder_path: folder_path, file_name: file, access_days: 1
      })).then((res) => {
        if (res?.url) {
          dispatch(setLoadingStatus(false));
          window.open(res?.url, "_blank")
        } else {
          console.error("Error fetching document:", res?.error);
          dispatch(setLoadingStatus(false));
        }
      })
    } catch (error) {
      console.error("Error fetching document:", error);
      dispatch(setLoadingStatus(false));
    }
  }

  const updateUseField = async (row) => {
    const tEdocument = transactionEdoc.filter(edoc => edoc.t_esign_template?.id == row.id);
    let edocId = null;
    if (tEdocument.length > 1) {
      let latestEdoc = tEdocument.reduce((a, b) => a.createdAt > b.createdAt ? a : b);
      if (latestEdoc) {
        if (latestEdoc.is_duplicate) {
          edocId = tEdocument.map(edoc => edoc.id);
        } else {
          edocId = [latestEdoc.id];
        }
      }
    } else {
      edocId = tEdocument[0]?.id ? [tEdocument[0]?.id] : null;
    }
    dispatch(setLoadingStatus(true));
    try {
      let updateData = {
        "use": !row?.use,
        transactionId: dataTransaction.id,
        edocId: edocId
      }
      const useFormResponse = await TransactionService.updateDocumentUseField(row?.id, updateData);
      await fetchDocuments(dataTransaction?.id, { ...search, ...searchProp }, true, tags);
      const closingFormName = row.template?.template_name == 'IYBA Buyer Closing Statement'
        ? 'buyer_closing'
        // : row.template?.template_name == 'IYBA Seller Closing Statement'
        //   ? 'seller_closing'
        : '';
      const existingDeposits = deal.t_details?.filter((detail) => { return detail.type == 'deposits' && detail.document_type == closingFormName.split('_')[0] });
      if (!row?.use && closingFormName && existingDeposits.length == 0) {
        formAutoSaveData(closingFormName);
      }
      toast.success("Document Updated Successfully.", {
        autoClose: 2000
      })
    } catch (error) {
      dispatch(setLoadingStatus(false));
      console.log(error);
      if (error.message.includes('409')) {
        toast.error('Warning: Document already have same use.')
      } else {
        toast.error("Error occurs, please try again.");
      }
    }
  }

  const getPreview = (documentName, id) => {
    try {
      dispatch(setLoadingStatus(true));
      TransactionService.getDocumentUrl(documentName)
        .then((res) => {
          dispatch(setLoadingStatus(false));
          console.log(res.data.url, "res.data.url");

          if (res?.data.url) {
            getESignedDocumentLink(process.env.REACT_APP_FORMS_BUCKET_NAME, `${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates`, res?.data?.url);
            // window.open(`https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates/${res.data.url}`, '_blank');
          } else {
            toast.error("Error occurs, please try again.", {
              autoClose: 2000
            })
          }
        })
        .catch(() => {
          dispatch(setLoadingStatus(false));
          toast.error("Error occurs, please try again.", {
            autoClose: 2000
          })
        })
    } catch (error) {
      toast.error("Error, While getting preview of document", {
        autoClose: 2000
      })
    }
  }


  const formAutoSaveData = (formType) => {
    const dataOffer = deal.t_offers;
    if (dataOffer && dataOffer?.less_deposit) {
      dispatch(setLoadingStatus(true));
      dispatch(buyerSellerTransaction({
        step: {
          db: "details",
          main: [{
            amount: dataOffer?.less_deposit || 0,
            date: null,
            type: "deposits",
            description: "Initial Deposit",
            document_type: formType.split('_')[0]
          }],
          detailType: "deposits",
          documentType: formType.split('_')[0]
        },
        transaction: {
          isBelonged: false,
          main: { ...getPlainTransactionData(deal), task_type: "deal" },
        },
      }, "")
      ).then((data) => {
        dispatch(getTransactionEsignById(dataTransaction.id)).then((response) => {
          dispatch(setLoadingStatus(false));
        }).catch((error) => {
          dispatch(setLoadingStatus(false));
        })
      }).catch((err) => {
        dispatch(setLoadingStatus(false));
      })
    }
  }

  const previewByStep = (documentName, documentId = null) => {
    try {
      dispatch(setLoadingStatus(true));
      let id = dataTransaction.id;

      switch (documentName) {
        case "Trade-In Vessel":
          const dataTradeInVessel = dataTransaction.t_trade_in_vessel ? dataTransaction.t_trade_in_vessel : null;
          id = dataTradeInVessel?.id || id;
          break;
        case "Seller Closing Statement":
          const dataSellerClosing = dataTransaction.t_seller_closing ? dataTransaction.t_seller_closing : null;
          id = dataSellerClosing?.id || id;
          break;
        case "Buyer Closing Statement":
          const dataBuyerClosing = dataTransaction.t_buyer_closing ? dataTransaction.t_buyer_closing : null;
          id = dataBuyerClosing?.id || id;
          break;
        case "IYBA Conditional Acceptance":
        case "Conditional Acceptance with Survey Allowance":
        case "IYBA Rejection of Vessel":
        case "IYBA Acceptance of Vessel":
        case "Acceptance of Vessel":
          id = id;
          break;
        case "Co-Broker Commission Agreement":
          const dataCobroker = dataTransaction?.co_broker || null;
          id = dataCobroker?.id || id;
          break;
        // case "Purchase & Sale Agreement Addendum":
        //   const addendumDocs = dataEdocuments.find((doc) => doc.title === "Purchase & Sale Agreement Addendum" && !doc.is_duplicate);
        //   id = addendumDocs?.id || id;
        //   break;
        // case "Purchase & Sale Agreement Exclusion":
        //   const exclusions = dataEdocuments.find((doc) => doc.title === "Purchase & Sale Agreement Exclusion" && !doc.is_duplicate);
        //   id = exclusions?.id || id;
        //   break;
        default:
          id = dataTransaction.id;
          break;
      }
      getPreview(documentName, id);
    } catch (error) {
      console.log(error);
      TransactionService.getDocumentUrl(documentName)
        .then((res) => {
          dispatch(setLoadingStatus(false));
          if (res?.data.url) {
            getESignedDocumentLink(process.env.REACT_APP_FORMS_BUCKET_NAME, `${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates`, res?.data.url)
            // window.open(`https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates/${res.data.url}`, '_blank');
          } else {
            toast.error("Error occurs, please try again.", {
              autoClose: 2000
            })
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          toast.error("Error occurs, please try again.", {
            autoClose: 2000
          })
        })
    }
  }

  const obj = {
    "single": "Single Use",
    "multi_select": "Multi Select",
    "multiple": "Multi Use"
  }

  const closePreviewModal = () => {
    setPreviewDocument(null);
    SetshowPreviewModal(false);
  }

  //Function for handle sort
  const handleSort = (column, sortDirection) => {
    setSearch((search) => {
      return {
        ...search,
        sortOrder: sortDirection,
        sortField: column.selector
      }
    });
  };

  const columns = [
    {
      name: "Order",
      selector: "sort_order",
      width: "80px",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.sort_order + 1 || " "
            }
          </div>
        </div>
      ),
    },
    {
      name: "USE",
      width: "130px",
      center: true,
      cell: (row) => (
        <label className='m-0'>
          <StyleSwitch
            checked={row.use}
            onChange={() => updateUseField(row)}
          />
        </label>
      ),
    },
    {
      name: "Template Name",
      selector: "template_name",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.is_custom_template ? row?.custom_template_title?.replace(',', '/') : row?.template?.template_name ? row?.template?.template_name?.replace(',', '/') : "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Association",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.is_custom_template ? "None" : row?.template?.association || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Category",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && convertUnderscoreSeparateLetter(row?.category || "") || "N/A"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Use Type",
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row?.is_custom_template ? "Multi Use" : obj[row?.template?.use_type] || ""
            }
          </div>
        </div>
      ),
    },
    {
      name: "Hand Signed Only",
      // grow: 2,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {
              row && row?.template?.is_hand_signed ? "Yes" : "No"
            }
          </div>
        </div>
      ),
    },
    {
      name: "Tags",
      center: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <div>
            {row && row?.tags?.join(", ")}
          </div>
        </div>
      ),
    },
    {
      name: "View Blank PDF",
      cell: (row) => (
        row?.template?.template_link && <div>
          <a onClick={() => {
            if (row && row?.template?.use_type && row?.template?.use_type == "multi_select") {
              SetshowPreviewModal(true);
              setPreviewDocument(row);
              return;
            }
            const fileLink = row?.template?.edocument?.title || false;
            if (fileLink) {
              previewByStep(fileLink, row?.template?.edocument?.id);
              return;
            }
            getESignedDocumentLink(process.env.REACT_APP_FORMS_BUCKET_NAME, `${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates`, row?.template?.template_link)
          }}>
            <img src="/icons/view.svg" className="svg" data-uk-tooltip="Preview Document" />
          </a>
        </div>
      ),
    },
  ];
  return (
    <>
      {showPreviewModal && previewDocument &&
        <FormPreviewModal document={previewDocument} previewByStep={previewByStep} onClose={closePreviewModal} />
      }
      {!documents.length && isLoading
        ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
        : <DataTable
          columns={columns}
          data={documents}
          highlightOnHover={true}
          striped={true}
          noHeader={true}
          onSort={handleSort}
          customStyles={datatableStyles}
        />
      }
    </>
  );
};

export default FormViewTable;
