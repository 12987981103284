import axios from 'axios';

const axiosInstance = axios.create({
  headers: {
    // Authorization: `Bearer ${Auth.getInstance().getAuthToken()}`,
    "Content-type": "application/json",
    "Cache-Control": "no-cache, no-store, must-revalidate",
    "Pragma": "no-cache",
    "Expires": "0",
    "Access-Control-Allow-Origin": process.env.REACT_APP_BACKEND_API_URL
  },
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  withCredentials: true
});

export const setHeaders = (token) => {
  Object.assign(axiosInstance.defaults, { headers: { authorization: `Bearer ${token}` } });
};

export default axiosInstance;
