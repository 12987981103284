import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from "react-redux";
import {
  Divider,
  Grid,
  ListItemText,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  ListItemIcon,
  createTheme,
  ThemeProvider,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
} from '@mui/material';
import { styled } from '@mui/system';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/core/SwitchUnstyled';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileSignature,
  faSignature,
  faCalendarDay,
  faUser,
  faAt,
  faBuilding,
  faBriefcase,
  faCalendarCheck,
  faCheckSquare,
} from '@fortawesome/free-solid-svg-icons';

import { setLoadingStatus } from "../../../../redux/actions/loading";
import { parseTemplate } from '../../../../redux/actions/templates';

import PDFViewer from '../../../common/PDFViewer/PDFViewer';
import { darkColors } from '../../../../utils/colors';
import { DocItem } from '../../../common/PDFViewer/drag-and-drop/DocItem';
import QuantityInput from '../../../../utils/NumberInput';
import { getSignURLForHandSign } from '../../../../redux/actions/transaction';

const theme = createTheme({
  components: {
    // Name of the component
    MuiSelect: {
      styleOverrides: {
        // Name of the slot
        select: {
          // Some CSS
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
      },
    },
  },
});

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
  },
  pdf_box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px',
    position: 'relative',
    height: '600px',
    overflow: 'scroll'
  },
  box_button: {
    lineHeight: 1.25
  },
  left_bar: {
    padding: '15px'
  },
  right_bar: {
    padding: '15px'
  },
});

const Switch = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin-top: 10px;
  cursor: pointer;

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchUnstyledClasses.track} {
    background: #b3c3d3;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 14px;
      top: 3px;
      background-color: #fff;
    }

    .${switchUnstyledClasses.track} {
      background: #007fff;
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

function extractUUIDFromUrl(url) {
  // Use a regular expression to capture the UUID from the URL
  const regex = /([a-f0-9\-]{36})\.pdf/;
  const match = url.match(regex);
  if (match) {
    return `${match[1]}.pdf`; // Return the UUID (first capture group)
  }
  return null; // Return null if no UUID is found
}

export const PlaceFieldsStep = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { roles, file_name, file_link, is_multi_section, total_sections } = props.form;
  // const template_file_link = `https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates/${file_link}`;
  const label = { componentsProps: { input: { 'aria-label': 'switch' } } };

  const [signer, setSigner] = useState(roles[0] || '');
  const [viewMode, setViewMode] = useState('edit');
  const [mergeField, setMergeField] = useState('');
  const [section, setSection] = useState('');
  const [isRequired, setRequired] = useState(false);
  const [assigner, setAssigner] = useState('');
  const [currentId, setCurrentId] = useState(null);
  const [pdfLink, setPdfLink] = useState(null);
  const [currentElem, setCurrentElem] = useState(null);
  const [fieldData, setFieldData] = useState(props.form.elements);

  // state to store current element status for edition 
  const [isFieldEditable, setIsFieldEditable] = useState(false);
  function extractUUIDFromUrl(url) {
    // Use a regular expression to capture the UUID from the URL
    const regex = /([a-f0-9\-]{36})\.pdf/;
    const match = url.match(regex);

    if (match) {
      return `${match[1]}.pdf`; // Return the UUID (first capture group)
    }
    return null; // Return null if no UUID is found
  }

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(parseTemplate(file_link)).then(res => {
      if (!props.form.template_id) {
        props.update('elements', res.fields);
        // props.update('file_link', template_file_link);
        setFieldData(res.fields);
      }
      props.update('width', res.width);
      props.update('height', res.height);
      dispatch(setLoadingStatus(false));
    }).catch(err => {
      dispatch(setLoadingStatus(false));
      console.log("Errors = ", err);
    });
  }, []);

  const getESignedDocumentLink = async (s3_bucket, folder_path, file) => {
    try {
      await dispatch(getSignURLForHandSign({
        s3_bucket: s3_bucket, folder_path: folder_path, file_name: file, access_days: 1
      })).then((res) => {
        if (res?.url) {
          setPdfLink(res?.url);
          dispatch(setLoadingStatus(false));
        } else {
          console.error("Error fetching document:", res?.error);
          dispatch(setLoadingStatus(false));
        }
      })
    } catch (error) {
      console.error("Error fetching document:", error);
      dispatch(setLoadingStatus(false));
    }
  }
  useEffect(async () => {

    if (file_link) {
      let link = await getESignedDocumentLink(process.env.REACT_APP_FORMS_BUCKET_NAME, `${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates`, extractUUIDFromUrl(file_link));
    }
  }, [file_link])

  useEffect(() => {
    if (currentId) {
      const element = fieldData.find(element => element.key === currentId);
      setCurrentElem(element);
      setMergeField(element.value);
      setIsFieldEditable(element?.is_editable || false); // set initially coming edition status
      setSection(element.section);
      setAssigner(element.assigner);

      if (element.required) {
        setRequired(element.required);
      }
    } else {
      setCurrentElem(null);
    }
  }, [currentId]);

  const handleChangeSigner = (event) => {
    setSigner(event.target.value);
  }

  const handleChangeAssigner = (event) => {
    setAssigner(event.target.value);
    const new_fields = fieldData.map(element => {
      let new_element = element;
      if (element.key === currentId) {
        new_element.assigner = event.target.value;
        if (event.target.value === "Me") {
          new_element.is_editable = false; // assigner changes to ME then element should non editable
        }
        else {
          new_element.is_editable = currentElem.is_editable;
          setIsFieldEditable(currentElem?.is_editable); // set current element edition status
        }
      }
      return new_element;
    });
    setFieldData(new_fields);
    props.update('elements', new_fields);
  }

  const handleChangeCoordination = (position) => {
    if (currentId) {
      const new_fields = fieldData.map(element => {
        if (element.key === currentId) {
          element.coord = position;
        }
        return element;
      });
      setFieldData(new_fields);
      props.update('elements', new_fields)
    }
  }

  const handleChangeViewMode = (event, newMode) => {
    setViewMode(newMode);
  };

  const handleChangeMergeField = (event) => {
    if (currentId) {
      setMergeField(event.target.value);
      const new_fields = fieldData.map(element => {
        if (element.key === currentId) {
          element.value = event.target.value;
        }
        return element;
      });
      setFieldData(new_fields);
      props.update('elements', new_fields);
    }
  }

  // function for do changes in particular element edition status as per change
  const handleChangeFieldEditable = (event) => {
    if (currentId) {
      setIsFieldEditable(event.target.value); // set state for mui radio button
      const new_fields = fieldData.map(element => {
        if (element.key === currentId) {
          element.is_editable = event.target.value === "true" ? true : false; // store as flag not in string for easy access
        }
        return element;
      });
      setFieldData(new_fields); // set new elements with updation
      props.update('elements', new_fields); // set new elements with updation in props element
    }
  }

  const handleChangeSection = (sectionValue) => {
    setSection(sectionValue);
    const new_fields = fieldData.map(element => {
      let new_element = element;
      if (element.key === currentId) {
        new_element.section = sectionValue;
      }
      return new_element;
    });
    setFieldData(new_fields);
    props.update('elements', new_fields);
  }

  const handleChangeRequired = (event) => {
    if (currentId) {
      const new_fields = fieldData.map(element => {
        if (element.key === currentId) {
          element['required'] = !isRequired;
          setRequired(!isRequired);
        }
        return element;
      });
      setFieldData(new_fields);
      props.update('elements', new_fields);
    }
  }

  const handleFocusField = (event) => {
    setCurrentId(event.target.id);
  }

  const handleBlurField = (event) => {
    setCurrentId(null);
  }

  const handleKey = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.key === "Delete") {
      const new_fields = fieldData.filter(element => element.key !== currentId);
      setCurrentId(null);
      setFieldData(new_fields);
      props.update('elements', new_fields);
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      console.log(event);
    }
  }

  // const handleDrag = (monitor) => {
  //   if (monitor.getClientOffset() && monitor.getSourceClientOffset()) {
  //     console.log({
  //       x: monitor.getClientOffset().x - monitor.getSourceClientOffset().x,
  //       y: monitor.getClientOffset().y - monitor.getSourceClientOffset().y
  //     });
  //   }
  // }

  const handleAddElement = useCallback((element) => {
    const new_data = [...fieldData, element];
    setFieldData(new_data);
    props.update('elements', new_data);
  }, [fieldData]);

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={2}>
          <Box className={classes.box}>
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={viewMode}
              exclusive
              onChange={handleChangeViewMode}
            >
              <ToggleButton value="edit">Edit</ToggleButton>
              <ToggleButton value="preview">Preview</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box className={classes.box}>
            Toolbar
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.box}>
            Toolbar
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2}>
          <Box className={classes.left_bar}>
            <Typography mb={2}>Signers</Typography>
            <Box sx={{ minWidth: 120 }} mb={2}>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120, width: '100%' }}>
                <InputLabel>Signer</InputLabel>
                <ThemeProvider theme={theme}>
                  <Select
                    value={signer}
                    onChange={handleChangeSigner}
                    displayEmpty
                    label="Signer"
                  >
                    <MenuItem key={0} value={'Me'}>
                      <ListItemIcon sx={{ minWidth: '18px !important', margin: '5px' }}><div style={{ backgroundColor: darkColors[0], width: '18px', height: '18px' }}></div></ListItemIcon>
                      <ListItemText>Me (Now)</ListItemText>
                    </MenuItem>
                    <MenuItem key={1} value={'Sender'}>
                      <ListItemIcon sx={{ minWidth: '18px !important', margin: '5px' }}><div style={{ backgroundColor: darkColors[1], width: '18px', height: '18px' }}></div></ListItemIcon>
                      <ListItemText>Sender</ListItemText>
                    </MenuItem>
                    {roles.map((role, idx) => {
                      return (
                        <MenuItem key={idx + 2} value={role}>
                          <ListItemIcon sx={{ minWidth: '18px !important', margin: '5px' }}><div style={{ backgroundColor: darkColors[idx + 2], width: '18px', height: '18px' }}></div></ListItemIcon>
                          <ListItemText>{role}</ListItemText>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </ThemeProvider>
              </FormControl>
            </Box>

            <Typography mb={1} mt={2}>Signature fields</Typography>
            <Box mb={1}>
              <DocItem
                name="Signature"
                type="signature"
                icon={faFileSignature}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Initials"
                type="initial"
                icon={faSignature}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>

            <Typography mb={1} mt={2}>Auto-fill fields</Typography>
            <Box mb={1}>
              <DocItem
                name="Date Signed"
                type="signature_date"
                icon={faCalendarDay}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Name"
                type="name"
                icon={faUser}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Email"
                type="email"
                icon={faAt}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Company"
                type="company"
                icon={faBuilding}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Tile"
                type="title"
                icon={faBriefcase}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>

            <Typography mb={1} mt={2}>Standard fields</Typography>
            <Box mb={1}>
              <DocItem
                name="Textbox"
                type="textbox"
                icon={faCalendarDay}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Checkbox"
                type="checkbox"
                icon={faUser}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Required Textbox"
                type="textbox"
                icon={faCalendarCheck}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
            <Box mb={1}>
              <DocItem
                name="Required Checkbox"
                type="checkbox"
                icon={faCheckSquare}
              // handleDrag={(monitor) => handleDrag(monitor)}
              />
            </Box>
          </Box>
        </Grid>

        {file_link ? <Grid item xs={8} sx={{ minWidth: '900px' }}>
          <Box className={classes.pdf_box}>
            <PDFViewer
              pdf={pdfLink}
              data={fieldData}
              current={currentElem}
              handleFocusField={handleFocusField}
              handleBlurField={handleBlurField}
              handleChangeCoordination={handleChangeCoordination}
              handleAddElement={handleAddElement}
              handleKey={handleKey}
              roles={roles}
              currentSigner={signer}
              width={props.form.width}
              height={props.form.height}
            />
          </Box>
        </Grid>: <p>Loadding PDF</p>}
        <Grid item xs={2}>
          <Box className={classes.left_bar}>
            {currentElem && (
              <>
                <Typography mb={2}>{currentElem.type}</Typography>
                <Typography mb={2}>Assigned to *</Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl variant="outlined" size="small" sx={{ minWidth: 120, width: '100%' }}>
                    <InputLabel>Assigner</InputLabel>
                    <ThemeProvider theme={theme}>
                      <Select
                        value={assigner}
                        onChange={handleChangeAssigner}
                        displayEmpty
                        label="Signer"
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <MenuItem key={0} value={'Me'}>
                          <ListItemIcon sx={{ minWidth: '18px !important', margin: '5px' }}><div style={{ backgroundColor: darkColors[0], width: '18px', height: '18px' }}></div></ListItemIcon>
                          <ListItemText>Me (Now)</ListItemText>
                        </MenuItem>
                        <MenuItem key={1} value={'Sender'}>
                          <ListItemIcon sx={{ minWidth: '18px !important', margin: '5px' }}><div style={{ backgroundColor: darkColors[1], width: '18px', height: '18px' }}></div></ListItemIcon>
                          <ListItemText>Sender</ListItemText>
                        </MenuItem>
                        {roles.map((role, idx) => {
                          return (
                            <MenuItem key={idx + 2} value={role}>
                              <ListItemIcon sx={{ minWidth: '18px !important', margin: '5px' }}><div style={{ backgroundColor: darkColors[idx + 2], width: '18px', height: '18px' }}></div></ListItemIcon>
                              <ListItemText>{role}</ListItemText>
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </ThemeProvider>
                  </FormControl>
                </Box>
                <Divider sx={{ margin: '10px 0px 10px 0px' }} />
                <Typography mb={2}>Merge field *</Typography>
                <TextField size="small" onChange={handleChangeMergeField} value={mergeField} label="Field Name" variant="outlined" />

                {/* if element type is textbox then only editable option will show and assiner should not be "Me" */}
                {(currentElem.type === "PDFTextField" && assigner !== "Me") && <div>
                  {/* Box to align FormLabel and RadioGroup side by side */}
                  <Box display="flex" alignItems="center" sx={{ marginRight: 2, marginTop: 2 }}>
                    <FormLabel sx={{ marginRight: 2 }}>Is Editable?</FormLabel>
                    <RadioGroup
                      aria-label="editable"
                      name="editable-group"
                      value={isFieldEditable} // Control the checked state based on isFieldEditable (true/false)
                      onChange={handleChangeFieldEditable}
                      row // Display radio buttons side by side
                    >
                      <FormControlLabel
                        value={true} // The "Yes" option is represented by value true
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false} // The "No" option is represented by value false
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Box>
                </div>}

                {(['PDFInitial', 'PDFSignature', 'PDFDateSigned'].includes(currentElem.type)) && is_multi_section &&
                  <>
                    <Divider sx={{ margin: '10px 0px 10px 0px' }} />
                    <div>

                      <Typography mb={1}>Section *</Typography>
                      {/* <TextField size="small" onChange={handleChangeSection} value={section} label="Section Number" variant="outlined" type='number' /> */}
                      <QuantityInput handleChange={handleChangeSection} value={section} label={'Section Number'} maxNum={parseInt(total_sections)} />
                    </div>
                  </>
                }

                {currentElem.type === 'PDFTextField' && currentElem.assigner !== 'Sender' && (
                  <>
                    <Divider sx={{ margin: '10px 0px 10px 0px' }} />
                    <Typography>Required *</Typography>
                    { /*<SwitchUnstyled component={Switch} {...label} onChange={handleChangeRequired} checked={isRequired} />*/}
                  </>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </div >
  );
};