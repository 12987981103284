import React from 'react';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { dateFormatter, momentDateWithTimezone } from "../../../../../utils/common";
import { getSigner } from "../../../../../utils/signer";

import { makeStyles } from "@mui/styles";
import { convertFirstCharIntoSmallCase } from '../../../../../utils/functions';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

const useStyles = makeStyles({
  previewSendDiv: {
    width: "250px !important",
  },
  handSignedDiv: {
    marginTop: "-4px",
  },
});
const CustomRenderStatus = ({ role,
  signedDate,
  signers,
  listings,
  isValidSend,
  handleOpenViewDocumentModal,
  previewByStep,
  onAdditionalMessageModalOpen,
  dataTransaction,
  onHandSignUploadModalOpen, eDoc, getEsignLink, renderLinkButtons, handleSignedDate, isValidPreview = null }) => {

  const classes = useStyles();

  const getSignerByRole = () => {
    switch (role) {
      case 'Selling-Broker':
      case 'Listing-Broker':
        return dataTransaction.broker;

      case 'Owner':
        return dataTransaction.seller;

      case 'Co-Owner':
        return dataTransaction.co_seller;

      case 'Broker':
        return dataTransaction.broker;

      default:
        return dataTransaction[convertFirstCharIntoSmallCase(role)];
    }
  }

  return (
    <>
      <div className="status-main-block">
        <div className="signer-status-block">
          <label className="uk-form-label" htmlFor="buyer">
            Status
          </label>
          <span>
            {getSigner(signers, role)?.status}
            {getSigner(signers, role)?.status == 'Declined' &&
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="right"
                overlay={
                  <Popover id={`popover-positioned-`}>
                    <Popover.Title as="h6">Reason</Popover.Title>
                    <Popover.Content>{eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.note}</Popover.Content>
                  </Popover>
                }>
                <img src="/icons/comment.svg" alt="comment" className="rejectReason" />
              </OverlayTrigger>
            }
          </span>
          <span className='d-block' style={{ fontSize: '10px' }}>
            {getSigner(signers, role)?.status == 'Sent'
              ? `${momentDateWithTimezone(getSigner(signers, role)?.updatedAt)}`
              : ''
            }
          </span>
        </div>
        <div className="signer-button-block">
          <div>
            <button
              className="uk-button uk-button-default"
              type="button"
              id="preview-pier-one-listing-agreement-for-esign"
              onClick={(evt) => {
                const signed_document = signers?.find(signer => signer?.request_signer.role === role)?.hand_signed_docs?.length ? signers?.find(signer => signer?.request_signer.role === role)?.hand_signed_docs : null;
                signed_document
                  ? handleOpenViewDocumentModal(signed_document)
                  : previewByStep(evt, role);
              }}
              disabled={!listings.transaction || (isValidPreview && !isValidPreview(listings.requiredFields))}
            ><i className='fa fa-cloud-download' area-hidden="true"></i>&nbsp;Preview
            </button>
          </div>

          {getSigner(signers, role)?.status !== 'Signed' && (
            <div className='d-flex flex-column'>
              <button style={getEsignLink(role) ? { height: '26px', marginBottom: '5px', padding: '0 12px 0' } : {}}
                className="uk-button uk-button-primary"
                id="send-pier-one-listing-agreement-for-esign"
                onClick={(evt) => onAdditionalMessageModalOpen("send", role, getSignerByRole())}
                disabled={
                  !listings.transaction || !isValidSend()
                }
              ><i className='fa fa-pencil-square-o' area-hidden="true"></i>&nbsp;Resend
              </button>
              {getEsignLink(role)
                ? renderLinkButtons(role)
                : <></>
              }
            </div>
          )}

          {getSigner(signers, role)?.status === 'Signed' && (
            <div>
              <button
                className="uk-button uk-button-primary"
                id="send-pier-one-listing-agreement-for-esign"
                onClick={(evt) => onAdditionalMessageModalOpen("send", role, getSignerByRole())}
                disabled={true}
              ><i className='fa fa-pencil-square-o' area-hidden="true"></i>&nbsp;Send
              </button>
            </div>
          )}

          <div className={classes.handSignedDiv}>
            <label className="uk-form-label" htmlFor="buyer">
              {signedDate && "Hand Signed"}
              {eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.status !== 'Signed' && "Hand Signed?"}
              {eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.status === 'Signed' && !signedDate && "Signed"}
            </label>
            {getSigner(signers, role)?.status === 'Signed'
              ? <>
                <span style={{ fontSize: '.8rem' }}>{handleSignedDate(role, signedDate)}</span>
                <p style={{ fontSize: '.8rem' }}>Signer IP: {eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.signed_from_ip || 'N/A'}</p>
              </>
              :
              <DatePicker
                className="uk-input uk-form-width-small"
                style={{ cursor: 'pointer', color: '#000' }}
                format={'MM/DD/YYYY'}
                onChange={async (date, dateString) => {
                  onHandSignUploadModalOpen(role, dateFormatter(dateString));
                }}
              />
            }
          </div>
        </div>
      </div>
    </>
  )

}

export default CustomRenderStatus