import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useAtom } from 'jotai';
import { atomWithHash } from 'jotai-location';

import SideVessel from "./layouts/accordions/SideVessel";
import SideSeller from "./layouts/accordions/SideSeller";
import SideToys from "./layouts/accordions/SideToys";
import SideAddendum from "./layouts/accordions/SideAddendum";
import SideExclusions from "./layouts/accordions/SideExclusions";
import ViewVesselForm from "./layouts/forms/ViewVessel";
import ViewSellerForm from "./layouts/forms/ViewSeller";
import ViewAddendumForm from "./layouts/forms/ViewAddendum";
import ViewExclusionsForm from "./layouts/forms/ViewExclusions";
import UploadDocument from "./layouts/forms/UploadDocument";
import DeleteConfirm from "./layouts/forms/DeleteConfirm";
// import UpdateTaskStatus from "./layouts/forms/UpdateTaskStatus";
import UpdateTaskStatus from "../../deals/View/layouts/forms/UpdateTaskStatus";
import EdocumentsViewTable from "./layouts/tables/EdocumentsViewTable";
import UploadViewTable from "./layouts/tables/UploadViewTable";
import UploadEditTable from "./layouts/tables/UploadEditTable";
import TaskViewTable from "./layouts/tables/TaskViewTable";
import TaskEditTable from "./layouts/tables/TaskEditTable";
import Activities from "../../deals/View/Activities";

import TransactionDetails from "../../common/TransactionDetails";
import PageSidebarContainer from "../../common/PageSidebarContainer";
import TransactionDetailContent from "../../common/TransactionDetailContent";
import PageMainContainer from "../../common/PageMainContainer";

import { getEdocCompanyTaskList, getTransactionEdocById, getTransactionEsignById, getTransactionInitialById, reOrderTasks, setAppliedTagFilter, updateRequiredFields } from "../../../redux/actions/transaction";
import { getTaskByChecklistId, getChecklist } from "../../../redux/actions/document_tasks";

import { setLoadingStatus } from "../../../redux/actions/loading";

import ViewToysForm from "./layouts/forms/ViewToys";
import FormViewTable from "./layouts/tables/FormViewTable";
import AddFormTask from "./layouts/forms/AddFormTask";
import FormEditTable from "./layouts/tables/FormEditTable";
import Signatures from "./layouts/forms/Signatures";

import "./MainView.css"
import DocumentAndTaskService from "../../../services/DocumentAndTaskService";
import TransactionService from "../../../services/TransactionService";
import FormDataSideBar from "./layouts/FormDataSideBar";
import { toast } from "react-toastify";
import { Tab, Tabs } from "@mui/material";
import { DebounceInput } from "react-debounce-input";
import AddEsignCustomDocModal from "../../common/AddEsignCustomDocModal";

const tabs = [
  { name: 'Prep Docs', tab: 'prepDocs' },
  { name: 'Signatures', tab: 'signatures' },
  { name: 'Uploads', tab: 'uploads' },
  { name: 'Tasks', tab: 'tasks' },
  { name: 'Forms', tab: 'forms' },
]

const tabAtom = atomWithHash('tab', tabs[0].tab);

const ListingMainView = (props) => {

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const checklist = useSelector((state) => state.documents.checklist);
  const allTasks = useSelector((state) => state.documents.tasks);
  const deals = useSelector((state) => state.transactions);
  const dataTransaction = useSelector((state) => state.transactions.transaction);

  const dataListing = dataTransaction || {};
  const dataExclusions = dataListing.t_exclusion;
  const dataAmmendment = dataListing.t_addendum;
  const dataToys = dataListing.t_toys;
  const dataTasks = dataListing.t_task_master || [];
  const dataEdocuments = dataListing.t_edocuments || [];

  const [uploadFilter, setUploadFilter] = useState(null);
  const [taskFilter, setTaskFilter] = useState(null);
  const [tagFilter, setTagFilter] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [tags, setTags] = useState("");
  const [tagList, setTagList] = useState([]);
  const [companytagList, setCompanytagList] = useState([]);
  const [search, setSearch] = useState({});

  const [tab, setTab] = useAtom(tabAtom);
  const [isUploadView, setIsUploadView] = useState(true);
  const isFetchingdoc = useRef(false);

  // dataForUpdateUploadListOrder State Will Contain Updated Data With Updated SortOrder Which Will Use In updateOrder's Api Payload
  const [dataForUpdateUploadListOrder, setDataForUpdateUploadListOrder] = useState([]);

  const [isTaskView, setIsTaskView] = useState(true);

  // dataForUpdateTaskListOrder State Will Contain Updated Data With Updated SortOrder Which Will Use In updateOrder's Api Payload
  const [dataForUpdateTaskListOrder, setDataForUpdateTaskListOrder] = useState([]);

  const [isFormView, setIsFormView] = useState(true);

  // dataForUpdateFormListOrder State Will Contain Updated Data With Updated SortOrder Which Will Use In updateOrder's Api Payload
  const [dataForUpdateFormListOrder, setDataForUpdateFormListOrder] = useState([]);

  const [updatingTask, setUpdatingTask] = useState(null);
  const [predocumentType, setPredocumentType] = useState(null);
  const [checklistId, setChecklistId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [statusModalShow, setStatusModalShow] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const company_id = user.company_id;

  useEffect(() => {
    if (props.location.state && props.location.state.active) {
      setTab(props.location.state.active);
      let state = props.location.state;
      delete state.active;
      history.replace({ ...history.location, state });
    }

    if (!checklist.length) { dispatch(getChecklist()) }
  }, []);

  useEffect(() => {
    setTagFilter(dataListing.applied_filter_tag_id || '');
  }, [dataListing.applied_filter_tag_id]);

  const getCompanyTagList = useCallback(async () => {
    try {
      const response = await DocumentAndTaskService.getCompanyTagList(company_id);
      setCompanytagList(response.data?.data);
    } catch (error) {
      console.error("Error=> ", error);
    }
  }, []);

  useEffect(() => {
    getCompanyTagList();
  }, [])

  useEffect(() => {
    dispatch(setLoadingStatus(true));
    dispatch(getTransactionInitialById(id)).then(res => {
      dispatch(setLoadingStatus(false));
      dispatch(getTransactionEdocById(id))
        .then(() => {
          dispatch(getTransactionEsignById(id))
            .catch(() => {
              dispatch(setLoadingStatus(false));
            })
        }).catch(() => {
          dispatch(setLoadingStatus(false));
        })
    }).catch(() => {
      dispatch(setLoadingStatus(false));
      history.push("/listing-agreement");
    });
  }, [id]);


  useEffect(() => {
    const listing = checklist.find(entry => entry.short_name === 'listing');
    if (listing) {
      setChecklistId(listing.id);
      dispatch(getTaskByChecklistId(listing.id, 'iyba', company_id));
      dispatch(getEdocCompanyTaskList(company_id, id));
    }
  }, [checklist]);

  // updateTaskAndUploadListOrder function made for call updateTaskAndUploadListOrder's api which will update sortOrder as per change
  const updateTaskAndUploadListOrder = async (data) => {
    dispatch(setLoadingStatus(true));
    try {
      await dispatch(reOrderTasks(data));
      // dispatch(setLoadingStatus(false));
    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error, While update sort order", {
        autoClose: 2000
      })
    }
  }

  // updateFormListOrder function made for call updateFormListOrder's api which will update sortOrder as per change
  const updateFormListOrder = async (data) => {
    dispatch(setLoadingStatus(true));
    try {
      await TransactionService.updateSortOrederForm(data);
      // dispatch(setLoadingStatus(false));
    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error, While update sort order", {
        autoClose: 2000
      })
    }
  }

  // function for handle click of updateUploadListOrder
  const handleUpdateUploadListOrder = async (isCancel = false) => {
    // if there is any changes in sort order then only call updateTaskAndUploadListOrder's Api
    if ((!isCancel) && dataForUpdateUploadListOrder.length > 0) {
      await updateTaskAndUploadListOrder(dataForUpdateUploadListOrder);
    }
    setIsUploadView(true);
    setDataForUpdateUploadListOrder([]);
  }

  // function for handle click of updateTaskListOrder
  const handleUpdateTaskListOrder = async (isCancel = false) => {
    // if there is any changes in sort order then only call updateTaskListOrder's Api
    if ((!isCancel) && dataForUpdateTaskListOrder.length > 0) {
      await updateTaskAndUploadListOrder(dataForUpdateTaskListOrder);
    }
    setIsTaskView(true);
    setDataForUpdateTaskListOrder([]);
  }

  // function for handle click of updateFormListOrder
  const handleUpdateFormListOrder = async (isCancel = false) => {
    // if there is any changes in sort order then only call updateFormListOrder's Api
    if ((!isCancel) && dataForUpdateFormListOrder.length > 0) {
      await updateFormListOrder(dataForUpdateFormListOrder);
    }
    setIsFormView(true);
    setDataForUpdateFormListOrder([]);
  }

  const handleFilterTagChange = (tagId) => {
    const filter = {
      applied_filter_tag_id: tagId || null
    };
    dispatch(setLoadingStatus(true));
    dispatch(setAppliedTagFilter(id, filter))
      .then(
        response => {
          setTagFilter(response.id || '');
          dispatch(setLoadingStatus(false));
        })
      .catch(
        error => {
          setTagFilter('');
          dispatch(setLoadingStatus(false));
        })
  }
  const handleFilterLATagChange = (value) => {
    setTags(value);
  }
  const handleEsignCustomDocModal = () => {
    setOpenModal(true);
  }

  const handleEsignCustomDocModalClose = () => {
    setOpenModal(false);
  }

  let dataDocument = dataTasks.filter((task) => {
    if (uploadFilter) {
      return task.task_type === "document" && task.status == uploadFilter
    }
    return task.task_type === "document";
  }).filter(task => {
    if (tagFilter && task.company_tasks?.create_type === 'global') {
      if (task.tags[0] == null) {
        return false;
      }
      return task.task_type === "document" && !!task.tags.find(tag => tag.id == tagFilter);
    }
    return task.task_type === "document";
  })
  // }).sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));

  let dataTask = dataTasks.filter((task) => {
    if (taskFilter) {
      if (taskFilter === "not_started") {
        return task.task_type === "task" && task.status == null;
      }
      return task.task_type === "task" && task.status == taskFilter
    }
    return task.task_type === "task"
  }).filter(task => {
    if (tagFilter && task.company_tasks?.create_type === 'global') {
      if (task.tags[0] == null) {
        return false;
      }
      return task.task_type === "task" && !!task.tags.find(tag => tag.id == tagFilter);
    }
    return task.task_type === "task";
  })
  // }).sort((a, b) => a.sort_order > b.sort_order ? 1 : -1);

  let dataForm = dataTasks.filter((task) => task.task_type === "form").sort((a, b) => a.updatedAt > b.updatedAt ? 1 : -1);

  const titles = {
    draft: "DRAFT",
    progress: "IN-PROCESS",
    cancelled: "CANCELLED",
    active: "ACTIVE",
    completed: "SOLD",
    rejected: "REJECTED",
    archived: "ARCHIVED"
  };

  const addAddendum = () => {
    history.push(`/listing-agreement/view/${id}/listing-agreement-addendum`);
  };

  const addExclusion = () => {
    history.push(`/listing-agreement/view/${id}/listing-agreement-exclusion`);
  }

  const renderForms = () => {
    return (
      <>
        <ViewVesselForm
          vessel={dataListing.t_vessel}
          askingPrice={dataListing.t_terms?.asking_price}
        />
        <ViewSellerForm
          type={dataListing?.seller_type}
          seller={dataListing?.seller}
          coseller={dataListing?.co_seller}
        />
        <ViewAddendumForm addendum={dataAmmendment} />
        <ViewExclusionsForm exclusions={dataExclusions} />
        <ViewToysForm toys={dataToys} />
      </>
    );
  };

  const getCompanyTaskList = () => {
    dispatch(setLoadingStatus(true));
    dispatch(getEdocCompanyTaskList(company_id, id, updatingTask.sortOrder ? { sortOrder: updatingTask.sortOrder } : {}))
      .then((response) => {
        dispatch(setLoadingStatus(false));
      }).catch((error) => {
        dispatch(setLoadingStatus(false));
      });
  }

  const renderUploadTabForms = () => {
    return (
      <div>
        <UploadDocument
          transactionId={dataListing.id}
          sortOrder={dataTasks.length}
          predocumentType={predocumentType}
          companyId={company_id}
          show={modalShow}
          onClose={() => setModalShow(false)}
          uniqueId={dataListing?.unique_transaction_id && `LA-${dataListing.unique_transaction_id?.split('-')[1] || ""}`}
          companyUId={dataListing?.unique_transaction_id && `${dataListing.unique_transaction_id?.split('-')[0] || ""}`}
        />
        <UpdateTaskStatus task={updatingTask} transactionId={id} show={statusModalShow} onClose={(refreshList = false) => { setStatusModalShow(false); setUpdatingTask(null); if (refreshList) { getCompanyTaskList() }; }} />
      </div>
    );
  };

  const renderPrefillTabForms = () => {
    return (
      <AddFormTask
        transactionId={dataListing.id}
        checklistId={checklistId}
        formList={allTasks.filter(task => task.task_type === 'form')}
        data={dataForm}
      />
    )
  }

  // const renderSideAccordions = () => {
  //   return (
  //     <>
  //       <b>SUMMARY INFO :</b>
  //       <ul data-uk-accordion style={{ marginTop: "10px" }}>
  //         <SideVessel
  //           vessel={dataListing.t_vessel}
  //           askingPrice={dataListing.t_terms?.asking_price}
  //         />
  //         <SideSeller seller={dataListing.seller} type={dataListing.seller_type} />
  //         <SideToys toys={dataToys} />
  //         <SideAddendum addendum={dataAmmendment} />
  //         <SideExclusions exclusions={dataExclusions} />
  //       </ul>
  //       <i style={{ fontSize: 11 }} >*Preview only - no editing</i>
  //     </>
  //   );
  // };

  const renderTabContent = () => {
    return (
      <>
        {(tab === "prepDocs" || tab === "edocuments") && (
          <>
            <EdocumentsViewTable data={dataEdocuments} setTab={setTab} status={dataListing.status} taskType={"listing"} />
            {/* <div style={{ marginTop: "20px" }}>
              <ThemeButton onClick={addExclusion}>Add Exclusion List</ThemeButton>
              <ThemeButton onClick={addAddendum}>Add Addendum</ThemeButton>
            </div> */}
          </>
        )}
        {tab === "uploads" && (
          <>
            <div className="nav-operation-buttons uploads_buttons">
              {isUploadView ? (
                <div className="d-flex flex-wrap">
                  {/* // ** Commenting this feature as per client requirement */}
                  <button
                    className="uk-button uk-button-default"
                    type="button"
                    onClick={() => { setPredocumentType(null); setModalShow(true); }} >
                    Add Document
                  </button>
                  <button
                    className="uk-button uk-button-default"
                    onClick={() => { setIsUploadView(false); setDataForUpdateUploadListOrder([]); }}
                  >
                    Edit Sort
                  </button>
                  <div className="one-column uk-padding-small">
                    <select className="uk-select" onChange={(e) => setUploadFilter(e.target.value)}>
                      <option key={`status_default`} value="">Select a Status</option>
                      <option key="Open" value="open">Open</option>
                      <option key="Required" value="required">Required</option>
                      <option key="Received" value="received">Received</option>
                    </select>
                  </div>
                  <div className="one-column uk-padding-small">
                    <select className="uk-select" onChange={(e) => handleFilterTagChange(e.target.value)} value={tagFilter}>
                      <option key={`status_default`} value="">Select a Tag</option>
                      {companytagList.map(tag => (
                        <option key={tag.id} value={tag.id}>{tag.tag}</option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : (
                <>
                  <button
                    className="uk-button uk-button-primary"
                    onClick={() => handleUpdateUploadListOrder()}
                  >
                    Save Sort
                  </button>
                  <button
                    className="uk-button uk-button-orange"
                    onClick={() => handleUpdateUploadListOrder(true)}
                  >
                    Cancel
                  </button>
                </>
              )}
            </div>
            {isUploadView
              ? <UploadViewTable
                data={dataDocument}
                show={isUploadView}
                setPredocumentType={setPredocumentType}
                setShowModal={setModalShow}
                setStatusShowModal={setStatusModalShow}
                setUpdatingTask={setUpdatingTask}
                companyId={company_id}
                transactionId={id}
                getTransactionEdoc={() => dispatch(getTransactionEdocById(id))}
              />
              : <UploadEditTable show={!isUploadView} companyId={company_id} setDataForUpdateUploadListOrder={setDataForUpdateUploadListOrder} />
            }
          </>
        )}
        {tab === "tasks" && (
          <>
            <div className="nav-operation-buttons uploads_buttons">
              {isTaskView ? (
                <div className="d-flex flex-wrap">
                  <button
                    className="uk-button uk-button-default"
                    onClick={() => { setIsTaskView(false); setDataForUpdateTaskListOrder([]); }}
                  >
                    Edit Sort
                  </button>
                  <div className="one-column uk-padding-small ">
                    <select className="uk-select" onChange={(e) => setTaskFilter(e.target.value)}>
                      <option key={`tasks_default`} value="">Select a Status</option>
                      <option key="not_started" value="not_started">Not Started</option>
                      <option key="in_process" value="in_process">In Process</option>
                      <option key="completed" value="completed">Completed</option>
                      <option key="not_available" value="not_available">N/A</option>
                    </select>
                  </div>
                  <div className="one-column uk-padding-small ">
                    <select className="uk-select" onChange={(e) => handleFilterTagChange(e.target.value)} value={tagFilter}>
                      <option key={`status_default`} value="">Select a Tag</option>
                      {companytagList.map(tag => (
                        <option key={tag.id} value={tag.id}>{tag.tag}</option>
                      ))}
                    </select>
                  </div>
                </div>

              ) : (
                <>
                  <button className="uk-button uk-button-primary" onClick={() => handleUpdateTaskListOrder()}>
                    Save Sort
                  </button>
                  <button className="uk-button uk-button-orange" onClick={() => handleUpdateTaskListOrder(true)}>
                    Cancel
                  </button>
                </>
              )}
            </div>
            {isTaskView
              ? <TaskViewTable data={dataTask} show={isTaskView} setStatusShowModal={setStatusModalShow} setUpdatingTask={setUpdatingTask} companyId={company_id} transactionId={id} />
              : <TaskEditTable show={!isTaskView} transactionId={id} companyId={company_id} setDataForUpdateTaskListOrder={setDataForUpdateTaskListOrder} />
            }
          </>
        )}
        {tab === "forms" && (
          <>
            <div className="nav-operation-buttons">
              {isFormView ? (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="max-w-100">
                      <button
                        className="uk-button uk-button-default"
                        onClick={() => { setIsFormView(false); setDataForUpdateFormListOrder([]); }}
                      >
                        Edit Sort
                      </button>
                    </div>


                    <div className="list-page-search-container search-block d-flex justify-content-end">
                      {/* <div className="two-column uk-padding-small d-flex">
                        <button className="uk-button uk-button-light" style={{
                          lineHeight: "10px",
                          padding: "0px 20px",
                          fontSize: "smaller"
                        }} onClick={() => {
                          handleEsignCustomDocModal();
                        }}><i className="fa fa-upload" aria-hidden="true"></i> Custom Document</button>
                      </div> */}
                      <div className="one-column uk-padding-small ">
                        <select className="uk-select" onChange={(e) => handleFilterLATagChange(e.target.value)} value={tags}>
                          <option key={`status_default`} value="">Select a Tag</option>
                          {tagList.map(tag => (
                            <option key={tag} value={tag}>{tag}</option>
                          ))}
                        </select>
                      </div>
                      <form className="uk-search uk-search-default">
                        <DebounceInput
                          className="uk-search-input"
                          type="search"
                          placeholder="Search"
                          onChange={(e) => setSearch((state) => {
                            return {
                              ...state,
                              name: e.target.value
                            }
                          })}
                          value={search.name || ''}
                          debounceTimeout={500}
                          minLength={1}
                        />
                        {search.name === "" ? (
                          <span className="bd-form-icon">
                            <i className="fa fa-search"></i>
                          </span>
                        ) : (
                          <span className="bd-form-icon" onClick={() => setSearch(state => {
                            return {
                              ...state,
                              name: ""
                            }
                          })}>
                            <i className="fa fa-times"></i>
                          </span>
                        )}
                      </form>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <button className="uk-button uk-button-primary" onClick={() => handleUpdateFormListOrder()}>
                    Save Sort
                  </button>
                  <button className="uk-button uk-button-orange" onClick={() => handleUpdateFormListOrder(true)}>
                    Cancel
                  </button>
                </>
              )}
            </div>
            {isFormView && <FormViewTable tags={tags} setTagList={setTagList} searchText={search} />}
            {!isFormView && <FormEditTable setDataForUpdateFormListOrder={setDataForUpdateFormListOrder} />}
          </>
        )}
        {tab === "signatures" && (
          <Signatures taskType={"listing"} />
        )}
      </>
    );
  };

  let title = dataListing.t_vessel
    ? `${dataListing.t_vessel.year || ""} ${dataListing.t_vessel.make || ""} ${dataListing.t_vessel.model || ""
      } ${dataListing.t_vessel.length || ""}` !== "   "
      ? `${dataListing.t_vessel.year || ""} ${dataListing.t_vessel.make || ""} ${dataListing.t_vessel.model || ""
      } ${dataListing.t_vessel.length || ""}`
      : "N/A"
    : "N/A";
  let status = dataListing.status ? titles[dataListing.status] : "Draft";

  const links = [
    { label: "Listing Agreements", link: "/listing-agreement" },
    { label: title, link: null },
  ];

  const renderActivities = () => {
    if (dataListing.id) {
      return <Activities transactionId={dataListing.id} />;
    }
  };

  const transactionUniqueId = dataListing.unique_transaction_id;

  return (
    <>
      <div>
        <TransactionDetails links={links} title={title} status={status} type="Back to Listing Agreements" typeLink="/listing-agreement" uniqueId={transactionUniqueId && `LA-${transactionUniqueId}`} currentTab={`listing_agreements_${tab}`} />
        <FormDataSideBar transactionId={dataTransaction?.id} sliderFor='edocument' />
        {/* <PageSidebarContainer type="transaction-details"> */}
        {/* {renderSideAccordions()} */}
        {/* </PageSidebarContainer> */}
        <PageMainContainer type="view-listing">
          <div className="uk-position-relative uk-margin-small user-tabs">
            <Tabs value={tab} indicatorColor="primary" onChange={(e, val) => setTab(val)}>
              {tabs.map((tabInfo, idx) => {
                if (tabInfo.name === "Prep Docs") {
                  return <Tab key={idx} value={tab === "edocuments" ? "edocuments" : "prepDocs"} label="Prep Docs" />
                }
                else {
                  return <Tab key={idx} value={tabInfo.tab} label={tabInfo.name} />
                }
              }
              )}
            </Tabs>
          </div>

          <TransactionDetailContent
            tabContent={renderTabContent}
            accordionContent={renderActivities}
            tab={tab}
          />
        </PageMainContainer>
        {renderForms()}
        {renderUploadTabForms()}
        {renderPrefillTabForms()}
        <DeleteConfirm />
      </div>
    </>
  );
};

export default ListingMainView;
