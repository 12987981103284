import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';

import PdfViewerComponent from './PDFEditor';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FormDataSideBarListing from '../listing_agreement/View/layouts/FormDataSideBar';
import FormDataSideBarDeal from '../deals/View/layouts/FormDataSideBar';

import Auth from '../../utils/auth';
import EDocService from '../../services/EDocService';
import TransactionService from '../../services/TransactionService';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingStatus } from '../../redux/actions/loading';

import Swal from 'sweetalert2';

import './index.css';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    pdf_box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        position: 'relative',
        height: '700px',
        overflow: 'scroll'
    },
});

const EditPreviewDocument = (props) => {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const propData = props.location.state;
    const [editorPdf, setEditorPdf] = useState(propData.file_url);
    const [timeStamp, setTimeStamp] = useState(new Date().getTime() + Math.random(10));

    const logged_user = Auth.getInstance().getUserData();

    const onPreviousPage = () => {
        history.push(`/${propData.type}/view/${propData.transactionId}/main`);
    }

    /**
     * Saves the updated pdf version in database with confirmation
     * @param {*} documentData 
     */
    const onSaveDocumentVersion = async (documentData) => {
        const apiBody = {
            file_name: documentData.submission_id,
            transaction_edocument_id: propData.documentId,
            is_current: true,
            transaction_id: propData.transactionId,
            activity_title: propData.documentName,
            activity_task_type: `pdf_edited`,
            activity_action_by: logged_user.id,
            uniqueId: propData.uniqueId,
            companyUId: propData.companyUId,
        };

        //Open a Warning pop-up
        Swal.fire({
            title: 'Attention!',
            html: `Saving any “PDF Edits” to this page will disconnect all future Form Data changes from entering into this PDF! <br/>  <br/>  <br/>All future updates to this document must be made from here - the PDF Editor ONLY!`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'I UNDERSTAND',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setLoadingStatus(true));
                try {
                    EDocService.updateDocumentPdf(apiBody).then(
                        response => {
                            dispatch(setLoadingStatus(false));
                            toast.success('Your PDF updated successfully.')
                            onPreviousPage();
                        }
                    ).catch(
                        error => {
                            dispatch(setLoadingStatus(false));
                            toast.error('Cannot update PDF right now!');
                            console.log(error)
                        });
                } catch (error) {
                    dispatch(setLoadingStatus(false));
                    console.log(error);
                }
            }
        });
    }

    /**
     * Call Reset pdf version API and reload pdf in editor with response pdf link
     */
    const onResetPdfVersion = () => {
        Swal.fire({
            title: 'Are you sure!',
            text: "Resetting a PDF will remove your updated data from it.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setLoadingStatus(true));
                try {
                    TransactionService.resetPdfEditorVersion({
                        id: propData.documentId,
                        transaction_id: propData.transactionId,
                        activity_title: propData.documentName,
                        activity_task_type: `pdf_reset`,
                        activity_action_by: logged_user.id,
                        activity_document_link: editorPdf ? editorPdf?.split('?')[0] : null
                    }).then(
                        response => {
                            if (response.status == 200) {
                                onPreviousPage();
                                // TransactionService.getPreview(propData.documentName, propData.transactionId, "preview").then(
                                //     (response) => {
                                //         dispatch(setLoadingStatus(false));
                                //         if (response.data && response.data.url) {

                                //             // Code for reload the page with new pdf
                                //             // const updatedLink = response.data.url;
                                //             // history.push({
                                //             //     pathname: '/deals/preview/edit',
                                //             //     state: {
                                //             //         file_url: updatedLink,
                                //             //         documentId: propData.documentId,
                                //             //         documentName: propData.documentName,
                                //             //         dataTransactionId: propData.dataTransactionId,
                                //             //         transactionId: propData.transactionId,
                                //             //         type: propData.type
                                //             //     }
                                //             // });
                                //             // window.location.reload(true);

                                //             // props.location.state['file_url'] = updatedLink;
                                //             // setEditorPdf(updatedLink);
                                //         }
                                //     }).catch(
                                //         (error) => {
                                //             toast.error('Error while reset PDF!');
                                //             dispatch(setLoadingStatus(false));
                                //             console.log(error);
                                //         })
                                toast.success('PDF reset successfully.');
                            } else {
                                toast.error('Something went wrong!');
                            }
                        }
                    ).catch(
                        error => {
                            dispatch(setLoadingStatus(false));
                            toast.error('Cannot reset PDF!');
                            console.log(error)
                        });
                } catch (error) {
                    dispatch(setLoadingStatus(false));
                    console.log(error);
                }
            }
        });
    }

    const refreshContent = async (isReload) => {
        if (isReload && !propData?.haveEditVersions) {
            dispatch(setLoadingStatus(true));
            let id = propData.transactionId;
            const dataTransaction = (await TransactionService.getTransactionEsignById(id)).data;
            switch (propData.documentName) {
                case "Listing Agreement Exclusion":
                case "Listing Agreement Addendum":
                    id = propData.documentId;
                    break;
                // case "Purchase & Sale Agreement Exclusion":
                // case "Purchase & Sale Agreement Addendum":
                case "IYBA Acceptance of Vessel":
                    id = `${propData.documentId}`;
                    break;
                case "Seller Closing Statement":
                    const dataSellerClosing = dataTransaction.t_seller_closing ? dataTransaction.t_seller_closing : null;
                    id = dataSellerClosing?.id || id;
                    break;
                case "Buyer Closing Statement":
                    const dataBuyerClosing = dataTransaction.t_buyer_closing ? dataTransaction.t_buyer_closing : null;
                    id = dataBuyerClosing?.id || id;
                    break;
                case "Trade-In Vessel":
                    const dataTradeInVessel = dataTransaction?.t_trade_in_vessel ? dataTransaction?.t_trade_in_vessel : null;
                    id = dataTradeInVessel?.id || id;
                    break;
                case "CYBA Counter Offer":
                case "Bill of Sale - Notary":
                case "Purchase & Sale Agreement Exclusion":
                case "Purchase & Sale Agreement Addendum":
                case "Bill of Sale - Notary (Seller Only)":
                case "YBAA Amendment to PSA":
                case "YBAA Amendment to PSA (Short Form)":
                case "Counter Offer":
                case "NYBA Counter Offer":
                    id = `${propData.transactionId}/${propData.documentId}`;
                    break;
                default:
                    id = propData.transactionId;
            }
            TransactionService.getPreview(propData.documentName, id, { module: "preview" }).then(
                (response) => {
                    const updatedLink = response.data.url;
                    setEditorPdf(updatedLink);
                    setTimeStamp(new Date().getTime() + Math.random(10));
                    dispatch(setLoadingStatus(false));
                }).catch((error) => {
                    dispatch(setLoadingStatus(false));
                });
        }
    }

    return (
        <div className="create-container">
            <div className="col-12 rsw-wrapper">
                {propData.type == 'listing-agreement' &&
                    <FormDataSideBarListing transactionId={propData.transactionId} sliderFor='editor' reloadPdf={refreshContent} isPdfEdit={true} />
                }
                {propData.type == 'deals' &&
                    <FormDataSideBarDeal transactionId={propData.transactionId} sliderFor='editor' reloadPdf={refreshContent} isPdfEdit={true} />
                }
                <div >
                    <div className="nav-bar">
                        <div style={{ position: 'absolute', left: '30px' }}>
                            <button className="btn btn-continue" onClick={onPreviousPage}>Back</button>
                        </div>

                        {propData?.haveEditVersions && <div style={{ position: 'absolute', right: '30px' }}>
                            <button className='btn btn-previous' onClick={onResetPdfVersion}>Reset</button>
                        </div>}
                    </div>

                    <div className={classes.container}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box className={classes.pdf_box}>
                                    <PdfViewerComponent document={editorPdf} onSubmitUpdatedPDF={onSaveDocumentVersion} current={timeStamp} />
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPreviewDocument;