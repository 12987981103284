import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormRow from './FormRow';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import TransactionService from '../../services/TransactionService';
import { retrieveBrokers } from '../../redux/actions/users';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLoadingStatus } from '../../redux/actions/loading';
import { uploadCustomTemplate, uploadTemplate } from '../../utils/template';
import { PDFDocument } from 'pdf-lib';

const AddEsignCustomDocModal = ({ show, onClose, transactionId, isFetchingdoc }) => {
    const [message, setMessage] = useState("");
    const dispatch = useDispatch();
    const docRef = useRef(null);
    const [url, setUrl] = useState(null);
    const schema = yup.object().shape({
        template_title: yup
            .string()
            .required("Please enter title"),
    });
    const [files, setFiles] = useState(null)
    const { register, handleSubmit, reset, getValues, setValue, watch, formState: { errors } } = useForm({
        defaultValues: {
            template_title: '',
            file_name: undefined
        },
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    useEffect(() => {

        return () => {
            setMessage("");
        }
    }, [show])
    const handleRegistration = async (data) => {
        setMessage("");
        dispatch(setLoadingStatus(true));
        let bodyData = data;
        if (files) {
            const res = await uploadCustomTemplate(files, 'pdf-documents');
            bodyData = { ...bodyData, file_name: res }
        }
        const response = await TransactionService.eSignCustomDocUpload(transactionId, bodyData);
        if (response?.status == 200) {
            toast.success(response?.data?.message)
        } else {
            toast.error(response?.error?.message)
        }
        isFetchingdoc.current = !isFetchingdoc.current
        reset()
        onClose();
        setFiles(null);
        dispatch(setLoadingStatus(false));
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleChange = async (e) => {
        if (!e.target.files?.[0]) {
            return
        }
        setFiles(e.target.files?.[0]);
        // const res = await uploadTemplate(e.target.files?.[0], 'pdf-documents');
        setValue("file_name", e.target.files?.[0]?.name)
        const base64 = await toBase64(e.target.files[0]);
        const url = URL.createObjectURL(e.target.files[0]);
        setUrl(url)

    };
    const handleError = (errors) => {
        console.log(errors);
    };

    const handleResetFile = (e) => {
        setFiles(null);
        setValue("file_name", undefined);
        setMessage("");
    }
    const openPreview = () => {
        window.open(url, "_blank")
    }


    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
            try {
                const pdfBytes = reader.result;
                await PDFDocument.load(pdfBytes);
                handleChange(event);
                setMessage("")
            } catch (error) {
                setFiles(null);
                setUrl(null);
                docRef.current.value = null;
                if (error.message.includes("encrypted") || error.message.includes("Password")) {
                    toast.error("This file is encrypted and cannot be uploaded.");
                } else {
                    toast.error("Error processing the PDF file.");
                }

            }
        };
    };
    return (
        <Modal
            show={show}
            onHide={onClose}
            centered={true}>
            <Modal.Header closeButton>
                <h3>Custom Document</h3>
            </Modal.Header>
            <form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <Modal.Body>
                    <div className="form-row uk-grid-margin uk-first-column mx-3">
                        <div className="column uk-padding-small">
                            <div className="uk-form-label text-wrap fs-2 pt-1 pb-1" style={{ fontSize: "medium" }}>
                                MODIFYING ASSOCIATION DOCUMENTS IS A <br />
                                VIOLATION OF BOTH BOATDOX TERMS OF SERVICE <br />
                                & YOUR ASSOCIATION RULES!
                            </div>
                            <div className="uk-form-label text-wrap fs-2 pt-1 pb-1" style={{ fontSize: "medium" }}>
                                Violating this by modifying and uploading manipulated <br />
                                documents will get you removed from this platform.
                            </div>
                            <div className="uk-form-label text-wrap fs-2 pt-1 pb-1" style={{ fontSize: "medium" }}>
                                Documents are designed to be used for your benefit <br />
                                and the benefit of the recipient who is already versed <br />
                                on assocation documents.
                            </div>
                            <div className="uk-form-label text-wrap fs-2 pt-1 pb-1" style={{ fontSize: "medium" }}>
                                This UPLOADING option is for custom documents and <br />
                                any forms you may need to process your transaction <br />
                                outside of association documents!
                            </div>
                            <div className="uk-form-label text-wrap fs-2 pt-1 pb-1" style={{ fontSize: "medium" }}>
                                If you do upload Association documents - BE SURE <br />
                                they are the original unaltered versions!   Thank you.
                            </div>
                        </div>
                    </div>
                    <div className="form-row uk-grid-margin uk-first-column mx-3">
                        <div className="column uk-padding-small">
                            <label className="uk-form-label" htmlFor="template_title">Title *</label>
                            <div className="input-group" style={{ width: "85%" }}>
                                <input className={clsx("uk-input rounded-pill-left form-control border border-right-0", { 'uk-form-danger': errors.template_title })} type={'text'} {...register("template_title")} />
                            </div>
                            <span class="input-group-addon" role="button" title="title" id="passBtn"></span>
                            <p className='text-danger'>{errors.template_title?.message}</p>
                        </div>
                    </div>
                    <div className="form-row uk-grid-margin uk-first-column mx-3">
                        <div className="column uk-padding-small">
                            <label className="uk-form-label" htmlFor="document">Document</label>
                            <div className="js-upload cursor-pointer" data-uk-form-custom="true" style={{ width: "85%" }}>
                                <input type="file" id="upload-file" ref={docRef} onChange={handleFileUpload} accept=".pdf, .doc, .docx, .xls, .xlsx, .png, .jpg, .jpeg" />
                                <button className="uk-button uk-button-primary w-100 "
                                    style={{
                                        background: "#0f7ae5",
                                        cursor: "pointer"
                                    }}
                                >  Upload Document</button>
                            </div>
                            <span class="input-group-addon" role="button" title="document" id="passBtn"></span>
                            <p className='text-danger'>{errors.file_name?.message ?? message}</p>
                        </div>
                    </div>
                    {!!files && <div style={{ display: 'flex', marginTop: '30px' }} className='mx-3 w-100'>
                        <div style={{ display: 'flex' }}>
                            <div style={{ visibility: 'visible' }}>
                                <svg width="1em" height="1em" viewBox="0 0 16 24" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7 4.5C7 5.32843 6.32843 6 5.5 6C4.67157 6 4 5.32843 4 4.5C4 3.67157 4.67157 3 5.5 3C6.32843 3 7 3.67157 7 4.5ZM12 4.5C12 5.32843 11.3284 6 10.5 6C9.67157 6 9 5.32843 9 4.5C9 3.67157 9.67157 3 10.5 3C11.3284 3 12 3.67157 12 4.5ZM5.5 11C6.32843 11 7 10.3284 7 9.5C7 8.67157 6.32843 8 5.5 8C4.67157 8 4 8.67157 4 9.5C4 10.3284 4.67157 11 5.5 11ZM12 9.5C12 10.3284 11.3284 11 10.5 11C9.67157 11 9 10.3284 9 9.5C9 8.67157 9.67157 8 10.5 8C11.3284 8 12 8.67157 12 9.5ZM5.5 16C6.32843 16 7 15.3284 7 14.5C7 13.6716 6.32843 13 5.5 13C4.67157 13 4 13.6716 4 14.5C4 15.3284 4.67157 16 5.5 16ZM7 19.5C7 20.3284 6.32843 21 5.5 21C4.67157 21 4 20.3284 4 19.5C4 18.6716 4.67157 18 5.5 18C6.32843 18 7 18.6716 7 19.5ZM10.5 16C11.3284 16 12 15.3284 12 14.5C12 13.6716 11.3284 13 10.5 13C9.67157 13 9 13.6716 9 14.5C9 15.3284 9.67157 16 10.5 16ZM12 19.5C12 20.3284 11.3284 21 10.5 21C9.67157 21 9 20.3284 9 19.5C9 18.6716 9.67157 18 10.5 18C11.3284 18 12 18.6716 12 19.5Z" fill="currentColor"></path>
                                </svg>
                            </div>
                            <svg data-qa-ref="file-icon" width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor">
                                <path fillRule="evenodd" clipRule="evenodd" d="M6 3a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V8a1 1 0 00-.293-.707l-2-2-2-2A1 1 0 0014 3H6zm1 16V5h5v3a2 2 0 002 2h3v9H7zm9.586-11l-1.293-1.293L14 5.414V8h2.586z"></path>
                            </svg>
                            <span data-type="Upload">
                                {files?.name ?? ""}
                            </span>
                        </div>
                        <a data-uk-tooltip="remove-document" style={{ marginLeft: '5px' }} onClick={handleResetFile}>
                            <img src="https://cdn.hellosign.com/1.116.0/build/ae9ae8331a142bc9ce60901d10a41fc6.svg" />
                        </a>

                        <a style={{ marginLeft: "11px" }} onClick={() => openPreview()}>
                            <img src="/icons/view.svg" className="svg" data-uk-tooltip="Preview Document" />
                        </a>
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <div className='pl-0 ml-0'>
                        <button
                            className="uk-button uk-button-primary small"
                            type="submit">Confirm & Upload</button>
                        <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={() => {
                            onClose(false);
                        }}>
                            Cancel
                        </button>

                    </div>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default AddEsignCustomDocModal;