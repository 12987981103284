import http, { setHeaders } from '../http-common';
import Auth from '../utils/auth';

// setHeaders(Auth.getInstance().getAuthToken());

const getAll = () => {
  return http.get("/users");
}

const get = id => {
  return http.get(`/users/${id}`);
}

const getProfile = id => {
  return http.get(`/auth/profile/${id}`);
}

const create = data => {
  return http.post("/users", data);
}

const update = (id, data) => {
  return http.put(`/users/${id}`, data);
}

const updateEmail = (id, data) => {
  return http.put(`/users/change-email/${id}`, data);
}

const checkEmail = (data) => {
  return http.post(`/users/check-email`, data);
}

const resendVerificationCode = (data) => {
  return http.post(`/auth/resend-code`, data);
}

const updatePassword = (id, data) => {
  return http.put(`/auth/${id}/change-password`, { data: data });
}

const updateProfile = (id, data) => {
  return http.put(`/auth/profile/${id}`, data);
}

const resetPassword = (email, code, new_password, config = null) => {
  return http.post('/auth/reset-password', {
    email: email,
    code: code,
    new_password: new_password
  }, { ...config, withCredentials: true });
}

const remove = id => {
  return http.delete(`/users/${id}`);
}

const removeArchivedUser = id => {
  return http.delete(`/users/delete-from-cognito/${id}`);
}

const removeAll = () => {
  return http.delete('/users');
}

const deleteParticipant = (participantId) => {
  return http.delete(`/users/participant/${participantId}`);
}

const deleteGuest = (guestId) => {
  return http.delete(`/users/guest/${guestId}`);
}

const findByName = name => {
  return http.post("/users/searchByName", name);
}

const findOneByName = name => {
  return http.post("/users/searchOne", { name: name });
}

const fetchRoles = () => {
  return http.get("/roles");
}

const fetchRelated = (id) => {
  return http.get(`/roles/${id}`);
}

const fetchOffices = (companyId) => {
  return http.get(`/companies/${companyId}/offices`);
}

const fetchBrokers = (companyId, officeId) => {
  return http.get(`/users/brokers/${companyId}/${officeId}`);
}

const inviteUser = (user) => {
  return http.post('/users/', user);
}

const resendInvitation = (id) => {
  return http.get(`/users/resend-invitation/${id}`);
}

const completeInvitation = (data, config = null) => {
  return http.post("/auth/complete-invitation", data, { ...config, withCredentials: true });
}

const sendEmailVerification = (token) => {
  return http.post("/auth/verify-email", { token: token });
}

const verifyEmailVerificationCode = (token, code, config = null) => {
  return http.post("/auth/verify-code", { token: token, code: code }, { ...config, withCredentials: true });
}

const getOfficeUsers = (officeId, company_id = null) => {
  if (company_id) {
    return http.get(`/users/office-users/${officeId}/${company_id}`);
  }
  return http.get(`/users/office-users/${officeId}`);
}

const getCompanyUsers = (company_id) => {
  return http.get(`/users/office-users-by-company/${company_id}`);
}

const retrieveMlsOffices = (id) => {
  return http.get(`/offices/retrieveMlsOffices/${id}`);
}

const searchByCompany = (id) => {
  return http.get(`/users/searchByCompany/${id}`);
}

const fetchAllOfficesWithBrokers = (companyId, editedUserId) => {
  let url = `/users/officeDetails/${companyId}`
  if (editedUserId != null) {
    url = url + `/${editedUserId}`
  }
  return http.get(url);
}

const fetchAllAccessibleBrokers = (userId) => {
  return http.get(`/vessel_toys/retrieveBrokerList/${userId}`);
}

const login = (data = null, config = null) => {
  return http.post('/auth/login', { username: data.username, password: data.password }, { ...config, withCredentials: true });
}

const sendCodeCognitoEmail = (data = null, config = null) => {
  return http.post('/auth/cognito-email-verification', data, { ...config, withCredentials: true });
}

const refreshCognitoSession = (data = null) => {
  return http.get('/auth/refresh-cognito-token', data);
}

const clearCognitoSession = () => {
  return http.get('/auth/logout-cognito-session');
}

const trackUserToken = (module) => {
  return http.get(`/auth/track-user?module=${module}`);
}

const getBrokerList = (companyId) => {
  return http.get(`/users/broker/${companyId}`);
}

const resetUserPassword = (userId, data = null) => {
  return http.put(`/users/change-password/${userId}`, data);
}

const addSecurityLog = (data = null) => {
  return http.post('/users/security-logs', data);
}

const setupPassword = (userId, data = null) => {
  return http.post(`/users/setup-password/${userId}`, data);
}

const getParticipantCompanies = (participantId) => {
  return http.get(`/users/participantCompanies/${participantId}`);
}

// const getGuestCompanies = (guestId) => {
//   return http.get(`/users/guestCompanies/${guestId}`);
// }

const getAssociatedTransactionForUser = (userId) => {
  return http.get(`/users/associated-transactions/${userId}`);
}

const guestAgentSignUpRequest = (data = null) => {
  return http.post(`/users/request-broker-full-account`, data);
}

const UserService = {
  getProfile,
  getAll,
  get,
  create,
  update,
  updatePassword,
  remove,
  removeArchivedUser,
  removeAll,
  deleteParticipant,
  deleteGuest,
  findByName,
  findOneByName,
  fetchRoles,
  fetchRelated,
  fetchOffices,
  fetchBrokers,
  inviteUser,
  resendInvitation,
  resetPassword,
  completeInvitation,
  sendEmailVerification,
  verifyEmailVerificationCode,
  getOfficeUsers,
  getCompanyUsers,
  updateEmail,
  checkEmail,
  resendVerificationCode,
  updateProfile,
  retrieveMlsOffices,
  searchByCompany,
  updateProfile,
  retrieveMlsOffices,
  searchByCompany,
  fetchAllOfficesWithBrokers,
  updateEmail,
  checkEmail,
  resendVerificationCode,
  fetchAllAccessibleBrokers,
  sendCodeCognitoEmail,
  login,
  refreshCognitoSession,
  clearCognitoSession,
  trackUserToken,
  getBrokerList,
  resetUserPassword,
  addSecurityLog,
  setupPassword,
  getParticipantCompanies,
  // getGuestCompanies,
  getAssociatedTransactionForUser,
  guestAgentSignUpRequest
}

export default UserService;
