import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { getEdocCompanyTaskList, getTransactionEdocById, updateTaskTitleForTransaction } from "../../../../../redux/actions/transaction";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "react-bootstrap";
import * as yup from 'yup';
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const UpdateDocumentName = ({ show, onClose }) => {

  const dispatch = useDispatch();
  const { id } = useParams(); // transaction id for get updated tasks

  useEffect(() => {
    setValue('title', show?.taskDetails?.company_tasks?.title ? show?.taskDetails?.company_tasks?.title : '');
  }, [show]);

  const schema = yup.object().shape({ title: yup.string().required("Document Name is required.") });

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      title: ''
    },
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true
  });

  const handleCloseModal = () => {
    reset();
    onClose();
  }

  // function for update custom added task document name  
  const handleUpdate = (formData) => {
    dispatch(setLoadingStatus(true));
    dispatch(updateTaskTitleForTransaction(show?.taskDetails?.master_task_id, formData?.title)).then((res) => {
      dispatch(setLoadingStatus(false));
      dispatch(getTransactionEdocById(id));
      dispatch(getEdocCompanyTaskList(show?.taskDetails?.companyId, id, {}));
      toast.success('Task edited successfully.');
      onClose(true);
    }).catch((err) => {
      console.log(err)
      dispatch(setLoadingStatus(false));
      toast.error('Task not edited.');
    })
  };

  return (
    <Modal
      show={show?.isModalOpen}
      onHide={onClose}
      centered={true}
      size="lg">
      <Modal.Header closeButton>
        <h3>Update Document Name</h3>
      </Modal.Header>
      <Modal.Body>
        <form style={{ padding: '10px' }}>
          <div className="form-row">
            <div className="two-column uk-padding-small">
              <label className="uk-form-label">Document Name</label>
              <input
                className={clsx("uk-input", { 'uk-form-danger': errors?.title })}
                type="text"
                {...register("title")}
              />
              {errors?.title ? <span className="text-danger">{errors?.title?.message}</span> : <></>}
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={(e) => handleCloseModal()}>
          Cancel
        </button>
        <button
          className="uk-button uk-button-primary small"
          type="button" onClick={handleSubmit(handleUpdate)}>Done</button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateDocumentName;
