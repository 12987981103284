import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import DataTable from "react-data-table-component";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import LinkButton from "../../../../common/buttons/LinkButton";
import ThemeButton from "../../../../common/buttons/ThemeButton";
import datatableStyles from "../../../../../utils/datatable-style";

import { getAuthorizedDocsIds, getTransactionEdocById, getTransactionEsignById, getTransactionInitialById, resetEdoc, sendEmail } from "../../../../../redux/actions/transaction";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import TransactionService from "../../../../../services/TransactionService";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import PreviewModal from "../../../../common/PreviewModal";
import { confirmAlert } from "react-confirm-alert";
import "./eDocumentsViewTable.css";
import { DELETE_MODAL_MESSAGE, DELETE_MODAL_TITLE } from "../../../../../constants/Alert";
import Auth from "../../../../../utils/auth";
import EDocService from "../../../../../services/EDocService";
import { DocumentResetModal } from "../../../../common/DocumentResetModal";
import { Tooltip } from "@mui/material";
import SendPreviewEmailModal from "../../../../common/SendPreviewEmailModal";

const EdocumentsViewTable = ({ data, setTab, status, taskType }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading.status);
  const user = Auth.getInstance().getUserData();
  const userRole = user?.UserInRole?.UserRole?.name ?? "Company Admin"

  const [isPreview, setIsPreview] = useState(false);
  const [requestSignature, setRequestSignature] = useState([])
  const [isReset, setIsReset] = useState(false);
  const [resetEdocDetail, setResetEdocDetail] = useState(null);
  const [authorizedDocsIds, setAuthorizedDocsIds] = useState([]);
  const [isauthorizedDocsSet, setIsauthorizedDocsSet] = useState(false);

  const { url } = useRouteMatch();
  const { id } = useParams();

  const formDataList = [
    "listing-agreement",
    "ybaa-us-central-listing-agreement",
    "pier-one-listing-agreement",
    "material-damage-disclosure",
    "ybaa-us-open-listing-agreement",
    "ybaa-canada-central-listing-agreement",
    "ybaa-canada-open-listing-agreement",
    "csa-listing-agreement",
    "cyba-listing-agreement",
    "cyba-listing-agreement-amendment",
    "cyba-open-listing-agreement",
    "ybaa-amendment-to-central-listing-agreement",
    "nyba-listing-agreement",
    "cyba-exclusive-listing-agreement---net",
    "cyba-exclusive-listing-agreement---gross",
    "material-damage-disclosure---seller-fills",
    "listing-disclosure-(seller-fills-form)",
    "yb-mls-license-agreement"
  ];

  const fetchTransactionData = (isCopied = false) => {
    dispatch(setLoadingStatus(true));
    dispatch(getTransactionInitialById(id)).then((res) => {
      dispatch(getTransactionEdocById(id))
        .then((res) => {
          dispatch(getTransactionEsignById(id)).then(
            (resp) => {
            }).catch(() => {
              dispatch(setLoadingStatus(false));
            }).then(() => {
              if (isCopied) {
                toast.success("Document added successfully.", {
                  autoClose: 2000
                });
              }
              dispatch(setLoadingStatus(false))
            })
        })
        .catch((err) => dispatch(setLoadingStatus(false)))
    });
  }

  // useEffect(() => {
  //   if (id) {
  //     fetchTransactionData();
  //   }
  // }, []);

  const deals = useSelector((state) => state.transactions);
  const dataTransaction = useSelector((state) => state.transactions.transaction);

  const [selectedDocuments, setSetselectedDocuments] = useState([]);
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);

  const showStatus = (status) => {
    if (status === "Completed") {
      return "Fully Executed";
    } else {
      return status || "Open";
    }
  };

  const filteredData = (userRole === "Agent" && authorizedDocsIds.length > 0) ? data.filter(item => authorizedDocsIds.includes(item?.t_esign_template?.template?.id))
    : isauthorizedDocsSet ? data : [];
  const sorted_data = filteredData.sort((a, b) => {
    if (a.t_esign_template?.sort_order == b.t_esign_template?.sort_order) {
      if (a.duplicate_occurrence) {
        return a.duplicate_occurrence === b.duplicate_occurrence ? 0 : a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1;
      } else {
        return 0
      }
    }
    return a.t_esign_template?.sort_order > b.t_esign_template?.sort_order ? 1 : -1;
  });

  // const getEdocId = (element) => {
  //   let edocId = element.id;
  //   switch (element.title) {
  //     case "Listing Agreement Exclusion":
  //       const exclusions = dataTransaction?.t_purchase_exclusion || [];
  //       const exclusion = exclusions.find(el => el.id === element.id);
  //       edocId = exclusion?.id || edocId;
  //       break;
  //     case "Listing Agreement Addendum":
  //       const dataAddendum = deals.transaction ? deals.transaction.t_purchase_addendum || [] : [];
  //       const addendum = dataAddendum.find(el => el.t_edocument_id === element.id);
  //       edocId = addendum?.id || edocId;
  //       break;
  //     default:
  //       edocId = element.id;
  //       break;
  //   }
  //   return edocId;
  // }

  // const validateDocuments = useMemo(() => sorted_data.map(
  //   (document) => !document.request_signature && {
  //     documentName: document.task_type,
  //     transactionId: document.transaction_id,
  //     docId: getEdocId(document)
  //   }).filter(document => document), [sorted_data]);

  // const [validSortedData, setValidSortedData] = useState([]);

  /**
   * Get value of validate the documents and modify the validation with hull number
   * if document is locked then do not check validation
   */
  // useEffect(() => {
  //   if (validateDocuments.length) {
  //     dispatch(setLoadingStatus(true));
  //     TransactionService.checkValidationForTransactionDocument({ documents: validateDocuments }).then(
  //       (response) => {
  //         let validDocuments = response.data?.validatedDocuments;

  //         // modify the validation return from API by checking hull_number validation manually
  //         validDocuments = validDocuments.map(doc => {
  //           // if hull number is not exist or less than 12 then remove validation for hull_number only
  //           if (dataTransaction.t_vessel?.is_old_built) {
  //             doc.validation = [
  //               ...doc.validation?.map(data => 'vessel' in data ? { vessel: [] } : data)
  //             ]
  //           } else {
  //             // if is_old_built is false then check if hull number length is less than 12 characters
  //             if (dataTransaction.t_vessel?.hull_number && dataTransaction.t_vessel?.hull_number?.length < 12) {
  //               doc.validation = [
  //                 // set validation manually for ['hull_number']
  //                 ...doc.validation?.map(data => 'vessel' in data ? { vessel: ['hull_number'] } : data)
  //               ]
  //             }
  //           }
  //           return doc;
  //         });

  //         const validData = sorted_data.map(data => {
  //           // skip if request_signature is exist in document
  //           if (data.request_signature) {
  //             return data
  //           }

  //           // return modified value of validation with set flag isValid is true/false by checking all the fields in validation array
  //           return {
  //             ...data,
  //             isValid: validDocuments.filter(value => value.documentName == data.task_type)[0]['validation'].filter(ref => Object.values(ref)[0].length).length == 0
  //           }
  //         });
  //         setValidSortedData(validData);
  //         dispatch(setLoadingStatus(false));
  //       }
  //     ).catch(
  //       (error) => {
  //         console.log(error);
  //         setValidSortedData(sorted_data);
  //         dispatch(setLoadingStatus(false));
  //       }
  //     )
  //   }
  // }, [validateDocuments]);

  const onClickPdfEdit = async (row) => {
    const documentName = row.title;
    const documentId = row.id;
    const templateLink = `https://${process.env.REACT_APP_FORMS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates/${row?.t_esign_template?.template?.template_link}`;
    let id = dataTransaction.id;
    let existRecord = false;
    switch (documentName) {
      case "Listing Agreement Exclusion":
        const exclusions = dataTransaction?.t_purchase_exclusion || [];
        const exclusion = exclusions.find(el => el.id === documentId);
        id = (exclusion && exclusion?.id) ? exclusion?.id : `${id}/${documentId}`;
        existRecord = (exclusion && exclusion?.id) ? true : false;
        break;
      case "Listing Agreement Addendum":
        const dataAddendum = deals.transaction ? deals.transaction.t_purchase_addendum || [] : [];
        const addendum = dataAddendum.find(el => el.t_edocument_id === documentId);
        id = (addendum && addendum?.id) ? addendum?.id : `${id}/${documentId}`;
        existRecord = (addendum && addendum?.id) ? true : false;
        break;
    }
    dispatch(setLoadingStatus(true));
    try {
      const docResponse = (await EDocService.getEditorDocumentPdf(documentId)).data;
      if (docResponse.found && docResponse.pdf_url) {
        dispatch(setLoadingStatus(false));
        history.push({
          pathname: `/listing-agreement/view/${dataTransaction.id}/preview/edit`,
          state: {
            file_url: docResponse.pdf_url,
            documentId: documentId,
            documentName: documentName,
            dataTransactionId: id,
            transactionId: dataTransaction.id,
            type: 'listing-agreement',
            haveEditVersions: true,
            templateLink: templateLink,
            eDocuments: data,
            uniqueId: `LA-${dataTransaction.unique_transaction_id?.split('-')[1] || ""}`,
            companyUId: `${dataTransaction.unique_transaction_id?.split('-')[0] || ""}`,
            updated_elements: docResponse?.updated_elements ? [...docResponse?.updated_elements] : [],
            roles: [...docResponse?.allowed_template_roles],
            is_pdf_merge: docResponse?.is_pdf_merge
          }
        });
      } else {
        TransactionService.getPreview(documentName, `${id}`, { module: "preview" }, existRecord).then((res) => {
          dispatch(setLoadingStatus(false));
          if (res.status !== 200 && res.data.url == null) {
            throw res.data;
          } else {
            history.push({
              pathname: `/listing-agreement/view/${dataTransaction.id}/preview/edit`,
              state: {
                file_url: res.data.url,
                documentId: documentId,
                documentName: documentName,
                dataTransactionId: id,
                transactionId: dataTransaction.id,
                type: 'listing-agreement',
                haveEditVersions: false,
                templateLink: templateLink,
                eDocuments: data,
                uniqueId: `LA-${dataTransaction.unique_transaction_id?.split('-')[1] || ""}`,
                companyUId: `${dataTransaction.unique_transaction_id?.split('-')[0] || ""}`,
                roles: [...docResponse?.allowed_template_roles],
                is_pdf_merge: docResponse?.is_pdf_merge
              }
            });
          }
        }).catch((err) => {
          TransactionService.getDocumentUrl(documentName)
            .then((res) => {
              dispatch(setLoadingStatus(false));
              if (res?.data.url) {
                history.push({
                  pathname: `/listing-agreement/view/${dataTransaction.id}/preview/edit`,
                  state: {
                    file_url: res.data.url,
                    documentId: documentId,
                    documentName: documentName,
                    dataTransactionId: id,
                    transactionId: dataTransaction.id,
                    type: 'listing-agreement',
                    haveEditVersions: false,
                    eDocuments: data,
                    uniqueId: `LA-${dataTransaction.unique_transaction_id?.split('-')[1] || ""}`,
                    companyUId: `${dataTransaction.unique_transaction_id?.split('-')[0] || ""}`,
                  }
                });
              } else {
                toast.error("Error occurs, please try again.", {
                  autoClose: 2000
                })
              }
            }).catch(() => {
              dispatch(setLoadingStatus(false));
              toast.error("Error occurs, please try again.", {
                autoClose: 2000
              })
            })
        });
      }

    } catch (error) {
      dispatch(setLoadingStatus(false));
      toast.error("Error occurs, please try again.", {
        autoClose: 2000
      });
    }
  }

  // const handleViewSig = (signature) => {
  //   window.open(signature.doc_link, "_blank");
  // };

  const handleEmailSigners = (edoc) => {
    dispatch(setLoadingStatus(true));
    dispatch(sendEmail(edoc.id)).then(res => {
      dispatch(setLoadingStatus(false));
    }).catch(err => {
      dispatch(setLoadingStatus(false));
      console.log(err);
    })
  }

  const logged_user = Auth.getInstance().getUserData();

  const handleReset = (edoc) => {
    setResetEdocDetail(edoc);
    setIsReset(true);
  };

  const closeResetModal = (isReload) => {
    if (isReload) {
      dispatch(getTransactionEdocById(id));
    }
    setIsReset(false);
    setResetEdocDetail(null);
  }

  const renderButton = (row) => {
    const isSignerSignatures = row.request_signature?.signers?.filter(signer => signer.status == "Signed" || signer.status == "Sent" || signer.status == "Declined" || signer.status == "Link Generated") || [];
    return isSignerSignatures.length;
  }

  const renderAction = (row) => {
    if (status === "cancelled") return;

    let type = row.status;
    let prefixUrl = url.replace("/main", "");
    let task_type = row.task_type;
    let link = `${prefixUrl}/${task_type}`;
    let previewLink = `${link}/send_to_signer`;
    // const should_disabled = false;
    //  making all documents active per client ticket 320
    //      row.task_type !== "listing-agreement" && sorted_data[0].status !== "Completed";

    if (formDataList.includes(task_type)) {
      if (!["Completed", "In-Progress"].includes(type)) {
        type = "Draft";
        previewLink = `/listing-agreement/add/${id}/send_to_signer`;
      } else if (type === "In-Progress") {
        // link = `/listing-agreement/add/${id}/vessel`;
        previewLink = `/listing-agreement/add/${id}/send_to_signer`;
      }
      link = `/listing-agreement/add/${id}/vessel`;
    }

    if (task_type === "listing-agreement-exclusion") {
      if (!["Completed", "In-Progress"].includes(type)) {
        type = "Draft";
        previewLink = `/listing-agreement/view/${id}/listing-agreement-exclusion/send_to_signer?doc_id=${row.id}`;
      } else if (type === "In-Progress") {
        // link = `/listing-agreement/view/${id}/listing-agreement-exclusion?doc_id=${row.id}`;
        previewLink = `/listing-agreement/view/${id}/listing-agreement-exclusion/send_to_signer?doc_id=${row.id}`;
      }
      link = `/listing-agreement/view/${id}/listing-agreement-exclusion?doc_id=${row.id}`;
    }

    if (task_type === "listing-agreement-addendum") {
      if (!["Completed", "In-Progress"].includes(type)) {
        type = "Draft";
        previewLink = `/listing-agreement/view/${id}/listing-agreement-addendum/send_to_signer?doc_id=${row.id}`;
      } else if (type === "In-Progress") {
        // link = `/listing-agreement/view/${id}/listing-agreement-addendum?doc_id=${row.id}`;
        previewLink = `/listing-agreement/view/${id}/listing-agreement-addendum/send_to_signer?doc_id=${row.id}`;
      }
      link = `/listing-agreement/view/${id}/listing-agreement-addendum?doc_id=${row.id}`;
    }

    const formDataBtn = () => (
      <LinkButton to={link} theme="primary">
        <img src="/icons/edit.svg" class="svg"></img>
        Form Data
      </LinkButton>
    )

    const resetBtn = (row) => {
      return <ThemeButton onClick={() => handleReset(row)} theme="danger">
        <img src="/icons/pdf.svg" class="svg"></img>
        Reset
      </ThemeButton>
    }

    const eSignBtn = () => (
      <ThemeButton onClick={() => {
        setTab("signatures")
        history.push(`/listing-agreement/view/${id}/main#tab=%22signatures%22`, { task_type })
      }} style={{ padding: "0 10px" }}>
        eSign
      </ThemeButton>
    )

    switch (type) {
      case "Draft":
        return (
          <>
            {/* {formDataBtn()} */}
            {renderButton(row)
              ? resetBtn(row)
              : eSignBtn()
            }
            {/* {eSignBtn()} */}
          </>
        );
      case "In-Progress":
        return (
          <>
            {/* {formDataBtn()} */}
            {renderButton(row)
              ? resetBtn(row)
              : <></>
            }
            {eSignBtn()}
          </>
        );
      case "Completed":
        return (
          <>
            {/* {formDataBtn()} */}
            {renderButton(row)
              ? resetBtn(row)
              : <></>
            }
          </>
        );
      default:
        return (
          <>
            {/* {formDataBtn()} */}
          </>
        );
    }
  };

  const openPreviewModal = async (request_signature, row) => {
    if (!request_signature || request_signature?.signers.length == 0) {
      await previewByStep(row.title, row.id)
      return;
    }
    setRequestSignature({ ...request_signature, documentStatus: row?.status });
    setIsPreview(true);
  }

  const closePreviewModal = () => {
    setIsPreview(false);
  }

  const previewByStep = (documentName, documentId) => {
    try {
      let id = dataTransaction.id;
      let existRecord = false;
      switch (documentName) {
        case "Listing Agreement Exclusion":
          const exclusions = dataTransaction?.t_purchase_exclusion || [];
          const exclusion = exclusions.find(el => el.id === documentId);
          id = (exclusion && exclusion?.id) ? exclusion?.id : `${id}/${documentId}`;
          existRecord = (exclusion && exclusion?.id) ? true : false;
          break;
        case "Listing Agreement Addendum":
          const dataAddendum = deals.transaction ? deals.transaction.t_purchase_addendum || [] : [];
          const addendum = dataAddendum.find(el => el.t_edocument_id === documentId);
          id = (addendum && addendum?.id) ? addendum?.id : `${id}/${documentId}`;
          existRecord = (addendum && addendum?.id) ? true : false;
          break;
      }
      dispatch(setLoadingStatus(true));
      TransactionService.getPreview(documentName, id, { module: "preview" }, existRecord)
        .then((res) => {
          dispatch(setLoadingStatus(false));
          if (res.status !== 200) {
            throw res.data;
          } else {
            // CloudFront URL Always unique on Click of preview
            // window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
            window.open(res.data.url, '_blank');
          }
        })
        .catch((err) => {
          TransactionService.getDocumentUrl(documentName)
            .then((res) => {
              dispatch(setLoadingStatus(false));
              if (res?.data.url) {
                window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
              } else {
                toast.error("Error occurs, please try again.", {
                  autoClose: 2000
                })
              }
            })
            .catch(() => {
              dispatch(setLoadingStatus(false));
              toast.error("Error occurs, please try again.", {
                autoClose: 2000
              })
            })
        });
    } catch (error) {
      TransactionService.getDocumentUrl(documentName)
        .then((res) => {
          dispatch(setLoadingStatus(false));
          if (res?.data.url) {
            window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
          } else {
            toast.error("Error occurs, please try again.", {
              autoClose: 2000
            })
          }
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          toast.error("Error occurs, please try again.", {
            autoClose: 2000
          })
        })
    }
  }

  const onDelete = (id) => {
    confirmAlert({
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MESSAGE,
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  };

  const handleDelete = async (id) => {
    try {
      dispatch(setLoadingStatus(true));
      await TransactionService.deleteEDocById(id);
      dispatch(setLoadingStatus(false));
      toast.success("Document deleted successfully.", {
        autoClose: 2000
      });
      fetchEDocuments(dataTransaction?.id)
    } catch (error) {
      toast.error("Error occurs, please try again.", {
        autoClose: 2000
      })
    }
  };

  const fetchEDocuments = (transactionId) => {
    dispatch(setLoadingStatus(true));
    dispatch(getTransactionEdocById(transactionId))
      .then((res) => dispatch(setLoadingStatus(false)))
      .catch((err) => {
        toast.error("Error occurs, please try again.", {
          autoClose: 2000
        })
      })
  }

  const isCompanyAdminCanEditPDF = () => {
    return dataTransaction.broker?.company?.brokerage?.is_only_admin_can_edit_pdf
      ? ['Company Admin', 'Boatdox Admin'].includes(logged_user.UserInRole.UserRole.name) :
      true;
  }

  const columns = [
    {
      name: "",
      width: "80px",
      cell: (row) => (
        <div>
          <span>
            <i
              className={clsx(
                "fa",
                { "fa-check-circle": row.status === "Completed" },
                { "fa-circle-o": ["Draft", "In-Progress", "Open"].includes(row.status) || !row.status }
              )}
            />
          </span>
        </div>
      ),
    },
    {
      name: "Title",
      selector: "title",
      sortable: true,
      cell: (row) => <div>{row.is_duplicate ? row?.duplicate_title : row?.t_esign_template?.template?.template_name || ""}</div>,
    },
    {
      name: "Status",
      // width: "160px",
      selector: "status",
      sortable: true,
      cell: (row) => <span className="status uk-label">{showStatus(row.status)}</span>,
    },
    {
      name: "Verify",
      center: true,
      cell: (row) => {
        return (
          <button style={{ marginRight: '0' }} onClick={() => {
            openPreviewModal(row.request_signature, row)
          }} className='uk-button uk-button-default' >
            Preview
          </button>
        )
      },
    },
    {
      name: 'PDF Editor',
      // width: "140px",
      center: true,
      cell: (row) => {
        return (!renderButton(row) && isCompanyAdminCanEditPDF())
          ? <Tooltip title="PDF Editor" placement="left">
            <button className='btn btn-previous btn-sm' onClick={() => { onClickPdfEdit(row) }}>
              {row.manual_edocuments_versions && row.manual_edocuments_versions.filter(version => version.is_current == true).length
                ? <>
                  Edited
                </>
                : <>
                  <img src="/icons/view.svg" class="svg"></img>Edit
                </>
              }
            </button>
          </Tooltip>
          : <></>
      }
    },
    {
      name: "Signature",
      width: "240px",
      selector: "action",
      cell: (row) => renderAction(row),
    },
    {
      name: "Duplicate",
      width: "120px",
      center: true,
      cell: (row) => {
        const isDuplicate = row?.t_esign_template?.template?.use_type || null
        if (isDuplicate === "multiple") {
          return (
            <>
              <Tooltip title="Duplicate" placement="left">
                <span className="copy_document"
                  onClick={() => {
                    copyDocument(row);
                  }}
                ><i class="fa fa-clone fa-lg"></i>
                </span>
              </Tooltip>
              {row?.is_duplicate &&
                <>
                  <Tooltip title="Delete" placement="right">
                    <a
                      className="delete_icon_link"
                      onClick={() => onDelete(row.id)}
                    >
                      <i class="text-danger fa fa-lg fa-trash" aria-hidden="true"></i>
                    </a>
                  </Tooltip>
                </>
              }

            </>
          )
        }
      }
    },
  ];

  const copyDocument = async (row) => {
    try {
      dispatch(setLoadingStatus(true));
      await TransactionService.copyDocuments(row.id);
      fetchTransactionData(true)
    } catch (error) {
      dispatch(setLoadingStatus(false));
      console.log(error);
      toast.error("Error occurs, please try again.", {
        autoClose: 2000
      })
    }
  }

  const handleFetchAuthorizedDocsId = () => {
    dispatch(setLoadingStatus(true));
    dispatch(getAuthorizedDocsIds(taskType)).then((res) => {
      setAuthorizedDocsIds(() => {
        return res?.data
      });
      setIsauthorizedDocsSet(true);
      dispatch(setLoadingStatus(false));
    }).catch((err) => dispatch(setLoadingStatus(false)));
  }

  useEffect(() => {
    handleFetchAuthorizedDocsId()
  }, [])

  return (
    <>
      {isPreview && <PreviewModal request_signature={requestSignature} onClose={closePreviewModal}></PreviewModal>}
      {isReset && <DocumentResetModal edoc={resetEdocDetail} logged_user={logged_user} onClose={closeResetModal} uniqueId={dataTransaction?.unique_transaction_id && `LA-${dataTransaction.unique_transaction_id?.split('-')[1] || ""}`} companyUID={dataTransaction?.unique_transaction_id && `${dataTransaction.unique_transaction_id?.split('-')[0] || ""}`} />}
      {sorted_data.length == 0
        ? <div className="no-record-container">
          {isLoading
            ? <p className="no-record">Fetching Records...</p>
            : <p className="no-record">Please select documents for this transaction in your "Forms" tab above.</p>}
        </div>
        : <DataTable
          columns={columns}
          data={sorted_data}
          highlightOnHover={true}
          striped={true}
          noHeader={true}
          customStyles={datatableStyles}
        />
      }
      <SendPreviewEmailModal show={showSendEmailModal} onClose={() => { setShowSendEmailModal(false); setSetselectedDocuments([]) }} documentList={selectedDocuments} />
    </>
  );
};

export default EdocumentsViewTable;
