import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import './uploadDocumentStep.css';

import { makeStyles } from '@mui/styles';
import { toast } from "react-toastify";
import { retrieveCompanies } from '../../../../redux/actions/companies';
import { getChecklist, getIYBADocumentsByChecklistId } from '../../../../redux/actions/document_tasks';
import TemplatesService from '../../../../services/TemplatesService';
import AddSubTypeModal from '../../../common/AddSubTypeModal';
import { getSignURLForHandSign } from "../../../../redux/actions/transaction";
import { setLoadingStatus } from '../../../../redux/actions/loading';
const useStyles = makeStyles({
  hand_signed_only_div: {
    marginTop: "30px",
    alignItems: "flex-start",
    display: "flex !important",
  },
  hand_signed_only_label: {
    fontSize: "20px",
    marginRight: "10px",
    fontWeight: "bold",
    color: "black"
  },
  radio_div_hand_signed_only: {
    marginTop: "4px"
  },
  radio_div: {
    marginTop: "25px",
    marginRight: "60px"
  },
  active_inactive_div: {
    marginTop: "28px",
    height: "67px",
    width: "315px !important",
  },
  is_broker_div: {
    width: "31% !important",
  },
  show_broker_label: {
    marginRight: "9px !important",
  },
  addNewBtn: {
    marginRight: "7px",
    fontSize: "small",
    textTransform: "none"
  }
});

export const UploadDocumentStep = props => {

  const dispatch = useDispatch();

  const toggleSwitchUI = () => {
    const allSwitches = document.querySelectorAll(".toggle-switch");

    allSwitches.forEach(myswitch => {

      const offbutton = myswitch.querySelector(".state-off");
      // const nonebutton = myswitch.querySelector(".state-none");
      const onbutton = myswitch.querySelector(".state-on");

      //initially state of all switches is n/a
      // nonebutton.classList.add("activated-state-none");

      function resetState() {
        offbutton.classList.remove("activated-state-on");
        onbutton.classList.remove("activated-state-off");
        offbutton.classList.remove("activated-state-off");
        // nonebutton.classList.remove("activated-state-none");
        onbutton.classList.remove("activated-state-on");
      }

      offbutton.addEventListener("click", function () {
        props.update('status', false);
        resetState();
        // onbutton.classList.remove("activated-state-off");
        offbutton.classList.add("activated-state-on");
        onbutton.classList.add("activated-state-off");
      });

      // nonebutton.addEventListener("click", function () {
      //     resetState();
      //     // nonebutton.classList.add("activated-state-none");
      // });

      onbutton.addEventListener("click", function () {
        props.update('status', true);
        resetState();
        offbutton.classList.add("activated-state-off");
        onbutton.classList.add("activated-state-on");

      });

    });

  };

  const setValueOfStatus = (status) => {
    const allSwitches = document.querySelectorAll(".toggle-switch");

    allSwitches.forEach(myswitch => {

      function resetState() {
        offbutton.classList.remove("activated-state-on");
        onbutton.classList.remove("activated-state-off");
        offbutton.classList.remove("activated-state-off");
        // nonebutton.classList.remove("activated-state-none");
        onbutton.classList.remove("activated-state-on");
      }

      const offbutton = myswitch.querySelector(".state-off");
      // const nonebutton = myswitch.querySelector(".state-none");
      const onbutton = myswitch.querySelector(".state-on");

      if (status) {
        resetState();
        offbutton.classList.add("activated-state-off");
        onbutton.classList.add("activated-state-on");
        return;
      }
      resetState();
      offbutton.classList.add("activated-state-on");
      onbutton.classList.add("activated-state-off");
    });
  }

  useEffect(() => {
    toggleSwitchUI()
  }, [])

  const [addSubTypeMode, setAddSubTypeMode] = useState(false);
  const dataChecklist = useSelector((state) => state.documents.checklist);
  const iybaEDocuments = useSelector((state) => state.documents.esign_doc_types || []);
  const dataCompanies = useSelector((state) => state.companies.companies || []);

  const classes = useStyles();

  const [checklistId, setChecklistId] = useState(props.form.checklist_id || "default");
  const [association, setAssociation] = useState(props.form.association || "default");
  const [iybaEdocumentsId, setIYBAEdocumentsId] = useState(props.form.iyba_edocuments_id || "default");
  const [use_type, setUseType] = useState(props.form.use_type || "default");
  const [category, setCategory] = useState("");
  const [templates, setTemplates] = useState([]);
  const [isHandSigned, setIsHandSigned] = useState(props.form.is_hand_signed || false);
  const [companyId, setCompanyId] = useState(props.form.company_id || "default");
  const [mainDocId, setMainDocId] = useState(props.form.is_main_document_id || "default");
  const [isMainDocument, setIsMainDocument] = useState(props.form.is_main);
  const [isMultiSection, setIsMultiSection] = useState(props.form.is_multi_section || false);
  const [totalSections, setTotalSections] = useState(props.form.total_sections || null);
  const [url, setUrl] = useState(null);
  const [file, setFile] = useState(props.form.template_file || null);
  const ref = useRef();
  const associationList = [
    { id: 0, name: 'None' },
    { id: 1, name: 'IYBA' },
    { id: 2, name: 'YBAA' },
    { id: 3, name: 'CYBA' },
    { id: 4, name: 'MYBA' },
    { id: 5, name: 'NYBA' },
    { id: 6, name: 'USCG' },
    { id: 7, name: 'Custom' }
  ];

  useEffect(() => {
    setValueOfStatus(props.form.status);
  }, [props.form.status])

  useEffect(() => {
    dispatch(retrieveCompanies());
  }, [])

  useEffect(() => {
    dispatch(getChecklist());
  }, []);

  function extractUUIDFromUrl(url) {
    // Use a regular expression to capture the UUID from the URL
    const regex = /([a-f0-9\-]{36})\.pdf/;
    const match = url.match(regex);
    if (match) {
      return `${match[1]}.pdf`; // Return the UUID (first capture group)
    }
    return null; // Return null if no UUID is found
  }

  useEffect(async () => {
    if (props.form.file_link && !url) {
      const getESignedDocumentLink = async (s3_bucket, folder_path, file) => {
        try {
          await dispatch(getSignURLForHandSign({
            s3_bucket: s3_bucket, folder_path: folder_path, file_name: file, access_days: 1
          })).then((res) => {
            if (res?.url) {
              setUrl(res?.url);
              dispatch(setLoadingStatus(false));
            } else {
              console.error("Error fetching document:", res?.error);
              dispatch(setLoadingStatus(false));
            }
          })
        } catch (error) {
          console.error("Error fetching document:", error);
          dispatch(setLoadingStatus(false));
        }
      }
      await getESignedDocumentLink(process.env.REACT_APP_FORMS_BUCKET_NAME, `${process.env.REACT_APP_CDN_BUCKET_URL}esign-templates`, extractUUIDFromUrl(props.form.file_link));
    }

    if (props.form.is_hand_signed) {
      setIsHandSigned(props.form.is_hand_signed)
    }

  }, [props.form.file_link])

  useEffect(() => {
    setChecklistId(props.form.checklist_id || "default");
  }, [props.form.checklist_id]);

  useEffect(() => {
    setChecklistId(props.form.checklist_id || "default");
    if (props.form.checklist_id) {
      dispatch(getIYBADocumentsByChecklistId(props.form.checklist_id));
    }
  }, [props.form.checklist_id]);

  useEffect(() => {
    setIYBAEdocumentsId(props.form.iyba_edocuments_id || "default");
  }, [props.form.iyba_edocuments_id]);

  useEffect(() => {
    setIsMainDocument(props.form.is_main);
  }, [props.form.is_main]);

  useEffect(() => {
    setIsMultiSection(props.form.is_multi_section);
  }, [props.form.is_multi_section]);

  useEffect(() => {
    setTotalSections(props.form.total_sections);
  }, [props.form.total_sections]);

  useEffect(() => {
    setUseType(props.form.use_type || "default");
  }, [props.form.use_type]);

  useEffect(() => {
    setCategory(props.form.category || "");
  }, [props.form.category]);

  useEffect(() => {
    setCompanyId(props.form.company_id || "default");
  }, [props.form.company_id]);

  useEffect(() => {
    setMainDocId(props.form.is_main_document_id || "default");
  }, [props.form.is_main_document_id]);

  useEffect(() => {
    setAssociation(props.form.association || "default");
  }, [props.form.association]);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  const handleSelectFile = (e) => {
    document.getElementById('file').click();
  }

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      const base64 = await toBase64(e.target.files[0]);
      const url = URL.createObjectURL(e.target.files[0]);
      setUrl(url)
      props.update('template_file', e.target.files[0]);
      props.update('file_name', e.target.files[0].name);
      props.update('update_doc_templete', true);
    }
  }

  const openPreview = () => {
    window.open(url, "_blank")
  }

  const handleResetFile = (e) => {
    ref.current.value = "";
    setFile(null);
    setUrl(null);
    props.update('template_file', null);
    props.update('file_name', null);
  }

  const handleChangeForm = (evt, type) => {
    props.update(type, evt.target.value);
  }

  useEffect(() => {
    props.update("is_main", isMainDocument);
    if (!isMainDocument && props.form.iyba_edocuments_id) {
      //Fetch all esign templetes by subtype;
      TemplatesService.getEsignTemplateByIybaDocument(props.form.iyba_edocuments_id)
        .then(({ data }) => {
          let templates = data && data?.templates || [];
          const mainTemplateId = templates.find((template) => template.is_main === true)?.id || null;
          props.update("is_main_document_id", mainTemplateId);
          setMainDocId(mainTemplateId);
          setTemplates(data && data?.templates || []);
          if (data && data?.templates && data?.templates.length == 0) {
            toast.error("There is no other document to select as main document.", {
              autoClose: 2000,
            });
            setIsMainDocument(true);
          }
        })
        .catch((err) => {
          toast.error("Error Occure, Please try again", {
            autoClose: 2000,
          });
          setIsMainDocument(true);
        })
    }
  }, [isMainDocument, iybaEdocumentsId]);

  const closeAddSubTypeModal = async () => {
    if (props.form.checklist_id) {
      dispatch(getIYBADocumentsByChecklistId(props.form.checklist_id));
    }
    setAddSubTypeMode(false);
  }

  return (
    <>
      {addSubTypeMode && <AddSubTypeModal dataChecklist={dataChecklist} onClose={closeAddSubTypeModal}
        type={props.form.checklist_id} />}
      <div className="container">
        <div className="col-12 container-box">
          <div>
            <h4 style={{ fontWeight: '400', fontSize: '24px', marginTop: '2rem' }}>What needs to be signed?</h4>
            <h5 style={{ marginTop: '2rem' }}>Select Document Type</h5>
            <div className="form-container uk-margin-small">
              <div className="form-row">
                <div className="one-column sm-full-width uk-padding-small">
                  <label className="uk-form-label">Association</label>
                  <select className="uk-select" onChange={(evt) => handleChangeForm(evt, "association")} value={association}>
                    <option disabled value={"default"}>Select a Association</option>
                    {associationList.map(association => <option value={association.name} key={association.id}>{association.name}</option>)}
                  </select>
                </div>

                <div className="one-column sm-full-width uk-padding-small">
                  <label className="uk-form-label">Type</label>
                  <select className="uk-select" onChange={(evt) => handleChangeForm(evt, "checklist_id")} value={checklistId}>
                    <option disabled value={"default"}>Select a Type</option>
                    {dataChecklist.map(checklist => <option value={checklist.id} key={checklist.id}>{checklist.name}</option>)}
                  </select>
                </div>

                <div className="one-column sm-full-width uk-padding-small">
                  <div className="uk-grid">
                    <div className="uk-width-1-1">
                      <div className="uk-grid">
                        <div className="uk-width-1-2 uk-width-medium-2-5 uk-pull-2-5"><label className="uk-form-label">Sub Type</label></div>

                        <div className="uk-width-1-2 uk-width-medium-2-5 uk-push-3-5">
                          <div className="uk-flex uk-flex-center@m uk-flex-right@l">
                            <button type="button" onClick={() => setAddSubTypeMode(true)} className={`uk-button uk-button-link ${classes.addNewBtn}`}>+Add New</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <select className="uk-select" onChange={(evt) => handleChangeForm(evt, "iyba_edocuments_id")} value={iybaEdocumentsId}>
                    <option value={""}>Select a sub-type</option>
                    {iybaEDocuments.map(doc => <option value={doc.id} key={doc.id}>{doc.title}</option>)}
                  </select>
                </div>

              </div>
              <div className='form-row'>
                <div className="one-column sm-full-width uk-padding-small">
                  <label className="uk-form-label">Use Type</label>
                  <select className="uk-select" onChange={(evt) => handleChangeForm(evt, "use_type")} value={use_type}>
                    <option value={""}>Select a use-type</option>
                    <option value="single" key={Math.random()}>Single Use</option>
                    <option value="multiple" key={Math.random()}>Multi Use</option>
                    <option value="multi_select" key={Math.random()}>Multi Select</option>
                  </select>
                </div>

                <div className="one-column sm-full-width uk-padding-small">
                  <label className="uk-form-label">Category</label>
                  <select className="uk-select" onChange={(evt) => handleChangeForm(evt, "category")} value={category}>
                    <option value="">Select a Category</option>
                    <option value="agreements" key={Math.random()}>Agreements</option>
                    <option value="closing_docs" key={Math.random()}>Closing Docs</option>
                    <option value="state_reg" key={Math.random()}>State Reg</option>
                    <option value="documentation" key={Math.random()}>Documentation</option>
                  </select>
                </div>

                <div className="one-column sm-full-width uk-padding-small">
                  <label className="uk-form-label">Company</label>
                  <select className="uk-select" onChange={(evt) => handleChangeForm(evt, "company_id")} value={companyId}>
                    <option value={""}>Select a company</option>
                    {dataCompanies.map(company => <option value={company.id} key={company.id}>{company.company_name}</option>)}
                  </select>
                </div>
              </div>

              {props?.form?.iyba_edocuments_id &&
                <>
                  <div className="form-row">
                    <div className={`one-column sm-full-width d-flex uk-padding-small ${classes.is_broker_div}`} style={{ marginTop: "30px" }}>
                      <Fragment key={Math.random()}>
                        <input id="isMain" name="isMain" type="checkbox" className="e-sign-checkbox uk-checkbox" onChange={() => setIsMainDocument(!isMainDocument)}
                          checked={isMainDocument} />
                        <label className={`uk-form-label mb-0 radio ${classes.show_broker_label}`} htmlFor="isMain">Is Main Document</label>
                      </Fragment>
                    </div>

                    {!isMainDocument && props?.form?.iyba_edocuments_id && templates && templates.length > 0 &&
                      <div className="one-column sm-full-width uk-padding-small">
                        <label className="uk-form-label">Main Document</label>
                        <select className="uk-select" onChange={(evt) => handleChangeForm(evt, "is_main_document_id")} value={mainDocId}>
                          <option disabled value={"default"}>Select a main document</option>
                          {templates.map(template => <option value={template?.id} key={template?.id}>{template?.template_name} {template.is_main && `( Main )`}</option>)}
                        </select>
                      </div>
                    }
                  </div>

                  <div className="form-row">
                    <div className={`one-column sm-full-width uk-padding-small`} style={{ marginTop: "30px" }}>
                      <Fragment key={Math.random()}>
                        <input id="isMultiSection" name="isMultiSection" className="e-sign-checkbox  uk-checkbox" type="checkbox" onChange={() => { setIsMultiSection(!isMultiSection); props.update("is_multi_section", !isMultiSection) }}
                          checked={isMultiSection} />
                        <label className={`uk-form-label mb-0 radio`} htmlFor="isMultiSection">Multiple Signature Sections</label>
                      </Fragment>
                    </div>

                    {isMultiSection &&
                      <div className="one-column sm-full-width uk-padding-small">
                        <label className="uk-form-label">Total Signature Sections</label>
                        <input className="uk-input" type="number" value={totalSections} onChange={(event) => { setTotalSections(event.target.value); props.update("total_sections", event.target.value) }} />
                      </div>
                    }
                  </div>
                </>
              }

              <div className="form-row">
                <div className={`one-column sm-full-width uk-padding-small  ${classes.hand_signed_only_div}`}>
                  <label className={`uk-form-label ${classes.hand_signed_only_label}`}>Hand Signed Only :</label>
                  <div className={`${classes.radio_div_hand_signed_only} d-flex`}>
                    <input type='radio' id="hand_sign_yes" checked={isHandSigned} onChange={(evt) => {
                      setIsHandSigned(true);
                      props.update("is_hand_signed", true);
                    }} value={true} />
                    <label className='uk-form-label mb-0  radio' htmlFor="hand_sign_yes">Yes</label>
                    <input type='radio' onChange={(evt) => {
                      setIsHandSigned(false);
                      props.update("is_hand_signed", false);
                    }} checked={!isHandSigned} value={false} id="hand_sign_no" />
                    <label className='uk-form-label  mb-0 radio' htmlFor="hand_sign_no">No</label>
                  </div>
                </div>

                <div className={`one-column sm-full-width uk-padding-small ${classes.active_inactive_div}`}>
                  <div className="toggle-switch">
                    <div className="state-on" onClick={(evt) => props.update("status", true)}>Active</div>
                    <div className="state-off" onClick={(evt) => props.update("status", false)}>Inactive</div>
                  </div>
                </div>

              </div>
            </div>
            <h5 style={{ marginTop: '2rem' }}>Select documents</h5>
            <div className="file-uploader-container">
              <div className="box">
                <input id="file" type="file" onChange={handleFileChange} ref={ref} hidden />
                <button className="btn btn-upload" onClick={handleSelectFile}>Upload file</button>
                <button className="btn btn-upload">Add template</button>
              </div>
              <div className="divider"></div>
              {/* <div className="box">
              <span style={{ fontSize: '14px', marginRight: '15px' }}>Upload from</span>

              <div className="online-uploader-container">
                <div>
                  <span data-qa-ref="prep-dropbox-integration">
                    <img src="https://cdn.hellosign.com/1.116.0/build/5ba9a2a229b94c98c6ac3eb9c75daead.svg" />
                  </span>
                </div>
                <div>
                  <span data-qa-ref="prep-google-drive-integration">
                    <img src="https://cdn.hellosign.com/1.116.0/build/cb279ced148387bc6fcd706761affdde.png" />
                  </span>
                </div>
                <div>
                  <span data-qa-ref="prep-box-integration">
                    <img src="https://cdn.hellosign.com/1.116.0/build/8d0a0f4dba8e0f6747d426382991b170.png" />
                  </span>
                </div>
                <div>
                  <span data-qa-ref="prep-evernote-integration">
                    <img src="https://cdn.hellosign.com/1.116.0/build/5f00af1c7cc314117221a1dfea28d36d.png" />
                  </span>
                </div>
                <div>
                  <span data-qa-ref="prep-one-drive-integration">
                    <img src="https://cdn.hellosign.com/1.116.0/build/46e679e7eaae46955d929a8a37c54102.png" />
                  </span>
                </div>
              </div>
            </div>
            <div className="divider"></div> */}
              <div className="box">
                <div className="online-uploader-container">
                  <img src="https://cdn.hellosign.com/1.116.0/build/33bcd013a7a9c3acfb40af4a3ab3098a.png" />
                  <div>
                    Drag and drop files here
                  </div>
                </div>
              </div>
            </div>
            <h5 style={{ marginTop: '2rem' }}>Documents you've selected</h5>
            <span>Drag and drop documents to reorder them</span>
            {(props.form.file_name || file?.name) && (
              <div style={{ display: 'flex', marginTop: '30px' }}>
                <div style={{ display: 'flex' }}>
                  <div style={{ visibility: 'visible' }}>
                    <svg width="1em" height="1em" viewBox="0 0 16 24" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M7 4.5C7 5.32843 6.32843 6 5.5 6C4.67157 6 4 5.32843 4 4.5C4 3.67157 4.67157 3 5.5 3C6.32843 3 7 3.67157 7 4.5ZM12 4.5C12 5.32843 11.3284 6 10.5 6C9.67157 6 9 5.32843 9 4.5C9 3.67157 9.67157 3 10.5 3C11.3284 3 12 3.67157 12 4.5ZM5.5 11C6.32843 11 7 10.3284 7 9.5C7 8.67157 6.32843 8 5.5 8C4.67157 8 4 8.67157 4 9.5C4 10.3284 4.67157 11 5.5 11ZM12 9.5C12 10.3284 11.3284 11 10.5 11C9.67157 11 9 10.3284 9 9.5C9 8.67157 9.67157 8 10.5 8C11.3284 8 12 8.67157 12 9.5ZM5.5 16C6.32843 16 7 15.3284 7 14.5C7 13.6716 6.32843 13 5.5 13C4.67157 13 4 13.6716 4 14.5C4 15.3284 4.67157 16 5.5 16ZM7 19.5C7 20.3284 6.32843 21 5.5 21C4.67157 21 4 20.3284 4 19.5C4 18.6716 4.67157 18 5.5 18C6.32843 18 7 18.6716 7 19.5ZM10.5 16C11.3284 16 12 15.3284 12 14.5C12 13.6716 11.3284 13 10.5 13C9.67157 13 9 13.6716 9 14.5C9 15.3284 9.67157 16 10.5 16ZM12 19.5C12 20.3284 11.3284 21 10.5 21C9.67157 21 9 20.3284 9 19.5C9 18.6716 9.67157 18 10.5 18C11.3284 18 12 18.6716 12 19.5Z" fill="currentColor"></path>
                    </svg>
                  </div>
                  <svg data-qa-ref="file-icon" width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor">
                    <path fillRule="evenodd" clipRule="evenodd" d="M6 3a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V8a1 1 0 00-.293-.707l-2-2-2-2A1 1 0 0014 3H6zm1 16V5h5v3a2 2 0 002 2h3v9H7zm9.586-11l-1.293-1.293L14 5.414V8h2.586z"></path>
                  </svg>
                  <span data-type="Upload">
                    {props.form.file_name || file.name}
                  </span>
                </div>
                <a data-uk-tooltip="remove-document" style={{ marginLeft: '5px' }} onClick={handleResetFile}>
                  <img src="https://cdn.hellosign.com/1.116.0/build/ae9ae8331a142bc9ce60901d10a41fc6.svg" />
                </a>

                <a style={{ marginLeft: "11px" }} onClick={() => openPreview()}>
                  <img src="/icons/view.svg" className="svg" data-uk-tooltip="Preview Document" />
                </a>
              </div>
            )}
            <hr />
          </div>
        </div>
      </div>
    </>
  );
};