import { useSelector } from 'react-redux';
import DataTable from "react-data-table-component";
import datatableStyles from "../../utils/datatable-style";

const ArchivedUserList = ({ searchProps, archivedUserList, totalRows, columns, myLoading }) => {

    const { search, setSearch } = searchProps;
    const isLoading = useSelector((state) => state.loading.status);

    //Function for handle row limit
    const handleRowsPerPage = (numberOfRows, page) => {
        setSearch((search) => {
            return {
                ...search,
                limit: numberOfRows,
                page: page
            }
        })
    }

    //Function for handle page no
    const handlePageChange = page => {
        setSearch((search) => {
            return {
                ...search,
                page: page
            }
        })
    };

    //Function for handle sort
    const handleSort = (column, sortDirection) => {
        // column.selector, sortDirection
        setSearch((search) => {
            return {
                ...search,
                sortOrder: sortDirection,
                sortField: column.selector
            }
        })
    };
    return (
        <>
            {!archivedUserList.length && (isLoading||myLoading)
                ? <div className="no-data-table"><div className="p-4">Fetching Details...</div></div>
                : <DataTable
                    title="Archived Users Table"
                    columns={columns}
                    data={archivedUserList}
                    striped={true}
                    highlightOnHover={true}
                    pointerOnHover={true}
                    center={true}
                    noHeader={true}
                    fixedHeader={true}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                    onChangeRowsPerPage={handleRowsPerPage}
                    onChangePage={handlePageChange}
                    onSort={handleSort}
                    sortServer
                    persistTableHead
                    customStyles={datatableStyles}
                />}
        </>
    )
}

export default ArchivedUserList;