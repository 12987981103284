import { Modal, Tooltip } from '@mui/material'
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useState } from 'react'
import { downloadDocument } from '../../utils/s3';
import { useDispatch } from 'react-redux';
import { getSignURLForHandSign, removeUploadDocument } from '../../redux/actions/transaction';
import { setLoadingStatus } from '../../redux/actions/loading';
import Swal from 'sweetalert2';
import Auth from '../../utils/auth';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    borderRadius: "10px",
    bgcolor: "#fff",
    border: '1px solid #000',
    boxShadow: 24,
    p: 0,
};

//Define styling
const useStyles = makeStyles({
    boxModalHeader: {
        borderRadius: "inherit"
    },
    modalTitle: {
        marginLeft: "91px"
    },
    modalBody: {
        display: "contents",
        padding: "20px"
    },
    formContainer: {
        borderRadius: "25px"
    },
    modalFooter: {
        borderRadius: "inherit"
    },
});

export const ViewDocumentsModal = ({ documentTask, transactionId = null, onClose, updateDocument = null, taskMasterId = null }) => {

    const dispatch = useDispatch();

    const classes = useStyles();
    const logged_user = Auth.getInstance().getUserData();

    const [taskDocument, setTaskDocument] = useState([]);

    useEffect(() => {
        if (documentTask && (documentTask?.transaction_task_documents?.length || documentTask?.length)) {
            if (documentTask?.length) {
                setTaskDocument(documentTask);
            } else {
                setTaskDocument(documentTask?.transaction_task_documents);
            }
        }
    }, [documentTask]);

    const fetchBufferData = async (taskMasterId, documentUrl) => {
        dispatch(setLoadingStatus(true));
        try {
            const token = Auth.getInstance().getAuthToken(); // Replace with your actual token
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_API_URL}/transactions/tasksTags/documents/${taskMasterId}/${documentUrl}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-type": "application/pdf",
                    "Cache-Control": "no-cache, no-store, must-revalidate",
                    "Pragma": "no-cache",
                    "Expires": "0",
                    "Access-Control-Allow-Origin": process.env.REACT_APP_BACKEND_API_URL
                }
            })
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const blob = await response.blob(); // Convert response to blob
            const url = URL.createObjectURL(blob);
            // window.open(url, "_blank");
            const newTab = window.open(url, "_blank");
            // Revoke Blob URL on tab close or page refresh
            window.addEventListener("beforeunload", () => URL.revokeObjectURL(url));
            dispatch(setLoadingStatus(false));
        } catch (error) {
            console.error("Error fetching buffer data:", error);
            dispatch(setLoadingStatus(false));
        }
    }


    const getHandSignedDocumentLink = async (file) => {
        dispatch(setLoadingStatus(true));
        try {
            dispatch(getSignURLForHandSign({
                s3_bucket: process.env.REACT_APP_FORMS_BUCKET_NAME, folder_path: `${process.env.REACT_APP_CDN_BUCKET_URL}hand-signed-documents`, file_name: file, access_days: 1
            })).then((res) => {
                if (res?.url) {
                    window.open(res?.url, "_blank");
                } else {
                    console.error("Error fetching document:", res?.error);
                }
                dispatch(setLoadingStatus(false));
            })
        } catch (error) {
            console.error("Error fetching document:", error);
            dispatch(setLoadingStatus(false));
        }
    }



    const handleDownload = (filename, title) => {
        const ext = filename.split(".")[1];
        dispatch(setLoadingStatus(true));
        downloadDocument(filename, transactionId)
            .then((rlt) => {
                function saveByteArray(reportName, byte) {
                    var blob;
                    if (ext === "pdf") {
                        blob = new Blob([byte], { type: `application/pdf` });
                    } else if (ext === "png" || ext === "jpeg" || ext === "bmp" || ext === "gif") {
                        blob = new Blob([byte], { type: `image/${ext}` });
                    } else if (ext === "plain") {
                        blob = new Blob([byte], { type: "text/plain" });
                    } else if (ext === "msword") {
                        blob = new Blob([byte], { type: "application/msword" });
                    } else if (ext === "vnd.openxmlformats-officedocument.wordprocessingml.document") {
                        blob = new Blob([byte], {
                            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        });
                    } else if (ext === "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                        blob = new Blob([byte], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                    } else if (ext === "vnd.ms-excel") {
                        blob = new Blob([byte], { type: "application/vnd.ms-excel" });
                    }
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.target = "blank";
                    var fileName = reportName;
                    link.download = fileName;
                    link.click();
                }
                saveByteArray(title, rlt.data);
                dispatch(setLoadingStatus(false));
            })
            .catch((err) => {
                console.log(err);
                dispatch(setLoadingStatus(false));
            });
    };

    const handleDeleteDocument = (document) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to remove this document.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setLoadingStatus(true));
                dispatch(removeUploadDocument(document.id, {
                    transaction_id: transactionId,
                    actionBy: logged_user.id,
                    remaining_length: taskDocument.length - 1,
                    transaction_task_master_id: taskMasterId
                })
                ).then(response => {
                    toast.success('Document removed successfully');
                    documentTask = taskDocument.filter(doc => doc.id != document.id);
                    setTaskDocument((state) => state.filter(doc => doc.id != document.id));
                    dispatch(setLoadingStatus(false));
                    updateDocument();
                    if (documentTask.length == 0) {
                        onClose();
                    }
                }
                ).catch(error => {
                    dispatch(setLoadingStatus(false));
                    console.error(error);
                });
            }
        })
    }

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box sx={style}>
                <div className={`uk-modal-header ${classes.boxModalHeader}`}>
                    <h3 className={`uk-modal-title ${classes.modalTitle}`}>View Documents</h3>
                </div>
                <div className={`uk-modal-body ${classes.modalBody}`}>
                    <div className='uk-padding-small'>
                        {taskDocument?.map((file, index) => (
                            <div className=" mt-2 ml-3 row" key={`${file.name}_${index}`}>
                                <div className='col-1 font-weight-bold '>{index + 1}.</div>
                                <div className='col'> <span className='font-weight-bold'>{file.original_file_name}</span>
                                    <Tooltip className='ml-2' title="View Document" placement="top">
                                        <a
                                            onClick={() => {
                                                if (file?.file_url) {
                                                    fetchBufferData(documentTask?.master_task_id, file?.file_url);
                                                } else {
                                                    getHandSignedDocumentLink(file.file_name)
                                                    // window.open(getHandSignedDocumentPath(file.file_name))
                                                }
                                            }}
                                        >
                                            {file?.file_url ? <i className="fa fa-file fa-lg" aria-hidden="true" style={{ color: "#0F294B", height: "21px", width: "20px" }}></i> : <img src="/icons/view.svg" className="svg" />}
                                        </a>
                                    </Tooltip>
                                    {transactionId &&
                                        <>
                                            {/* <Tooltip title="Download Document" placement="top">
                                                <a className='ml-2' onClick={() => handleDownload(file.file_url, file.original_file_name)}>
                                                    <img src="/icons/download.svg"
                                                        className="svg" />
                                                </a>
                                            </Tooltip> */}
                                            <Tooltip title="Remove Document" placement="top">
                                                <a className='ml-2' onClick={() => handleDeleteDocument(file)}>
                                                    <img src="/icons/delete.svg" className="svg" />
                                                </a>
                                            </Tooltip>
                                        </>
                                    }</div>

                            </div>
                        ))}
                    </div>
                </div>
                <div className={`uk-modal-footer uk-text-right ${classes.modalFooter}`}>
                    <button value="Submit" className="uk-button uk-button-primary" type="button" onClick={onClose} >Close</button>
                </div>
            </Box>
        </Modal>
    )
}
