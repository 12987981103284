import { yupResolver } from "@hookform/resolvers/yup";
import React, { useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import Swal from 'sweetalert2';
import "./createMLSForm.css";
import { getFigure, getPrice } from "../../utils/currency";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearVessel, getGlobalVesselOrToy, getMLSFilters, getVesselOrToy } from "../../redux/actions/vessels_toys";
import hull_materials from "../../constants/hull-materials";
import { setLoadingStatus } from "../../redux/actions/loading";
import VesselAndToyService from "../../services/VesselAndToyService";
import Auth from "../../utils/auth";
import { fileUploadWithServer, uploadDocument } from "../../utils/s3";
import { toast } from "react-toastify";
import clsx from "clsx";
import { FSx } from "aws-sdk";
import { Autocomplete, TextField } from "@mui/material";
import vessels from "../../constants/dropdownLists/vessel-manufacturer";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { dateFormatter } from "../../utils/common";

const schema = yup.object().shape({

    status: yup.string().required(),
    vessel_name: yup.string().required(),
    model: yup.string().required(),
    datesold: yup
        .string()
        .when("status", {
            is: "Sold",
            then: yup.string().required("Sold date is required")
        }),
    sold_price: yup
        .string()
        .when("status", {
            is: "Sold",
            then: yup.string().required("sold price is required")
        }),
    sharetype: yup.string().required(),
    signedlistingagree: yup.string().required(),
    vessel_detail_listingtype: yup.string().required(),
    country: yup.string().required(),
    state: yup.string().when("country", {
        is: "United States",
        then: yup.string().required()
    }),
    city: yup.string().when("country", {
        is: "United States",
        then: yup.string().required()
    }),
    zip: yup.string().when("country", {
        is: "United States",
        then: yup.string().required()
    }),
    primary_currency: yup.string().required(),
    price: yup.string().required(),
    vessel_detail_hin: yup.string().required(),
    vessel_condition: yup.string().required(),
    type: yup.string().required(),
    manufacturer_name: yup.string().required(),
    category: yup.string().required(),
    fueltype: yup.string().required(),
    year: yup.number().required(),
    display_length_feet: yup.number().required(),
    display_length_meters: yup.number().required(),
    vessel_detail_hullmaterial: yup.string().required(),
    loa_feet: yup.number().required(),
    loa_inch: yup.number().required(),
    loa_meters: yup.number().required(),
    vessel_locale: yup.string().required(),
    // image: yup.mixed().test(
    //     "type",
    //     "Please select a file",
    //     (file) => {
    //         console.log("==================== FIle ======================");
    //         console.log(file);
    //         if (file) {
    //             return  !file || (file && file.name)
    //           } else {
    //             return true;
    //           }
    //     }
    //   )
    //   .test(
    //     "abc",
    //     "abc",
    //     (value) => !value?.name
    //   )
    // image: yup.string().required()
    // image: yup.mixed().required("Required File").test("file", "You need to provide a file", (value) => {
    //     if (value?.name) {  
    //       return true;
    //     }
    //     return false;
    //     }),

});

const obj = {
    "hull_number": "vessel_detail_hin",
    "boat_type": "type",
    "make": "manufacturer_name",
    "boat_category": "category",
    "year": "year",
    "engine_hp": "engine_power_hp",
    "engine_make": "engine_make",
    "engine_model": "engine_model",
    "vessel_name": "vessel_name",
    "model": "model"
}


const CreateMLSVesselForm = ({ onClose, vesselId }) => {

    //Define maximum char limit
    const maxLimit = 300;

    const MAX_ENGINE_COUNT = 6;

    //Get LoggedIn user Data
    const logged_user = Auth.getInstance().getUserData();

    //Take a state to store the conversation of feet to meters
    const [isFtToMAuto, setIsFtToMAuto] = useState(true);

    //Take a state to check the status of vessel is sold or not
    const [isStatusSold, setIsStatusSold] = useState(false);

    const [fileObj, setFileObj] = useState({});

    //State for storing boat type
    const [boatType, setBoatType] = useState("");

    //State for storing boat manufactures dropdown
    const [boatManufacturers, setBoatManufacturers] = useState([]);

    //State for displaying remaining charator for summary
    const [remainingChar, setRemainingChar] = useState(maxLimit);

    //State for storing boat category dropdown
    const [boatCategories, setBoatCategories] = useState([]);

    //Define dispatch
    const dispatch = useDispatch();

    //Get vessel data from redux
    const dataVessel = useSelector((state) => state.vessel_toys.current);

    //Clear current vessel
    useEffect(() => {
        return () => {
            dispatch(clearVessel());
        };
    }, []);

    //Fetch Vessel Data
    useEffect(() => {
        if (vesselId) {
            dispatch(setLoadingStatus(true))
            dispatch(getGlobalVesselOrToy(vesselId))
                .then(() => dispatch(setLoadingStatus(false)))
                .catch(() => setLoadingStatus(false))
        }
    }, [])

    /* Form Validation */
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        control,
        watch,
        trigger,
        setFocus,
        formState: { errors },
    } = useForm({
        mode: "all",
        defaultValues: {
            engine: [
                {}
            ]
        },
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    //Take an array to store the multiple engine
    const {
        fields,
        append,
        remove
    } = useFieldArray({
        control,
        name: "engine"
    });

    //get mls filters on app load for use of vessel forms
    useEffect(() => {
        dispatch(getMLSFilters());
    }, []);

    //Get data of country for MLS Vessels
    const country = useSelector((state) => state.vessel_toys.mlsFilters.countries);

    //Get data of state for MLS Vessels
    const state = useSelector((state) => state.vessel_toys.mlsFilters.state);

    //Remove null manufactures from all data manufactures
    // const dataManuFecturers = vessels.sort();

    let dataManuFecturers = useSelector((state) => {
        return state.vessel_toys.mlsFilters.manufacturers || [];
    });

    dataManuFecturers = dataManuFecturers.filter((item, i, ar) => ar.indexOf(item) === i).map(mfr => { return { label: mfr } });

    const [makeValue, setMakeValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    //Separate power category of boat from all category of boat
    const dataPowerCategories = useSelector((state) => {
        if (state.vessel_toys.mlsFilters.PowerCategories) {
            return state.vessel_toys.mlsFilters.PowerCategories || [];
        } else {
            return [];
        }
    });

    //Separate sail category of boat from all category of boat
    const dataSailCategories = useSelector((state) => {
        if (state.vessel_toys.mlsFilters.SailCategories) {
            return state.vessel_toys.mlsFilters.SailCategories || [];
        } else {
            return [];
        }
    });

    const returnKeyfromValue = (value, object) => {
        for (const key in object) {
            if (Object.hasOwnProperty.call(object, key)) {
                const element = object[key];
                if (element.label === value) {
                    return element.label
                }
            }
        }

    }

    /**
     * COnverts horsepower to kilowatts and returns value 
     * @param {*} hpValue 
     * @returns HorsePower value
     */
    const convertEngineHPToKW = (hpValue) => {
        return Number(hpValue * 0.7457).toFixed(2);
    }

    //UseEffect for setBoatCategories with sorting
    useMemo(() => {
        let categories = boatType == "Power" ? dataPowerCategories : dataSailCategories;
        setBoatCategories(categories.sort());
    }, [boatType, watch("type")]);

    useEffect(() => {
        setValue("category", dataVessel?.boat_category || "")
    }, [boatCategories])

    //set Vessels data
    useEffect(() => {
        if (dataVessel) {
            setBoatType(dataVessel?.boat_type || "Power");
            setMakeValue(dataVessel['make'] ? { label: dataVessel['make'] } : null);
            Object.keys(dataVessel)
                .forEach((key) => {
                    if (key == "length") {
                        if (dataVessel["length_unit"] == "ft") {
                            setValue("display_length_feet", dataVessel["length"]);
                            feetToMeters('loa');
                            feetToMeters('display');
                            return;
                        }
                        setValue("display_length_meters", dataVessel["length"]);
                        meters2feet();
                        return;
                    }
                    if (obj[key] == "manufacturer_name") {
                        setValue("manufacturer_name", returnKeyfromValue(dataVessel[key], dataManuFecturers))
                        return;
                    }
                    if (["engine_hp", "engine_make", "engine_model"].includes(key)) {
                        setValue(`engine.${0}.${obj[key]}`, dataVessel[key]);
                        if (key === 'engine_hp') {
                            const enginePowerInHP = parseInt(dataVessel['engine_hp'] || 0);
                            if (enginePowerInHP) {
                                setValue(`engine.${0}.engine_power_kw`, convertEngineHPToKW(enginePowerInHP));
                            }
                        }
                        return;
                    }
                    if (obj[key]) {
                        setValue(obj[key], dataVessel[key])
                    }

                })
            //Push engine fields as per number of engine fields
            Array.from({ length: (dataVessel?.number_of_engines) || 0 }).forEach((_, index) => {
                const realIndex = index + 1;
                setValue(`engine.${index}.engine_year`, dataVessel[`engine_year_${realIndex}`]);
                setValue(`engine.${index}.engine_serial_number`, dataVessel[`engine_serial_${realIndex}`]);
                setValue(`engine.${index}.engine_hours`, dataVessel[`engine_hours_${realIndex}`]);
                setValue(`engine.${index}.engine_make`, dataVessel[`engine_make`]);
                setValue(`engine.${index}.engine_model`, dataVessel[`engine_model`]);
                setValue(`engine.${index}.engine_power_hp`, parseInt(dataVessel[`engine_hp`] || 0));
                const enginePowerInHP = parseInt(dataVessel['engine_hp'] || 0);
                if (enginePowerInHP) {
                    setValue(`engine.${index}.engine_power_kw`, convertEngineHPToKW(enginePowerInHP));
                }
                // setValue(`engine.${index}.engine_power_kw`, dataVessel[`engine_power_kw`]);
            });
        }
    }, [dataVessel])

    /**
     * It will change the soldState as per status is slected.
     * @param {Object} event 
     * @returns It's open swal to warn user for enter the sold price and sold date
     */
    const handleStatusChange = (event) => {
        if (["Sold"].includes(event.target.value)) {

            //Make a sold status as a true
            setIsStatusSold(true);

            //Define warning html string.
            var soldWarningHtml = '<div class="text-center warning-html"><p>After saving a SOLD vessel<br>you will no longer be able to edit this vessel.</p>';
            soldWarningHtml += '<p class="m-t-15">This helps to maintain accurate Sold Boat Reporting.</p>';
            soldWarningHtml += '<p class="m-t-15">To relist any Sold vessel - please simply duplicate this vessel and update the newly created version of this listing</p>';
            soldWarningHtml += '<p class="m-t-15" style="font-size: 24px;">Please enter valid sold date & price</p></div>';

            //Open a Warning pop-up
            Swal.fire({
                title: "<h3 style='color:#534343 !important'>WARNING</h3>",
                type: "warning",
                Text: soldWarningHtml,
                html: soldWarningHtml,
                icon: 'warning',
                allowEscapeKey: false,
                showCancelButton: false,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Click to Confirm"
            })
            return;
        }

        //Make a sold status as a false.
        setIsStatusSold(false);
    }

    //This function is use to add $ sign in price field
    const handleBlur = (ref) => (e) => {
        const price = getPrice(getValues(ref));
        setValue(ref, price);
    };

    //This function is use to remove $ sign in price field
    const handleFocus = (ref) => (e) => {
        const price = getFigure(getValues(ref));
        setValue(ref, price);
    };

    //This function is use to convert engine data (From Array of object to) as API required.
    const filterEngineData = (engineArr) => {
        let arr = [
            "engine_hours",
            "engine_make",
            "engine_model",
            "engine_power_hp",
            "engine_power_kw",
            "engine_serial_number",
            "engine_type",
            "engine_year",
        ];
        let obj = {}
        for (const item of arr) {
            obj[item] = engineArr.map((obj) => obj[item])
        }
        return obj;
    }

    //This function is used to send the data of vessel
    const handleRegistration = async (vesselData) => {

        //Open a Warning pop-up
        const { isConfirmed } = await Swal.fire({
            title: 'Are you sure?',
            text: "You want to create MLS Vessel.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        })

        if (isConfirmed) {

            //If the status of vessel is Sold then add sold price is 0. if it is not available
            if (["Sold"].includes(vesselData?.status)) {
                vesselData.sold_price = getFigure(vesselData?.sold_price) || 0
            } else {
                vesselData.datesold = null;
                vesselData.sold_price = null;
            }

            vesselData.make = makeValue?.label || '';

            //Convert Engine data ( Array of object to ) as per API required
            const engineData = filterEngineData(vesselData?.engine);

            //Merge engine data with the vessels data
            vesselData = {
                ...vesselData,
                user_id: logged_user?.mls_user_id,
                ...engineData
            }

            //Delete engine field from vessel data.
            delete vesselData?.engine;

            //Set the data key of price as per selected primary currency
            const priceKey = vesselData?.primary_currency?.toLowerCase();
            vesselData[`price_${priceKey}`] = parseInt(vesselData?.price) || 0;

            //Delet the price field from vessel data.
            delete vesselData?.price;

            dispatch(setLoadingStatus(true));
            try {
                //Upload MLS Vessel Image
                if (fileObj && fileObj?.name) {
                    const imageUrl = await uploadDocument(fileObj, 'export-vessel-pictures');
                    vesselData["image_url"] = `https://${process.env.REACT_APP_UPLOADS_BUCKET_NAME}${process.env.REACT_APP_CDN_BUCKET_URL}export-vessel-pictures/${imageUrl}`;
                }

                //Api Calling for creating MLS Vessel.
                const { data } = await VesselAndToyService.createMLSVessel(logged_user?.id, vesselId, vesselData);

                //Close loader
                dispatch(setLoadingStatus(false));
                const str = `<i>${data?.message}, VesselId: ${data?.vesselId}</i>`

                Swal.fire({
                    html: str,
                    icon: 'success'
                })

                //Close the modal
                onClose();

            } catch (error) {
                dispatch(setLoadingStatus(false));
                toast.error(error?.message, {
                    autoClose: 2000
                })
                console.log(error);
            }
        }
    }

    const handleError = (error) => {
        console.log('errors=>', error);
    }

    const feetToMeters = (type) => {
        if (!isFtToMAuto) {
            return;
        }

        if (type == 'loa') {
            const feet = Number(getValues("loa_feet")) || 0;
            const inch = Number(getValues("loa_inch")) > 0 ? Number(getValues("loa_inch")) : 0;
            setValue("loa_meters", parseFloat(((feet * 12) + inch) / 39.370078740157474).toFixed(2));
        } else {
            let displayFeet = Number(getValues("display_length_feet")) || 0;
            let displayMeters = ((displayFeet * 12)) / 39.370078740157474;
            setValue("display_length_meters", parseFloat(displayMeters).toFixed(2));
        }

    }

    const meters2feet = () => {
        if (!isFtToMAuto) {
            return;
        }

        const displayMeters = Number(getValues("display_length_meters")) || 0;
        setValue("display_length_feet", parseFloat((displayMeters * 39.370078740157474) / 12).toFixed(2));
    }

    /**
     * This function is used to convert feet to meter and meter to feet.
     * @param {Number} value 
     * @param {String} convetIn 
     */
    const feetToMeterHandler = (value, convetIn) => {
        if (isFtToMAuto) {
            switch (convetIn) {
                case "feet":
                    let valueInMt = parseInt(value) || 0;
                    setValue("display_length_meters", valueInMt);
                    var inches = value * 39.370078740157474;
                    setValue("display_length_feet", parseInt(inches / 12));
                    setValue("loa_inch", parseInt(inches % 12));
                    setValue("loa_feet", parseInt(inches / 12));
                    break;
                case "meter":
                    let valueInFt = parseInt(value) || 0;
                    setValue("display_length_feet", valueInFt);
                    if (isFtToMAuto && valueInFt > 0) {
                        let valueInMt = ((valueInFt * 12)) / 39.370078740157474;
                        let inch = getValues("loa_inch") ? parseInt(getValues("loa_inch")) : 0;
                        var meters = ((value * 12) + inch) / 39.370078740157474;
                        setValue("loa_meters", parseFloat(meters).toFixed(2));
                        setValue("display_length_meters", parseFloat(valueInMt).toFixed(2))
                    }
                    break;
                default:
                    break;
            }
        }
    }

    //This function is use to validate the length of summary.
    const validateSummary = (e) => {
        const summaryValue = e.target.value;
        if ((maxLimit - summaryValue.length) >= 0) {
            setValue("vessel_locale", summaryValue);
            setRemainingChar(maxLimit - summaryValue?.length)
        }
    }

    const handleFileChange = (e) => {
        setFileObj(e.target.files[0]);
        setValue("image_url", e.target.files[0])
    }

    const handleCancleBtn = () => {
        //Define warning html string.
        var soldWarningHtml = '<div class="text-center warning-html"><p>After saving a SOLD vessel<br>you will no longer be able to edit this vessel.</p>';
        soldWarningHtml += '<p class="m-t-15">This helps to maintain accurate Sold Boat Reporting.</p>';
        soldWarningHtml += '<p class="m-t-15">To relist any Sold vessel - please simply duplicate this vessel and update the newly created version of this listing</p>';
        soldWarningHtml += '<p class="m-t-15" style="font-size: 24px;">Please enter valid sold date & price</p></div>';

        //Open a Warning pop-up
        Swal.fire({
            title: 'Are you sure?',
            text: "Any data entered will be lost.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: "No"
        }).
            then((result) => {
                if (result.isConfirmed) {
                    Swal.close();
                    setTimeout(() => {
                        onClose();
                    }, 1000);
                }
            })
    }

    return (
        <>
            <div className="uk-modal-body" style={{
                height: "85%"
            }}>
                <form style={{ height: "100%", overflowY: "auto" }} onSubmit={handleSubmit(handleRegistration, handleError)} className="sky-form popup-form">
                    <div className="row feet_meter_conversion_text">
                        <div className="col-6 mr-0 pr-0 boatdox-label-div">
                            <label className="boatdox-label"><b>Boat Upload</b></label>
                        </div>
                        <div className="m-0 p-0">
                            <label for="auto_conversion"><small>Ft/M Auto</small></label>
                        </div>
                        <div className="col-1 mt-1">
                            <input type="radio" id="auto_conversion" onChange={() => setIsFtToMAuto(true)} name="auto_feet_meter_conversion" checked={isFtToMAuto} />
                        </div>
                        <div className="m-0 p-0">
                            <label for="manual_conversion"><small>Ft/M Manual</small></label>
                        </div>
                        <div className="col-1 mt-1" >
                            <input type="radio" id="manual_conversion" onChange={() => setIsFtToMAuto(false)} name="auto_feet_meter_conversion" checked={!isFtToMAuto} />
                        </div>
                    </div>
                    <label>
                        <font color="red">(All fields are required)</font>
                    </label>
                    <div className="row">
                        <div className="col-md-3">
                            <label className="uk-form-label">*Featured Image</label>
                            <div className="custom-file">
                                <input type="file" className={clsx(" custom-file-input form-control", { 'uk-form-danger': errors.image })} id="image" name="image"
                                    accept="image/x-png,image/jpeg"
                                    // {...register("image")}
                                    onChange={(e) => handleFileChange(e)}
                                    lang="es" />
                                <label className="custom-file-label imageLabel" for="customFileLang">{`${fileObj?.name ? `${fileObj?.name}` : "Select Featured Image"}`}</label>
                                {
                                    errors?.image_url &&
                                    <label className="custom-file-label imageLabel"><font color="red">Please select file</font></label>
                                }
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label className="uk-form-label">*Status</label>
                            <select className={clsx("uk-select", { "uk-form-danger": errors?.status })} name="status" {...register("status")} onChange={handleStatusChange}>
                                <option value="">Select One</option>
                                <option value="Draft"  >Draft</option>
                                <option value="On"  >On</option>
                                <option value="Under Contract"  >Under Contract</option>
                                <option value="Sold"  >Sold</option>
                                <option value="Suspend"  >Suspend</option>
                                <option value="Charter Only"  >Charter Only</option>
                                <option value="Private"  >Private</option>
                            </select>
                        </div>
                        {
                            isStatusSold &&
                            <>
                                <div className="col-md-3 sold-fields">
                                    <div>
                                        <div className="form-group">
                                            <label className="uk-form-label">*Sold Date</label>
                                            <Controller
                                                control={control}
                                                name="datesold"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        portalId="my-popper"
                                                        className={clsx("form-control", { 'form-control uk-form-danger': errors.datesold })}
                                                        style={{ cursor: "pointer", color: "#000" }}
                                                        format={'MM/DD/YYYY'}
                                                        value={field.value && dayjs(field.value)}
                                                        onChange={(date, dateString) => {
                                                            field.onChange(dateFormatter(dateString));
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 sold-fields">
                                    <div>
                                        <div className="form-group">
                                            <label className="uk-form-label">*Sold Price</label>
                                            <input
                                                type="text"
                                                {...register("sold_price")}
                                                id="select_sold_price"
                                                name="sold_price"
                                                className={clsx("form-control", { 'form-control uk-form-danger': errors.sold_price })}
                                                onBlur={handleBlur("sold_price")}
                                                onFocus={handleFocus("sold_price")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className="col-md-3">
                            <label className="uk-form-label">*Share Type</label>
                            <select id="select_sharetype" className={clsx("uk-select", { "uk-form-danger": errors?.sharetype })} name="sharetype" {...register("sharetype")}>
                                <option value="">Select One</option>
                                <option value="yes" >
                                    Available For Co-Brokerage
                                </option>
                                <option value="no" >
                                    Not Available for Co-Brokerage
                                </option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <label className="uk-form-label">*Signed Listing Agreement</label>
                            <select id="select_signedlistingagree" className={clsx("uk-select", { "uk-form-danger": errors?.signedlistingagree })} {...register("signedlistingagree")} name="signedlistingagree">
                                <option value="">Select One</option>
                                <option value="yes" >
                                    Yes
                                </option>
                                <option value="no" >
                                    No
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <label className="uk-form-label">*Listing Type</label>
                            <select id="select_listingtype" name="vessel_detail_listingtype" className={clsx("uk-select", { "uk-form-danger": errors?.vessel_detail_listingtype })} {...register("vessel_detail_listingtype")}>
                                <option value="">Select One</option>
                                <option value="CENTRAL_EXCLUSIVE" >
                                    Central/Exclusive
                                </option>
                                <option value="OPEN_NON_EXCLUSIVE" >
                                    Open/Non-Exclusive
                                </option>
                                <option value="TRADE_IN" >
                                    Trade-in
                                </option>
                            </select>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">Country</label>

                                <select name="country" className={clsx("uk-select", { "uk-form-danger": errors?.country })} {...register("country")}>
                                    <option value="">None</option>
                                    {country?.map((country) => {
                                        return (
                                            <option value={country} key={country}>
                                                {country}
                                            </option>
                                        );
                                    })}
                                </select>

                                <i></i>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label className="uk-form-label">*State (required for US)</label>
                            <div className="form-group">

                                <select className={clsx("uk-select", { "uk-form-danger": errors?.state })} name="state" {...register("state")}>
                                    <option value="">None</option>
                                    {state?.map((state) => {
                                        return (
                                            <option value={state} key={state}>
                                                {state}
                                            </option>
                                        );
                                    })}
                                </select>

                                <i></i>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*City (required for US)</label>
                                <input type="text" className={clsx("form-control", { 'uk-form-danger': errors.city })} id="city" name="city" {...register("city")} />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*Zip/Postal Code (required for US)</label>
                                <input className={clsx("form-control", { 'uk-form-danger': errors.zip })} type="text" name="zip" id="zip" {...register("zip")} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <label className="uk-form-label">*Primary Currency</label>
                            <div className="form-group">
                                <select data-placeholder="" id="select_primary_currency" className={clsx("uk-select", { "uk-form-danger": errors?.primary_currency })} {...register("primary_currency")} name="primary_currency">
                                    <option value="usd" >
                                        USD
                                    </option>
                                    <option value="euro" >
                                        Euro
                                    </option>
                                    <option value="cad" >
                                        CAD
                                    </option>
                                    <option value="gbp" >
                                        GBP
                                    </option>
                                </select>
                                <i></i>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">Price</label>
                                <input className={clsx("form-control", { 'uk-form-danger': errors.price })} type="text" name="price" {...register("price")} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*HIN/IMO#</label>
                                <input name="vessel_detail_hin" className={clsx("form-control", { 'uk-form-danger': errors.vessel_detail_hin })} {...register("vessel_detail_hin")} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*Condition</label>
                                <select id="select_vessel_condition" className={clsx("uk-select", { "uk-form-danger": errors?.vessel_condition })} name="vessel_condition" {...register("vessel_condition")}>
                                    <option value="">Select One</option>
                                    <option value="new" >
                                        New
                                    </option>
                                    <option value="used" >
                                        Used
                                    </option>
                                </select>
                                <i></i>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*Type</label>
                                <select id="select_type" name="type" className={clsx("uk-select", { "uk-form-danger": errors?.type })} {...register("type")} onChange={(e) => {
                                    setValue("type", e.target.value);
                                    setBoatType(e.target.value);
                                }} >
                                    <option value="">Select Type</option>
                                    <option value="Power" >Power</option>
                                    <option value="Sail" >Sail</option>
                                </select>
                                <i></i>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*Manufacturers</label>

                                <Autocomplete
                                    id="size-small-outlined"
                                    size="small"
                                    style={{ borderLeft: 'none' }}
                                    className={clsx({ "uk-form-danger": !makeValue })}
                                    isOptionEqualToValue={(option, value) => option.label === value.label}
                                    value={makeValue}
                                    onChange={(event, newValue) => {
                                        setMakeValue(newValue);
                                        setValue('manufacturer_name', newValue.label)
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                    }}
                                    options={dataManuFecturers}
                                    renderInput={(params) => <TextField {...params} />}
                                />

                                <i></i>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*Category</label>
                                <select id="select_category" name="category" className={clsx("uk-select", { "uk-form-danger": errors?.category })} {...register("category")}>
                                    <option value="">Select One</option>
                                    {boatCategories.map((boatCategoryKey, idx) => (
                                        <option key={`vessel_boat_category_${idx}`} value={boatCategoryKey}>{boatCategoryKey}</option>
                                    ))}
                                </select>
                                <i></i>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*Fuel Type</label>
                                <select id="select_fueltype" name="fueltype" className={clsx("uk-select", { "uk-form-danger": errors?.fueltype })} {...register("fueltype")}>
                                    <option value="">Select One</option>
                                    <option value="Diesel" >
                                        Diesel
                                    </option>
                                    <option value="Electric" >
                                        Electric
                                    </option>
                                    <option value="Gas/Petrol" >
                                        Gas/Petrol
                                    </option>
                                    <option value="LPG" >
                                        LPG
                                    </option>
                                    <option value="Other" >
                                        Other
                                    </option>
                                </select>
                                <i></i>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*Year (Model)</label>
                                <input type="number" className={clsx("form-control", { 'uk-form-danger': errors.year })} max="9999" name="year" {...register("year")} />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*Hull Materials</label>
                                <select id="select_hullmaterial" name="vessel_detail_hullmaterial" className={clsx("uk-select", { "uk-form-danger": errors?.vessel_detail_hullmaterial })} {...register("vessel_detail_hullmaterial")} >
                                    <option value="">Select One</option>
                                    {
                                        hull_materials.map((material, idx) => (
                                            <option key={`hull_materials_${idx}`} value={material}>{material}</option>
                                        ))
                                    }
                                </select>
                                <i></i>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*Model</label>
                                <input
                                    className={clsx("uk-input", {
                                        "uk-form-danger": errors.model?.message,
                                    })}
                                    type="text"
                                    {...register("model")}
                                />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label" htmlFor="vessel_name">
                                    Vessel Name
                                </label>
                                <input
                                    className={clsx("uk-input", {
                                        "uk-form-danger": errors.vessel_name?.message,
                                    })}
                                    type="text"
                                    {...register("vessel_name")}
                                />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*Display Length M</label>
                                <input
                                    type="number"
                                    className={clsx("form-control", { 'uk-form-danger': errors.display_length_meters })}
                                    id="select_display_length_meters"
                                    {...register("display_length_meters")}
                                    onBlur={(e) => meters2feet()}
                                />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*Display Length Ft</label>
                                <input
                                    type="number"
                                    className={clsx("form-control", { 'uk-form-danger': errors.display_length_feet })}
                                    id="select_display_length_feet"
                                    {...register("display_length_feet")}
                                    onBlur={(e) => feetToMeters('display')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*LOA Feet</label>
                                <input className={clsx("form-control", { 'uk-form-danger': errors.loa_feet })} type="number" name="loa_feet" {...register("loa_feet")}
                                    onBlur={(e) => feetToMeters('loa')}
                                    id="select_loa_feet" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">
                                    <font>LOA Inches <small>(1 to 11 only OR leave blank)</small></font>
                                </label>
                                <input className={clsx("form-control", { 'uk-form-danger': errors.loa_inch })} type="number" name="loa_inch" placeholder="Inches (1 to 11 only OR leave blank)" {...register("loa_inch")}
                                    onBlur={(e) => feetToMeters('loa')}
                                    max="11" id="select_loa_inch" />
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="uk-form-label">*LOA Meters</label>
                                <input className={clsx("form-control", { 'uk-form-danger': errors.loa_meters })} type="number" {...register("loa_meters")} disabled
                                    name="loa_meters" id="select_loa_meters" />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-4">
                                        <label className="uk-form-label">*Summary - include your company name <small>{`(${remainingChar} characters left)`}</small></label>
                                    </div>
                                </div>
                                <textarea rows="2" name="vessel_locale" className={clsx("form-control", { 'uk-form-danger': errors.vessel_locale })} {...register("vessel_locale")} value={getValues("vessel_locale")} onChange={validateSummary}
                                    id="summary"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div id="engines_list" className="col-md-12">
                            {
                                Array.from({ length: getValues('engine').length || 0 }).map((item, index) => {
                                    return (
                                        <div className="row" key={index} id={`engine-${index}`}>
                                            <div className="col-md-12 mb-2">Engine {index + 1} {index != 0 && <i onClick={() => remove(index)} className="fa fa-minus-square custom-grey-text text-35 ml-2 mr-2 fa-sm" aria-hidden="true"></i>}
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="uk-form-label">
                                                        <font>Engine Make</font>
                                                    </label>
                                                    <input className="form-control" {...register(`engine.${index}.engine_make`)} type="text" name={`engine.${index}.engine_make`} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="uk-form-label">
                                                        <font>Engine Model</font>
                                                    </label>
                                                    <input className="form-control" type="text" {...register(`engine.${index}.engine_model`)} name={`engine.${index}.engine_model`} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="uk-form-label">
                                                        <font>Engine Year</font>
                                                    </label>
                                                    <input className="form-control" type="number" {...register(`engine.${index}.engine_year`)} name={`engine.${index}.engine_year`} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="uk-form-label">
                                                        <font>Serial #</font>
                                                    </label>
                                                    <input className="form-control" type="text" {...register(`engine.${index}.engine_serial_number`)} name={`engine.${index}.engine_serial_number`} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="uk-form-label">
                                                        <font>Power HP</font>
                                                    </label>
                                                    <input className="form-control" type="number" {...register(`engine.${index}.engine_power_hp`)} name={`engine.${index}.engine_power_hp`}
                                                        onKeyUp={() => { setValue(`engine.${index}.engine_power_kw`, getValues(`engine.${index}.engine_power_hp`) ? convertEngineHPToKW(getValues(`engine.${index}.engine_power_hp`)) : '') }} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="uk-form-label">
                                                        <font>Power KW</font>
                                                    </label>
                                                    <input className="form-control" type="number" {...register(`engine.${index}.engine_power_kw`)} name={`engine.${index}.engine_power_kw`} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="uk-form-label">
                                                        <font>Engine Hours</font>
                                                    </label>
                                                    <input className="form-control" type="number" {...register(`engine.${index}.engine_hours`)} name={`engine.${index}.engine_hours`} />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="uk-form-label">
                                                        <font>Engine Type</font>
                                                    </label>
                                                    <select {...register(`engine.${index}.engine_type`)} name={`engine.${index}.engine_type`} className="uk-select engine_type">
                                                        <option value="">Select One</option>
                                                        <option value="Electric">Electric</option>
                                                        <option value="Inboard">Inboard</option>
                                                        <option value="Inboard/Outboard">Inboard/Outboard</option>
                                                        <option value="Outboard">Outboard</option>
                                                        <option value="Outboard 2 Stroke">Outboard 2 Stroke</option>
                                                        <option value="Outboard 4 Stroke">Outboard 4 Stroke</option>
                                                        <option value="Other">Other</option>
                                                        <option value="Stern Drive">Stern Drive</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="row mt-3 mb-5">
                        <div className="col-md-3 plus-btn mt-3" id="add-engine-btn">
                            <div className="add-engine" onClick={() => {
                                append(
                                    {
                                        // engine_make: getValues('engine')[0]?.engine_make || "",
                                        // engine_model: getValues('engine')[0]?.engine_model || "",
                                        // engine_power_hp: getValues('engine')[0]?.engine_power_hp || "",
                                        // engine_power_kw: getValues('engine')[0]?.engine_power_kw || ""
                                    }
                                )
                            }}>
                                <i className="fa fa-plus-square custom-grey-text plus-icon text-35 mr-2 fa-lg" aria-hidden="true">
                                </i>
                                <span className="mb-2 font-family-open-sans-light text-20">
                                    Add Engine
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="uk-modal-footer uk-text-right">
                <button value="Submit" className="uk-button btn btn-success submit-button" type="button"
                    onClick={async () => {
                        await trigger();
                        const isValid = schema.isValidSync(getValues());
                        if (isValid) {
                            handleRegistration(getValues());
                        }
                    }} >Submit</button>
                <button className="uk-button uk-button-primary" type="button"
                    onClick={handleCancleBtn} >Cancel</button>
            </div>

        </>

    )
}

export default CreateMLSVesselForm


