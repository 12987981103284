import React, { useEffect, useMemo, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import MoonLoader from "react-spinners/MoonLoader";
import { Rnd } from "react-rnd"; // Keep Rnd for resizable
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "../loading";
import { height, width } from "@mui/system";
import { useSelector } from "react-redux";
import { allDateFields, dropdownFields, priceFields } from "../../utils/AppConstants";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Controller, useForm } from "react-hook-form";
import FormInputWrapper from "../common/FormInputWapper";
import moment from "moment";
import country from "../../constants/Country";
import { PDFDocument } from "pdf-lib";
import { getFigure, getPrice } from "../../utils/currency";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormRow from "../common/FormRow";
import { getTextareaWidth } from "../../utils/functions";
// import transparentPng from ""
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfRender({
  restoreScrollPosition,
  currentScrollPosition,
  setCurrentScrollPosition,
  saveScrollPosition,
  handleHiddenScrollContent,
  isHorizontalScrollbarVisible,
  isAddingText,
  setIsAddingText,
  setIsAddingCheckbox,
  isAddingLogo,
  formData,
  setIsAddingLogo,
  setCheckboxFields,
  isAddingCheckbox,
  pageProps,
  doc,
  renderPdf,
  mergeProps,
  templatePageCount,
  textFields,
  setTextFields,
  logoFields,
  setLogoFields,
  handleLogoClick,
  handleTextChange,
  handleCheckboxSize,
  handleTextDelete,
  handleTextFontSize,
  handleLogoBackground,
  handleLogoDelete,
  checkboxFields,
  handleCheckboxChange,
  handleCheckboxClick,
  handleTextBackground,
  handleCheckboxDelete,
  handleCheckboxBackground,
  signatureFields,
  setSignatureFields,
  signatureAssigner,
  getSignatureBgColor,
  handleSignatureDelete,
  formFieldsProps,
  reloadKey,
  modalPosition,
  setModalPosition,
  handleFieldChange,
  documentName,
  savePDF,
  templateFields,
  setTemplateFields,
  // allFormFields,
  savePdfField,
  modalLoading,
  undoRedoProps,
  setModalLoading,
  reflectPdfEdit,
  paperRef,
  leftSideBarRef,
  updatedPdfUrl,
  updated_elements = []
}) {
  const currentPage = useRef(0);

  // for vessel formFields of pdf
  const { vesselFields, ybaaAmendmentField, acceptanceOfVesselField, sellerClosing, agreementAmmendmuntField, buyerClosing, agreementExclusion, termFields, tradeInVesselFields, offerFields, coBrokerFields, buyerFields, coBuyerFields, sellerFields, coSellerFields, exclusionFields, updatedFormFields, handleFormFieldBackground, handleFormFieldDelete, handleFormFieldFontSize, formFields, setFormFields, dragableFields, setDragableFields } = formFieldsProps;

  const { mergedPdfUrl, setMergedPdfUrl, mergeRef } = mergeProps;
  const [numPages, setNumPages] = useState(0);
  const [files, setFiles] = useState(null);
  const [isSet, SetIsSet] = useState(false);
  const { pageNumber, setPageNumber, size, setSize } = pageProps;
  const [isLoading, setIsLoading] = useState(true);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // flag for field changes modal opening status
  const [modalPositionCalcX, setModalPositionCalcX] = useState(0); // for calculated x position of modal and for restrict modal position 
  const [e, setE] = useState(null);
  const { fieldTrackArr, setFieldTrackArr, fieldTrackObj, setFieldTrackObj, setTempEvent } = undoRedoProps;

  // store clicked field details like name , value and type
  const [clickedFieldDetails, setClickedFieldDetails] = useState({});

  //Remove null manufactures from all data manufactures
  let dataManuFecturers = useSelector((state) => {
    return state.vessel_toys.mlsFilters.manufacturers || [];

  });
  dataManuFecturers = dataManuFecturers.filter((item, i, ar) => ar.indexOf(item) === i).map(mfr => mfr);

  // ref for opening modal on multi or single field
  const modalRef = useRef({});


  const [renderedScale, setRenderedScale] = useState(null);
  const pdfRef = useRef(null);
  const pageRefs = useRef(Array(numPages).fill().map(() => React.createRef()));
  const pdfEditorFields = useSelector((state) => state?.pdfEditorReducer);
  //get number of pages
  useEffect(() => {
    pageRefs.current = Array(numPages).fill().map(() => React.createRef());
  }, [numPages]);
  //scroll to page on page number click
  useEffect(() => {
    if (pageNumber > 0 && pageNumber <= numPages) {
      const index = pageNumber - 1;
      const ref = pageRefs.current[index]?.current;
      if (ref) {
        ref.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error(`Ref for page ${pageNumber} is not ready`);
      }
    }
  }, [pageNumber, numPages]);

  const possibleFields = {
    vesselFields: vesselFields,
    termFields: termFields,
    buyerFields: buyerFields,
    coBuyerFields: coBuyerFields,
    sellerFields: sellerFields,
    coSellerFields: coSellerFields,
    tradeInVesselFields: tradeInVesselFields,
    offerFields: offerFields,
    exclusionFields: exclusionFields,
    coBrokerDealSideFields: coBrokerFields[0],
    coBrokerOtherSideFields: coBrokerFields[1],
    ybaaAmendmentField: ybaaAmendmentField,
    acceptanceOfVesselField: acceptanceOfVesselField,
    agreementExclusion: agreementExclusion,
    agreementAmmendmuntField: agreementAmmendmuntField,
    buyerClosing: buyerClosing,
    sellerClosing: sellerClosing,
  };
  const getParentKey = (targetKey) => {
    const parentKey = Object.keys(possibleFields)?.find(parentKey => {
      return possibleFields?.[parentKey]?.some(field => field.key === targetKey);
    });
    const targetObject = possibleFields?.[parentKey]?.find(field => field.key === targetKey);
    return { parentKey: parentKey, targetObject: targetObject }
  }



  function onDocumentLoadSuccess({ numPages }) {
    setIsLoading(false);
    setNumPages(numPages);
  }

  const fileOptions = useMemo(() => ({ url: mergedPdfUrl || doc }), [mergedPdfUrl, doc]);
  useEffect(() => {
    if (fileOptions?.url) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    updatedPdfUrl.current = fileOptions;
  }, [fileOptions]);

  // Adjust text fields when zoom level changes
  useEffect(() => {
    setTextFields(prev => prev.map(textField => ({
      ...textField,
      size: size,

    })));
    setCheckboxFields(
      prev => prev.map(cFields => ({
        ...cFields,
        size: size,
      }))
    )
  }, [size]);

  // store needed details for modal drag restriction and modal initial opening
  const [dragPositionDetails, setDragPositionDetails] = useState({
    totalWidth: 0,     // total width is from start of screen to end of Paper container
    modalTotalWidth: 0, // modal total width is from start of screen to end of open modal
    extraWidth: 0, // extra width is modalTotalWidth - totalWidth
    isDrag: false, // when modal drags first time it will become true throw out it is opened
  });

  // calculate total width and store it
  useEffect(() => {
    // calculate if drag is not done before , after drag it will auto take position
    if (!dragPositionDetails?.isDrag && paperRef?.current && leftSideBarRef?.current) {
      setDragPositionDetails((prev) => {
        const pL = paperRef?.current?.offsetLeft; // paper left 
        const pW = paperRef?.current?.offsetWidth; // paper width
        const lsbW = leftSideBarRef?.current?.offsetWidth; // left side bar width
        const tW = pL + pW + lsbW; // total width from left 0 to paper right end
        return { ...prev, totalWidth: tW }
      })
    }
  }, [paperRef, leftSideBarRef]);

  // calculate modal total width and store it
  useEffect(() => {
    // calculate if drag is not done before , after drag it will auto take position
    if (!dragPositionDetails?.isDrag && modalRef?.current && modalPosition?.clientX > 0) {
      setDragPositionDetails((prev) => {
        const mW = modalRef?.current?.offsetWidth; // modal width
        const mtW = modalPosition?.clientX + mW; // modal total width with screen left start to modal right end
        if ((prev?.totalWidth > 0 && mtW > 0) && (mtW > prev?.totalWidth)) {
          const eW = mtW - prev?.totalWidth;
          return { ...prev, modalTotalWidth: mtW, extraWidth: eW }
        }
        return { ...prev, modalTotalWidth: mtW }
      })
      setValue(modalPosition?.label, modalPosition.value)
    }
  }, [modalPosition, modalRef]);

  // calculate modal's position x for restrict modal position horizontally inside editor div
  useEffect(() => {
    setModalPositionCalcX((prev) => {
      // if modal is outside of editor div
      if (!dragPositionDetails?.isDrag && dragPositionDetails?.extraWidth > 0) {
        let xPos = modalPosition?.x - (dragPositionDetails?.extraWidth) - 15;
        if (xPos >= 0) { return xPos; } else {
          return -xPos; // left side restriction
        }
      } else { return modalPosition?.x; }
    });
  }, [dragPositionDetails]);

  const leftTextFieldRefs = useRef([]); // for store refs of individual modal left fields

  // Handle focus on specific modal left-side TextFields
  const handleFocus = (index) => {
    if (leftTextFieldRefs.current[index]) {
      leftTextFieldRefs.current[index].focus();   // for focus the field
    }
  };
  const schema = yup.object().shape({});

  const { register, setValue, getValues, setFocus, formState: { errors }, control } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const handleFocusInput = (ref) => (e) => {
    const price = getFigure(getValues(ref));
    setValue(ref, price);
  };
  const handleBlur = (ref) => (e) => {
    const price = getPrice(getValues(ref));
    setValue(ref, price);
  };

  // For Focus the single TextField modal initially
  const modalSingleFieldRef = useRef(null);
  // For Focus the right side TextField initially
  const rightTextFieldRef = useRef(null);
  useEffect(() => {
    // if modal opens then only make focus field
    if (isModalOpen) {
      setTimeout(() => {
        if (rightTextFieldRef.current) {
          rightTextFieldRef.current.focus(); // for focus the field initially
          rightTextFieldRef.current.setSelectionRange(rightTextFieldRef.current.value.length, rightTextFieldRef.current.value.length); // Move the cursor to the end
        }
        if (modalSingleFieldRef.current) {
          modalSingleFieldRef.current.focus(); // for focus the field initially
          modalSingleFieldRef.current.setSelectionRange(modalSingleFieldRef.current.value.length, modalSingleFieldRef.current.value.length); // Move the cursor to the end
        }
      }, 100); // Wait 100ms to ensure modal is rendered before focusing

      // for price field focus in single field modal
      if (modalPosition?.is_price_field && modalPosition?.label && (!modalPosition?.is_multi_field)) {
        // Focus the input field when the component mounts
        setFocus(modalPosition?.label); // `modalPosition?.label` is the name of the input
      }
    }
  }, [isModalOpen]);

  // function for make dropdown options array dynamically field name wise
  const getDropdownValues = (fieldName) => {
    switch (fieldName) {
      case "vessel_make":
        return dataManuFecturers || [];

      case "buyer_country":
        return country.map((country, ind) => country?.code) || [];

      case "seller_country":
        return country.map((country, ind) => country?.code) || [];

      case "offer_delivery_loc_country":
        return country.map((country, ind) => country?.code) || [];

      default:
        return [];
    }
  }

  // function for make mainField value to show right side of modal popup
  const makeMainFieldValue = (mainFieldValue, fieldValue) => {
    /*
     * in below line we are making string for multiline model right side main field input
     * we have multiple inputs in multi field modal and have to show it on right side 
     * for show right side we have to concate it one by one 
     * here we maintained cases of null , undefined or blank space when it will not make any blank place 
     * put space between every input for seperation
     */
    return `${mainFieldValue}${(mainFieldValue === "") ? mainFieldValue : (fieldValue === "" ? fieldValue : " ")}${fieldValue || ""}`;
  };
  const transactions = useSelector((state) => state?.transactions)
  // handle click over pdf
  const handlePdfClick = async (e, pageNumber) => {
    // if custom text area clicked then enable options or clicked on any place then hide options
    if (e.target.type === "textarea") {
      // for customly added text fields
      if (e.target.getAttribute("data-is-custom")) {
        setTextFields(prev => prev.map((tField) =>
          tField.id != e.target.getAttribute("data-field-id") ? {
            ...tField,
            showControls: false
          } : tField
        )
        );
      };
      // for customly dragged form fields
      if (e.target.getAttribute("data-is-formfield")) {
        setFormFields(prev => prev.map((formField) =>
          formField.uniqueId != e.target.getAttribute("data-field-id") ? {
            ...formField,
            showControls: false
          } : formField
        )
        );

      }
    } else {
      setTextFields(prev => prev.map((tField) => {
        return {
          ...tField,
          showControls: false
        }
      }
      )
      );
      setFormFields(prev => prev.map((formField) => {
        return {
          ...formField,
          showControls: false
        }
      }));
    }

    // store clicked field initial details
    setClickedFieldDetails({
      "fieldName": e.target.name,
      "fieldType": e.target.type,
      "fieldValue": e.target.value
    });


    const pageElement = pageRefs.current[pageNumber - 1]?.current;
    e.stopPropagation()
    setModalPosition({ x: 0, y: 0, id: null }); // reset modal 
    setIsModalOpen(false); // set modal open status false initially
    // on new modal open, reset needed details
    setDragPositionDetails((prev) => { return { ...prev, modalTotalWidth: 0, extraWidth: 0, isDrag: false } });
    if (pageElement) {
      const rect = pageElement.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;
      const xPosition = offsetX / size;  // Store position in unscaled coordinates
      const yPosition = offsetY / size;  // Store position in unscaled coordinates
      // if (["text", "textarea", "checkbox"].includes(e.target.type) && !e.target.getAttribute("data-is-custom")) {
      // store clicked field information from  template
      let existingPdfBytes = await fetch(updatedPdfUrl.current?.url).then((res) =>
        res.arrayBuffer()
      );

      if (mergedPdfUrl) {
        existingPdfBytes = await fetch(mergedPdfUrl).then((res) =>
          res.arrayBuffer()
        );
      }
      // // Load the PDF document from the fetched bytes
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      // Access the form in the PDF
      const form = pdfDoc.getForm();
      // console.log(form?.getField(e.target.name), "form");
      const clickedTemplateField = templateFields?.filter((templateField) => ((templateField?.key === e.target.name) || (templateField?.value === e.target.name) || ((templateField?.value === e.target.name?.split("Custom_Textbox_")?.[1]) || (templateField?.key === e.target.name?.split("Custom_Textbox_")?.[1]))))?.[0] || {};
      // get value of clicked field
      const clickedTemplateFieldValue = e.target.value || "";
      if (clickedTemplateField && (Object.keys(clickedTemplateField).length > 0) && (clickedTemplateField?.type === "PDFTextField") && (clickedTemplateField?.is_editable === true)) {
        // if field have multiple fields
        if (clickedTemplateField?.is_multi_field === true && (Object.keys(clickedTemplateField.multi_fields).length > 0)) {
          const multiFieldsNames = [...clickedTemplateField?.multi_fields];
          let multiFieldsWithValues = {}; // for multiple fields name and value
          let dropdownFieldsWithValues = {}; // for dropdown options
          let mainFieldValue = ""; // for main field value showing 
          // make multifields object with values
          for (let i = 0; i < multiFieldsNames?.length; i++) {
            const fieldValue = pdfEditorFields?.[multiFieldsNames[i]];
            let dropdownValue = await getDropdownValues(multiFieldsNames[i]) || []; // get dropdown options
            multiFieldsWithValues = {
              ...multiFieldsWithValues,
              // [multiFieldsNames[i]]: [fieldValue, allDateFields?.includes(multiFieldsNames[i])], // for handle datev in multi field
              [multiFieldsNames[i]]: fieldValue,
            };
            dropdownFieldsWithValues = {
              ...dropdownFieldsWithValues,
              [multiFieldsNames[i]]: dropdownValue,
            }
            mainFieldValue = makeMainFieldValue(mainFieldValue, fieldValue); // do together all field values
          }
          // selt object of multifields
          setModalPosition({
            x: xPosition,
            y: yPosition,
            id: Date.now(),
            size: size,
            pageNumber: pageNumber,
            // value: e.target.type === "checkbox" ? e.target.checked === "on" ? false : true : e.target.value,
            value: e.target.value || mainFieldValue || "",
            type: e.target.type,
            label: clickedTemplateField?.value,
            key: clickedTemplateField?.key,
            is_dragable_field: true,
            name: e.target.name,
            is_multi_field: true,
            multi_fields: multiFieldsWithValues,
            dropdown_fields: dropdownFieldsWithValues,
            clientX: e?.clientX || 0, // store clicked horizontal place
          });
          setIsModalOpen(true); // do status true when modal opens
        } else {
          let dropdownValues = await getDropdownValues(clickedTemplateField?.value) || []; // get dropdown options
          setModalPosition({
            x: xPosition,
            y: yPosition,
            id: Date.now(),
            size: size,
            pageNumber: pageNumber,
            // value: e.target.type === "checkbox" ? e.target.checked === "on" ? false : true : e.target.value,
            value: e.target.value || clickedTemplateFieldValue || "",
            type: e.target.type,
            key: clickedTemplateField?.key,
            is_dragable_field: true,
            // label: transactions?.transaction?.t_edocuments?.filter((e) => e?.title === documentName)?.[0]?.t_esign_template?.template?.template_fields?.filter?.((field) => field?.key === e.target.name)?.[0]?.value,
            label: clickedTemplateField?.value,
            is_date_field: allDateFields?.includes(clickedTemplateField?.value),
            is_multi_field: false,
            is_price_field: priceFields?.includes(clickedTemplateField?.value),
            name: e.target.name,
            dropdown_fields: dropdownValues,
            clientX: e?.clientX || 0, // store clicked horizontal place
          });
          setIsModalOpen(true); // do status true when modal opens
        }
      } else if (isAddingText) {
        setTextFields([
          ...textFields,
          {
            x: xPosition,
            y: yPosition,
            text: '',
            id: Date.now(),
            fontSize: 10,
            background: false,
            size: size,
            pageNumber: pageNumber,
            initialWidth: 105,  // Store initial unscaled width
            initialHeight: 18,   // Store initial unscaled height
            showControls: false,  // Hide controls when text field is added
            isDragging: false,    // Store dragging status
          }
        ]);
        setIsAddingText(false);
      } else if (isAddingCheckbox) {
        setCheckboxFields([
          ...checkboxFields,
          {
            x: xPosition,
            y: yPosition,
            pageNumber: pageNumber,
            checked: 0,
            id: Date.now(),
            background: true,
            size: size,
            initialWidth: 25,  // Store initial unscaled width
            initialHeight: 25,
            showControls: true,  // Show controls when checkbox field is added
            isDragging: false,   // Store dragging status
          }
        ]);
        setIsAddingCheckbox(false);
      } else if (isAddingLogo) {
        setLogoFields([
          ...logoFields,
          {
            x: xPosition,
            y: yPosition,
            pageNumber: pageNumber,
            logo: "/images/logo-upload.svg",
            id: Date.now(),
            // background: true,
            size: size,
            file: {},
            initialWidth: logoFields?.[logoFields?.length - 1]?.initialWidth ?? 54,  // Store initial unscaled width
            initialHeight: logoFields?.[logoFields?.length - 1]?.initialHeight ?? 54,
            showControls: true,  // Show controls when logo field is added
            isDragging: false,   // Store dragging status
          }
        ]);
        setIsAddingLogo(false);
      }
    }
  };

  // handle mouse middle key clicking effect
  const handlePdfMouseDown = (e, pageNumber) => {
    if (e.button === 1) {
      e.preventDefault(); // Prevent default middle-click action (e.g., paste)
      if (!isModalOpen) {
        handlePdfClick(e, pageNumber); // if modal is not open then open it
      }
    }
  }

  // when form data updates , form fields will update
  useEffect(() => {
    if (formFields) {
      if (updatedFormFields?.length === 1) {
        setFormFields((prev) => {
          return prev?.map((field) => {
            let updatedField = updatedFormFields?.find((uField) => uField?.fieldId === field?.id) || {};
            if (Object.keys(updatedField).length > 0) {
              return { ...field, text: updatedField?.fieldValue }
            }
            return field;
          })
        });
      } else if (updatedFormFields?.length > 1) {
        // update multiple fields when changes happens in offer form from editor
        setFormFields((prev) => {
          return prev?.map((field) => {
            // check updated field by name 
            for (let i = 0; i < updatedFormFields?.length; i++) {
              if (field.name === updatedFormFields[i]?.fieldName) {
                return { ...field, text: updatedFormFields[i]?.fieldValue }
              }
            }
            return field;
          })
        });
      }
    }
  }, [updatedFormFields])
  // const possibleFields = {
  //   vesselFields: vesselFields,
  //   termFields: termFields,
  //   buyerFields: buyerFields,
  //   coBuyerFields: coBuyerFields,
  //   sellerFields: sellerFields,
  //   coSellerFields: coSellerFields,
  //   tradeInVesselFields: tradeInVesselFields,
  //   offerFields: offerFields,
  //   exclusionFields: exclusionFields,
  //   coBrokerDealSideFields: coBrokerFields[0],
  //   coBrokerOtherSideFields: coBrokerFields[1],
  //   ybaaAmendmentField: ybaaAmendmentField,
  //   acceptanceOfVesselField: acceptanceOfVesselField,
  //   agreementExclusion: agreementExclusion,
  //   agreementAmmendmuntField: agreementAmmendmuntField,
  //   buyerClosing: buyerClosing,
  //   sellerClosing: sellerClosing,
  // };
  // const getParentKey = (targetKey) => {
  //   const parentKey = Object.keys(possibleFields)?.find(parentKey => {
  //     return possibleFields?.[parentKey]?.some(field => field.key === targetKey);
  //   });
  //   const targetObject = possibleFields?.[parentKey]?.find(field => field.key === targetKey);
  //   return { parentKey: parentKey, targetObject: targetObject }
  // }
  const handleFileChange = async (e) => {
    // manage selected file in one state for merging purpose
    setFiles((prev) => [e.target.files[0]]);
  }

  // handle drop signature/initials/date over pdf
  const handleDrop = (e, pageNumber, type) => {
    e.preventDefault();
    setModalPosition({ x: 0, y: 0, id: null }); // reset modal 
    setIsModalOpen(false); // set modal open status false initially
    // when any new field drops on pdf then hide all formfield options
    setFormFields(prev => prev.map((formField) => {
      return {
        ...formField,
        showControls: false
      }
    }));

    const pageElement = pageRefs.current[pageNumber - 1]?.current;
    const rect = pageElement.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    const xPosition = offsetX / size;  // Store position in unscaled coordinates
    const yPosition = offsetY / size;  // Store position in unscaled coordinates

    // handle form fields dropping
    if (pageElement && type === "FormFields") {
      setFormFields((prev) => {
        const formType = e.dataTransfer.getData('formType');
        const key = e.dataTransfer.getData('key');
        const initialField = possibleFields[formType]?.filter((vField) => vField.fieldId === e.dataTransfer.getData('fieldId'))[0] || {};
        if (initialField && Object.keys(initialField).length > 0) {
          prev = (prev && prev?.length > 0) ? prev : [];
          let rawData = {
            name: initialField?.fieldName,
            x: xPosition,
            y: yPosition,
            formName: formType,
            key: `Textbox_${Date.now()}`,
            value: initialField?.key,
            is_editable: true,
            text: String(initialField?.fieldValue),
            id: `${initialField?.fieldId}`,
            uniqueId: String(Date.now()),
            fontSize: 10,
            background: false,
            size: size,
            pageNumber: pageNumber,
            initialWidth: (initialField?.fieldValue?.toString()?.length) * (10 * 0.565) + 10,  // Store initial unscaled width,
            initialHeight: 18,   // Store initial unscaled height
            showControls: false,  // Show controls when text field is added
            isDragging: false,    // Store dragging status
          }
          setDragableFields((fields) => {
            return [
              ...fields,
              rawData
            ];
          });
          return [
            ...prev,
            rawData
          ];
        }
      }
      );
    }
    else {
      // handle signature, initials and date dropping
      if (pageElement && ["Signature", "Initials", "Date Signed"].includes(type)) {
        const fieldBgColor = getSignatureBgColor(signatureAssigner);
        let newItem = {
          coord: {
            x: xPosition,
            y: yPosition,
            width: 140,
            height: 26
          },
          page_number: pageNumber,
          assigner: signatureAssigner || '',
          bg_color: fieldBgColor,
          is_manually_added: true,
          showControls: true,  // Show controls when field drops
          isDragging: false,    // Store dragging status
        };
        switch (type) {
          case 'Signature':
            newItem = {
              key: `Signature_${Date.now()}`,
              value: `Signature_${Date.now()}`,
              type: 'PDFSignature',
              ...newItem
            };
            break;
          case 'Initials':
            newItem = {
              ...newItem,
              key: `Initials_${Date.now()}`,
              value: '',
              type: 'PDFInitial',
              coord: {
                ...newItem.coord,
                width: 80,
                height: 26
              },
            };
            break;
          case 'Date Signed':
            newItem = {
              key: `DateSignature_${Date.now()}`,
              value: '',
              type: 'PDFDateSigned',
              ...newItem
            };
            break;
          default:
            break;
        }
        setSignatureFields((prev) => [...prev, { ...newItem }]);
      }
    }
  }

  const loading = renderedPageNumber !== pageNumber;
  const handleSelectFile = (e) => {
    document.getElementById('file').click();
  }
  let checkboxImagesUrl = ["/images/check-box-tick.png", "/images/check-box-cross.png"];

  // function for handle change of multi field and reflect in main field
  const handleMultiFieldChange = (e, key) => {
    setModalPosition((prev) => {
      let mainFieldValue = "";
      Object.keys(prev?.multi_fields).map((fieldKey, index) => {
        const addValue = fieldKey === key ? e.target.value : prev?.multi_fields[fieldKey]; // for add value of all fields one bye one
        mainFieldValue = makeMainFieldValue(mainFieldValue, addValue);
      });
      setClickedFieldDetails((prev) => { return { ...prev, "fieldValue": mainFieldValue || "" } }); // field value storing on edition time for multiFields
      return { ...prev, value: mainFieldValue, multi_fields: { ...prev.multi_fields, [key]: e.target.value } };
    })
  }

  // function for handle change of textarea for custom added text fieldS and dragged formfields
  const handleTextareaOnChange = (e, field, isFormField = false) => {
    let textArea = e.target;
    const minWidth = 20; // minimum width
    const minHeight = 18; // minimum height
    const textAreaWidth = getTextareaWidth(textArea?.value, field?.fontSize); // do total of all over width
    const newWidth = textAreaWidth + 10; // add some padding
    const newHeight = textArea?.value?.toString()?.split('\n')?.length * (field?.fontSize * 1.315); // new height as per content
    if (!isFormField) {
      setTextFields(prev => prev.map((tField) =>
        tField.id === field.id ? {
          ...tField,
          text: textArea.value,
          initialWidth: Math.max(newWidth, minWidth), // take maximum width
          initialHeight: Math.max(newHeight, minHeight) // take maximum height
        } : tField
      )
      );
    } else {
      setFormFields(prev => prev.map((formField) =>
        formField.uniqueId === field.uniqueId ? {
          ...formField,
          text: textArea?.value,
          initialWidth: Math.max(newWidth, minWidth), // take maximum width 
          initialHeight: Math.max(newHeight, minHeight) // take maximum height
        } : formField
      )
      );
    }
  }

  return (
    updatedPdfUrl.current?.url ? <div className="bg-gradient">
      <div className="d-flex justify-content-center">
        <Document
          key={reloadKey}
          file={updatedPdfUrl.current}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => {
            setIsLoading(false);
          }}
          className={`d-flex align-items-center pdf-document-viewer ${isHorizontalScrollbarVisible ? 'pdf-horizontal-scroll' : ''} ${loading ? 'pdf-loaded' : ''}`}
          onRender={() => <Loading loading={true} />}
          loading={() => <div style={{ height: '75vh', display: 'flex', alignItems: 'center' }}><MoonLoader color="#0063A6" /></div>}
        >
          <div ref={pdfRef}>
            {Array.from(new Array(numPages)).map((_, index) => (
              <div key={index} className="mb-2 shadow" ref={pageRefs.current[index]}>
                <Page
                  onClick={(e) => handlePdfClick(e, index + 1)}
                  onMouseDown={(e) => handlePdfMouseDown(e, index + 1)} // for handle mouse middle key effect
                  onDragOver={(e) => { e.preventDefault(); }}
                  onDrop={(e) => { handleDrop(e, index + 1, e.dataTransfer.getData('type')) }}
                  key={`${index}@${90}_canvas@${size}_canvas`}
                  scale={size}
                  wrap={true}
                  renderMode="canvas"
                  onRenderSuccess={() => {
                    handleHiddenScrollContent();
                    restoreScrollPosition(currentScrollPosition);
                  }}
                  pageNumber={index + 1}
                  enhanceTextSelection={true}
                  onLoadError={(error) => console.log('Error while loading document! ' + error.message)}
                  renderAnnotationLayer={true}
                  renderForms={true}
                  onRenderAnnotationLayerSuccess={(e) => { renderPdf(e, index + 1); setRenderedPageNumber(index + 1); }}
                  loading={() => <Loading loading={true} />}
                  className={`position-relative react-pdf__Page custom-editor-page ${(mergedPdfUrl || (templatePageCount && numPages > templatePageCount)) && "merge-pdf__page"}`}
                >
                  {
                    modalPosition.id && modalPosition.pageNumber === index + 1 && (
                      <>
                        <Rnd
                          bounds=".modal-drag-restriction"
                          key={modalPosition.id}
                          // here we gives x position condition based if modal overlaping right sidebar then we have to restrict it into Paper component other wise put as same position
                          position={{ x: modalPositionCalcX * size, y: modalPosition?.y * size }} // Scale position by zoom factor
                          minHeight={150} // minimum height
                          minWidth={250} // minimum width
                          maxWidth={650} // maximum width for control width 
                          // dragHandleClassName="handle-modal-drag"
                          enableResizing={false}
                          onDragStop={(e, data) => {
                            // prevent position change on change of select dropdowns 
                            if (e.target.tagName !== "SELECT") {
                              // Update x/y based on zoom level
                              setModalPosition(prevState => {
                                if (prevState.id === modalPosition.id) {
                                  let updatedFieldValue = prevState?.value || "";
                                  // for price field get value
                                  if (modalPosition?.is_price_field) {
                                    updatedFieldValue = getValues(modalPosition?.label) || 0; // get updated registered value before dragstop and value loss, if null then value will zero
                                  }
                                  return {
                                    ...prevState,
                                    value: updatedFieldValue,
                                    x: data.x / size,  // Store in unscaled coordinates
                                    y: data.y / size,  // Store in unscaled coordinates
                                  };
                                } return prevState
                              });
                            }
                            // do isDrag true because it is drraged now and it is inside container and do calculations 0 
                            setDragPositionDetails((prev) => { return { ...prev, modalTotalWidth: 0, extraWidth: 0, isDrag: true } });
                          }}
                          style={{
                            zIndex: 9999,
                          }}
                        >
                          <Box
                            ref={modalRef} // for get modal width
                            sx={{
                              // width: "auto",
                              bgcolor: "white",
                              border: "1px solid #ddd",
                              borderRadius: "8px",
                              boxShadow: 24,
                              p: 3,
                              zIndex: 99999, // Ensure it appears above other elements
                            }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {/* Text Field */}
                            {
                              (modalPosition?.type === "text" || modalPosition?.type === "textarea") && (
                                modalPosition?.hasOwnProperty('multi_fields') ? (
                                  <div class="row w-100">
                                    {/* implementation for multiple fields on one popup */}
                                    <div class="col" style={{ borderRight: "5px solid #3e8eff" }}>
                                      <h4 className="mb-4" style={{ color: "#3e8eff" }}>Save to Database</h4>
                                      {Object.keys(modalPosition?.multi_fields).map((key, index) => {
                                        if (dropdownFields?.includes(key)) {
                                          // for dropdown fields
                                          return (<FormInputWrapper label={key} style={{ paddingRight: "0px" }}>
                                            <select
                                              className="uk-select mb-2"
                                              style={{ fontSize: "14px" }}
                                              value={modalPosition?.multi_fields[key] || ""} // Bind the value of the select to the state
                                              onChange={(e) => { handleMultiFieldChange(e, key); } // Update the state when the user selects an option
                                              }
                                            >
                                              {/* <option key={`dropdown_option_default`} value={modalPosition?.multi_fields[key]}>{modalPosition?.multi_fields[key]}</option> */}
                                              {(modalPosition?.dropdown_fields[key])?.map((dropdownOptionsKey, idx) => {
                                                return <option key={`dropdown_options_${idx}`} value={dropdownOptionsKey}>{dropdownOptionsKey}</option>
                                              })}
                                            </select>
                                          </FormInputWrapper>)
                                        } else {
                                          return (
                                            <>
                                              <TextField
                                                key={key}
                                                type={modalPosition?.type}
                                                label={key} // Set label dynamically
                                                multiline={true} // enable multi line input 
                                                // multiline={modalPosition?.type === "text" ? false : true}
                                                // rows={modalPosition?.type === "text" ? 1 : 4}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                name={key} // Use the key as the name of the field
                                                sx={{ mb: index === Object.keys(modalPosition?.multi_fields).length - 2 ? 2 : 3 }}
                                                // inputRef={(input) => input && input.focus()}
                                                inputRef={(el) => (leftTextFieldRefs.current[index] = el)} // assign and store ref
                                                onClick={(e) => handleFocus(index)} // use ref for focus
                                                value={modalPosition?.multi_fields[key] || ""}
                                                onChange={(e) => {
                                                  handleMultiFieldChange(e, key)
                                                }} // Update the state when the user selects an option
                                              />
                                            </>
                                          );
                                        }
                                      })}
                                    </div>
                                    <div class="col pr-0 d-flex flex-column justify-content-between" >
                                      <div>
                                        <h4 className="mb-4" style={{ color: "#3e8eff" }}>Show in Field</h4>
                                        <div className="d-flex flex-column justify-content-between">
                                          <TextField
                                            key={modalPosition?.label}
                                            type={modalPosition?.type}
                                            label={modalPosition?.label} // Set label dynamically
                                            multiline={true}  // enable multi line input 
                                            // multiline={modalPosition?.type === "text" ? false : true}
                                            // rows={modalPosition?.type === "text" ? 1 : 4}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            name={modalPosition?.name} // Use the key as the name of the field like Text68
                                            sx={{ mb: index === Object.keys(modalPosition?.multi_fields).length - 2 ? 2 : 3 }}
                                            inputRef={rightTextFieldRef}
                                            // inputRef={(input) => input && input.focus()}   // focus on modal input field
                                            value={modalPosition?.value || ""}
                                            onChange={(e) => {
                                              setModalPosition({ ...modalPosition, value: e.target.value })
                                              setClickedFieldDetails((prev) => { return { ...prev, "fieldValue": e.target.value } });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {/* Buttons */}
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          // marginTop : "100%",
                                          gap: 1,
                                        }}
                                      >
                                        <Button
                                          variant="outlined"
                                          color="error"
                                          sx={{ width: "45%" }}
                                          onClick={() => { setModalPosition({ x: null, y: null, id: null }); setIsModalOpen(false); }}
                                        >
                                          Close
                                        </Button>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          onClick={() => {
                                            setCurrentScrollPosition(saveScrollPosition()); // save scroll position before save changes 
                                            savePdfField(modalPosition?.is_multi_field ? modalPosition?.multi_fields : { [modalPosition?.label]: modalPosition?.value }, modalPosition?.value, modalPosition?.is_dragable_field ? modalPosition?.key : modalPosition?.name, updatedPdfUrl.current?.url);
                                          }}
                                          // onClick={(e) => { handleModalSave(e); }}
                                          sx={{ width: "45%" }}
                                          disabled={modalLoading} // Disable the button while loading
                                        >
                                          {modalLoading ? (
                                            <CircularProgress size={24} color="inherit" />
                                          ) : (
                                            'Save'  // Show 'Save' text when not loading
                                          )}
                                        </Button>
                                      </Box>
                                    </div>
                                  </div>
                                  // </div>
                                ) : (
                                  <>
                                    {(!modalPosition?.is_date_field) ?
                                      (
                                        modalPosition?.is_price_field ?
                                          <>
                                            <FormInputWrapper label={modalPosition?.label}>
                                              <input
                                                className="uk-input"
                                                style={{ fontSize: "medium" }}
                                                type="text"
                                                {...register(modalPosition?.label)}
                                                onBlur={handleBlur(modalPosition?.label)}
                                                onFocus={handleFocusInput(modalPosition?.label)}
                                              />
                                            </FormInputWrapper>
                                          </>
                                          :
                                          <>
                                            {dropdownFields?.includes(modalPosition?.label) ?
                                              <FormInputWrapper label={modalPosition?.label} style={{ paddingRight: "0px" }}>
                                                <select
                                                  className="uk-select mb-2"
                                                  style={{ fontSize: "14px" }}
                                                  value={modalPosition?.value || ""} // Bind the value of the select to the state
                                                  ref={input => input && input.focus()} // focus select field initially
                                                  onChange={(e) => {
                                                    setModalPosition({ ...modalPosition, value: e.target.value });
                                                    setClickedFieldDetails((prev) => { return { ...prev, "fieldValue": e.target.value } });
                                                  } // Update the state when the user selects an option
                                                  }
                                                >
                                                  {/* <option key={`dropdown_option_default`} value={modalPosition?.value}>{modalPosition?.value}</option> */}
                                                  {(modalPosition?.dropdown_fields)?.map((dropdownOptionsKey, idx) => {
                                                    return <option key={`dropdown_options_${idx}`} value={dropdownOptionsKey}>{dropdownOptionsKey}</option>
                                                  })}
                                                </select>
                                              </FormInputWrapper>
                                              :
                                              <TextField
                                                key={modalPosition?.name}
                                                type={modalPosition?.type}
                                                label={modalPosition?.label}
                                                multiline={true} // enable multi line input 
                                                // multiline={modalPosition?.type === "text" ? false : true}
                                                // rows={modalPosition?.type === "text" ? 1 : 4}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                name={modalPosition?.name}
                                                sx={{ mb: 3 }}
                                                inputRef={modalSingleFieldRef}
                                                value={modalPosition?.value || ""}
                                                onChange={(e) => {
                                                  setModalPosition({ ...modalPosition, value: e.target.value })
                                                  setClickedFieldDetails((prev) => { return { ...prev, "fieldValue": e.target.value } });
                                                }}
                                              />
                                            }
                                          </>
                                      ) :
                                      (
                                        <>
                                          <FormInputWrapper label={modalPosition?.label} style={{ paddingRight: "0px" }}>
                                            <DatePicker
                                              className="uk-input mt-1 d-flex justify"
                                              label={modalPosition?.label}
                                              style={{ cursor: "pointer", color: "#000" }}
                                              format={'MM-DD-YYYY'}
                                              value={modalPosition?.value && dayjs(modalPosition?.value)}
                                              ref={input => input && input.focus()} // focus date field initially
                                              onChange={(date, dateString) => {
                                                setE(e);
                                                // handleFieldChange(e)
                                                setClickedFieldDetails((prev) => { return { ...prev, "fieldValue": dateString ? dateString : "" } });
                                                setModalPosition({ ...modalPosition, value: dateString ? moment(dateString)?.format("YYYY-MM-DD") : null, date: dateString ? modalPosition?.is_dragable_field ? moment(dateString)?.format("YYYY-MM-DD") : moment(dateString)?.format("MM-DD-YYYY") : null });
                                              }}
                                            />
                                          </FormInputWrapper>
                                        </>
                                      )}
                                    {/* Buttons */}
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: 1,
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="error"
                                        sx={{ width: "45%" }}
                                        onClick={(e) => { renderPdf(e, modalPosition?.pageNumber); setModalPosition({ x: null, y: null, id: null }); setModalLoading(false); setClickedFieldDetails({}); }}
                                      >
                                        Close
                                      </Button>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          // in price field if value is null then make it zero
                                          savePdfField(modalPosition?.is_multi_field ? modalPosition?.multi_fields : { [modalPosition?.label]: modalPosition?.is_price_field ? (getValues(modalPosition?.label) ? getFigure(modalPosition?.value) : 0) : modalPosition?.value }, !!modalPosition?.date && modalPosition?.is_date_field ? modalPosition?.date : modalPosition?.is_price_field ? (getValues(modalPosition?.label) ? getFigure(modalPosition?.value) : 0) : modalPosition?.value, modalPosition?.is_dragable_field ? modalPosition?.key : modalPosition?.name, updatedPdfUrl.current?.url);
                                          setCurrentScrollPosition(saveScrollPosition()); // save scroll position before save changes 
                                        }}
                                        sx={{ width: "45%" }}
                                        disabled={modalLoading} // Disable the button while loading
                                      >
                                        {modalLoading ? (
                                          <CircularProgress size={24} color="inherit" />
                                        ) : (
                                          'Save'  // Show 'Save' text when not loading
                                        )}
                                      </Button>
                                    </Box>
                                  </>
                                )
                              )
                            }
                            {/* {modalPosition?.type === "checkbox" && (
                              <Box sx={{ mb: 3 }}>
                                <FormControlLabel
                                  label={modalPosition?.label}
                                  name={modalPosition?.name}
                                  control={<Checkbox checked={modalPosition?.value}
                                    onChange={(e) => {
                                      setModalPosition({ ...modalPosition, value: e.target.checked });
                                    }} />}
                                />
                              </Box>
                            )} */}
                          </Box>
                        </Rnd>

                      </>
                    )
                  }

                  {/* Render Text Fields */}
                  {textFields?.map((field) => (
                    field.pageNumber === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.id}
                        position={{ x: field.x * size, y: field.y * size }} // Scale position by zoom factor
                        size={{
                          width: field.initialWidth * size,  // Scale width by zoom factor
                          height: field.initialHeight * size  // Scale height by zoom factor
                        }}
                        minHeight={10 * size}
                        minWidth={15 * size}
                        dragHandleClassName="handle-textbox-drag"
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // After resizing, store unscaled width and height
                          setTextFields(prevState => prevState.map(tField => {
                            if (tField.id === field.id) {
                              return {
                                ...tField,
                                initialWidth: ref.offsetWidth / size,  // Store unscaled width
                                initialHeight: ref.offsetHeight / size,  // Store unscaled height
                                width: ref.offsetWidth,  // Store scaled width
                                height: ref.offsetHeight  // Store scaled height
                              };
                            }
                            return tField;
                          }));
                        }}
                        onDragStart={(e) => {
                          e.stopPropagation();
                          setTextFields(prev => prev.map((tField) => {
                            return {
                              ...tField,
                              showControls: false, // hide controls
                              isDragging: true, // do drag status true
                            }
                          }
                          )
                          );
                        }}
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level
                          setTextFields(prevState => prevState.map(tField => {
                            if (tField.id === field.id) {
                              return {
                                ...tField,
                                x: data.x / size,  // Store in unscaled coordinates
                                y: data.y / size,  // Store in unscaled coordinates
                                showControls: true, // on drag stop show options
                                isDragging: false, // do drag status false
                              };
                            }
                            return { ...tField, isDragging: false };
                          }));
                        }}
                        style={{
                          outline: "none",
                          border: "2px solid skyblue",
                          zIndex: 3,
                        }}
                      // className="position-relative"
                      >
                        {
                          field?.showControls &&
                          <div
                            className="border border-0"
                            style={{
                              position: 'absolute',
                              top: `${-38 * size}px`, // handle zoom effects on position 
                              left: `${-2 * size}px`,
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            <div className="d-flex align-items-center rounded mt-1 gap-2"
                              style={{
                                backgroundColor: "#212529",
                                width: `${130 * size}px`,
                                height: `${30 * size}px`,
                                justifyContent: "space-evenly"
                                // minWidth: "100px",
                                // cursor: "pointer"
                              }}
                              onClick={(e) => e.stopPropagation()} // for stop clicking on pdf
                            >
                              {/* <div className="handle-textbox-drag border border-1 bg-light" style={{ cursor: "grab" }}>
                              <i className="fa fa-arrows-alt fa-lg m-1 "
                                aria-hidden="true"
                                style={{ fontSize: `${20 * size}px`, cursor: "all-scroll" }}
                                draggable={false}
                              ></i>
                            </div> */}
                              {/* <div>
                              <input type="number" min={6} max={50}
                              onChange={(e) => handleTextFontSize(e, field.id)}
                              value={field.fontSize}
                              className="rounded size-changer mx-1 p-0"
                              style={{
                                height: `${20 * size}px`,
                                width: `${40 * size}px`,
                                  border: '2px solid black',
                                  fontSize: `${15 * size}px`,
                                  textAlign: 'center'
                                }}
                                />
                                </div> */}
                              <div
                                className=""
                                onClick={(e) => handleTextFontSize(false, field.id)}
                              >
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: `${15 * size}px`,
                                    cursor: "pointer",
                                    userSelect: "none" // Add this line to prevent text selection
                                  }}>A</span>
                              </div>

                              <div
                                className=""
                                onClick={(e) => handleTextFontSize(true, field.id)}
                              >
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: `${20 * size}px`,
                                    cursor: "pointer",
                                    userSelect: "none" // Add this line to prevent text selection
                                  }}>A</span>
                              </div>

                              <div>
                                <i
                                  className="fa fa-trash fa-lg text-light"
                                  aria-hidden="true"
                                  style={{ fontSize: `${20 * size}px`, cursor: "pointer" }}
                                  onClick={(e) => handleTextDelete(e, field.id)}
                                  draggable={false}
                                ></i>
                              </div>

                              <div
                                className=""
                                // className="position-absolute m-1"
                                onClick={(e) => handleTextBackground(field?.background, field.id)}
                                style={{
                                  // backgroundColor: "#D9E5FF",
                                  border: "1px double",
                                  borderColor: field?.background ? "#3e8eff" : "black",
                                  cursor: "pointer",
                                  width: `${20 * size}px`,
                                  height: `${20 * size}px`,
                                  userSelect: "none" // Add this line to prevent text selection
                                  // left: "100%"
                                }}
                              >
                                {
                                  <img src="/images/transparent.png" draggable={false}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover"
                                    }} alt="transparent" />
                                }
                              </div>
                            </div>
                          </div>
                        }
                        <div
                          className="border border-0"
                          // className = "handle-textbox-drag border"       // for border move cursor 
                          style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            width: '100%',
                            height: '100%',
                            // cursor: "move"                               // for border move cursor
                          }}
                        >
                          <div className="h-100 w-100 handle-textbox-drag">
                            <textarea
                              value={field.text}
                              placeholder="Aa"
                              onChange={(e) => handleTextareaOnChange(e, field, false)}
                              onFocus={(e) => {
                                e.stopPropagation();
                                if (!field.isDragging) {
                                  setTextFields(prev => prev.map((tField) =>
                                    tField.id === field.id ? {
                                      ...tField,
                                      showControls: true
                                    } : tField
                                  )
                                  );
                                }
                              }}
                              style={{
                                height: '100%',
                                width: '100%',
                                backgroundColor: field.background === true ? "transparent" : "#d9e5ff",
                                border: '2px solid black',
                                fontSize: `${(field.fontSize) * size}px`,
                                color: 'black',
                                verticalAlign: "middle",
                                textWrap: "nowrap",
                                outline: "none",
                                overflow: "hidden",
                                cursor: "auto",
                              }}
                              draggable={false}
                              className="p-0 border border-0 outline-none"
                              data-is-custom="true"
                              data-field-id={field?.id}
                            />
                          </div>
                        </div>
                      </Rnd>
                    )
                  ))}
                  {/* Render Checkbox Fields */}
                  {checkboxFields?.map((field) => (
                    field.pageNumber === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.id}
                        position={{ x: field.x * size, y: field.y * size }} // Scale position by zoom factor
                        size={{
                          width: field?.initialWidth * size,  // Dynamically adjust width, fallback to 50 if not defined
                          height: field?.initialHeight * size, // Dynamically adjust height, fallback to 50 if not defined
                        }}
                        lockAspectRatio={true} // Lock aspect ratio to make resizing square
                        dragHandleClassName="handle-checkbox-drag"
                        onDragStart={(e) => {
                          e.stopPropagation();
                          setCheckboxFields(prev => prev.map((cField) => {
                            return {
                              ...cField,
                              showControls: false, // hide controls
                              isDragging: true, // do drag status true
                            }
                          }));
                        }}
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level for checkboxes
                          setCheckboxFields(prevState => prevState.map(cField => {
                            if (cField.id === field.id) {
                              return {
                                ...cField,
                                x: data.x / size,  // Store in unscaled coordinates
                                y: data.y / size,  // Store in unscaled coordinates
                                showControls: true, // on drag stop show options
                                isDragging: false, // do drag status false
                              };
                            }
                            return { ...cField, showControls: true, isDragging: false };
                          }));
                        }}
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // Resize checkbox based on Rnd size and maintain square aspect ratio
                          setCheckboxFields(prevState => prevState.map(cField => {
                            if (cField.id === field.id) {
                              const newSize = ref.offsetWidth; // The width and height will be the same
                              return {
                                ...cField,
                                initialWidth: newSize / size,  // Store unscaled width
                                initialHeight: newSize / size,  // Store unscaled height
                                width: newSize,  // Store scaled width
                                height: newSize,  // Store scaled height
                              };
                            }
                            return cField;
                          }));
                        }}
                        style={{
                          border: "2px solid skyblue",
                          zIndex: 3,
                          padding: "0px",
                          margin: "0px"
                        }}
                      >
                        {
                          field?.showControls &&
                          <div
                            className="border border-0"
                            style={{
                              position: 'absolute',
                              top: `${-38 * size}px`, // handle zoom effects on position 
                              left: `${-2 * size}px`,
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            <div className="d-flex align-items-center rounded mt-1 gap-2"
                              style={{
                                backgroundColor: "#212529",
                                width: `${130 * size}px`,
                                height: `${30 * size}px`,
                                justifyContent: "space-evenly"
                                // minWidth: "100px",
                                // cursor: "pointer"
                              }}
                              onClick={(e) => e.stopPropagation()} // for stop clicking on pdf
                            >
                              <div className="handle-checkbox-drag" style={{ cursor: "grab" }}>
                                <i className="fa fa-arrows-alt fa-lg text-light"
                                  aria-hidden="true"
                                  style={{ fontSize: `${20 * size}px`, cursor: "all-scroll" }}
                                  draggable={false}
                                ></i>
                              </div>

                              <div>
                                <i
                                  className="fa fa-trash fa-lg text-light"
                                  aria-hidden="true"
                                  style={{ fontSize: `${20 * size}px`, cursor: "pointer" }}
                                  onClick={(e) => handleCheckboxDelete(e, field?.id)}
                                  draggable={false}
                                ></i>
                              </div>

                              <div
                                className=""
                                // className="position-absolute m-1"
                                onClick={(e) => handleCheckboxBackground(field?.background, field?.id)}
                                style={{
                                  // backgroundColor: "#D9E5FF",
                                  border: "1px double",
                                  borderColor: field?.background ? "black" : "#3e8eff",
                                  cursor: "pointer",
                                  width: `${20 * size}px`,
                                  height: `${20 * size}px`,
                                  userSelect: "none" // Add this line to prevent text selection
                                  // left: "100%"
                                }}
                              >
                                {
                                  <img src="/images/transparent.png" draggable={false}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover"
                                    }} alt="transparent" />
                                }
                              </div>
                            </div>
                          </div>
                        }
                        <div
                          className="p-0 m-0 position-relative "
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: field.background ? 'rgba(0,0,0,0.1)' : 'transparent',
                          }}
                          data-is-checkbox="true"
                          data-field-id={field?.id}
                        >
                          {/* checkbox image */}
                          <div className="w-100 h-100"
                            style={{
                              backgroundColor: `${field.background ? "white" : "transparent"}`,
                              cursor: "pointer",
                              userSelect: "none"
                            }}
                            onClick={(e) => { handleCheckboxClick(field?.id) }}
                          >
                            {
                              field?.checked !== 2 && <img className="m-0 p-0" draggable={false}
                                src={checkboxImagesUrl[field?.checked]} style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain"
                                }} alt="checkbox" />
                            }
                          </div>


                        </div>
                      </Rnd>
                    )
                  ))}
                  {/* Render Logo */}
                  {logoFields?.map((field) => (
                    field.pageNumber === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.id}
                        position={{ x: field.x * size, y: field.y * size }} // Scale position by zoom factor
                        size={{
                          width: field?.initialWidth * size,  // Dynamically adjust width, fallback to 50 if not defined
                          height: field?.initialHeight * size, // Dynamically adjust height, fallback to 50 if not defined
                        }}
                        lockAspectRatio={true} // allow any size not only square
                        dragHandleClassName="handle-logo-drag"
                        onDragStart={(e) => {
                          e.stopPropagation();
                          setLogoFields(prev => prev.map((logoField) => {
                            return {
                              ...logoField,
                              showControls: false, // hide controls
                              isDragging: true, // do drag status true
                            }
                          }));
                        }}
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level for checkboxes
                          setLogoFields(prevState => prevState.map(logoField => {
                            if (logoField?.id === field?.id) {
                              return {
                                ...logoField,
                                x: data.x / size,  // Store in unscaled coordinates
                                y: data.y / size,  // Store in unscaled coordinates
                                showControls: true, // on drag stop show options
                                isDragging: false, // do drag status false
                              };
                            }
                            return { ...logoField, showControls: true, isDragging: false };
                          }));
                        }}
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // Resize checkbox based on Rnd size and maintain square aspect ratio
                          setLogoFields(prevState => prevState.map(cField => {
                            if (cField.id === field.id) {
                              const newWidth = ref.offsetWidth; // Width as per resizing
                              const newHeight = ref.offsetHeight; // Height as per resizing
                              return {
                                ...cField,
                                initialWidth: newWidth / size,  // Store unscaled width
                                initialHeight: newHeight / size,  // Store unscaled height
                                width: newWidth,  // Store scaled width
                                height: newHeight,  // Store scaled height
                              };
                            }
                            return cField;
                          }));
                        }}
                        style={{
                          border: "2px solid skyblue",
                          zIndex: 1000,
                          padding: "0px",
                          margin: "0px"
                        }}
                      >
                        {/* Logo controls */}
                        {
                          field?.showControls &&
                          <div
                            className="border border-0"
                            style={{
                              position: 'absolute',
                              top: `${-38 * size}px`, // handle zoom effects on position 
                              left: `${-2 * size}px`,
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            <div className="d-flex align-items-center rounded mt-1 gap-2"
                              style={{
                                backgroundColor: "#212529",
                                width: `${130 * size}px`,
                                height: `${30 * size}px`,
                                justifyContent: "space-evenly"
                                // minWidth: "100px",
                                // cursor: "pointer"
                              }}
                              onClick={(e) => e.stopPropagation()} // for stop clicking on pdf
                            >
                              <div className="handle-logo-drag" style={{ cursor: "grab" }}>
                                <i className="fa fa-arrows-alt fa-lg text-light"
                                  aria-hidden="true"
                                  style={{ fontSize: `${20 * size}px`, cursor: "all-scroll" }}
                                  draggable={false}
                                ></i>
                              </div>

                              <div>
                                <i
                                  className="fa fa-trash fa-lg text-light"
                                  aria-hidden="true"
                                  style={{ fontSize: `${20 * size}px`, cursor: "pointer" }}
                                  onClick={(e) => handleLogoDelete(e, field?.id)}
                                  draggable={false}
                                ></i>
                              </div>

                              < div>
                                <i className=" fa fa-copy text-light fa-lg"
                                  aria-hidden="true"
                                  style={{ fontSize: `${20 * size}px`, cursor: "pointer", color: "#3e8eff" }}
                                  onClick={(e) => {
                                    const pageElement = pageRefs.current[pageNumber - 1]?.current;
                                    if (pageElement) {
                                      const rect = pageElement.getBoundingClientRect();
                                      const offsetX = e.clientX - rect.left;
                                      const offsetY = e.clientY - rect.top;
                                      const xPosition = offsetX / size;  // Store position in unscaled coordinates
                                      const yPosition = offsetY / size;
                                      setLogoFields([
                                        ...logoFields,
                                        {
                                          x: xPosition,
                                          y: yPosition,
                                          pageNumber: pageNumber,
                                          logo: field?.logo,
                                          file: field.file ?? {},
                                          id: Date.now(),
                                          size: size,
                                          initialWidth: field?.initialWidth,  // Store initial unscaled width
                                          initialHeight: field?.initialHeight,
                                          showControls: true,  // Show controls when text field is added
                                          isDragging: false,    // Store dragging status
                                        }
                                      ]);
                                    }
                                  }}
                                  draggable={false}
                                ></i>
                              </div>
                            </div>
                          </div>
                        }
                        <div
                          className="p-0 m-0 position-relative"
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: field?.background ? 'rgba(0,0,0,0.1)' : 'transparent',
                          }}
                        >
                          {/* logo image */}
                          <div className="w-100 h-100"
                            style={{
                              backgroundColor: "whitesmoke",
                              cursor: "pointer",
                              userSelect: "none",
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: field?.initialHeight,
                              width: field?.initialWidth
                            }}
                          // onClick={(e) => { handleLogoClick(field.id) }}
                          >
                            <input type="file"
                              className="m-0 p-0"
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                                opacity: 0
                              }}
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={(e) => {
                                handleLogoClick(field?.id, e.target?.files[0]);
                              }}
                              alt="logo"
                            />
                            <img src={field?.logo} style={{
                              width: field?.logo === "/images/logo-upload.svg" ? "50%" : "100%",
                              height: field?.logo === "/images/logo-upload.svg" ? "50%" : "100%",
                            }} />
                          </div>
                        </div>
                      </Rnd>
                    )
                  ))}
                  {/* render signature fields  */}
                  {signatureFields?.map((field) => (
                    field.page_number === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.key}
                        position={{ x: field.coord.x * size, y: field.coord.y * size }} // Scale position by zoom factor
                        size={{
                          width: field.coord.width * size,  // Scale width by zoom factor
                          height: field.coord.height * size  // Scale height by zoom factor
                        }}
                        minHeight={10 * size}
                        minWidth={15 * size}
                        dragHandleClassName="handle-sign-drag"
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // After resizing, store unscaled width and height
                          setSignatureFields(prevState => prevState.map(sField => {
                            if (sField.key === field.key) {
                              return {
                                ...sField,
                                coord: { ...sField.coord, width: ref.offsetWidth / size, height: ref.offsetHeight / size }
                              };
                            }
                            return sField;
                          }));
                        }}
                        onDragStart={(e) => {
                          e.stopPropagation();
                          setSignatureFields(prev => prev.map((sField) => {
                            return {
                              ...sField,
                              showControls: false, // hide controls
                              isDragging: true, // do drag status true
                            }
                          }));
                        }}
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level
                          setSignatureFields(prevState => prevState.map(sField => {
                            if (sField.key === field.key) {
                              return {
                                ...sField,
                                coord: { ...sField.coord, x: data.x / size, y: data.y / size },
                                showControls: true, // on drag stop show options
                                isDragging: false, // do drag status false
                              };
                            }
                            return { ...sField, showControls: true, isDragging: false };
                          }));
                        }}
                        style={{
                          outline: "none",
                          border: "1px solid black",
                          zIndex: 3,
                        }}
                      >
                        {
                          field?.showControls &&
                          <div
                            className="border border-0"
                            style={{
                              position: 'absolute',
                              top: `${-32 * size}px`, // handle zoom effects on position 
                              left: `${-2 * size}px`,
                              width: '100%',
                              height: '100%',
                            }}
                            draggable={false}
                          >
                            <div
                              className="position-absolute p-1 mt-1 gap-2 rounded d-flex align-items-center "
                              style={{
                                backgroundColor: "#212529",
                                width: `${25 * size}px`,
                                height: `${25 * size}px`,
                                // left: "100%",
                                justifyContent: "space-evenly"
                              }}
                              onClick={(e) => e.stopPropagation()} // for stop clicking on pdf
                            >
                              <i
                                className="fa fa-trash fa-lg text-light"
                                aria-hidden="true"
                                style={{ fontSize: `${18 * size}px`, cursor: "pointer" }}
                                onClick={(e) => handleSignatureDelete(e, field?.key)}
                                draggable={false}
                              ></i>
                            </div>
                          </div>
                        }
                        <div
                          className="border border-0 handle-sign-drag"
                          style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <input
                            style={{
                              fontSize: "15px",
                              color: 'black',
                              verticalAlign: "middle",
                              textWrap: "nowrap",
                              overflow: "hidden",
                              cursor: 'move',
                              width: '100%',
                              height: '100%',
                              padding: '5px',
                              outline: 'none',
                              borderRadius: '2px',
                              backgroundColor: field.bg_color,
                              border: '1px solid black'
                            }}
                            id={field.key}
                            onClick={(event) => event.stopPropagation()}
                            className="p-1 border border-0 outline-none"
                            value={field.type === "PDFSignature" ? "Signature" : (field.type === "PDFInitial" ? "XX" : field.type === "PDFTextField" ? pdfEditorFields[field?.value] : "Date")}
                            readOnly
                          />
                        </div>
                      </Rnd>
                    )
                  ))}
                  {/* Render form Fields */}
                  {formFields?.map((field) => (
                    field.pageNumber === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.id}
                        position={{ x: field.x * size, y: field.y * size }} // Scale position by zoom factor
                        size={{
                          width: field.initialWidth * size,  // Scale width by zoom factor
                          height: field.initialHeight * size  // Scale height by zoom factor
                        }}
                        minHeight={10 * size}
                        minWidth={15 * size}
                        dragHandleClassName="handle-textbox-drag"
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // After resizing, store unscaled width and height
                          setFormFields(prevState => prevState.map(formField => {
                            if (formField.uniqueId === field.uniqueId) {
                              return {
                                ...formField,
                                initialWidth: ref.offsetWidth / size,  // Store unscaled width
                                initialHeight: ref.offsetHeight / size,  // Store unscaled height
                                width: ref.offsetWidth,  // Store scaled width
                                height: ref.offsetHeight  // Store scaled height
                              };
                            }
                            return formField;
                          }));
                        }}
                        onDragStart={(e) => {
                          e.stopPropagation();
                          setFormFields(prev => prev.map((formField) => {
                            return {
                              ...formField,
                              showControls: false, // hide controls
                              isDragging: true, // do drag status true
                            }
                          }));
                        }}
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level
                          setFormFields(prevState => prevState.map(formField => {
                            if (formField.uniqueId === field.uniqueId) {
                              return {
                                ...formField,
                                x: data.x / size,  // Store in unscaled coordinates
                                y: data.y / size,  // Store in unscaled coordinates
                                showControls: true, // on drag stop show options
                                isDragging: false, // do drag status false
                              };
                            }
                            return { ...formField, isDragging: false };
                          }));
                        }}
                        style={{
                          outline: "none",
                          border: "2px solid skyblue",
                          zIndex: 3,
                        }}
                      >
                        {
                          field?.showControls &&
                          <div
                            className="border border-0"
                            style={{
                              position: 'absolute',
                              top: `${-38 * size}px`, // handle zoom effects on position 
                              left: `${-2 * size}px`,
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            <div className="d-flex align-items-center rounded mt-1 gap-2"
                              style={{
                                backgroundColor: "#212529",
                                width: `${130 * size}px`,
                                height: `${30 * size}px`,
                                justifyContent: "space-evenly"
                                // minWidth: "100px",
                                // cursor: "pointer"
                              }}
                              onClick={(e) => e.stopPropagation()} // for stop clicking on pdf
                            >
                              <div
                                className=""
                                onClick={(e) => handleFormFieldFontSize(false, field?.uniqueId)}
                              >
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: `${15 * size}px`,
                                    cursor: "pointer",
                                    userSelect: "none" // Add this line to prevent text selection
                                  }}>A</span>
                              </div>

                              <div
                                className=""
                                onClick={(e) => handleFormFieldFontSize(true, field?.uniqueId)}
                              >
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: `${20 * size}px`,
                                    cursor: "pointer",
                                    userSelect: "none" // Add this line to prevent text selection
                                  }}>A</span>
                              </div>

                              <div>
                                <i
                                  className="fa fa-trash fa-lg text-light"
                                  aria-hidden="true"
                                  style={{ fontSize: `${20 * size}px`, cursor: "pointer" }}
                                  onClick={(e) => handleFormFieldDelete(e, field?.uniqueId)}
                                  draggable={false}
                                ></i>
                              </div>

                              <div
                                className=""
                                // className="position-absolute m-1"
                                onClick={(e) => handleFormFieldBackground(field?.background, field?.uniqueId)}
                                style={{
                                  // backgroundColor: "#D9E5FF",
                                  border: "1px double",
                                  borderColor: field?.background ? "#3e8eff" : "black",
                                  cursor: "pointer",
                                  width: `${20 * size}px`,
                                  height: `${20 * size}px`,
                                  userSelect: "none" // Add this line to prevent text selection
                                  // left: "100%"
                                }}
                              >
                                {
                                  <img src="/images/transparent.png" draggable={false}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover"
                                    }} alt="transparent" />
                                }
                              </div>
                            </div>
                          </div>
                        }
                        <div
                          className="border border-0"
                          // className = "handle-textbox-drag border"       // for border move cursor 
                          style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            width: '100%',
                            height: '100%',
                            // cursor: "move"                               // for border move cursor
                          }}
                        >
                          <div className="h-100 w-100 handle-textbox-drag">
                            <textarea
                              value={field?.text}
                              name={field?.value}
                              onChange={(e) => handleTextareaOnChange(e, field, true)}
                              onFocus={(e) => {
                                e.stopPropagation();
                                if (!field?.isDragging) {
                                  setFormFields(prev => prev.map((formField) =>
                                    formField?.uniqueId === field.uniqueId ? {
                                      ...formField,
                                      showControls: true
                                    } : formField
                                  ));
                                }
                              }}
                              style={{
                                height: '100%',
                                width: '100%',
                                backgroundColor: field.background === true ? "transparent" : "#d9e5ff",
                                border: '2px solid black',
                                fontSize: `${(field.fontSize) * size}px`,
                                color: 'black',
                                verticalAlign: "middle",
                                textWrap: "nowrap",
                                outline: "none",
                                overflow: "hidden"
                              }}
                              className="p-0 border border-0 outline-none"
                              data-is-formfield="true"
                              data-field-id={field?.uniqueId}
                            />

                          </div>
                        </div>
                      </Rnd>
                    )
                  ))}
                </Page>
              </div>
            ))}
          </div>
        </Document>
      </div >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div >

      :
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        }}
        className="bg-gradient"
      >
        {/* <input id="file" type="file" accept="application/pdf" onChange={handleFileChange} ref={mergeRef} hidden />
      <button
        style={{
          backgroundColor: '#3e8eff',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '4px',
          fontSize: '16px',
          cursor: 'pointer',
        }}
      onClick={handleSelectFile}
      >
        Select PDF file
      </button>
      <p
        style={{
          marginTop: '10px',
          color: '#333',
          fontSize: '14px',
        }}
      >
        Or drop your PDF file here
      </p> */}
      </div>

  )
}

export default PdfRender;
