import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormRow from './FormRow';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setLoadingStatus } from '../../redux/actions/loading';
import { updateTransaction } from '../../redux/actions/transaction';

import { toast } from 'react-toastify';
import moment from 'moment';
import * as yup from 'yup';
import clsx from 'clsx';
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { dateFormatter } from '../../utils/common';
import FormInputWrapper from './FormInputWapper';
import { getFigure, getPrice } from '../../utils/currency';

const ChangeTransactionStatusModal = ({ show, onClose, transaction }) => {

    const dispatch = useDispatch();

    const schema = yup.object().shape({
        status: yup.string().required()
    });

    const { register, handleSubmit, reset, getValues, setValue, watch, formState: { errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const [soldDate, setSoldDate] = useState(moment(transaction?.sold_date || new Date()).utc().format("yyyy-MM-DD"));

    const transactionStatusList = [
        { value: 'progress', name: 'In-Process' },
        { value: 'active', name: 'Active' },
        { value: 'completed', name: 'Sold' },
        { value: 'cancelled', name: 'Cancelled' },
        { value: 'rejected', name: 'Rejected' },
        { value: 'archived', name: 'Archived' },
    ];

    useEffect(() => {
        reset();
        if (transaction) {
            setValue('status', transaction.status == 'draft' ? '' : transaction.status);
            setValue('price', transaction.status == 'completed' ? transaction.sold_price : transaction?.t_offers?.purchase_price ?  transaction?.t_offers?.purchase_price: transaction?.sold_price ||0 );
        }
    }, [show]);

    const handleRegistration = (formData) => {
        if (formData.status == transaction.status && formData.price == transaction.sold_price) {
            setSoldDate(moment(transaction?.sold_date || new Date()).utc().format("yyyy-MM-DD"));
            toast.info('Status already selected.');
            onClose(false);
            return;
        }
        if (formData.status == 'completed' && (!soldDate || !formData?.price || !getFigure(formData?.price))) {
            if (!soldDate) {
                toast.error('Please select sold date');
                return;
            } if (!((!!getFigure(formData?.price)) && formData?.price)) {
                toast.error('Please add sold price');
                return;
            }
        }
        let dataBody = {
            status: formData.status
        }
        if (formData.status == 'completed') {
            dataBody = {
                ...dataBody,
                sold_date: soldDate,
                sold_price: getFigure(formData?.price)
            }
        }
        dispatch(setLoadingStatus(true));

        dispatch(updateTransaction(transaction.id, dataBody)).then(() => {
            toast.success('Status updated successfully.');
            dispatch(setLoadingStatus(false));
            onClose(true);
        }).catch((error) => {
            toast.error('Something went wrong while updating status.')
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    }
    const handleError = (errors) => {
        console.log(errors);
    };

    const vesselName = `${transaction.t_vessel?.year || ""} ${transaction.t_vessel?.make || ""} ${transaction.t_vessel?.model || ""
        } ${transaction.t_vessel?.length || ""}`;

    const selectedStatus = useMemo(() => getValues("status"), [watch('status')]);

    const handleBlur = (ref) => (e) => {
        const price = getPrice(getValues(ref));
        setValue(ref, price);
    };

    const handleFocus = (ref) => (e) => {
        const price = getFigure(getValues(ref));
        setValue(ref, price);
    };


    return (
        <Modal
            show={show}
            onHide={onClose}
            centered={true}>
            <Modal.Header closeButton className='py-2'>
                <h3 className='my-2'>Update Status</h3>
            </Modal.Header>
            <form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <Modal.Body className='p-2'>
                    <div className='p-1'>
                        <FormRow>
                            <div className="uk-padding-small ">
                                <div className='px-3'>

                                    <div className=" font-weight-bold"> Vessel</div>
                                    <h5 className='m-0'>{vesselName}</h5>
                                </div>
                                <div className="mx-3">
                                    <div className=''>
                                        <label className="uk-form-label my-1 py-1">Select Status</label>
                                        <select className={clsx("uk-select", { "uk-form-danger": errors?.status })} id="status" name="status" {...register('status')}>
                                            <option value="">Select Status</option>
                                            {transactionStatusList.map((status) => (
                                                <option key={status.value} value={status.value}>{status.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectedStatus == 'completed' &&
                                        <div className='d-flex justify-content-between'>
                                            <div className='mr-2 w-100'>
                                                <label className="uk-form-label my-1 py-1" htmlFor="name">Verify Date Sold</label>
                                                <DatePicker
                                                    className="uk-input"
                                                    style={{ cursor: 'pointer', color: '#000' }}
                                                    format={'MM/DD/YYYY'}
                                                    value={soldDate && dayjs(soldDate)}
                                                    onChange={async (date, dateString) => {
                                                        setSoldDate(dateFormatter(dateString));
                                                    }}
                                                />
                                            </div>
                                            <div className=' ml-2 w-100'>
                                                <label className="uk-form-label py-1 my-1" htmlFor="name">Verify Sold Price</label>
                                                <input className="uk-input" type="text"
                                                    {...register("price")}
                                                    onBlur={handleBlur("price")}
                                                    onFocus={handleFocus("price")}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </FormRow>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="uk-button uk-button-default uk-modal-close small" type="button" onClick={() => { onClose(false) }}>
                        Cancel
                    </button>
                    <button
                        className="uk-button uk-button-primary small"
                        type="submit" >Done</button>
                </Modal.Footer>
            </form>
        </Modal >
    )
}

export default ChangeTransactionStatusModal;