import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from "@hookform/resolvers/yup";

import Form from '../../../../../common/Form';
import FormRow from '../../../../../common/FormRow';
import FormInputWrapper from '../../../../../common/FormInputWapper';
import Column from '../../../../../common/Column';
import { getFigure, getPrice } from '../../../../../../utils/currency';
import { getPlainTransactionData } from '../../../../../../utils/stepFilter';
import { setLoadingStatus } from '../../../../../../redux/actions/loading';
import { buyerSellerTransaction, getTransactionEdocById, getTransactionEsignById, getTransactionInitialById, upsertTransaction } from '../../../../../../redux/actions/transaction';

import * as yup from "yup";

const figureFields = [
    "sales_price_vessel",
    "sales_price_additional_units",
    "sales_price_total",
    "survey_allowance",
    "deposit_amount",
    "final_sales_price",
    "sub_total",
    "sales_tax_vessel",
    "county_other_taxes",
    "registration_fees",
    "documentation_fees",
    "processing_other_fees",
    "finance_amount",
    "trade_in_allowance_see_addendum",
    "sales_tax_additional_units",
    "trade_in_debt",
    "balance_due",
    "expense_total",
    "credit_total",
    "deposit_total",
    "total_amount_due"
];

const taxInputType = {
    PERCENTAGE: "percentage",
    AMOUNT: "amount"
}

const TabFormTransactionDetails = ({ closePanel, menuType }) => {

    const schema = yup.object().shape({});

    const { register, handleSubmit, setValue, reset, getValues, watch, formState: { isDirty, errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const dispatch = useDispatch();

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataDeal = dataTransaction || {};
    const dataBuyerClosing = dataDeal.t_buyer_closing;
    const dataSellerClosing = dataDeal.t_seller_closing || {};
    const dataVesselAcceptance = dataDeal.t_acceptance || [];
    const [taxInput, setTaxInput] = useState(taxInputType.PERCENTAGE);
    const [isTax, setIsTax] = useState(dataBuyerClosing?.is_sales_tax);

    // const dataVesselAcceptance = dataDeal?.t_acceptance.filter(t_acceptance =>t_acceptance.status==2)[0] ; for reference of count
    const dataDetails = dataDeal.t_details || [];
    const toys = dataDeal.t_toys || [];
    const tailers = dataDeal.t_trailer || [];
    const broker = dataDeal.broker || {};

    useEffect(() => {
        if (dataDeal && dataDeal.id) {
            dispatch(getTransactionInitialById(dataDeal.id));
        }
    }, []);

    useEffect(() => {
        if (dataBuyerClosing) {
            Object.keys(dataBuyerClosing).map((key) => {
                if (figureFields.indexOf(key) > -1) {
                    setValue(key, getPrice(dataBuyerClosing[key]));
                } else {
                    setValue(key, dataBuyerClosing[key]);
                }
            });
            setIsTax(dataBuyerClosing?.is_sales_tax);
            setTaxInput(dataBuyerClosing?.is_tax_percentage ? taxInputType.PERCENTAGE : taxInputType.AMOUNT);
        }
        const additional_price = toys.reduce((total, toy) => total + toy.item_value, 0);
        setValue("sales_price_vessel", getPrice((dataDeal.t_offers?.purchase_price)?.toFixed(2)));
        setValue("survey_allowance", getPrice(dataBuyerClosing?.survey_allowance ?? dataSellerClosing?.survey_allowance ?? dataVesselAcceptance?.[0]?.adjustment_amount));
        setValue("sales_price_additional_units", getPrice(tailers.reduce((total, trailer) => total + parseFloat(trailer.price), additional_price)))
        setValue("trade_in_allowance_see_addendum", getPrice(getPrice((dataDeal.t_trade_in_vessel?.allowance))));
        setValue('processing_other_fees', getPrice(dataBuyerClosing?.processing_other_fees ?? broker.company?.brokerage?.fees));
    }, [dataBuyerClosing, dataVesselAcceptance, dataSellerClosing]);

    useEffect(() => {
        const total = getFigure(getValues('sales_price_total'));
        const addt = getFigure(getValues("sales_price_additional_units"));
        if (total > 0) {
            setValue("sales_price_vessel", getPrice((total || 0) - (addt || 0)));
        }
    }, [watch("sales_price_total")]);

    useEffect(() => {
        // set detail of expense, credit and deposit total from previous forms
        const details = dataDetails.filter((d) => d.document_type === "buyer");
        const { expenseTotal, creditTotal, depositTotal } = getTotals(details);
        setValue("expense_total", getPrice(expenseTotal));
        setValue("credit_total", getPrice(creditTotal));
        setValue("deposit_total", getPrice(getPrice((depositTotal))));
    }, [dataDetails]);

    useEffect(() => {
        const { sales_price_vessel, sales_price_additional_units, survey_allowance } = getValues();
        const sales_total = (getFigure(sales_price_vessel) || 0) + (getFigure(sales_price_additional_units) || 0);
        setValue("sales_price_total", getPrice(getPrice((dataDeal.t_offers?.total_purchase_price))));

        const final_sales_price = sales_total - (getFigure(survey_allowance) || 0);
        setValue("final_sales_price", getPrice(final_sales_price));

        const sales_tax_additional_units = sales_price_additional_units * 100;
        sales_tax_additional_units && setValue("sales_tax_additional_units", getPrice(sales_tax_additional_units));
    }, [watch("sales_price_vessel"), watch("sales_price_additional_units"), watch("survey_allowance")]);

    useEffect(() => {
        if (!isTax) {
            setValue('sales_tax_rate', 0);
            setValue('sales_tax_vessel', 0);
        }
    }, [isTax])
    useEffect(() => {
        let { sales_tax_rate, sales_tax_vessel, sales_price_vessel, survey_allowance, trade_in_allowance_see_addendum } = getValues();
        const price_vessel = getFigure(sales_price_vessel) - getFigure(survey_allowance) - getFigure(trade_in_allowance_see_addendum);

        if (taxInput === taxInputType.PERCENTAGE) {
            sales_tax_vessel = (price_vessel || 0) * sales_tax_rate / 100;
            sales_tax_vessel = sales_tax_vessel > 0 ? sales_tax_vessel : 0
            setValue('sales_tax_vessel', getPrice(sales_tax_vessel) || 0);
        } else if (taxInput === taxInputType.AMOUNT && price_vessel) {
            sales_tax_rate = ((getFigure(sales_tax_vessel) || 0) * 100 / price_vessel).toFixed(2);
            setValue('sales_tax_rate', sales_tax_rate);
        }
    }, [watch("sales_price_vessel"), watch('sales_tax_rate'), watch('sales_tax_vessel'), watch("survey_allowance")]);

    useEffect(() => {
        const final_sales_price = getFigure(getValues("final_sales_price")) || 0;
        const sales_tax_vessel = getFigure(getValues("sales_tax_vessel")) || 0;
        const sales_tax_additional_units = getFigure(getValues("sales_tax_additional_units")) || 0;
        const county_other_taxes = getFigure(getValues("county_other_taxes")) || 0;
        const registration_fees = getFigure(getValues("registration_fees")) || 0;
        const documentation_fees = getFigure(getValues("documentation_fees")) || 0;
        const processing_other_fees = getFigure(getValues("processing_other_fees")) || 0;

        const sub_total =
            final_sales_price +
            sales_tax_vessel +
            sales_tax_additional_units +
            county_other_taxes +
            registration_fees +
            documentation_fees +
            processing_other_fees;
        setValue("sub_total", getPrice(sub_total));
    }, [
        watch("final_sales_price"),
        watch("county_other_taxes"),
        watch("registration_fees"),
        watch("documentation_fees"),
        watch("processing_other_fees"),
        watch("sales_tax_additional_units"),
        watch("sales_tax_vessel"),
    ]);

    useEffect(() => {
        const trade_in_debt = getFigure(getValues("trade_in_debt")) || 0;
        const sub_total = getFigure(getValues("sub_total")) || 0;
        const expenses_total = getFigure(getValues("expense_total")) || 0;
        const total_amount_due = trade_in_debt + sub_total + expenses_total;
        setValue("total_amount_due", getPrice(total_amount_due));
    }, [watch("sub_total"), watch("trade_in_debt"), watch("expense_total")]);

    useEffect(() => {
        const total_amount_due = getFigure(getValues("total_amount_due")) || 0;
        const trade_in_allowance_see_addendum = getFigure(getValues("trade_in_allowance_see_addendum")) || 0;
        const credit_total = getFigure(getValues("credit_total")) || 0;
        const deposit_total = getFigure(getValues("deposit_total")) || 0;
        const finance_amount = getFigure(getValues("finance_amount")) || 0;
        const balance_due = total_amount_due - trade_in_allowance_see_addendum - credit_total - deposit_total - finance_amount;
        setValue("balance_due", getPrice(balance_due));
    }, [
        watch("total_amount_due"),
        watch("trade_in_allowance_see_addendum"),
        watch("credit_total"),
        watch("deposit_total"),
        watch("finance_amount"),
    ]);

    useEffect(() => {

        if (dataBuyerClosing?.sub_total != getFigure(getValues("sub_total")) || dataBuyerClosing?.balance_due != getFigure(getValues("balance_due"))) {
            initialSubmit(getValues())
        }
    }, []);


    const getPayload = (detail) => {
        detail['is_sales_tax'] = isTax;
        detail['is_tax_percentage'] = taxInput === taxInputType.PERCENTAGE;

        if (detail.sales_tax_rate === "") {
            detail.sales_tax_rate = null
        }
        let transaction_detail = {}

        for (const key in detail) {
            if (Object.hasOwnProperty.call(detail, key)) {
                const element = detail[key];
                if (figureFields.includes(key)) {
                    transaction_detail[key] = getFigure(element);
                } else {
                    transaction_detail[key] = element;
                }
            }
        }
        return transaction_detail
    }
    const initialSubmit = (detail) => {
        let transaction_detail = getPayload(detail);

        dispatch(setLoadingStatus(true));
        dispatch(buyerSellerTransaction({
            step: {
                db: "buyer_closing",
                main: {
                    ...transaction_detail,
                    seller_closing_id: dataSellerClosing.id ? dataSellerClosing.id : null,
                    acceptance_ids: dataTransaction?.t_acceptance?.filter((e) => e?.status === 2)?.map((e) => e?.id)
                },
            },
            transaction: {
                isBelonged: false,
                main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
            },
        }, "Buyer Closing")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionEsignById(data.id));
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    };


    const getTotals = (details) => {
        let expenseTotal = 0;
        let creditTotal = 0;
        let depositTotal = 0;
        details.forEach((detail) => {
            const amount = getFigure(detail.amount);
            switch (detail.type) {
                case "expenses":
                    expenseTotal += amount || 0;
                    break;
                case "credits":
                    creditTotal += amount || 0;
                    break;
                case "deposits":
                    depositTotal += amount || 0;
                    break;
                default:
                    break;
            }
        });
        return { expenseTotal, creditTotal, depositTotal };
    };

    const handleBlur = (ref) => {
        const price = getPrice(getValues(ref));
        setValue(ref, price);
    };

    const handleFocus = (ref) => {
        const figure = getFigure(getValues(ref));
        setValue(ref, figure);
    };

    const handleRegistration = (detail) => {
        let transaction_detail = getPayload(detail);

        dispatch(setLoadingStatus(true));
        dispatch(upsertTransaction({
            step: {
                db: "buyer_closing",
                main: {
                    ...transaction_detail,
                    seller_closing_id: dataSellerClosing.id ? dataSellerClosing.id : null,
                    acceptance_ids: dataTransaction?.t_acceptance?.filter((e) => e?.status === 2)?.map((e) => e?.id) ?? []
                },
            },
            transaction: {
                isBelonged: false,
                main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
            },
        }, "Buyer Closing")
        ).then((data) => {
            dispatch(getTransactionInitialById(data.id)).then(res => {
                dispatch(setLoadingStatus(false));
                dispatch(getTransactionEsignById(data.id))
                    .then((res) => {
                        dispatch(getTransactionEdocById(data.id, menuType === 'invitation', dataDeal.broker?.company_id))
                            .catch(() => {
                                dispatch(setLoadingStatus(false));
                            })
                    }).catch(() => {
                        dispatch(setLoadingStatus(false));
                    })
            }).catch(() => {
                dispatch(setLoadingStatus(false));
            });
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper label="Sales Price (Vessel)">
                        <input className="uk-input" type="text" {...register("sales_price_vessel")} readOnly />
                    </FormInputWrapper>
                    <FormInputWrapper label="Sales Price (Additional Units)">
                        <input className="uk-input" type="text" {...register("sales_price_additional_units")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Sales Price (Total)*">
                        <input className="uk-input" type="text" {...register("sales_price_total")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Survey Allowance">
                        <input className="uk-input" type="text" {...register("survey_allowance")}
                            onBlur={() => handleBlur("survey_allowance")}
                            onFocus={() => handleFocus("survey_allowance")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Trade-In Allowance (see Addendum)">
                        <input className="uk-input" type="text" {...register("trade_in_allowance_see_addendum")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Final Sales Price">
                        <input className="uk-input" type="text" {...register("final_sales_price")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <Column>
                        <div className="radio-toolbar">
                            <input id='taxable' type="radio" name="radioTax" checked={isTax === true}
                                onChange={() => setIsTax(true)} />
                            <label htmlFor='taxable' className="uk-form-label radio">Taxable</label>
                            <input id='exempt' type="radio" name="radioTax radio" checked={isTax === false}
                                onChange={() => { setValue('sales_tax_rate', undefined); setValue('sales_tax_vessel', undefined) }}
                                onClick={() => setIsTax(false)} />
                            <label htmlFor='exempt' className="uk-form-label">Exempt/Buyer Pays</label>
                        </div>
                        <div className="radio-toolbar">
                            <input id='percentage' type="radio" name="radioToys" value={taxInputType.PERCENTAGE}
                                checked={taxInput === taxInputType.PERCENTAGE}
                                onChange={() => setTaxInput(taxInputType.PERCENTAGE)} />
                            <label htmlFor='percentage' className="uk-form-label radio">Percentage</label>
                            <input id='amount' type="radio" name="radioToys radio" value={taxInputType.AMOUNT}
                                checked={taxInput === taxInputType.AMOUNT}
                                onChange={() => setTaxInput(taxInputType.AMOUNT)} />
                            <label htmlFor='amount' className="uk-form-label">Amount</label>
                        </div>
                    </Column>
                    {isTax &&
                        <>
                            <FormInputWrapper label="Sales Tax Rate %">
                                <input className="uk-input" type="text" {...register("sales_tax_rate")}
                                    readOnly={taxInput !== taxInputType.PERCENTAGE} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Sales Tax (vessel) *">
                                <input className="uk-input" type="text" {...register("sales_tax_vessel")}
                                    onBlur={() => handleBlur("sales_tax_vessel")}
                                    onFocus={() => handleFocus("sales_tax_vessel")}
                                    readOnly={taxInput !== taxInputType.AMOUNT} />
                            </FormInputWrapper>
                        </>
                    }
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Sales Tax (additional units)">
                        <input className="uk-input" type="text"
                            {...register("sales_tax_additional_units")}
                            onBlur={() => handleBlur("sales_tax_additional_units")}
                            onFocus={() => handleFocus("sales_tax_additional_units")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="County & Other Taxes">
                        <input className="uk-input" type="text"
                            {...register("county_other_taxes")}
                            onBlur={() => handleBlur("county_other_taxes")}
                            onFocus={() => handleFocus("county_other_taxes")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Registration Fees">
                        <input className="uk-input" type="text" {...register("registration_fees")}
                            onBlur={() => handleBlur("registration_fees")}
                            onFocus={() => handleFocus("registration_fees")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Documentation Fees">
                        <input className="uk-input" type="text" {...register("documentation_fees")}
                            onBlur={() => handleBlur("documentation_fees")}
                            onFocus={() => handleFocus("documentation_fees")} />
                    </FormInputWrapper>
                    <FormInputWrapper label={<>Processing & Other Fees <span className="font-italic">(Brokerage settings)</span></>}>
                        <input className="uk-input" type="text" {...register("processing_other_fees")}
                            onBlur={() => handleBlur("processing_other_fees")}
                            onFocus={() => handleFocus("processing_other_fees")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Sub-Total *">
                        <input className="uk-input" type="text" {...register("sub_total")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Trade-In Debt">
                        <input className="uk-input" type="text" {...register("trade_in_debt")}
                            onBlur={() => handleBlur("trade_in_debt")}
                            onFocus={() => handleFocus("trade_in_debt")} />
                    </FormInputWrapper>
                    <FormInputWrapper label="Expense Total">
                        <input className="uk-input" type="text" {...register("expense_total")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Total Amount Due">
                        <input className="uk-input" type="text" {...register("total_amount_due")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Credit Total">
                        <input className="uk-input" type="text" {...register("credit_total")} readOnly />
                    </FormInputWrapper>
                    <FormInputWrapper label="Deposit Total">
                        <input className="uk-input" type="text" {...register("deposit_total")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Finance Amount">
                        <input className="uk-input" type="text" {...register("finance_amount")}
                            onBlur={() => handleBlur("finance_amount")}
                            onFocus={() => handleFocus("finance_amount")} />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <FormInputWrapper label="Balance Due *">
                        <input className="uk-input" type="text" {...register("balance_due")} readOnly />
                    </FormInputWrapper>
                </FormRow>
                <FormRow>
                    <div className="text-right pt-3">
                        <button type="button" className='uk-button uk-button-orange' onClick={() => closePanel(true)}>Close</button>
                        <button type="submit" className='uk-button uk-button-primary'>Save</button>
                    </div>
                </FormRow>
            </Form>
        </div>
    )
}

export default TabFormTransactionDetails;