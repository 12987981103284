import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setLoadingStatus } from "../../redux/actions/loading";
import TemplatesService from "../../services/TemplatesService";
import Auth from "../../utils/auth";
import FormInputWrapper from "../common/FormInputWapper";

export default function AuthorizedDocumentsModal({
  isModalOpen = false,
  setIsModalOpen = () => { },
  modalType = "listing_agreement",
  LADocsList = [],
  setLADocsList = () => { },
  DealDocsList = [],
  setDealDocsList = () => { },
  userId = 0,
}) {
  const dispatch = useDispatch();
  const [docsList, setDocsList] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);

  const user = Auth.getInstance().getUserData();
  const companyId = user.company_id;

  const taskTypeMapping = {
    listing_agreement: "Listing Agreement",
    deal: "Deal",
  };

  const dropdownOption = docsList
    .map((doc) => ({
      id: doc?.template?.id,
      name: doc?.template?.template_name,
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const handleGetDocumentsList = async (type) => {
    dispatch(setLoadingStatus(true));
    const taskType =
      taskTypeMapping[type] === "Listing Agreement"
        ? "Listing"
        : taskTypeMapping[type];
    setSelectedDocs(() => type === "listing_agreement" ? [...LADocsList] : [...DealDocsList]);
    await TemplatesService.getCompanyTemplate(companyId, taskType, "", "")
      .then((res) => {
        const filteredList = res.data.templates?.filter((list) => {
          return list?.use;
        });
        setDocsList(filteredList);
        dispatch(setLoadingStatus(false));
      })
      .catch((err) => {
        console.error("Error fetching documents:", err);
        dispatch(setLoadingStatus(false));
      });
  };

  const handleSelectDocs = (newDocs) => {
    setSelectedDocs(newDocs);
  };

  const handleUpdateModal = () => {
    if (modalType === "listing_agreement") {
      setLADocsList(selectedDocs);
    } else {
      setDealDocsList(selectedDocs);
    }
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen && modalType) {
      handleGetDocumentsList(modalType);
    }
  }, [isModalOpen, modalType]);

  return (
    <Modal
      show={isModalOpen}
      // onHide={() => setIsModalOpen(false)}
      centered
      size="lg"
    >
      <Modal.Header
      // closeButton
      >
        <h3>Select Authorized Documents</h3>
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            className="small uk-button uk-button-orange"
            type="button"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="ml-2 small uk-button uk-button-primary"
            type="button"
            onClick={handleUpdateModal}
          >
            Update
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: "20rem" }}>
          <div className="col-md-12 pt-3 px-0">
            <FormInputWrapper
              label={`Select ${taskTypeMapping[modalType]} Document Allowed`}
              width="sm-full-width"
            >
              <Multiselect
                options={dropdownOption}
                displayValue="name"
                showCheckbox="true"
                selectedValues={
                  modalType === "listing_agreement" ? LADocsList : DealDocsList
                }
                onSelect={handleSelectDocs}
                onRemove={handleSelectDocs}
              />
            </FormInputWrapper>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
