import React, { Fragment, useEffect, useState } from 'react';
import { Divider, Tab, Tabs } from '@mui/material';
import TabFormPayoffInformation from './panel_seller_closing_statement/TabFormPayoffInformation';
import TabFormExpenses from './panel_seller_closing_statement/TabFormExpenses';
import TabFormCredits from './panel_seller_closing_statement/TabFormCredits';
import TabFormDeposits from './panel_seller_closing_statement/TabFormDeposits';
import TabFormDisbursements from './panel_seller_closing_statement/TabFormDisbursements';
import TabFormNotes from './panel_seller_closing_statement/TabFormNotes';
import TabFormTransactionDetails from './panel_seller_closing_statement/TabFormTransactionDetails';
import TabPanel from './TabPanel';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import "./PannelSellerClosing.css"

const PanelFormSellerClosingStatement = ({ closePanel, menuType }) => {

    const [current, setCurrent] = useState('t_details');
    const [asterix, setAsterix] = useState({});

    const dataTransaction = useSelector((state) => state.transactions.transaction);

    const dataDeal = dataTransaction || {};
    const dataSellerClosing = dataDeal.t_seller_closing;
    const dataSellerPayoff = dataDeal.t_seller_payoff;
    const dataDetails = dataDeal.t_details || [];

    useEffect(() => {
        if (dataSellerClosing) {
            const { gross_sales_commission } = dataSellerClosing;
            if (gross_sales_commission || gross_sales_commission == 0) {
                setAsterix((prev) => ({ ...prev, detail: "fulfill" }));
            } else {
                setAsterix((prev) => ({ ...prev, detail: "empty" }));
            }
        } else {
            setAsterix((prev) => ({ ...prev, detail: "empty" }));
        }
        if (dataSellerPayoff) {
            setAsterix((prev) => ({ ...prev, payoff: "fulfill" }));
        } else {
            setAsterix((prev) => ({ ...prev, payoff: "empty" }));
        }
        if (dataDetails.filter((d) => d.type === "expenses" && d.document_type === "seller").length > 0) {
            setAsterix((prev) => ({ ...prev, expense: "fulfill" }));
        } else {
            setAsterix((prev) => ({ ...prev, expense: "empty" }));
        }
        if (dataDetails.filter((d) => d.type === "credits" && d.document_type === "seller").length > 0) {
            setAsterix((prev) => ({ ...prev, credit: "fulfill" }));
        } else {
            setAsterix((prev) => ({ ...prev, credit: "empty" }));
        }
        if (dataDetails.filter((d) => d.type === "deposits" && d.document_type === "seller").length > 0) {
            setAsterix((prev) => ({ ...prev, deposit: "fulfill" }));
        } else {
            setAsterix((prev) => ({ ...prev, deposit: "empty" }));
        }
        if (dataDetails.filter((d) => d.type === "disbursements" && d.document_type === "seller").length > 0) {
            setAsterix((prev) => ({ ...prev, disbursement: "fulfill" }));
        } else {
            setAsterix((prev) => ({ ...prev, disbursement: "empty" }));
        }
        if (dataDetails.filter((d) => d.type === "notes" && d.document_type === "seller").length > 0) {
            setAsterix((prev) => ({ ...prev, note: "fulfill" }));
        } else {
            setAsterix((prev) => ({ ...prev, note: "empty" }));
        }
    }, [dataTransaction]);

    const handleChange = (event, newValue) => {
        setCurrent(newValue);
    };

    const renderValidationCircle = (formType) => {
        return <i style={{ fontSize: '16px' }}
            className={clsx(
                "pr-2 fa ",
                { "fa-check-circle stepbar": asterix[formType] === "fulfill" },
                { "fa-circle-o": asterix[formType] === "empty" || !asterix[formType] },
                { "fa-circle": asterix[formType] === "notFulfill" }
            )}
        ></i>
    }

    return (
        <Fragment>
            <Tabs value={current} onChange={handleChange} indicatorColor="primary" textColor="primary" className='tab-wrapper'>
                <Tab icon={renderValidationCircle('payoff')} iconPosition='start' value="payoff" style={{ padding: '10px', fontSize: '12px' }} label="Payoff Information" />
                <Tab icon={renderValidationCircle('expense')} iconPosition='start' value="expenses" style={{ padding: '10px', fontSize: '12px' }} label="Expenses" />
                <Tab icon={renderValidationCircle('credit')} iconPosition='start' value="credits" style={{ padding: '10px', fontSize: '12px' }} label="Credits" />
                <Tab icon={renderValidationCircle('deposit')} iconPosition='start' value="deposits" style={{ padding: '10px', fontSize: '12px' }} label="Deposits" />
                <Tab icon={renderValidationCircle('disbursement')} iconPosition='start' value="disbursements" style={{ padding: '10px', fontSize: '12px' }} label="Disbursements" />
                <Tab icon={renderValidationCircle('note')} iconPosition='start' value="notes" style={{ padding: '10px', fontSize: '12px' }} label="Notes" />
                <Tab icon={renderValidationCircle('detail')} iconPosition='start' value="t_details" style={{ padding: '10px', fontSize: '12px' }} label="Transaction Details" />
            </Tabs>
            <Divider />
            <Fragment>
                <TabPanel value={current} index={'payoff'}>
                    <TabFormPayoffInformation closePanel={closePanel} />
                </TabPanel>
                <TabPanel value={current} index={'expenses'}>
                    <TabFormExpenses closePanel={closePanel} />
                </TabPanel>
                <TabPanel value={current} index={'credits'}>
                    <TabFormCredits closePanel={closePanel} />
                </TabPanel>
                <TabPanel value={current} index={'deposits'}>
                    <TabFormDeposits closePanel={closePanel} />
                </TabPanel>
                <TabPanel value={current} index={'disbursements'}>
                    <TabFormDisbursements closePanel={closePanel} />
                </TabPanel>
                <TabPanel value={current} index={'notes'}>
                    <TabFormNotes closePanel={closePanel} />
                </TabPanel>
                <TabPanel value={current} index={'t_details'}>
                    <TabFormTransactionDetails closePanel={closePanel} menuType={menuType} />
                </TabPanel>
            </Fragment>
        </Fragment>
    )
}

export default PanelFormSellerClosingStatement;