import { useEffect } from "react";

export default function PdfViewerComponent({ document, onSubmitUpdatedPDF, current }) {

    const handleMessage = (event) => {
        const payload = (() => {
            try {
                return typeof event.data == "string" ? JSON.parse(event.data) : null;
            } catch (e) {
                console.error("Failed to parse Iframe event payload", e);
                return null;
            }
        })();

        switch (payload?.type) {
            case "DOCUMENT_LOADED":
                const documentId = payload.data?.document_id;
                return;

            case "SUBMISSION_SENT":
                const documentData = payload.data;
                onSubmitUpdatedPDF(documentData);
                return;

            default:
                return;
        }
    }

    useEffect(() => {
        window.addEventListener('message', handleMessage, false);
    }, [])

    const context = { customerId: '123', environment: 'production' };
    const encodedContext = encodeURIComponent(btoa(JSON.stringify(context)));

    return (
        <>
            <iframe key={current} className="pdfEditor" id="PDFEditor" width="100%" height="100%" src={`https://boatdox.simplepdf.eu/editor?open=${encodeURIComponent(document)}&context=${encodedContext}`} >
            </iframe>
        </>
    )
}