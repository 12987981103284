import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormRow from "../../../../common/FormRow";

import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { eSignCoBroker, getTransactionEdocById, updateRequiredFields } from "../../../../../redux/actions/transaction";
import Auth from "../../../../../utils/auth";
import { convertFirstCharIntoSmallCase, isSigned, unauthorizedErrorToast } from "../../../../../utils/functions";
import { RECORD_OWNER } from "../../../../../utils/signer";
import FormInputWrapper from "../../../../common/FormInputWapper";
import HandSignUploadModal from "../../../../common/HandSignUploadModal";
import SignatureMessageModal from "../../../../common/SignatureMessageModal";
import { ViewDocumentsModal } from "../../../../common/ViewDocumentsModal";
import CustomActionButtons from "./CustomActionButtons";
import CustomRenderStatus from "./CustomRenderStatus";

const useStyles = makeStyles({
  previewSendDiv: {
    width: "250px !important"
  },
  handSignedDiv: {
    marginTop: "-4px"
  }
});

/**
 * @param {Function} updateDocumentObj // It is used to update payload object
 * @param {String} selectedRole //It is used to check which role is selected from signature tab
 * @param {Object} payload //Prepared object for the back-end
 * @param {Function} makeDropDownSigners // Send signers array to the signature tab for displaying signer drop-down
 * @returns 
 */
const EsignCoBroker = ({ transactionId, updateDocumentObj, makeDropDownSigners, selectedRole, payload, roles, menuType }) => {

  const user = Auth.getInstance().getUserData();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [showAdditionalModal, setShowAdditionalModal] = useState(false);
  const [emailDetailsToSend, setEmailDetailsToSend] = useState(null);
  const [showHandsignModal, setShowHandsignModal] = useState(false);
  const [handSignDetails, setHandSignDetails] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const [document, setDocument] = useState(null);
  const loginEmail = Auth.getInstance().getUserData().email;

  const deals = useSelector((state) => state.transactions);

  const dataTransaction = deals.transaction || {};
  const dataCoBroker = dataTransaction && dataTransaction?.co_broker || null;
  const dataVessel = deals.transaction ? deals.transaction.t_vessel : null;
  const required = useSelector((state) => state.transactions.requiredFields);

  const eDoc = dataTransaction && dataTransaction.t_edocuments
    ? dataTransaction.t_edocuments.find(doc => doc.task_type === "co-broker-commission-agreement")
    : null;

  const signers = eDoc && eDoc.request_signature && eDoc.request_signature.signers ? eDoc.request_signature.signers : [];

  //Define links for this page
  const links = [
    { label: "Deals", link: `/deals/view/${transactionId}/main` },
    { label: "Co-Broker Commission Agreement", link: "" },
  ];

  useEffect(() => {
    let coBrokerRequireds = [];

    const sellingCoBrokerFields = {
      selling_broker_email: "Selling Broker Email",
      selling_brokerage_broker: "Selling Broker Name",
      selling_broker_phone_number: "Selling Broker Phone Number",
    }

    const listingCoBrokerFields = {
      listing_broker_email: "Listing Broker Email",
      listing_brokerage_broker: "Listing Broker Name",
      listing_broker_phone_number: "Listing Broker Phone Number",
    }

    if (dataCoBroker?.selling_broker_email && dataCoBroker?.selling_brokerage_broker) {
      Object.keys(sellingCoBrokerFields).forEach(key => {
        if (!dataCoBroker[key]) {
          coBrokerRequireds.push({ [key]: sellingCoBrokerFields[key] });
        }
      });
    }

    if (dataCoBroker?.listing_broker_email && dataCoBroker?.listing_brokerage_broker) {
      Object.keys(listingCoBrokerFields).forEach(key => {
        if (!dataCoBroker[key]) {
          coBrokerRequireds.push({ [key]: listingCoBrokerFields[key] });
        }
      });
    }

    dispatch(
      updateRequiredFields({
        co_broker: coBrokerRequireds,
      })
    );
  }, [dataCoBroker])

  /**
   * useEffect to generate the array of signers which is available 
   * for this document only
  */
  useEffect(() => {
    if (!updateDocumentObj || !isValidPreviewOrSend()) {
      return;
    }

    let signerArr = [];

    if ((dataCoBroker &&
      ((dataCoBroker?.listing_broker_email && dataCoBroker?.listing_brokerage_broker))) && !isSigned(signers, "listing_broker")) {
      signerArr.push("listing_broker");
    }

    if ((dataCoBroker &&
      (dataCoBroker?.selling_broker_email && dataCoBroker?.selling_brokerage_broker)) && !isSigned(signers, "selling_broker")) {
      signerArr.push("selling_broker");
    }

    makeDropDownSigners(signerArr);
  }, [dataTransaction, signers.length])

  //Apply use Effect when role is changed from the signature tab
  useEffect(() => {
    /**
     * Check selected role is included for this document &
     * Check the selected role's status is not equal to signed &
     * Check this document has proper data for the sign this document.
    */
    if (selectedRole && roles.includes(selectedRole)
      && (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === selectedRole)?.status != "Signed")
      && dataTransaction && isValidPreviewOrSend()) {

      switch (selectedRole) {
        case "listing_broker":
          if ((dataCoBroker &&
            (dataCoBroker?.listing_broker_email == '' && dataCoBroker?.listing_brokerage_broker == ''))) {
            return;
          }
          break;
        case "selling_broker":
          if ((dataCoBroker &&
            (dataCoBroker?.selling_broker_email == '' && dataCoBroker?.selling_brokerage_broker == ''))) {
            return;
          }
          break;
        default:
          break;
      }

      //Prepare object for the payload
      let obj = {}
      obj[selectedRole] = {
        document_name: "co-broker-commission-agreement",
        id: dataCoBroker?.id
      };

      //Update payload object
      updateDocumentObj(obj, selectedRole);
      return;
    }
  }, [selectedRole])

  let vesselName = `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}` !== "'   "
    ? `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}`
    : "N/A";

  /**
   * @param {Object} evt // Event Object
   * @param {String} role
  */
  const previewByStep = (evt, role) => {
    dispatch(setLoadingStatus(true));
    dispatch(eSignCoBroker(dataCoBroker?.id, "preview", role))
      .then((res) => {
        dispatch(setLoadingStatus(false));
        if (res.status !== 200) {
          throw res;
        } else {
          // CloudFront URL Always unique on Click of preview
          // window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
          window.open(res.data.url, '_blank');
        }
      })
      .catch((err) => {
        dispatch(setLoadingStatus(false));
        switch (err.status) {
          case 400:
            setMessage(`${err.data.error.message}`);
            break;
          case 401:
            toast.error("You are not authorized to access this action.")
            break;
          default:
            setMessage(`${err.data.message}`);
            break;
        }
      });
  }

  /**
   * @param {Object} evt // Event Object
   * @param {String} mode //It can be "send" or "hand-signed"
   * @param {*} role 
   * @param {*} data // selected date for the hand signed
  */
  const sendByStep = (mode, role, data = null) => {
    let obj = {};
    if (data) {
      if (mode == 'signed') {
        obj["signed_date"] = data.signed_date;
        obj["signed_document"] = JSON.stringify(data.documents);
      } else {
        obj["additional_email_content"] = data.additional_email_content || '';
      }
      obj["selected_recipients"] = data.selectedUser
      obj["sign_url_access_days"] = data.sign_url_access_days;
    }
    obj["is_email_send"] = data.is_email_send ? 'yes' : 'no';
    obj["requested_by"] = user.id;
    dispatch(setLoadingStatus(true));
    dispatch(eSignCoBroker(dataCoBroker?.id, mode, role, obj)).then(res => {
      dispatch(setLoadingStatus(false));
      if (res.status === 200) {
        dispatch(getTransactionEdocById(deals.transaction.id, menuType === 'invitation', dataTransaction?.company_id));
        if (data.signed_date) {
          setMessage("Signed Date saved successfully");
          toast.success(`Hand signed documents uploaded successfully.`);
        } else {
          if (!data.is_email_send) {
            setShowAdditionalModal(false);
            toast.success('E-Sign Link generated successfully.');
          } else {
            setMessage("Document sent for eSignature successfully.");
          }
        }
      } else {
        switch (res.status) {
          case 400:
            setMessage(`${res.data.error.message}`);
            break;
          case 401:
            unauthorizedErrorToast()
            break;
          default:
            toast.error(`Something went wrong while upload hand signed documents.`);
            setMessage(`${res.data.message}`);
            break;
        }
      }
    });
  }

  const onAdditionalMessageModalOpen = (mode, role, data = null) => {
    const selectedRecipients = eDoc.request_signature?.signers?.find((signer) => signer.request_signer?.role === role) || {};
    setEmailDetailsToSend({ mode: mode, role: role, data: data, selectedRecipients: selectedRecipients.selected_recipients || [] });
    setShowAdditionalModal(true);
  }

  const onCloseAdditionalEmailModal = (isEmailSend, emailAdditionalContent = null) => {
    setShowAdditionalModal(false);
    if (isEmailSend) {
      sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, { ...emailAdditionalContent.data, is_email_send: true, selectedUser: emailAdditionalContent.data.selected_recipients })
    }
  }

  const onGenerateEsignatureLink = (emailAdditionalContent) => {
    const signatureLink = signers.find(signer => signer.request_signer.role === emailAdditionalContent.role)?.doc_signer_link || '';
    // if (signatureLink) {
    //   setShowAdditionalModal(false);
    //   toast.success('E-Sign link generated successfully.');
    // } else {
      sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, { ...emailAdditionalContent.data, is_email_send: false, selectedUser: emailAdditionalContent.data.selected_recipients });
    // }
  }

  /**
   * On open Hand Sign document selection modal after selecting date
   * @param {*} role 
   * @param {*} signDate 
  */
  const onHandSignUploadModalOpen = (role, signDate) => {
    const selectedRecipients = eDoc.request_signature?.signers?.find((signer) => signer.request_signer?.role === role) || {};
    const client = role.includes('Employing')
      ? dataTransaction?.employing_broker
      : role.includes('Listing') || role.includes('Selling')
        ? dataCoBroker
        : dataTransaction[role.toLowerCase().replace('-', '_')];
    setHandSignDetails({
      documentName: eDoc.t_esign_template?.template?.template_name,
      signDate: signDate,
      role: role,
      client: client,
      selectedRecipients: selectedRecipients.selected_recipients || []
    });
    if (signDate && signDate != null) {
      setShowHandsignModal(true);
    }
  }

  /**
   * On close Hand Sign modal and submit uploaded document to sendByStep() function
   * @param {*} isUploaded 
   * @param {*} documentUploadFiles 
  */
  const onCloseHandsignModal = (isUploaded, documentUploadFiles = null, selectedUser = []) => {
    setShowHandsignModal(false);
    if (isUploaded) {
      sendByStep("signed", handSignDetails.role, { signed_date: handSignDetails.signDate, documents: documentUploadFiles, selectedUser: selectedUser })
    }
    setHandSignDetails(null);
  }

  /**
   * On open view hand signed uploaded modal
   * @param {*} document 
  */
  const handleOpenViewDocumentModal = (document) => {
    setDocument(document);
    setShowDocument(true);
  }

  /**
   * On close view hand signed uploaded modal
  */
  const handleCloseModal = () => {
    setDocument(null);
    setShowDocument(false);
  }

  /**
   * Check this document is valid for preview or not
   * @returns True or False
  */
  const isValidPreviewOrSend = () => {
    let valid = !deals.requiredFields.buyer.some(obj => Object.keys(obj).includes('email')) &&
      !deals.requiredFields.seller.some(obj => Object.keys(obj).includes('email'));

    if (dataTransaction.co_buyer_contact_id) {
      valid = valid && !deals.requiredFields.co_buyer.some(obj => Object.keys(obj).includes('email'))
    }

    if (dataTransaction.co_seller_contact_id) {
      valid = valid && !deals.requiredFields.co_seller.some(obj => Object.keys(obj).includes('email'))
    }

    return valid;
  };



  const handleSignedDate = (role, signedDate) => {
    if (signedDate) {
      return moment(signedDate ? signedDate : null).utc().format("MM-DD-yyyy")
    }

    if (!signedDate) {
      if (eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.status === 'Signed') {
        const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
        return `${moment(eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.esigned_date || null).local().format("MM-DD-yyyy hh:mm A")} ${timezone}`;
      }
    }
    return null;
  }

  /**
   * Provides the eSign link for email send for signature
   * @param {*} role 
   * @returns 
  */
  const getEsignLink = (role) => {
    return signers.find(signer => signer.request_signer.role === role)?.doc_signer_link || '';
  }

  const [tooltipText, setTooltipText] = useState('Copy E-Sign Link');

  /**
   * Renders the button for eSign Link according to role of client
   * @param {*} role 
   * @returns Button for Link
   */
  const renderLinkButtons = (role) => {
    if (role.includes('broker') || role.includes('Broker')) {
      return <button style={{ height: '26px' }}
        className="uk-button uk-button-primary"
        onClick={() => { window.open(getEsignLink(role), '_blank'); }}>
        Sign </button>
    }

    return <Tooltip title={tooltipText} arrow>
      <button style={{ height: '26px', padding: '0 4px 0' }}
        className="uk-button uk-button-primary"
        onClick={() => {
          navigator.clipboard.writeText(getEsignLink(role));
          setTooltipText('E-Sign Link Copied..')
          setTimeout(() => {
            setTooltipText('Copy E-Sign Link');
          }, 800);
        }}>Copy E-Sign Link </button>
    </Tooltip>
  }

  /**
   * This function is used to change the payload 
   * as per user manually checks the checkbox of particular checkbox
   * @param {Object} e //Event Object 
   * @param {String} role 
   * @returns 
  */
  const checkBoxandler = (e, role) => {
    let obj = {};
    obj[role] = {
      document_name: "co-broker-commission-agreement",
      id: dataCoBroker?.id
    };
    updateDocumentObj(obj, role, !e.target.checked, true);
  }

  const renderAction = (role) => {
    return (
      <CustomActionButtons
        role={role}
        signers={signers}
        isValidSend={isValidPreviewOrSend}
        handleOpenViewDocumentModal={handleOpenViewDocumentModal}
        previewByStep={previewByStep}
        onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
        onHandSignUploadModalOpen={onHandSignUploadModalOpen}
        dataTransaction={dataTransaction}
        deals={deals}
        menuType={menuType}
      />
    )
  }

  const renderStatus = (role) => {
    return (<CustomRenderStatus
      role={role}
      signedDate={signers.find(signer => signer.request_signer.role === role)?.signed_date}
      signers={signers}
      isValidSend={isValidPreviewOrSend}
      handleOpenViewDocumentModal={handleOpenViewDocumentModal}
      previewByStep={previewByStep}
      onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
      onHandSignUploadModalOpen={onHandSignUploadModalOpen}
      dataTransaction={dataTransaction}
      deals={deals}
      eDoc={eDoc}
      getEsignLink={getEsignLink}
      renderLinkButtons={renderLinkButtons}
      handleSignedDate={handleSignedDate}
    />
    )
  }

  /**
   * Function that used for Display Broker Name and Email Address as per the status 
   * If status is not Sent or Signed then display current Broker data otherwise display Signer data instead
   * @param {*} brokerType 
   * @returns Broker Name and Email Address 
  */
  const getBrokerNameAndEmail = (brokerType) => {
    return !["Sent", "Signed"].includes(signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `${brokerType}_broker`)?.status)
      ? { name: dataCoBroker[`${brokerType}_brokerage_broker`], email: dataCoBroker[`${brokerType}_broker_email`] }
      : {
        name: (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `${brokerType}_broker`))?.request_signer?.name,
        email: (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `${brokerType}_broker`))?.request_signer?.email_address
      }
  }

  return (
    <>
      <div className="uk-container uk-container-small uk-position-relative m-0">
        <div className="scroll-div">
          <div className="form-container scroll-fix-div">
            <div className="esign-send listing-agreement">
              <h4>
                <b>{eDoc?.t_esign_template?.template?.template_name}</b>
              </h4>
              <FormRow><hr /></FormRow>

              <FormRow>
                <FormInputWrapper label="Vessel">{vesselName}</FormInputWrapper>
              </FormRow>
              <FormRow><hr /></FormRow>

              {dataCoBroker && (dataCoBroker?.selling_broker_email && dataCoBroker?.selling_brokerage_broker) ?
                // menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail ? <></> : 
                <>
                  <div className="client-row">
                    <FormInputWrapper label="Selling Broker" className="signer-name-block">{getBrokerNameAndEmail('selling').name || "N/A"}</FormInputWrapper>
                    <FormInputWrapper label="Selling Broker's Email" className="signer-email-block">{getBrokerNameAndEmail('selling').email || "N/A"}</FormInputWrapper>
                    {eDoc &&
                      <div className={!updateDocumentObj && "signers_div"}>
                        {signers && (
                          signers.find(signer => signer.request_signer.role === "Selling-Broker")?.status
                            ? renderStatus("Selling-Broker")
                            : renderAction("Selling-Broker")
                        )}
                      </div>
                    }
                    {eDoc && updateDocumentObj && !isSigned(signers, "Selling-Broker") &&
                      <div className="col-1">
                        <input
                          type="checkbox"
                          className="signatures_checkbox"
                          onChange={(e) => { checkBoxandler(e, "selling_broker"); }}
                          checked={payload?.selling_broker?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                          disabled={
                            (!dataTransaction || !isValidPreviewOrSend()) || selectedRole && selectedRole != "selling_broker" || (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail)
                          }
                        />
                      </div>
                    }
                  </div>
                  <FormRow><hr /></FormRow>
                </>
                : <></>
              }

              {dataCoBroker && (dataCoBroker?.listing_broker_email && dataCoBroker?.listing_brokerage_broker) ?
                // menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail ? <></>: 
                <>
                  <div className="client-row">
                    <FormInputWrapper label="Listing Broker" className="signer-name-block">{getBrokerNameAndEmail('listing').name || "N/A"}</FormInputWrapper>
                    <FormInputWrapper label="Listing Broker's Email" className="signer-email-block">{getBrokerNameAndEmail('listing').name || "N/A"}</FormInputWrapper>
                    {eDoc &&
                      <div className={!updateDocumentObj && "signers_div"}>
                        {signers && (
                          signers.find(signer => signer.request_signer.role === "Listing-Broker")?.status
                            ? renderStatus("Listing-Broker")
                            : renderAction("Listing-Broker")
                        )}
                      </div>
                    }
                    {eDoc && updateDocumentObj && !isSigned(signers, "Listing-Broker") &&
                      <div className="col-1">
                        <input
                          type="checkbox"
                          className="signatures_checkbox"
                          onChange={(e) => { checkBoxandler(e, "listing_broker"); }}
                          checked={payload?.listing_broker?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                          disabled={
                            (!dataTransaction || !isValidPreviewOrSend()) || selectedRole && selectedRole != "listing_broker" || (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail)
                          }
                        />
                      </div>
                    }
                  </div>
                  <FormRow><hr /></FormRow>
                </>
                : <></>
              }

              <br />
              {required && !isValidPreviewOrSend() && (
                <>
                  <p>Fill the required fields to send the document for signature.</p>
                  {/* {required?.vessel?.length !== 0 && (
                <div className="form-row uk-padding-small">
                  <label className="uk-form-label" htmlFor="name">
                    Vessel (Send)
                  </label>
                  <div>
                    {required?.vessel?.map((obj, key) => {
                      return key === required?.vessel?.length - 1
                        ? `${Object.values(obj)[0]}`
                        : `${Object.values(obj)[0]}, `;
                    })}
                  </div>
                </div>
              )} */}
                  {deals.requiredFields.buyer && deals.requiredFields.buyer.some(obj => Object.keys(obj).includes('email')) && (
                    <div className="form-row uk-margin-small">
                      <label className="uk-form-label" htmlFor="name">
                        Buyer (Send)
                      </label>
                      <div>
                        {required?.buyer?.map((obj, key) => {
                          return Object.values(obj)[0] == 'Email'
                            ? `${Object.values(obj)[0]}`
                            : ``;
                        })}
                      </div>
                    </div>
                  )}
                  {dataTransaction?.seller_type !== RECORD_OWNER && deals.requiredFields.seller && deals.requiredFields.seller.some(obj => Object.keys(obj).includes('email')) && (
                    <div className="form-row uk-margin-small">
                      <label className="uk-form-label" htmlFor="name">
                        Seller (Send)
                      </label>
                      <div>
                        {required?.seller?.map((obj, key) => {
                          return Object.values(obj)[0] == 'Email'
                            ? `${Object.values(obj)[0]}`
                            : ``;
                        })}
                      </div>
                    </div>
                  )}
                  {/* {required?.offer?.length !== 0 && (
                <div className="form-row uk-margin-small">
                  <label className="uk-form-label" htmlFor="name">
                    Offer (Send)
                  </label>
                  <div>
                    {required?.offer?.map((obj, key) => {
                      return key === required?.offer?.length - 1
                        ? `${Object.values(obj)[0]}`
                        : `${Object.values(obj)[0]}, `;
                    })}
                  </div>
                </div>
              )} */}
                  {required?.co_broker && deals.requiredFields.co_broker.some(obj => Object.keys(obj).includes('email')) && (
                    <div className="form-row uk-margin-small">
                      <label className="uk-form-label" htmlFor="name">
                        Co-Broker (Send)
                      </label>
                      <div>
                        {required?.co_broker?.map((obj, key) => {
                          return Object.values(obj)[0] == 'Email'
                            ? `${Object.values(obj)[0]}`
                            : ``;
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <br />
      </div>
      <SignatureMessageModal
        show={showAdditionalModal}
        onClose={(isEmailSend, emailAdditionalContent = null) => onCloseAdditionalEmailModal(isEmailSend, emailAdditionalContent)}
        emailDetail={emailDetailsToSend}
        onGenerateLink={onGenerateEsignatureLink} />
      {showHandsignModal && <HandSignUploadModal show={showHandsignModal} onClose={onCloseHandsignModal} signData={handSignDetails} />}
      {showDocument && <ViewDocumentsModal documentTask={document} onClose={handleCloseModal} />}
    </>
  );
};

export default EsignCoBroker;
