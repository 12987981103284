import {
  CREATE_USER,
  RETRIEVE_USERS,
  GET_USER,
  SET_USER,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
  DELETE_USER,
  DELETE_ALL_USERS,
  FIND_USER_BY_NAME,
  FIND_ONE_BY_NAME,
  CLEAR_USER,
  RETRIEVE_USER_ROLES,
  RETRIEVE_USER_RELATED,
  RETRIEVE_USER_OFFICES,
  INVITE_USER,
  RESEND_INVITATION,
  CLEAR_BROKERS,
  RETRIEVE_OFFICE_USERS,
  CLEAR_OFFICE_USERS,
  RETRIEVE_BROKERS,
  UPDATE_VERIFICATION_STATUS,
  UPDATE_PROFILE,
  RETRIEVE_MLS_OFFICES,
  SET_OFFICE_WITH_BROKER,
  ALL_ACCESSIBLE_BROKERS
} from './types';

// import ClientService from '../../services/ClientService';
import UserService from '../../services/UserService';

export const createUser = (client) => async (dispatch) => {
  try {
    const res = await UserService.create(client);

    dispatch({
      type: CREATE_USER,
      payload: res.data
    });
    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}


export const updateProfile = (id, data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PROFILE.pending,
      payload: 'loading'
    });

    const res = await UserService.updateProfile(id, data);

    dispatch({
      type: UPDATE_PROFILE.success,
      payload: res.data
    });

    return res;
  } catch (err) {
    dispatch({
      type: UPDATE_PROFILE.error,
      payload: err.response
    });

    return err.response;
  }
}

export const retrieveUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_USERS.pending,
      payload: 'loading'
    })

    const res = await UserService.getAll();

    dispatch({
      type: RETRIEVE_USERS.success,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: RETRIEVE_USERS.error,
      payload: err
    })
  }
}

export const updateUser = (id, data) => async (dispatch) => {

  return new Promise((resolve, reject) => {
    UserService.update(id, data).then(res => {
      dispatch({
        type: UPDATE_USER.success,
        payload: res.data
      });
      resolve(res);
    }).catch(err => {
      dispatch({
        type: UPDATE_USER.error,
        payload: err
      });
      reject(err);
    });
  });
}

export const updatePassword = (id, user) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_PASSWORD.pending,
    payload: 'loading'
  });

  return new Promise((resolve, reject) => {
    UserService.updatePassword(id, user).then(res => {
      dispatch({
        type: UPDATE_USER_PASSWORD.success,
        payload: res.data
      });
      resolve(res);
    }).catch(err => {
      dispatch({
        type: UPDATE_USER_PASSWORD.error,
        payload: err.response.data.message
      });
      reject(err);
    });
  });
}

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER.pending,
      payload: 'loading'
    });

    const res = await UserService.get(id);

    dispatch({
      type: GET_USER.success,
      payload: res.data
    });

    return res.data;
  } catch (err) {
    dispatch({
      type: GET_USER.error,
      payload: err
    });
    return err;
  }
}

export const getProfile = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER.pending,
      payload: 'loading'
    });

    const res = await UserService.getProfile(id);

    dispatch({
      type: GET_USER.success,
      payload: res.data
    });

    return res.data;
  } catch (err) {
    return Promise.reject(err);
  }
}

export const deleteUser = (id) => async (dispatch) => {
  try {
    await UserService.remove(id);

    dispatch({
      type: DELETE_USER,
      payload: { id }
    });
  } catch (err) {
    console.log(err);
  }
}

export const deleteArchivedUser = (id) => async (dispatch) => {
  try {
    const res = await UserService.removeArchivedUser(id);

    dispatch({
      type: DELETE_USER,
      payload: { id }
    });
    return Promise.resolve(res);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
}

export const deleteAllUsers = () => async (dispatch) => {
  try {
    const res = await UserService.removeAll();

    dispatch({
      type: DELETE_ALL_USERS,
      payload: res.data
    });

    return Promise.resolve(res.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const findUserByName = (name) => async (dispatch) => {
  try {
    dispatch({
      type: FIND_USER_BY_NAME.pending,
      payload: 'loading'
    });

    const res = await UserService.findByName(name);

    dispatch({
      type: FIND_USER_BY_NAME.success,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: FIND_USER_BY_NAME.error,
      payload: err
    });
  }
}

export const findOneByName = (name) => async (dispatch) => {
  try {
    const res = await UserService.findOneByName(name);

    dispatch({
      type: FIND_ONE_BY_NAME,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
}

export const clearUser = () => async (dispatch) => {
  dispatch({
    type: CLEAR_USER
  });
}

export const retrieveRoles = () => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_USER_ROLES.pending,
      payload: 'loading'
    });

    const res = await UserService.fetchRoles();

    dispatch({
      type: RETRIEVE_USER_ROLES.success,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: RETRIEVE_USER_ROLES.error,
      payload: err
    })
  }
}

export const retrieveRelated = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_USER_RELATED.pending,
      payload: 'loading'
    });

    const res = await UserService.fetchRelated(id);

    dispatch({
      type: RETRIEVE_USER_RELATED.success,
      payload: res.data
    });

    return res;
  } catch (err) {
    dispatch({
      type: RETRIEVE_USER_RELATED.error,
      payload: err
    });

    return err;
  }
}

export const retrieveOffices = (companyId) => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_USER_OFFICES.pending,
      payload: 'loading'
    });

    const res = await UserService.fetchOffices(companyId);

    dispatch({
      type: RETRIEVE_USER_OFFICES.success,
      payload: res.data
    });

    return res;
  } catch (err) {
    dispatch({
      type: RETRIEVE_USER_OFFICES.error,
      payload: err
    });
  }
}

export const retrieveMlsOffices = (companyId) => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_MLS_OFFICES.pending,
      payload: 'loading'
    });

    const res = await UserService.retrieveMlsOffices(companyId);

    dispatch({
      type: RETRIEVE_MLS_OFFICES.success,
      payload: res.data
    });

    return res;
  } catch (err) {
    dispatch({
      type: RETRIEVE_MLS_OFFICES.error,
      payload: []
    });
  }
}

export const retrieveBrokers = (companyId, officeId) => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_BROKERS.pending,
      payload: 'loading'
    });

    const res = await UserService.fetchBrokers(companyId, officeId);

    dispatch({
      type: RETRIEVE_BROKERS.success,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: RETRIEVE_BROKERS.error,
      payload: err
    });

    return Promise.resolve(err);
  }
}

export const retrieveOfficeUsers = (officeId, company_id = null) => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_OFFICE_USERS.pending,
      payload: 'loading'
    });

    const res = await UserService.getOfficeUsers(officeId, company_id);

    dispatch({
      type: RETRIEVE_OFFICE_USERS.success,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: RETRIEVE_OFFICE_USERS.error,
      payload: err
    });

    return Promise.resolve(err);
  }
}

export const retrieveCompanyUsers = (company_id) => async (dispatch) => {
  try {
    dispatch({
      type: RETRIEVE_OFFICE_USERS.pending,
      payload: 'loading'
    });

    const res = await UserService.getCompanyUsers(company_id);

    dispatch({
      type: RETRIEVE_OFFICE_USERS.success,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: RETRIEVE_OFFICE_USERS.error,
      payload: err
    });

    return Promise.resolve(err);
  }
}

export const removeBrockers = () => (dispatch) => {
  dispatch({
    type: CLEAR_BROKERS
  })
}

export const removeOfficeUsers = () => (dispatch) => {
  dispatch({
    type: CLEAR_OFFICE_USERS
  })
}

export const inviteUser = (user) => async (dispatch) => {
  try {
    dispatch({
      type: INVITE_USER.pending,
      payload: 'loading'
    });

    const res = await UserService.inviteUser(user);

    dispatch({
      type: INVITE_USER.success,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: INVITE_USER.error,
      payload: err
    });
    return Promise.reject(err.response);
  }
}

export const resendInvitation = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESEND_INVITATION.pending,
      payload: 'loading'
    });

    const res = await UserService.resendInvitation(id);

    dispatch({
      type: RESEND_INVITATION.success,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: RESEND_INVITATION.error,
      payload: err
    });

    return Promise.reject(err.response);
  }
}

export const setAWSUser = (user) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER,
      payload: user
    });
  } catch (err) {
    console.log('Errors:', err);
  }
}

export const sendEmailVerification = (token) => async (dispatch) => {
  try {
    const res = await UserService.sendEmailVerification(token);

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const verifyEmailVerificationCode = (token, code, config) => async (dispatch) => {
  try {
    const res = await UserService.verifyEmailVerificationCode(token, code, config);

    dispatch({
      type: UPDATE_VERIFICATION_STATUS,
      payload: true
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const findOfficeWithBrokers = (companyId, editedUserId = null) => async (dispatch) => {
  try {
    const res = await UserService.fetchAllOfficesWithBrokers(companyId, editedUserId);

    dispatch({
      type: SET_OFFICE_WITH_BROKER,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

export const fetchAllAccessibleBroker = (userId) => async (dispatch) => {
  try {

    const res = await UserService.fetchAllAccessibleBrokers(userId);

    dispatch({
      type: ALL_ACCESSIBLE_BROKERS,
      payload: res.data
    });

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}