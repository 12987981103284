import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import Country from "../../../../../constants/Country";
import StatesForUSA from "../../../../../constants/StatesForUSA";
import AutoCompleteSearch from '../../../../common/Autocomplete';
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { CLIENT_REQUIRED_FIELDS } from '../../../../../utils/signer';
import { getFormattedData, getPlainTransactionData } from '../../../../../utils/stepFilter';
import { clearClient, deleteClient, findClientByName, findOneByName, getClient, getTransactionClient } from '../../../../../redux/actions/clients';
import { getTransactionInitialById, updateTransaction, upsertTransaction } from '../../../../../redux/actions/transaction';

import Auth from '../../../../../utils/auth';
import clsx from 'clsx';
import * as yup from "yup";
import PhoneInput from 'react-phone-input-2';
import { Checkbox, FormControlLabel } from '@mui/material';

const useStyles = makeStyles({
    divider: {
        marginBottom: "15px",
    },
    phone_input: {
        padding: '12px 12px 12px 50px !important',
        width: '100% !important'
    },
    phoneInputDiv: {
        padding: "8px",
        marginLeft: "-7px",
        marginRight: "7px",
        marginTop: "-7px"
    }
});

const PanelFormCoSeller = ({ closePanel, menuType }) => {

    const schema = yup.object().shape({

    });
    const { register, handleSubmit, setValue, getValues, reset, watch } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: false,
    });

    const dispatch = useDispatch();

    const classes = useStyles();
    const logged_user = Auth.getInstance().getUserData();

    const [isSelectedUSA, setSelectedUSA] = useState(true);
    const [searchName, setSearchName] = useState(null);
    const [isSameAddress, setIsSameAddress] = useState(false);
    const [isCoSeller, setIsCoSeller] = useState(true);
    const [isEditCoSeller, setIsEditCoSeller] = useState(false);
    const [requiredFields, setRequiredFields] = useState(CLIENT_REQUIRED_FIELDS);

    /* Get Datas from Store State */
    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
    const dataClient = useSelector((state) => state.clients.client);
    const dataClients = useSelector((state) => state.clients.list);
    const [isHideFromOtherBroker, setIsHideFromOtherBroker] = useState(false);

    const dataDeal = dataTransaction || {};
    const dataSeller = dataDeal.seller || {};

    const optionsForAutocomplete = dataClients
        .map((client) => {
            return { id: client.id, value: `${client.first_name} ${client.last_name}` };
        })
        .sort((prev, next) => prev.value.localeCompare(next.value))
        .reduce((newArray, cur) => newArray.length && newArray.at(-1).value === cur.value ? newArray : [...newArray, cur], []);

    useEffect(() => {
        if (dataDeal && dataDeal.co_seller_contact_id) {
            dispatch(getTransactionClient(dataDeal.co_seller_contact_id));
            setIsCoSeller(true);
            setIsEditCoSeller(true);
        } else {
            setValue("country", "");
            setSelectedUSA(true);
            setIsEditCoSeller(false);
        }
    }, []);

    useEffect(() => {
        dispatch(findClientByName());
        return () => {
            dispatch(clearClient());
        };
    }, []);

    useEffect(() => {
        if (searchName) {
            dispatch(findOneByName(searchName));
        } else {
            setValue("country", "");
            setSelectedUSA(true);
        }
    }, [searchName]);

    useEffect(() => {
        if (dataClient) {
            Object.keys(dataClient).map((key) => setValue(key, dataClient[key]));
            requiredFields.map((value) => {
                manageRequireFields(value, getValues(value));
            });
        } else {
            reset({});
            setValue("address_1", null);
            setValue("country", "");
            setSelectedUSA(true);
        }
    }, [dataClient]);

    useEffect(() => {
        setIsSameAddress(dataDeal?.is_seller_same_address || false);
        setIsHideFromOtherBroker(dataDeal.is_co_seller_hide_by_invited_broker || false);
    }, [dataDeal]);

    useEffect(() => {
        if (isSameAddress) {
            if (dataSeller.country === "USA") {
                setSelectedUSA(true);
            }
            setValue("address_1", dataSeller.address_1);
            setValue("address_2", dataSeller.address_2);
            setValue("address_3", dataSeller.address_3);
            setValue("country", dataSeller.country);
            setValue("state", dataSeller.state);
            setValue("city", dataSeller.city);
            setValue("postal_code", dataSeller.postal_code);
        } else {
            setValue("address_1", null);
            setValue("address_2", null);
            setValue("address_3", null);
            setValue("country", "");
            setValue("state", null);
            setValue("city", null);
            setValue("postal_code", null);
        }
    }, [isSameAddress]);

    useEffect(() => {
        setSelectedUSA(getValues("country") === "USA" ? true : false);
    }, [watch("country")]);

    const handleRegistration = (coseller) => {
        dispatch(setLoadingStatus(true));
        if (!Object.values(coseller)?.filter((e) => e)?.length) {
            removeCoSeller();
            closePanel(true);
            dispatch(setLoadingStatus(false));
            return
        }
        coseller = getFormattedData(coseller);

        if (dataDeal && dataDeal.co_seller_contact_id) {
            coseller.id = dataDeal.co_seller_contact_id;
        }

        if (!isEditCoSeller) {
            delete coseller.id;
        }

        dispatch(upsertTransaction({
            step: {
                db: "coseller",
                main: {
                    ...coseller,
                    broker_user_id: dataBroker?.id,
                    company_id: dataBroker?.company_id,
                    company_office_id: dataBroker?.company_office_id,
                    createdBy: logged_user?.id,
                    updatedBy: logged_user?.id
                },
            },
            transaction: {
                isBelonged: true,
                main: {
                    ...getPlainTransactionData(dataDeal),
                    task_type: "deal",
                    is_seller_same_address: isSameAddress,
                    broker_user_id: dataBroker?.id,
                    company_id: dataBroker?.company_id,
                    company_office_id: dataBroker?.company_office_id,
                    is_co_seller_hide_by_invited_broker: isHideFromOtherBroker
                },
                foreignkeyName: "co_seller_contact_id",
            },
        }, "Co-Seller's")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionInitialById(data.id));
            closePanel(true);
        }).catch((error) => {
            console.log(error);
            dispatch(setLoadingStatus(false));
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    const removeCoSeller = () => {
        if (dataDeal && dataDeal.co_seller_contact_id) {
            dispatch(setLoadingStatus(true));
            dispatch(deleteClient(dataDeal.co_seller_contact_id)).finally(() => {
                dispatch(updateTransaction(dataDeal.id, { co_seller_contact_id: null })).finally(() => {
                    dispatch(updateTransaction(dataDeal.id, { is_co_seller_hide_by_invited_broker: false })).then(() => {
                        dispatch(setLoadingStatus(false));
                        closePanel(true);
                    }).catch((error) => {
                        dispatch(setLoadingStatus(false));
                        console.log(error);
                    });
                });
            });
        }
    };

    const handleSelect = (value, option) => {
        dispatch(getClient(option.id));
    };

    const manageRequireFields = (fieldName, fieldValue) => {
        setRequiredFields(state => {
            if (fieldValue) {
                return state.filter(field => field != fieldName);
            }
            return state.includes(fieldName) ? state : [...state, fieldName];
        });
    }


    return (
        <div className="form-container panel-form">
            <FormRow>
                <FormInputWrapper label="Is there a Co-Seller? (If No, then leave blank)" className="toys">
                    {/* <div className="radio-toolbar">
                        <input type="radio" id="activeCoSellerYes" name="radioActiveCoSeller"
                            checked={isCoSeller} onChange={(e) => setIsCoSeller(e.target.checked)} />
                        <label htmlFor='activeCoSellerYes' className="uk-form-label radio">Yes</label>
                        <input type="radio" id="activeCoSellerNo" name="radioActiveCoSeller"
                            checked={!isCoSeller} onChange={(e) => setIsCoSeller(!e.target.checked)} />
                        <label htmlFor='activeCoSellerNo' className="uk-form-label radio">No</label>
                    </div> */}
                </FormInputWrapper>
            </FormRow>

            {isCoSeller &&
                <>
                    {menuType == 'invitation' &&
                        <FormRow>
                            <FormControlLabel
                                style={{ display: 'flex', justifyContent: 'end' }}
                                value="end"
                                control={
                                    <Checkbox
                                        checked={isHideFromOtherBroker}
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                        onChange={() => { setIsHideFromOtherBroker(!isHideFromOtherBroker) }}
                                    />
                                }
                                label="Hide info from other broker?"
                            />
                        </FormRow>
                    }
                    <FormRow style={{ marginBottom: "10px" }}>
                        <AutoCompleteSearch options={optionsForAutocomplete} onSelect={handleSelect} placeholder="Search Your Client List" />
                    </FormRow>

                    <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                        <FormRow>
                            <FormInputWrapper label="First Name *">
                                <input className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('first_name') })}
                                    type="text" {...register("first_name", {
                                        onChange: e => {
                                            manageRequireFields('first_name', e.target.value);
                                        }
                                    })} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Middle Name">
                                <input className="uk-input" type="text" {...register("middle_name")} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Last Name *">
                                <input className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('last_name') })}
                                    type="text" {...register("last_name", {
                                        onChange: e => {
                                            manageRequireFields('last_name', e.target.value);
                                        }
                                    })} />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="Email *">
                                <input
                                    className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('email') })}
                                    type="text"
                                    {...register("email", {
                                        onChange: e => {
                                            manageRequireFields('email', e.target.value);
                                        }
                                    })}
                                />
                            </FormInputWrapper>
                            <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                                <label className="uk-form-label" htmlFor="name">Mobile Phone *</label>
                                <input type="hidden" required name="mobile_phone"  {...register("mobile_phone")} />
                                <PhoneInput
                                    placeholder='+'
                                    country={'us'}
                                    inputClass={classes.phone_input}
                                    containerClass={classes.phone_container}
                                    autoFocus={false}
                                    onFocus={() => { }}
                                    inputStyle={{
                                        background: `${requiredFields.includes('mobile_phone') ? '#fff7f8' : ''}`,
                                        borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}`
                                    }}
                                    buttonStyle={{ borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}` }}
                                    value={getValues("mobile_phone")}
                                    onChange={phone => {
                                        setValue("mobile_phone", phone, { shouldValidate: true });
                                        manageRequireFields('mobile_phone', phone);
                                    }}
                                />
                            </div>
                            <FormInputWrapper label="Citizen Of">
                                <input className="uk-input" type="text" {...register("nationality")} />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="">
                                <input type="checkbox" checked={isSameAddress}
                                    onClick={() => setIsSameAddress(!isSameAddress)} />
                                <span className="checkmark">Address same as seller</span>
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="Address">
                                <input className="uk-input" type="text" {...register("address_1")} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Address 2">
                                <input className="uk-input" type="text" {...register("address_2")} />
                            </FormInputWrapper>
                            <FormInputWrapper label="Address 3">
                                <input className="uk-input" type="text" {...register("address_3")} />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="Country">
                                <select className="uk-select" {...register("country")}>
                                    {Country.map((country) => {
                                        return (
                                            <option value={country.code} key={country.code}>
                                                {country.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <FormInputWrapper label="City">
                                <input className="uk-input" type="text" {...register("city")} />
                            </FormInputWrapper>
                            <FormInputWrapper label="State">
                                {isSelectedUSA
                                    ? (
                                        <select className="uk-select" {...register("state")}>
                                            <option defaultValue={undefined}></option>
                                            {StatesForUSA.map((state) => {
                                                return (
                                                    <option value={state.code} key={state.code}>
                                                        {state.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    )
                                    : (
                                        <input className="uk-input" type="text" {...register("state")} />
                                    )
                                }
                            </FormInputWrapper>
                            <FormInputWrapper label="Zip/Postal Code">
                                <input className="uk-input" type="text" {...register("postal_code")} />
                            </FormInputWrapper>
                        </FormRow>
                        <FormRow>
                            <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                            <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                        </FormRow>
                    </Form>
                </>
            }
            {!isCoSeller && (
                <FormRow>
                    <button type="button" className='uk-button uk-button-primary pull-right' onClick={removeCoSeller}>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            )}
        </div>
    )
}

export default PanelFormCoSeller;