import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { eSignNYBAPurchaseAndSales, getTransactionEdocById } from "../../../../../redux/actions/transaction";

import FormInputWrapper from "../../../../common/FormInputWapper";
import FormRow from "../../../../common/FormRow";

import { toast } from "react-toastify";
import { CORPORATE_OWNED, ENTITY_AND_OR_ASSIGN, JOINT_OWNER, LEGAL_AND_OR_ASSIGN, LEGAL_OR_ASSIGN, LEGAL_OWNER, LLC_OWNED, OWNER_OF_RECORD, RECORD_OWNER, TRUST_OWNED } from "../../../../../utils/signer";

import FirstPageIcon from '@mui/icons-material/FirstPage';
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Auth from "../../../../../utils/auth";
import { capitalizeFirstLetter, convertFirstCharIntoSmallCase, convertUnderscoreSeparateLetter, isSigned, renderMaskedValue, unauthorizedErrorToast } from "../../../../../utils/functions";
import HandSignUploadModal from "../../../../common/HandSignUploadModal";
import SignatureMessageModal from "../../../../common/SignatureMessageModal";
import { ViewDocumentsModal } from "../../../../common/ViewDocumentsModal";
import CustomActionButtons from "./CustomActionButtons";
import CustomRenderStatus from "./CustomRenderStatus";
import Column from "../../../../common/Column";

const useStyles = makeStyles({
    previewSendDiv: {
        width: "250px !important"
    },
    handSignedDiv: {
        marginTop: "-4px"
    },
    back_button_div: {
        paddingLeft: "37%",
        width: "50% !important"
    },
    arrow_sign: {
        float: "left"
    },
    back_icon: {
        marginBottom: "3px",
        marginRight: "1px"
    },
    back_icon_span: {
        marginRight: "75px",
        fontSize: "larger"
    }
});

/**
* @param {Function} updateDocumentObj // It is used to update payload object
* @param {String} selectedRole //It is used to check which role is selected from signature tab
* @param {Object} payload //Prepared object for the back-end
* @param {Function} makeDropDownSigners // Send signers array to the signature tab for displaying signer drop-down
* @returns 
*/
const EsignNYBAPurchaseAndSales = ({ updateDocumentObj, makeDropDownSigners, selectedRole, payload, roles, menuType }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [isSuccess, setSuccess] = useState(undefined);
    const [isError, setError] = useState(undefined);
    const [message, setMessage] = useState("");
    const [showAdditionalModal, setShowAdditionalModal] = useState(false);
    const [emailDetailsToSend, setEmailDetailsToSend] = useState(null);
    const [showHandsignModal, setShowHandsignModal] = useState(false);
    const [handSignDetails, setHandSignDetails] = useState(null);
    const [showDocument, setShowDocument] = useState(false);
    const [document, setDocument] = useState(null);
    const loginEmail = Auth.getInstance().getUserData().email;
    const [signerSections, setSignerSections] = useState({});
    const [currentSection, setCurrentSection] = useState(1)

    //Get logged User
    const user = Auth.getInstance().getUserData();
    const classes = useStyles();

    const deals = useSelector((state) => state.transactions);
    const dataTransaction = deals.transaction || {};

    const dataBuyer = dataTransaction.buyer;
    const dataCoBuyer = dataTransaction.co_buyer;
    const dataVessel = dataTransaction.t_vessel;
    const dataSeller = dataTransaction.seller;
    const dataCoSeller = dataTransaction.co_seller
    const dataCoBroker = dataTransaction.co_broker
    const dataEdocuments = dataTransaction.t_edocuments || [];
    const eDoc = dataEdocuments.find(doc => doc.task_type === "nyba-purchase-and-sale-agreement");

    const signers = eDoc && eDoc.request_signature && eDoc.request_signature.signers ? eDoc.request_signature.signers : [];


    /**
     * useEffect to generate the array of signers which is available 
     * for this document only
    */
    useEffect(() => {
        if (!updateDocumentObj || !isValidSend()) {
            return;
        }

        let signerArr = [];

        if ((dataCoBroker && (dataCoBroker?.broker_type == "selling" || dataCoBroker?.broker_type == "both") &&
            dataTransaction?.employing_broker) && !isSigned(signers, "selling_employing_broker")) {
            signerArr.push("selling_employing_broker");
        } else if ((dataCoBroker && (dataCoBroker?.broker_type == "selling" || dataCoBroker?.broker_type == "both") &&
            !dataTransaction?.employing_broker) && !isSigned(signers, "selling_broker")) {
            signerArr.push("selling_broker");
        }

        if ((dataCoBroker &&
            ((dataCoBroker?.broker_type == "listing" || dataCoBroker?.broker_type == "both")) &&
            dataTransaction?.employing_broker) && !isSigned(signers, "listing_employing_broker")) {
            signerArr.push("listing_employing_broker");
        } else if ((dataCoBroker &&
            ((dataCoBroker?.broker_type == "listing" || dataCoBroker?.broker_type == "both")) &&
            !dataTransaction?.employing_broker) && !isSigned(signers, "listing_broker")) {
            signerArr.push("listing_broker");
        }


        if (dataTransaction?.co_buyer && !isSigned(signers, "co_buyer")) {
            signerArr.push("co_buyer");
        }

        if (dataTransaction?.co_seller && !isSigned(signers, "co_seller")) {
            signerArr.push("co_seller");
        }

        if (dataTransaction?.buyer && !isSigned(signers, "buyer")) {
            signerArr.push("buyer");
        }

        if (dataTransaction?.seller && !isSigned(signers, "seller")) {
            signerArr.push("seller");
        }

        makeDropDownSigners(signerArr);

    }, [dataTransaction, signers.length, currentSection]);

    useEffect(() => {
        if (Object.keys(signerSections).length > 0) {
            const currentSectionNumber = getCurrentSectionValue()

            setCurrentSection(currentSectionNumber);
        }
    }, [signerSections, eDoc]);

    const transformData = (data) => {

        const result = {};
        let payload = {}
        if (dataTransaction?.employing_broker) {
            payload = { ...payload, "Listing-Broker": data["Listing-Broker"] }
        }
        if (dataTransaction?.employing_broker) {
            payload = { ...payload, "Selling Broker": data["Selling-Broker"] }
        }
        if (dataTransaction?.co_broker && (dataTransaction?.co_broker?.broker_type == "selling" || dataTransaction?.co_broker?.broker_type == "both" || !isSigned(signers, "selling_employing_broker")) &&
            dataTransaction?.employing_broker) {
            payload = { ...payload, "selling-employing-broker": data["selling-employing-broker"] }
        } else if (dataTransaction?.co_broker && (dataTransaction?.co_broker?.broker_type == "selling" || dataTransaction?.co_broker?.broker_type == "both") &&
            dataTransaction?.employing_broker) {
            payload = { ...payload, "Selling-Broker": data["selling_broker"] }
        }
        if (dataTransaction?.co_broker && (dataTransaction?.co_broker?.broker_type == "listing" || dataTransaction?.co_broker?.broker_type == "both") &&
            dataTransaction?.employing_broker && !isSigned(signers, "listing_employing_broker")) {
            payload = { ...payload, "listing-employing-broker": data["listing-employing-broker"] }
        } else if (dataTransaction?.co_broker && (dataTransaction?.co_broker?.broker_type == "listing" || dataTransaction?.co_broker?.broker_type == "both") &&
            dataTransaction?.employing_broker) {
            payload = { ...payload, "Listing-Broker": data["listing_broker"] }
        }
        if (dataTransaction?.co_buyer) {
            payload = { ...payload, "Co-Buyer": data["Co-Buyer"] }
        }

        if (dataTransaction?.co_seller) {
            payload = { ...payload, "Co-Seller": data["Co-Seller"] }
        }

        if (dataTransaction?.buyer) {
            payload = { ...payload, Buyer: data?.Buyer }
        }

        if (dataTransaction?.seller) {
            payload = { ...payload, Seller: data?.Seller }
        }
        // Iterate over each role in the data object
        for (const [role, ids] of Object.entries(payload)) {
            // Iterate over each ID associated with the current role
            ids?.forEach(id => {
                // If the ID is not yet in the result object, initialize it with an empty array
                if (!result[id]) {
                    result[id] = [];
                }
                // Add the current role to the ID's array
                result[id].push(role);
            });
        }

        return result;
    }

    const getCurrentSectionValue = () => {
        const totalSection = Number(eDoc.t_esign_template?.template?.total_sections);
        const sectionData = transformData(signerSections);

        let currentSectionNumber = 0;

        for (let i = 1; i <= totalSection; i++) {
            // Filter signers for the current section
            let sectionSigner = signers?.filter(signer => (signer?.section_signers?.some(section => section?.section === i) || (signer?.is_hand_signed && sectionData[i]?.includes(signer?.request_signer?.role))));

            // Check if the number of section signers matches the number of transformed data
            if (sectionSigner?.length !== sectionData[i]?.length) {
                currentSectionNumber = i;
                break;
            } else {
                // Map over the transformed data for the current section
                let allSignersSigned = true;
                sectionData[i]?.forEach(signer => {
                    let currentSigner = sectionSigner?.find(sign => sign?.request_signer?.role === signer);
                    if (!currentSigner?.is_hand_signed) {
                        currentSigner = currentSigner?.section_signers.find(
                            (signer) => signer.section == i
                        );
                        if (currentSigner?.status !== "Signed") {
                            currentSectionNumber = i;
                            allSignersSigned = false;
                            return;
                        }
                    }
                });

                // If not all signers are signed, break the loop
                if (!allSignersSigned) {
                    break;
                } else {
                    if (i === totalSection) {
                        // setCurrentSection(0);
                        currentSectionNumber = totalSection
                    }
                }
            }
        }
        return currentSectionNumber;
    }

    //Apply use Effect when role is changed from the signature tab
    useEffect(() => {
        const assignerSectionCounts = getSectionSignerWithCount();
        const currentSectionForSigner = getCurrentSectionValue();

        /**
         * Check selected role is included for this document &
         * Check the selected role's status is not equal to signed &
         * Check this document has proper data for the sign this document.
         */
        if (selectedRole
            && (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === selectedRole)?.status != "Signed")
            && (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === selectedRole)?.section_signers?.find(sectionSigner => sectionSigner.section == currentSection)?.status != "Signed")
            && assignerSectionCounts[capitalizeFirstLetter(selectedRole)]?.includes(currentSectionForSigner.toString())
            && dataTransaction && isValidSend()) {

            //If selected role has no related data to the transaction then return
            switch (selectedRole) {
                case "co_buyer":
                    if (!dataTransaction.co_buyer) {
                        return;
                    }
                    break;
                case "co_seller":
                    if (!dataTransaction?.co_seller) {
                        return;
                    }
                    break;
                default:
                    break;
            }

            //Prepare object for the payload
            let obj = {}

            obj[selectedRole] = {
                document_name: "nyba-purchase-and-sale-agreement",
                id: dataTransaction.id,
                section: currentSection,
                final_section_no: Math.max(...assignerSectionCounts[capitalizeFirstLetter(selectedRole)])
            };
            //Update payload object
            updateDocumentObj(obj, selectedRole);
            return;
        }
    }, [selectedRole, currentSection]);

    /**
     * @param {Object} evt // Event Object
     * @param {String} role
    */
    const previewByStep = (evt, role) => {
        dispatch(setLoadingStatus(true));
        dispatch(eSignNYBAPurchaseAndSales(dataTransaction.id, "preview", role)).then((res) => {
            dispatch(setLoadingStatus(false));
            if (res.status !== 200) {
                throw res;
            } else {
                // CloudFront URL Always unique on Click of preview
                // window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
                window.open(res.data.url, '_blank');
            }
        }).catch((err) => {
            console.log("err", err);
            dispatch(setLoadingStatus(false));
            switch (err.status) {
                case 400:
                    setMessage(`${err.data?.error.message}`);
                    break;
                default:
                    setMessage(`${err.data?.message}`);
                    break;
            }
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 5000);
        });
    }

    /**
     * @param {Object} evt // Event Object
     * @param {String} mode //It can be "send" or "hand-signed"
     * @param {*} role 
     * @param {*} data // selected date for the hand signed
    */
    const sendByStep = (mode, role, data = null) => {
        let obj = {};
        if (data) {
            if (mode == 'signed') {
                obj["signed_date"] = data?.signed_date;
                obj["signed_document"] = JSON.stringify(data.documents);
            } else {
                obj["additional_email_content"] = data?.additional_email_content || '';
            }
            obj["selected_recipients"] = data?.selectedUser;
            obj["sign_url_access_days"] = data.sign_url_access_days;
        }
        obj["is_email_send"] = data?.is_email_send ? 'yes' : 'no';
        obj["requested_by"] = user.id;
        obj['section'] = data.section;
        obj['final_section_no'] = data.finalSection;
        if (data.is_hand_signed) {
            obj['is_hand_signed'] = true;
        }

        dispatch(setLoadingStatus(true));
        dispatch(eSignNYBAPurchaseAndSales(dataTransaction.id, mode, role, obj)).then(res => {
            dispatch(setLoadingStatus(false));
            if (res.status === 200) {
                dispatch(getTransactionEdocById(dataTransaction.id, menuType === 'invitation', dataTransaction?.company_id));
                if (data?.signed_date) {
                    setMessage("Signed Date saved successfully");
                    toast.success(`Hand signed documents uploaded successfully.`);
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(undefined);
                    }, 3000);
                } else {
                    if (!data?.is_email_send) {
                        setShowAdditionalModal(false);
                        toast.success('E-Sign Link generated successfully.');
                    } else {
                        setMessage("Document sent for eSignature successfully.");
                        setSuccess(true);
                        setTimeout(() => {
                            setSuccess(undefined);
                        }, 3000);
                    }
                }
            } else {
                switch (res.status) {
                    case 400:
                        setMessage(`${res.data.error.message}`);
                        break;
                    case 401:
                        unauthorizedErrorToast()
                        break;
                    default:
                        toast.error(`Something went wrong while upload hand signed documents.`);
                        setMessage(`${res.data.message}`);
                        break;
                }
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 5000);
            }
        }).catch((err) => {
            dispatch(setLoadingStatus(false));
            toast.error("Error occurs, please try again.", {
                autoClose: 2000
            })
        })
    }

    const onAdditionalMessageModalOpen = (mode, role, data = null) => {

        setEmailDetailsToSend({ mode: mode, role: role, data: data });
        setShowAdditionalModal(true);
    }

    const onCloseAdditionalEmailModal = (isEmailSend, emailAdditionalContent = null) => {
        setShowAdditionalModal(false);
        if (isEmailSend) {
            sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, { ...emailAdditionalContent.data, is_email_send: true, selectedUser: emailAdditionalContent.data.selected_recipients, section: emailAdditionalContent.data.section, finalSection: emailAdditionalContent.data.finalSection })
        }
    }

    const onGenerateEsignatureLink = (emailAdditionalContent) => {
        const signatureLink = signers.find(signer => signer.request_signer.role === emailAdditionalContent.role)?.section_signers?.find(sectionSigner => sectionSigner.section == currentSection)?.doc_signer_link || '';
        // if (signatureLink) {
        //     setShowAdditionalModal(false);
        //     toast.success('E-Sign link generated successfully.');
        // } else {
            sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, { ...emailAdditionalContent.data, is_email_send: false, selectedUser: emailAdditionalContent.data.selected_recipients, section: emailAdditionalContent.data.section, finalSection: emailAdditionalContent.data.finalSection });
        // }
    }

    /**
     * On open Hand Sign document selection modal after selecting date
     * @param {*} role 
     * @param {*} signDate 
    */
    const onHandSignUploadModalOpen = (role, signDate, section, signerFinalSection) => {
        const client = role.includes('Employing') || role.includes('employing')
            ? dataTransaction?.employing_broker
            : role.includes('Listing') || role.includes('Selling')
                ? dataCoBroker
                : dataTransaction[role.toLowerCase().replace('-', '_')];
        setHandSignDetails({
            documentName: eDoc.t_esign_template?.template?.template_name,
            signDate: signDate,
            role: role,
            client: client,
            section: section,
            final_section_no: signerFinalSection
        });
        if (signDate && signDate != null) {
            setShowHandsignModal(true);
        }
    }

    /**
     * On close Hand Sign modal and submit uploaded document to sendByStep() function
     * @param {*} isUploaded 
     * @param {*} documentUploadFiles 
    */
    const onCloseHandsignModal = (isUploaded, documentUploadFiles = null, selectedUser = [], isHandSigned) => {
        setShowHandsignModal(false);

        if (isUploaded) {
            sendByStep("signed", handSignDetails.role, { signed_date: handSignDetails.signDate, documents: documentUploadFiles, section: handSignDetails.section, finalSection: handSignDetails.final_section_no, selectedUser: selectedUser, is_hand_signed: isHandSigned })
        }
        setHandSignDetails(null);
    }

    /**
     * On open view hand signed uploaded modal
     * @param {*} document 
    */
    const handleOpenViewDocumentModal = (document) => {
        setDocument(document);
        setShowDocument(true);
    }

    /**
     * On close view hand signed uploaded modal
    */
    const handleCloseModal = () => {
        setDocument(null);
        setShowDocument(false);
    }

    /**
     * Check this document is valid for preview or not
     * @returns True or False
    */
    const isValidPreview = () => {
        let temp = deals.requiredFields.seller.length;
        if (dataTransaction?.seller_type == RECORD_OWNER) {
            temp = 0;
        }
        return deals.requiredFields.buyer.length === 0 && temp === 0;
    };

    /**
     * Check this document is valid for send or not
     * @returns True or False
    */
    const isValidSend = () => {
        let valid = !deals.requiredFields.buyer.some(obj => Object.keys(obj).includes('email')) &&
            !deals.requiredFields.seller.some(obj => Object.keys(obj).includes('email'));

        if (dataTransaction.co_buyer_contact_id) {
            valid = valid && !deals.requiredFields.co_buyer.some(obj => Object.keys(obj).includes('email'))
        }

        if (dataTransaction.co_seller_contact_id) {
            valid = valid && !deals.requiredFields.co_seller.some(obj => Object.keys(obj).includes('email'))
        }

        return valid;
    };

    //Define vessel name to show
    let vesselName = `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}` !== "'   "
        ? `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}`
        : "N/A";

    //Define co buyer name
    let coBuyerName = dataCoBuyer
        ? `${dataCoBuyer.first_name || ""} ${dataCoBuyer.last_name || ""}` === " "
            ? "N/A"
            : `${dataCoBuyer.first_name || ""} ${dataCoBuyer.last_name || ""}`
        : "N/A";

    //Define co seller name
    let coSellerName = dataCoSeller
        ? `${dataCoSeller.first_name || ""} ${dataCoSeller.last_name || ""}` === " "
            ? "N/A"
            : `${dataCoSeller.first_name || ""} ${dataCoSeller.last_name || ""}`
        : "N/A";


    /**
     * This function is used to show a signed date of document.
     * @param {String} role 
     * @param {Date} signedDate 
     * @returns Date
    */
    const handleSignedDate = (role, signedDate, section) => {
        if (signedDate) {
            return moment(signedDate ? signedDate : null).utc().format("MM-DD-yyyy")
        }

        if (!signedDate) {
            if (eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)) {
                const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
                const roleSigner = eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)
                const currentSigner = roleSigner.section_signers.find(signer => signer.section == section)
                if (currentSigner?.e_signed_date) {
                    return `${moment(currentSigner?.e_signed_date || null).local().format("MM-DD-yyyy hh:mm A")} ${timezone}`;
                } else {
                    return `${moment(currentSigner?.signed_date || null).local().format("MM-DD-yyyy")}`;
                }
            }
        }

        return null;
    }

    /**
     * Provides the eSign link for email send for signature
     * @param {*} role 
     * @returns 
    */
    const getEsignLink = (role) => {
        return signers.find(signer => signer.request_signer.role === role)?.doc_signer_link || '';
    }

    const [tooltipText, setTooltipText] = useState('Copy E-Sign Link');

    /**
     * Renders the button for eSign Link according to role of client
     * @param {*} role 
     * @returns Button for Link
     */
    const renderLinkButtons = (role) => {
        if (role.includes('broker') || role.includes('Broker')) {
            return <button style={{ height: '26px' }}
                className="uk-button uk-button-primary"
                onClick={() => { window.open(getEsignLink(role), '_blank'); }}>
                Sign </button>
        }

        return <Tooltip title={tooltipText} arrow>
            <button style={{ height: '26px', padding: '0 4px 0' }}
                className="uk-button uk-button-primary"
                onClick={() => {
                    navigator.clipboard.writeText(getEsignLink(role));
                    setTooltipText('E-Sign Link Copied..')
                    setTimeout(() => {
                        setTooltipText('Copy E-Sign Link');
                    }, 800);
                }}>Copy E-Sign Link </button>
        </Tooltip>
    }

    /**
     * This function is used to change the payload 
     * as per user manually checks the checkbox of particular checkbox
     * @param {Object} e //Event Object 
     * @param {String} role 
     * @returns 
    */
    const checkBoxHandler = (e, role, currentSection) => {
        let obj = {};
        obj[role] = {
            document_name: "nyba-purchase-and-sale-agreement",
            id: dataTransaction.id,
            section: currentSection,
            final_section_no: role === 'selling_employing_broker' ? Math.max(...signerSections['selling-employing-broker']) : role === 'lising_employing_broker' ? Math.max(...signerSections['listing-employing-broker']) : Math.max(...signerSections[capitalizeFirstLetter(role)])
        };
        updateDocumentObj(obj, role, !e.target.checked, true);
    }

    const renderAction = (role, signerSection) => {
        return (
            <CustomActionButtons
                role={role}
                signers={signers}
                isValidSend={isValidSend}
                handleOpenViewDocumentModal={handleOpenViewDocumentModal}
                previewByStep={previewByStep}
                onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
                onHandSignUploadModalOpen={onHandSignUploadModalOpen}
                dataTransaction={dataTransaction}
                deals={deals}
                section={signerSection}
                currentSection={currentSection}
                signerFinalSection={Math.max(...signerSections[role])}
                menuType={menuType}
            />
        )
    }

    const renderStatus = (role, signerSection) => {
        return (
            <CustomRenderStatus
                role={role}
                signedDate={signers.find(signer => signer.request_signer.role === role)?.signed_date}
                signers={signers}
                isValidSend={isValidSend}
                handleOpenViewDocumentModal={handleOpenViewDocumentModal}
                previewByStep={previewByStep}
                onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
                onHandSignUploadModalOpen={onHandSignUploadModalOpen}
                dataTransaction={dataTransaction}
                deals={deals}
                eDoc={eDoc}
                getEsignLink={getEsignLink}
                renderLinkButtons={renderLinkButtons}
                handleSignedDate={handleSignedDate}
                section={signerSection}
                currentSection={currentSection}
                signerFinalSection={Math.max(...signerSections[role])}
            />
        )
    }

    /**
     * Filter the e-sign template object and get the total sections with assigners
     * @param {*} data 
     * @returns filtered data for total number of sections and how many assigners in a section
     */
    function filterSections(data) {
        const sectionMap = {};
        const assignerSections = {};
        if (data) {

            for (const item of data) {
                const section = item.section;
                const assigner = item.assigner;

                if (!sectionMap[section]) {
                    sectionMap[section] = {
                        section: section,
                        items: []
                    };
                }

                // Only add the assigner if it doesn't already exist in the section
                if (!sectionMap[section].items.find(i => i.assigner === assigner)) {
                    sectionMap[section].items.push(item);
                }

                // Initialize assigner in the map if not already present
                if (!assignerSections[assigner]) {
                    assignerSections[assigner] = new Set();
                }

                // Add section to the assigner's set
                assignerSections[assigner].add(section);
            }
        }

        return {
            sections: Object.values(sectionMap),
            assignerSections: assignerSections
        };
    }

    useEffect(() => {
        setSignerSections(getSectionSignerWithCount());
    }, [dataCoBroker]);

    // Function to get the array of all Signers with their assigned sections in number of array
    const getSectionSignerWithCount = () => {
        const totalSignatureSections = eDoc.t_esign_template?.template?.template_fields?.filter((field) => { return field.type == 'PDFSignature' || field.type == 'PDFInitial' });
        const signatureSectionsWithSigners = filterSections(totalSignatureSections);

        let assignerSections = signatureSectionsWithSigners.assignerSections
        if (Object.keys(assignerSections).length) {

            if (dataTransaction?.employing_broker && ['selling', 'both'].includes(dataCoBroker?.broker_type) && assignerSections["Selling-Broker"]) {
                // const { Selling-Broker,...otherObject} = assignerSections;
                assignerSections = { ...assignerSections, "selling-employing-broker": assignerSections["Selling-Broker"] }
                assignerSections = Object.fromEntries(
                    Object.entries(assignerSections)?.filter(([key]) => key !== 'Selling-Broker')
                );
            } else if (dataTransaction?.employing_broker && ['listing'].includes(dataCoBroker?.broker_type) && assignerSections["Listing-Broker"]) {
                assignerSections = { ...assignerSections, "Listing-Employing-Broker": assignerSections["Listing-Broker"] }
                assignerSections = Object.fromEntries(
                    Object.entries(assignerSections)?.filter(([key]) => key !== 'Listing-Broker')
                );
            }

            if (['both'].includes(dataCoBroker?.broker_type)) {
                assignerSections = Object.fromEntries(
                    Object.entries(assignerSections)?.filter(([key]) => key !== 'Listing-Broker')
                );
            }
            const assignerSectionCounts = Object.entries(assignerSections)?.reduce((data, [assigner, sections]) => {

                data[assigner] = Array?.from(sections)?.sort();
                return data;
            }, {});

            return assignerSectionCounts;
        }
    }

    /**
     * Function that used for Display Broker Name and Email Address as per the status 
     * If status is not Sent or Signed then display current Broker data otherwise display Signer data instead
     * @param {*} brokerType 
     * @returns Broker Name and Email Address 
    */
    const getBrokerNameAndEmail = (brokerType) => {
        if (brokerType.includes('employing-broker')) {
            return !["Sent", "Signed"].includes(signers.find(signer => signer.request_signer.role === `${brokerType}`)?.status)
                ? {
                    name: `${dataTransaction.employing_broker?.first_name || "N/A"} ${dataTransaction.employing_broker?.last_name || "N/A"}`,
                    email: dataTransaction?.employing_broker?.email || "N/A"
                }
                : {
                    name: (signers && signers.find(signer => signer.request_signer.role === `${brokerType}`))?.request_signer?.name,
                    email: (signers && signers.find(signer => signer.request_signer.role === `${brokerType}`))?.request_signer?.email_address
                }
        }

        return !["Sent", "Signed"].includes(signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `${brokerType}_broker`)?.status)
            ? { name: dataCoBroker[`${brokerType}_brokerage_broker`], email: dataCoBroker[`${brokerType}_broker_email`] }
            : {
                name: (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `${brokerType}_broker`))?.request_signer?.name,
                email: (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `${brokerType}_broker`))?.request_signer?.email_address
            }
    }

    return (
        <>
            <div className="uk-container uk-container-small uk-position-relative m-0">
                <div className="scroll-div">
                    {!updateDocumentObj &&
                        <div className="form-row">
                            <div className="half-column uk-padding-small">
                                <h2>Send for eSignature</h2>
                            </div>
                            <div className={`half-column uk-padding-small ${classes.back_button_div}`}>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    history.goBack()
                                }}>
                                    <FirstPageIcon className={classes.back_icon} >Back</FirstPageIcon>
                                    <span className={classes.back_icon_span}>Back</span>
                                </a>
                            </div>
                        </div>
                    }
                    <div className="form-container scroll-fix-div">
                        <div className="esign-send listing-agreement">
                            {isSuccess
                                ? (
                                    <div className="uk-alert uk-alert-primary" data-uk-alert>
                                        <p>{message}</p>
                                    </div>
                                ) : isError ? (
                                    <div className="uk-alert uk-alert-danger" data-uk-alert>
                                        <p>{message}</p>
                                    </div>
                                ) : null
                            }
                            <FormRow>
                                <h4>
                                    <b>{eDoc?.t_esign_template?.template?.template_name}</b>
                                </h4>
                            </FormRow>
                            <FormRow><hr /></FormRow>
                            <FormRow>
                                <FormInputWrapper label="Vessel">{vesselName}</FormInputWrapper>
                            </FormRow>
                            <FormRow><hr /></FormRow>

                            {signerSections['Buyer']?.map((sectionNum) => {
                                return (
                                    <>
                                        {/* {menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail ? <></> : */
                                            <>
                                                <div className="client-row">
                                                    <Column size="one" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                        <label className={`uk-form-label signer-name-block`}>Buyer - <span className="font-weight-bold">Signature Section {sectionNum}</span></label>
                                                        {[LEGAL_OWNER, LEGAL_OR_ASSIGN, JOINT_OWNER, LEGAL_AND_OR_ASSIGN].indexOf(dataTransaction?.buyer_type) > -1 && (
                                                            (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                                                                ? renderMaskedValue(`${dataBuyer?.first_name} ${dataBuyer?.last_name}`.length)
                                                                : `${dataBuyer?.first_name} ${dataBuyer?.last_name}`
                                                        )}
                                                        {[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1 && (
                                                            (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                                                                ? renderMaskedValue(`${dataBuyer?.trust_signer_name}`.length)
                                                                : `${dataBuyer?.trust_signer_name}`
                                                        )}
                                                        {!([LEGAL_OWNER, LEGAL_OR_ASSIGN, JOINT_OWNER, LEGAL_AND_OR_ASSIGN, ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1) && (
                                                            `N/A`
                                                        )}
                                                    </Column>
                                                    <FormInputWrapper label="Buyer's Email" className="signer-email-block" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                        {[LEGAL_OWNER, LEGAL_OR_ASSIGN, JOINT_OWNER, LEGAL_AND_OR_ASSIGN].indexOf(dataTransaction?.buyer_type) > -1 && (
                                                            (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                                                                ? renderMaskedValue(`${dataBuyer?.email}`.length)
                                                                : `${dataBuyer?.email}`
                                                        )}
                                                        {[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1 && (
                                                            (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                                                                ? renderMaskedValue(`${dataBuyer?.trust_signer_email}`.length)
                                                                : `${dataBuyer?.trust_signer_email}`
                                                        )}
                                                        {!([LEGAL_OWNER, LEGAL_OR_ASSIGN, LEGAL_AND_OR_ASSIGN, JOINT_OWNER, ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1) && (
                                                            `N/A`
                                                        )}
                                                    </FormInputWrapper>
                                                    {eDoc &&
                                                        <div className={!updateDocumentObj && "signers_div"}>
                                                            {signers && (
                                                                signers.find(signer => signer.request_signer.role === 'Buyer')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status || signers.find(signer => signer?.request_signer?.role === 'Buyer')?.is_hand_signed
                                                                    ? renderStatus("Buyer", sectionNum)
                                                                    : renderAction("Buyer", sectionNum)
                                                            )}
                                                        </div>
                                                    }
                                                    {eDoc && updateDocumentObj && (!isSigned(signers, "Buyer") && signers.find(signer => signer.request_signer.role === 'Buyer')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status != 'Signed') &&
                                                        <div className="col-1">
                                                            <input
                                                                type="checkbox"
                                                                className="signatures_checkbox"
                                                                onChange={(e) => { checkBoxHandler(e, "buyer", sectionNum); }}
                                                                checked={payload?.buyer?.find((obj) => (obj.document_name == eDoc.task_type && sectionNum == currentSection) ? true : false) || false}
                                                                disabled={
                                                                    (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "buyer" || sectionNum > currentSection || (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                                                                }
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <FormRow><hr /></FormRow>
                                            </>}

                                    </>
                                )
                            })}

                            {dataCoBuyer && (
                                signerSections['Co-Buyer']?.map((sectionNum) => {
                                    return (
                                        <>
                                            <>
                                                <div className="client-row">
                                                    <Column size="one" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                        <label className={`uk-form-label signer-name-block`}>Co-Buyer - <span className="font-weight-bold">Signature Section {sectionNum}</span></label>
                                                        {
                                                            (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_buyer_hide_by_invited_broker)
                                                                ? renderMaskedValue(`${coBuyerName}`.length)
                                                                : `${coBuyerName || "N/A"}`
                                                        }
                                                    </Column>
                                                    <FormInputWrapper label="Co-Buyer's Email" className="signer-email-block" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                        {
                                                            (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_buyer_hide_by_invited_broker)
                                                                ? renderMaskedValue(`${dataCoBuyer?.email}`.length)
                                                                : `${dataCoBuyer?.email || "N/A"}`
                                                        }
                                                    </FormInputWrapper>
                                                    {eDoc &&
                                                        <div className={!updateDocumentObj && "signers_div"}>
                                                            {signers && (
                                                                signers.find(signer => signer.request_signer.role === 'Co-Buyer')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status || signers.find(signer => signer?.request_signer?.role === 'Co-Buyer')?.is_hand_signed
                                                                    ? renderStatus("Co-Buyer", sectionNum)
                                                                    : renderAction("Co-Buyer", sectionNum)
                                                            )}
                                                        </div>
                                                    }
                                                    {eDoc && updateDocumentObj && (!isSigned(signers, "Co-Buyer") && signers.find(signer => signer.request_signer.role === 'Co-Buyer')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status != 'Signed') &&
                                                        <div className="col-1">
                                                            <input
                                                                type="checkbox"
                                                                className="signatures_checkbox"
                                                                onChange={(e) => { checkBoxHandler(e, "co_buyer", sectionNum); }}
                                                                checked={payload?.co_buyer?.find((obj) => (obj.document_name == eDoc.task_type && sectionNum == currentSection) ? true : false) || false}
                                                                // disabled={true}
                                                                disabled={
                                                                    (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "co_buyer" || sectionNum > currentSection || (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_buyer_hide_by_invited_broker)
                                                                }
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <FormRow><hr /></FormRow>
                                            </>
                                        </>
                                    )
                                }
                                )
                            )}

                            {
                                // menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail
                                //     ? <></>
                                //     :
                                (dataCoBroker.listing_broker_email || ['listing'].includes(dataCoBroker?.broker_type)
                                    ? (["Sent", "Signed"].includes(signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `listing_employing_broker`)?.status) || (dataTransaction?.employing_broker && ['listing'].includes(dataCoBroker?.broker_type)))
                                        && !["Sent", "Signed"].includes(signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `listing_broker`)?.status)
                                        ?
                                        signerSections['Listing-Employing-Broker']?.map((sectionNum) => <>

                                            <div className="client-row">
                                                {/* <Column size="one" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                        <label className={`uk-form-label signer-name-block`}>Listing Employing Broker - <span className="font-weight-bold">Signature Section {sectionNum}</span></label>
                                                        {dataTransaction?.employing_broker?.email || "N/A"}
                                                    </Column> */}
                                                <Column size="one" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                    <label className={`uk-form-label signer-name-block`}>Listing Employing Broker - <br /> <span className="font-weight-bold">Signature Section {sectionNum}</span></label>
                                                    {getBrokerNameAndEmail('listing-employing-broker').name}
                                                </Column>
                                                <FormInputWrapper label="Listing Employing Broker's Email" className="signer-email-block" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                    {getBrokerNameAndEmail('listing-employing-broker').email}
                                                </FormInputWrapper>

                                                {eDoc &&
                                                    <div className={!updateDocumentObj && "signers_div"}>
                                                        {signers &&
                                                            signers.find(signer => signer.request_signer.role === 'Listing-Employing-Broker')?.section_signers?.find(sectionSigner => (sectionSigner.section == sectionNum))?.status || signers.find(signer => signer?.request_signer?.role === 'Listing-Employing-Broker')?.is_hand_signed
                                                            ? renderStatus("Listing-Employing-Broker", sectionNum)

                                                            : renderAction("Listing-Employing-Broker", sectionNum)
                                                        }
                                                    </div>
                                                }

                                                {eDoc && updateDocumentObj && (!isSigned(signers, "Listing-Employing-Broker") && signers.find(signer => signer.request_signer.role === 'Listing-Employing-Broker')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status != 'Signed') &&
                                                    <div className="col-1">
                                                        <input
                                                            type="checkbox"
                                                            className="signatures_checkbox"
                                                            onChange={(e) => { checkBoxHandler(e, "listing_employing_broker", sectionNum); }}
                                                            checked={payload?.listing_employing_broker?.find((obj) => (obj.document_name == eDoc.task_type && sectionNum == currentSection) ? true : false) || false}
                                                            // disabled={true}
                                                            disabled={
                                                                (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "listing_employing_broker" || sectionNum > currentSection || (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail)
                                                            }
                                                        />
                                                    </div>
                                                }
                                            </div >
                                            <FormRow><hr /></FormRow>
                                        </>)
                                        : signerSections['Listing-Broker']?.map((sectionNum) => <>
                                            <div className="client-row">
                                                <Column size="one" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                    <label className={`uk-form-label signer-name-block`}>Listing Broker - <br /> <span className="font-weight-bold">Signature Section {sectionNum}</span></label>
                                                    {getBrokerNameAndEmail('listing').name || "N/A"}
                                                </Column>
                                                <FormInputWrapper label="Listing Broker's Email" className="signer-email-block" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                    {getBrokerNameAndEmail('listing').email || "N/A"}
                                                </FormInputWrapper>
                                                {eDoc &&
                                                    <div className={!updateDocumentObj && "signers_div"}>
                                                        {signers &&
                                                            signers.find(signer => signer.request_signer.role === 'Listing-Broker')?.section_signers?.find(sectionSigner => (sectionSigner.section == sectionNum))?.status || signers.find(signer => signer?.request_signer?.role === 'Listing-Broker')?.is_hand_signed
                                                            ? renderStatus("Listing-Broker", sectionNum)
                                                            : renderAction("Listing-Broker", sectionNum)
                                                        }
                                                    </div>
                                                }

                                                {eDoc && updateDocumentObj && (!isSigned(signers, "Listing-Broker") && signers.find(signer => signer.request_signer.role === 'Listing-Broker')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status != 'Signed') &&
                                                    <div className="col-1">
                                                        <input
                                                            type="checkbox"
                                                            className="signatures_checkbox"
                                                            onChange={(e) => { checkBoxHandler(e, "listing_broker", sectionNum); }}
                                                            checked={payload?.listing_broker?.find((obj) => (obj.document_name == eDoc.task_type && sectionNum == currentSection) ? true : false) || false}
                                                            // disabled={true}
                                                            disabled={
                                                                (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "listing_broker" || sectionNum > currentSection || (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail)
                                                            }
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <FormRow><hr /></FormRow>
                                        </>)
                                    : <></>)
                            }

                            {
                                [LEGAL_OWNER, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED, JOINT_OWNER, OWNER_OF_RECORD].indexOf(dataTransaction?.seller_type) > -1 && (
                                    signerSections['Seller']?.map((sectionNum) => {
                                        return (
                                            <>
                                                {/* {menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail
                                                ? <></>
                                                : */
                                                    <>
                                                        <div className="client-row">
                                                            <Column size="one" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                                <label className={`uk-form-label signer-name-block`}>Seller - <span className="font-weight-bold">Signature Section {sectionNum}</span></label>
                                                                {/* <FormInputWrapper label="Seller" className="signer-name-block"> */}
                                                                {[LEGAL_OWNER, JOINT_OWNER].indexOf(dataTransaction?.seller_type) > -1 && (
                                                                    (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                                                                        ? renderMaskedValue(`${dataSeller?.first_name} ${dataSeller?.last_name}`.length)
                                                                        : `${dataSeller?.first_name} ${dataSeller?.last_name}`
                                                                )}
                                                                {[OWNER_OF_RECORD].indexOf(dataTransaction?.seller_type) > -1 && (
                                                                    (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                                                                        ? renderMaskedValue(`Owner of Record`.length)
                                                                        : `Owner of Record`
                                                                )}
                                                                {[TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1 && (
                                                                    (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                                                                        ? renderMaskedValue(`${dataSeller?.trust_name}`.length)
                                                                        : `${dataSeller?.trust_name}`
                                                                )}
                                                                {!([LEGAL_OWNER, TRUST_OWNED, OWNER_OF_RECORD, JOINT_OWNER, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1) && (
                                                                    `N/A`
                                                                )}
                                                            </Column>
                                                            <FormInputWrapper label="Seller's Email" className="signer-email-block" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                                {[LEGAL_OWNER, OWNER_OF_RECORD, JOINT_OWNER].indexOf(dataTransaction?.seller_type) > -1 && (
                                                                    (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                                                                        ? renderMaskedValue(`${dataSeller?.email}`.length)
                                                                        : `${dataSeller?.email}`
                                                                )}
                                                                {[TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1 && (
                                                                    (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                                                                        ? renderMaskedValue(`${dataSeller?.trust_signer_email}`.length)
                                                                        : `${dataSeller?.trust_signer_email}`
                                                                )}
                                                                {!([LEGAL_OWNER, TRUST_OWNED, LLC_OWNED, JOINT_OWNER, OWNER_OF_RECORD, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1) && (
                                                                    `N/A`
                                                                )}
                                                            </FormInputWrapper>
                                                            {eDoc &&
                                                                <div className={!updateDocumentObj && "signers_div"}>
                                                                    {signers && (
                                                                        signers.find(signer => signer.request_signer.role === 'Seller')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status || signers.find(signer => signer?.request_signer?.role === 'Seller')?.is_hand_signed
                                                                            ? renderStatus("Seller", sectionNum)
                                                                            : renderAction("Seller", sectionNum)
                                                                    )}
                                                                </div>
                                                            }

                                                            {eDoc && updateDocumentObj && (!isSigned(signers, "Seller") && signers.find(signer => signer.request_signer.role === 'Seller')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status != 'Signed') &&
                                                                <div className="col-1">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="signatures_checkbox"
                                                                        onChange={(e) => { checkBoxHandler(e, "seller", sectionNum); }}
                                                                        checked={payload?.seller?.find((obj) => (obj.document_name == eDoc.task_type && sectionNum == obj.section) ? true : false) || false}
                                                                        // disabled={true}
                                                                        disabled={
                                                                            (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "seller" || sectionNum > currentSection || (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                        <FormRow><hr /></FormRow>
                                                    </>
                                                }
                                            </>
                                        )
                                    })
                                )}

                            {
                                dataCoSeller && (
                                    signerSections['Co-Seller']?.map((sectionNum) => {
                                        return (
                                            <>
                                                {/* {menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail ? <></> : */
                                                    <>
                                                        <div className="client-row">
                                                            <Column size="one" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                                <label className={`uk-form-label signer-name-block`}>Co-Seller - <span className="font-weight-bold">Signature Section {sectionNum}</span></label>
                                                                {
                                                                    (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_seller_hide_by_invited_broker)
                                                                        ? renderMaskedValue(`${coSellerName}`.length)
                                                                        : `${coSellerName || "N/A"}`
                                                                }
                                                            </Column>
                                                            {/* <FormInputWrapper label="Co-Seller" className="signer-name-block">{coSellerName || "N/A"}</FormInputWrapper> */}
                                                            <FormInputWrapper label="Co-Seller's Email" className="signer-email-block" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                                {
                                                                    (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_seller_hide_by_invited_broker)
                                                                        ? renderMaskedValue(`${dataCoSeller?.email}`.length)
                                                                        : `${dataCoSeller?.email || "N/A"}`
                                                                }
                                                            </FormInputWrapper>
                                                            {eDoc &&
                                                                <div className={!updateDocumentObj && "signers_div"}>
                                                                    {signers &&
                                                                        signers.find(signer => signer.request_signer.role === 'Co-Seller')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status || signers.find(signer => signer?.request_signer?.role === 'Co-Seller')?.is_hand_signed
                                                                        ? renderStatus("Co-Seller", sectionNum)
                                                                        : renderAction("Co-Seller", sectionNum)
                                                                    }
                                                                </div>
                                                            }

                                                            {eDoc && updateDocumentObj && (!isSigned(signers, "Co-Seller") && signers.find(signer => signer.request_signer.role === 'Co-Seller')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status != 'Signed') &&
                                                                <div className="col-1">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="signatures_checkbox"
                                                                        onChange={(e) => { checkBoxHandler(e, "co_seller", sectionNum); }}
                                                                        checked={payload?.co_seller?.find((obj) => (obj.document_name == eDoc.task_type && sectionNum == currentSection) ? true : false) || false}
                                                                        // disabled={true}
                                                                        disabled={
                                                                            (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "co_seller" || sectionNum > currentSection || (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_seller_hide_by_invited_broker)
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                        <FormRow><hr /></FormRow>
                                                    </>}
                                            </>
                                        )
                                    })
                                )}

                            {
                                // menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail
                                // ? <></>
                                //     :
                                (dataCoBroker?.selling_broker_email || ['selling', 'both'].includes(dataCoBroker?.broker_type)
                                    ? (["Sent", "Signed"].includes(signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `selling_employing_broker`)?.status) || (dataTransaction?.employing_broker && ['selling', 'both'].includes(dataCoBroker?.broker_type)))
                                        && !["Sent", "Signed"].includes(signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === `selling_broker`)?.status)
                                        ?
                                        signerSections['selling-employing-broker']?.map((sectionNum) => <>

                                            <div className="client-row">
                                                {/* <Column size="one" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                        <label className={`uk-form-label signer-name-block`}>Selling Employing Broker - <span className="font-weight-bold">Signature Section {sectionNum}</span></label>
                                                        {dataTransaction?.employing_broker?.email || "N/A"}
                                                    </Column> */}
                                                <Column size="one" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                    <label className={`uk-form-label signer-name-block`}>Selling Employing Broker - <br /> <span className="font-weight-bold">Signature Section {sectionNum}</span></label>
                                                    {getBrokerNameAndEmail('selling-employing-broker').name}
                                                </Column>
                                                <FormInputWrapper label="Selling Employing Broker's Email" className="signer-email-block" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                    {getBrokerNameAndEmail('selling-employing-broker').email}
                                                </FormInputWrapper>

                                                {eDoc &&
                                                    <div className={!updateDocumentObj && "signers_div"}>
                                                        {signers &&
                                                            signers.find(signer => signer.request_signer.role === 'selling-employing-broker')?.section_signers?.find(sectionSigner => (sectionSigner.section == sectionNum))?.status || signers.find(signer => signer?.request_signer?.role === 'selling-employing-broker')?.is_hand_signed
                                                            ? renderStatus("selling-employing-broker", sectionNum)
                                                            : renderAction("selling-employing-broker", sectionNum)
                                                        }
                                                    </div>
                                                }

                                                {eDoc && updateDocumentObj && (!isSigned(signers, "selling-employing-broker") && signers.find(signer => signer.request_signer.role === 'selling-employing-broker')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status != 'Signed') &&
                                                    <div className="col-1">
                                                        <input
                                                            type="checkbox"
                                                            className="signatures_checkbox"
                                                            onChange={(e) => { checkBoxHandler(e, "selling_employing_broker", sectionNum); }}
                                                            checked={payload?.selling_employing_broker?.find((obj) => (obj.document_name == eDoc.task_type && sectionNum == currentSection) ? true : false) || false}
                                                            // disabled={true}
                                                            disabled={
                                                                (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "selling_employing_broker" || sectionNum > currentSection || (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail)
                                                            }
                                                        />
                                                    </div>
                                                }

                                                {/* {eDoc && updateDocumentObj && (!isSigned(signers, "selling-employing-broker") && signers.find(signer => signer.request_signer.role === 'selling-employing-broker')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status != 'Signed') &&
                                                    <div className="col-1">
                                                        <input
                                                            type="checkbox"
                                                            className="signatures_checkbox"
                                                            onChange={(e) => { checkBoxHandler(e, "selling_employing_broker", sectionNum); }}
                                                            checked={payload?.selling_employing_broker?.find((obj) => (obj.document_name == eDoc.task_type && sectionNum == currentSection) ? true : false) || false}
                                                            // disabled={true}
                                                            disabled={
                                                                (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "selling_employing_broker" || sectionNum > currentSection
                                                            }
                                                        />
                                                    </div>
                                                } */}
                                            </div >
                                            <FormRow><hr /></FormRow>
                                        </>)
                                        : signerSections['Selling-Broker']?.map((sectionNum) => <>
                                            <div className="client-row">
                                                <Column size="one" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                    <label className={`uk-form-label signer-name-block`}>Selling Broker -  <br /> <span className="font-weight-bold">Signature Section {sectionNum}</span></label>
                                                    {getBrokerNameAndEmail('selling').name || "N/A"}
                                                </Column>
                                                <FormInputWrapper label="Selling Broker's Email" className="signer-email-block" style={{ opacity: sectionNum > currentSection ? 0.5 : 1 }}>
                                                    {getBrokerNameAndEmail('selling').email || "N/A"}
                                                </FormInputWrapper>
                                                {eDoc &&
                                                    <div className={!updateDocumentObj && "signers_div"}>
                                                        {signers &&
                                                            signers.find(signer => signer.request_signer.role === 'Selling-Broker')?.section_signers?.find(sectionSigner => (sectionSigner.section == sectionNum))?.status || signers.find(signer => signer?.request_signer?.role === 'Selling-Broker')?.is_hand_signed
                                                            ? renderStatus("Selling-Broker", sectionNum)
                                                            : renderAction("Selling-Broker", sectionNum)
                                                        }
                                                    </div>
                                                }

                                                {eDoc && updateDocumentObj && (!isSigned(signers, "Selling-Broker") && signers.find(signer => signer.request_signer.role === 'Selling-Broker')?.section_signers?.find(sectionSigner => sectionSigner.section == sectionNum)?.status != 'Signed') &&
                                                    <div className="col-1">
                                                        <input
                                                            type="checkbox"
                                                            className="signatures_checkbox"
                                                            onChange={(e) => { checkBoxHandler(e, "selling_broker", sectionNum); }}
                                                            checked={payload?.selling_broker?.find((obj) => (obj.document_name == eDoc.task_type && sectionNum == currentSection) ? true : false) || false}
                                                            // disabled={true}
                                                            disabled={
                                                                (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "selling_broker" || sectionNum > currentSection || (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail)
                                                            }
                                                        />
                                                    </div>
                                                }
                                            </div >
                                            <FormRow><hr /></FormRow>
                                        </>)
                                    : <></>)
                            }

                            <br />
                            {
                                deals && deals.requiredFields && !isValidSend(deals.requiredFields) && (
                                    <>
                                        <p>Fill the required fields to send the document for signature.</p>
                                        {/* {deals.requiredFields.vessel.length !== 0 && (
                                        <div className="form-row uk-padding-small">
                                            <label className="uk-form-label" htmlFor="name">
                                                Vessel (Send)
                                            </label>
                                            <div>
                                                {deals.requiredFields.vessel.map((obj, key) => {
                                                    return key === deals.requiredFields.vessel.length - 1
                                                        ? `${Object.values(obj)[0]}`
                                                        : `${Object.values(obj)[0]}, `;
                                                })}
                                            </div>
                                        </div>
                                    )} */}
                                        {deals.requiredFields.buyer && deals.requiredFields.buyer.some(obj => Object.keys(obj).includes('email')) && (
                                            <div className="form-row uk-margin-small">
                                                <label className="uk-form-label" htmlFor="name">
                                                    Buyer (Send)
                                                </label>
                                                <div>
                                                    {deals.requiredFields.buyer.map((obj, key) => {
                                                        return Object.values(obj)[0] == 'Email'
                                                            ? `${Object.values(obj)[0]}`
                                                            : ``;
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                        {dataCoBuyer && deals.requiredFields.co_buyer.some(obj => Object.keys(obj).includes('email')) && (
                                            <div className="form-row uk-margin-small">
                                                <label className="uk-form-label" htmlFor="name">
                                                    Co-Buyer (Send)
                                                </label>
                                                <div>
                                                    {deals.requiredFields.co_buyer.map((obj, key) => {
                                                        return Object.values(obj)[0] == 'Email'
                                                            ? `${Object.values(obj)[0]}`
                                                            : ``;
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                        {dataTransaction?.seller_type !== RECORD_OWNER && deals.requiredFields.seller && deals.requiredFields.seller.some(obj => Object.keys(obj).includes('email')) && (
                                            <div className="form-row uk-margin-small">
                                                <label className="uk-form-label" htmlFor="name">
                                                    Seller (Send)
                                                </label>
                                                <div>
                                                    {deals.requiredFields.seller.map((obj, key) => {
                                                        return Object.values(obj)[0] == 'Email'
                                                            ? `${Object.values(obj)[0]}`
                                                            : ``;
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                        {dataTransaction.owner_or_legal === 1 && dataCoSeller && deals.requiredFields.co_seller.some(obj => Object.keys(obj).includes('email')) && (
                                            <div className="form-row uk-margin-small">
                                                <label className="uk-form-label" htmlFor="name">
                                                    Co-Seller (Send)
                                                </label>
                                                <div>
                                                    {deals.requiredFields.co_seller.map((obj, key) => {
                                                        return Object.values(obj)[0] == 'Email'
                                                            ? `${Object.values(obj)[0]}`
                                                            : ``;
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                        {/* {deals.requiredFields.offer.length !== 0 && (
                                        <div className="form-row uk-margin-small">
                                            <label className="uk-form-label" htmlFor="name">
                                                Offer (Send)
                                            </label>
                                            <div>
                                                {deals.requiredFields.offer.map((obj, key) => {
                                                    return key === deals.requiredFields.offer.length - 1
                                                        ? `${Object.values(obj)[0]}`
                                                        : `${Object.values(obj)[0]}, `;
                                                })}
                                            </div>
                                        </div>
                                    )}
                                    {deals.requiredFields.terms.length !== 0 && (
                                        <div className="form-row uk-margin-small">
                                            <label className="uk-form-label" htmlFor="name">
                                                Terms (Send)
                                            </label>
                                            <div>
                                                {deals.requiredFields.terms.map((obj, key) => {
                                                    return key === deals.requiredFields.terms.length - 1
                                                        ? `${Object.values(obj)[0]}`
                                                        : `${Object.values(obj)[0]}, `;
                                                })}
                                            </div>
                                        </div>
                                    )} */}
                                    </>
                                )
                            }
                        </div >
                        <br />
                    </div >
                </div >
            </div >

            {showAdditionalModal && <SignatureMessageModal
                show={showAdditionalModal}
                onClose={(isEmailSend, emailAdditionalContent = null) => onCloseAdditionalEmailModal(isEmailSend, emailAdditionalContent)}
                emailDetail={emailDetailsToSend}
                onGenerateLink={onGenerateEsignatureLink} />
            }
            {showHandsignModal && <HandSignUploadModal show={showHandsignModal} onClose={onCloseHandsignModal} signData={handSignDetails} />}
            {showDocument && <ViewDocumentsModal documentTask={document} onClose={handleCloseModal} />}
        </>
    )
}


export default EsignNYBAPurchaseAndSales