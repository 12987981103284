import { Link, useLocation } from "react-router-dom";
import {
  useDispatch,
  useSelector
} from 'react-redux';
import * as SideBarUri from "../../constants/SideBarUri";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { clearFilter } from '../../redux/actions/report';

import {
  isAgent,
  isboatdoxAdmin,
  isCompanyAdmin,
  isCompanyAdminAgent,
  isGuestAgent,
  isParticipant,
} from '../../utils/permission';
import { useEffect, useState } from "react";
import TutorialService from "../../services/TutorialService";
import Swal from "sweetalert2";
import UserService from "../../services/UserService";
import Auth from "../../utils/auth";

const SidebarBroker = (props) => {
  const [allVideo, setAllVideo] = useState(null);

  const loggedUser = Auth.getInstance().getUserData();

  const isLoading = useSelector((state) => state.loading);
  let isHidden = useLocation().pathname.split("/").length > 2 ? true : false;
  const uri = useLocation().pathname.split("/")[1];
  const dispatch = useDispatch();

  if (uri === SideBarUri.URI_SIDEBAR_IYBA_DOCUMENT_TASK ||
    uri === SideBarUri.URI_SIDEBAR_COMPANY_DOCUMENT_TASK) {
    isHidden = false;
  }
  useEffect(() => {
    if (uri === SideBarUri.URI_SIDEBAR_TUTORIALS) {
      getAllVideo()
    }
  }, [isLoading.status]);

  useEffect(() => {
    getAllVideo();
  }, []);

  const getAllVideo = () => {
    TutorialService.getAll().then(
      (res) => {
        setAllVideo(res?.data);
      }).catch((err) => {
        console.log(err);
      });
  }

  const RenderActivityLog = () => {
    return (<li className={uri === SideBarUri.URI_SIDEBAR_ACTIVITYLOG && "active"}>
      <Link
        to={{
          pathname: "/activity-log",
          state: { model: "iyba" },
        }}>
        <img
          src={uri === SideBarUri.URI_SIDEBAR_ACTIVITYLOG
            ? "/icons/activity-active.svg"
            : "/icons/activity.svg"
          }
          className="sidebar-svg"
          alt=""
        />
        Activity Log
      </Link>
    </li>)
  }

  const RenderFileLibrary = () => {
    return (<li className={uri === SideBarUri.URI_SIDEBAR_UPLOAD_FILE_MANAGER && "active"}>
      <Link
        to={{
          pathname: "/upload-file-manager",
          state: { model: "iyba" },
        }}>
        <img
          src={uri === SideBarUri.URI_SIDEBAR_UPLOAD_FILE_MANAGER
            ? "/icons/file-manager-active.svg"
            : "/icons/file-manager.svg"
          }
          className="sidebar-svg"
          alt=""
        />
        Upload File Manager
      </Link>
    </li>)
  }

  const handleGuestAgentSignUp = () => {
    Swal.fire({
      // title: 'Are you sure?',
      imageUrl: "/images/boatdox-logo-blue.png",
      imageHeight: 150,
      imageAlt: "Boatdox logo",
      html: "<p>Need to close more deals?</p><h2>Join Boatdox now!</h2><br/><p>Click below to request full access for both you and your brokerage.</p>",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: "Cancel",
      confirmButtonText: 'Submit',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          heightAuto: false,
          didOpen: () => {
            Swal.showLoading();
            const { id: user_id, first_name, last_name, email: user_email, mobile_phone, company_id } = loggedUser;
            UserService.guestAgentSignUpRequest({
              user_id,
              user_name: `${first_name} ${last_name}`,
              user_email,
              mobile_phone,
              company_id,
              company_name: loggedUser.company?.company_name
            }).then((response) => {
              Swal.fire({
                title: "Sent",
                text: "Sign Up request successfully sent to BoatDox.",
                icon: "success"
              })
            }).catch((error) => {
              Swal.fire({
                title: "Error",
                text: "Something went wrong, please try again later.",
                icon: "error"
              })
            })
          }
        })
      }
    });
  }

  if (!isHidden) {
    return (
      <div className="tm-sidebar-left broker">
        <ul className="uk-nav uk-nav-default">
          {!(isParticipant() || isGuestAgent())
            ? <li className={(uri === SideBarUri.URI_SIDEBAR_DASHBOARD ? 'active' : "")}>
              <Link to={"/dashboard"}>
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_DASHBOARD
                    ? '/icons/dashboard-active.svg'
                    : '/icons/dashboard.svg'
                  }
                  className="sidebar-svg"
                  alt=""
                />
                Dashboard
              </Link>
            </li>
            : <></>
          }
          {!(isParticipant() || isGuestAgent())
            ? <> <li className={uri === SideBarUri.URI_SIDEBAR_LISTING_AGREEMENTS ? 'active' : ""}>
              <Link to={"/listing-agreement"}>
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_LISTING_AGREEMENTS
                    ? '/icons/listing-agreements-active.svg'
                    : '/icons/listing-agreements.svg'
                  }
                  className="sidebar-svg"
                  alt=""
                />
                Listing Agreements
              </Link>
            </li>
              {allVideo?.listing_agreements_main_menu && <li className="pl-4 py-1" >
                <a href={allVideo?.listing_agreements_main_menu} target="_blank" rel="noreferrer" style={{ fontSize: "12px" }} className="d-flex align-items-center p-0">
                  <PlayCircleOutlineIcon style={{ cursor: "pointer" }} fontSize="small" className="mr-1" />
                  LA Tutorials
                </a>
              </li>}
            </>
            : <></>
          }
          {!isGuestAgent() && <> <li className={uri === SideBarUri.URI_SIDEBAR_DEALS ? 'active' : ""}>
            <Link to={"/deals"}>
              <img
                src={uri === SideBarUri.URI_SIDEBAR_DEALS
                  ? '/icons/deals-active.svg'
                  : '/icons/deals.svg'
                }
                className="sidebar-svg"
                alt=""
              />
              Deals
            </Link>
          </li>
            {allVideo?.deal_main_menu && <li className="pl-4 py-1">
              <a href={allVideo?.deal_main_menu} target="_blank" rel="noreferrer" style={{ fontSize: "12px" }} className="d-flex align-items-center p-0">
                <PlayCircleOutlineIcon style={{ cursor: "pointer" }} fontSize="small" className="mr-1" />
                Deal Tutorials
              </a>
            </li>}
          </>

          }
          {isAgent() || isGuestAgent() || isCompanyAdminAgent()
            ? <li className={uri === SideBarUri.URI_SIDEBAR_INVITATION_DEALS ? 'active' : ""}>
              <Link to={"/invitation-deals"}>
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_INVITATION_DEALS
                    ? '/icons/deals-active.svg'
                    : '/icons/deals.svg'
                  }
                  className="sidebar-svg"
                  alt=""
                />
                Invitation Deals
              </Link>
            </li>
            : <></>
          }
        </ul>
        {!(isParticipant() || isGuestAgent())
          ? <ul className="uk-nav uk-nav-default tm-nav">
            <li className="uk-nav-header side-head">Reports</li>
            <li className={uri === SideBarUri.URI_SIDEBAR_REPORTS_DEALS ? 'active' : ""}>
              <Link to={"/reports/deals"} onClick={() => dispatch(clearFilter())}>
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_REPORTS_DEALS
                    ? '/icons/reports-active.svg'
                    : '/icons/reports.svg'
                  }
                  className="sidebar-svg"
                  alt=""
                />
                Deals
              </Link>
            </li>
            <li className={uri === SideBarUri.URI_SIDEBAR_REPORTS_BROKERS ? 'active' : ""}>
              <Link to={"/reports/listing-agreements"} onClick={() => dispatch(clearFilter())}>
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_REPORTS_BROKERS
                    ? '/icons/reports-active.svg'
                    : '/icons/reports.svg'
                  }
                  className="sidebar-svg"
                  alt=""
                />
                Listing Agreements
              </Link>
            </li>
            <li className={uri === SideBarUri.URI_SIDEBAR_VESSELS ? 'active' : ""}>
              <Link to={"/vessels"}>
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_VESSELS
                    ? '/icons/vessels-active.svg'
                    : '/icons/vessels.svg'
                  }
                  className="sidebar-svg"
                  alt=""
                />
                MLS Vessels
              </Link>
            </li>
            <li className={uri === SideBarUri.URI_SIDEBAR_CLIENTS ? 'active' : ""}>
              <Link to={"/clients"}>
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_CLIENTS
                    ? '/icons/clients-active.svg'
                    : '/icons/clients.svg'
                  }
                  className="sidebar-svg"
                  alt=""
                />
                Client CRM
              </Link>
            </li>
          </ul>
          : <></>
        }

        <ul className="uk-nav uk-nav-default tm-nav">
          <li className="uk-nav-header side-head">Settings</li>
          {!(isParticipant() || isGuestAgent()) &&
            <>
              {isCompanyAdmin() && (
                <>
                  <li className={uri === SideBarUri.URI_SIDEBAR_COMPANY_DOCUMENT_TASK ? 'active' : ""}>
                    <Link
                      to={{
                        pathname: "/company-document-task",
                        state: { model: "company" },
                      }}
                    >
                      <img
                        src={uri === SideBarUri.URI_SIDEBAR_COMPANY_DOCUMENT_TASK
                          ? '/icons/documents-and-tasks-active.svg'
                          : '/icons/documents-and-tasks.svg'
                        }
                        className="sidebar-svg"
                        alt=""
                      />
                      Uploads List &amp; Tasks
                    </Link>
                  </li>
                </>
              )}
              {(isboatdoxAdmin() || isCompanyAdmin()) && (
                <>
                  {/*              
              {url === SideBarUri.URI_SIDEBAR_EMAIL_TEMPLATES ? (
                <li className="active">
                  <Link  to={"/email-templates"}>
                    <img
                      src="/icons/email-templates-active.svg"
                      className="sidebar-svg"
                      alt=""
                    />
                    Email Templates
                  </Link>
                </li>
              ) : (
                <li>
                  <Link  to={"/email-templates"}>
                    <img
                      src="/icons/email-templates.svg"
                      className="sidebar-svg"
                      alt=""
                    />
                    Email Templates
                  </Link>
                </li>
              )}
              */}
                  <li className={uri === SideBarUri.URI_SIDEBAR_OFFICES ? 'active' : ""}>
                    <Link to={"/offices"}>
                      <img
                        src={uri === SideBarUri.URI_SIDEBAR_OFFICES
                          ? '/icons/offices-active.svg'
                          : '/icons/offices.svg'
                        }
                        className="sidebar-svg"
                        alt=""
                      />
                      Offices
                    </Link>
                  </li>
                  <li className={uri === SideBarUri.URI_SIDEBAR_USERS ? 'active' : ""}>
                    <Link to={"/users"}>
                      <img
                        src={uri === SideBarUri.URI_SIDEBAR_USERS
                          ? '/icons/users-active.svg'
                          : '/icons/users.svg'
                        }
                        className="sidebar-svg"
                        alt=""
                      />
                      Users
                    </Link>
                  </li>
                </>
              )}
            </>
          }
          <li className={uri === SideBarUri.URI_SIDEBAR_PROFILE ? 'active' : ""}>
            <Link to={"/profile"}>
              <img
                src={uri === SideBarUri.URI_SIDEBAR_PROFILE
                  ? '/icons/users-active.svg'
                  : '/icons/users.svg'
                }
                className="sidebar-svg"
                alt=""
              />
              Profile
            </Link>
          </li>
          {isGuestAgent() &&
            <li>
              <a onClick={() => handleGuestAgentSignUp()}>
                <img
                  src='/icons/signup.svg'
                  className="sidebar-svg"
                  alt=""
                />
                Sign Up
              </a>
            </li>
          }
          {!(isParticipant() || isGuestAgent()) && <>
            {(isCompanyAdmin()) && (
              <>
                <li className={uri === SideBarUri.URI_SIDEBAR_BROKERAGE ? 'active' : ""}>
                  <Link to={"/brokerage"}>
                    <img
                      src={uri === SideBarUri.URI_SIDEBAR_BROKERAGE
                        ? '/icons/users-active.svg'
                        : '/icons/users.svg'
                      }
                      className="sidebar-svg"
                      alt=""
                    />
                    Brokerage
                  </Link>
                </li>
              </>
            )}

            <li>
              <a onClick={() => props.showMyOffice(true)}>
                <img
                  src="/icons/offices.svg"
                  className="sidebar-svg"
                  alt=""
                />
                My Office
              </a>
            </li>
            {/* render activity block */}
            {isboatdoxAdmin() && <RenderActivityLog />}
            {isCompanyAdmin() && <li className={uri === SideBarUri.URI_SIDEBAR_LIBRARY_MANAGER && "active"}>
              <Link
                to={{
                  pathname: "/form-library-manager",
                  state: { model: "iyba" },
                }}>
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_LIBRARY_MANAGER
                    ? "/icons/email-templates-active.svg"
                    : "/icons/email-templates.svg"
                  }
                  className="sidebar-svg"
                  alt=""
                />
                Form Library Manager
              </Link>
            </li>
            }

            {/* render file library block  */}
            {isCompanyAdmin() && <RenderFileLibrary />}
          </>
          }
        </ul>

        {isboatdoxAdmin() === true && (
          <ul className="uk-nav uk-nav-default tm-nav">
            <li className="uk-nav-header side-head">Boatdox Admin Only</li>
            <li className={uri === SideBarUri.URI_SIDEBAR_COMPANIES ? 'active' : ""}>
              <Link to={"/companies"}>
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_COMPANIES
                    ? "/icons/companies-active.svg"
                    : "/icons/companies.svg"}
                  className="sidebar-svg"
                  alt=""
                />
                Companies
              </Link>
            </li>
            <li className={uri === SideBarUri.URI_SIDEBAR_IYBA_DOCUMENT_TASK ? 'active' : ""}>
              <Link
                to={{
                  pathname: "/iyba-document-task",
                  state: { model: "iyba" },
                }}
              >
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_IYBA_DOCUMENT_TASK
                    ? '/icons/documents-and-tasks-active.svg'
                    : '/icons/documents-and-tasks.svg'
                  }
                  className="sidebar-svg"
                  alt=""
                />
                Uploads List &amp; Tasks
              </Link>
            </li>

            {/* {uri === SideBarUri.URI_SIDEBAR_EDOC_TEMPLATES ? (
              <li className="active">
                <Link 
                  to={{
                    pathname: "/edoc-templates",
                    state: { model: "iyba" },
                  }}
                >
                  <img
                    src="/icons/email-templates-active.svg"
                    className="sidebar-svg"
                    alt=""
                  />
                  eDocument Templates
                </Link>
              </li>
            ) : (
              <li>
                <Link 
                  to={{
                    pathname: "/edoc-templates",
                    state: { model: "iyba" },
                  }}
                >
                  <img
                    src="/icons/email-templates.svg"
                    className="sidebar-svg"
                    alt=""
                  />
                  eDocument Templates
                </Link>
              </li>
            )} */}

            <li className={uri === SideBarUri.URI_SIDEBAR_ESIGN_TEMPLATES && "active"}>
              <Link
                to={{
                  pathname: "/esign-templates",
                  state: { model: "iyba" },
                }}>
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_ESIGN_TEMPLATES
                    ? "/icons/email-templates-active.svg"
                    : "/icons/email-templates.svg"
                  }
                  className="sidebar-svg"
                  alt=""
                />
                E-Sign Templates
              </Link>
            </li>
            <li className={uri === SideBarUri.URI_SIDEBAR_TUTORIALS && "active"}>
              <Link
                to={{
                  pathname: "/tutorials",
                  state: { model: "iyba" },
                }}>
                <img
                  src={uri === SideBarUri.URI_SIDEBAR_TUTORIALS
                    ? "/icons/video-player-active.svg"
                    : "/icons/video-player.svg"
                  }
                  className="sidebar-svg"
                  alt=""
                />
                Tutorials
              </Link>
            </li>
            {/* render activity block */}
            <RenderActivityLog />
            {/* render file library block  */}
            <RenderFileLibrary />
            {/* {url === SideBarUri.URI_SIDEBAR_DOCUMENT_TEMPLATES ? (
              <li className="active">
                <Link 
                  to={{
                    pathname: "/document-templates",
                    state: { model: "iyba" },
                  }}
                >
                  <img
                    src="/icons/email-templates-active.svg"
                    className="sidebar-svg"
                    alt=""
                  />
                  Document Templates
                </Link>
              </li>
            ) : (
              <li>
                <Link 
                  to={{
                    pathname: "/document-templates",
                    state: { model: "iyba" },
                  }}
                >
                  <img
                    src="/icons/email-templates.svg"
                    className="sidebar-svg"
                    alt=""
                  />
                  Document Templates
                </Link>
              </li>
            )} */}
          </ul>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default SidebarBroker;
